<template>
	<dialog-form
		ref="form"
		:value="value"
		@input="$emit('input', $event)"
		:title="$localize('CreateOffer.title')"
		@commit="save"
		:allow-continue="false"
		@reset="createOffer = { displayGrandTotal: true }"
		:loading="loading"
		:action-text="$localize('CreateOffer.button.createOffer')"
	>
		<select-address
			v-if="project.relationId"
			:relation-id="project.relationId"
			v-model="createOffer.addressId"
			:select-first="true"
			:required="false"
		/>

		<date-select
			v-model="createOffer.offerDate"
			:label="$localize('Offer.created')"
		/>
		<date-select
			v-model="createOffer.expires"
			:label="$localize('Offer.expires')"
		/>

		<v-switch
			v-model="createOffer.displayGrandTotal"
			:label="$localize('Offer.displayGrandTotal')"
		/>
		<template v-slot:actions-right>
			<v-btn class="mt-3" @click="downloadExample">
				Voorbeeld
				<v-icon class="red--text">mdi-adobe-acrobat</v-icon>
			</v-btn>
		</template>
	</dialog-form>
</template>

<script>
import DialogForm from '@/shared/components/DialogForm'
import DateSelect from '@/shared/components/DateSelect'

import sendCommand from '@/services/sendCommand'
import { isGuid } from '@/services/validation'
import SelectAddress from '@/main/components/forms/SelectAddress'
import { getProjectById, getExampleOffer } from '@/services/api'
import { format, addDays } from 'date-fns'
export default {
	components: { DialogForm, DateSelect, SelectAddress },
	props: {
		value: {
			type: Boolean,
			default: false,
		},
		projectId: {
			validator: isGuid,
			required: true,
		},
	},

	data() {
		return {
			loading: false,
			project: {},
			createOffer: {},
		}
	},
	async created() {
		this.loading = true
		const { result, ok } = await getProjectById.call({
			args: { projectId: this.projectId },
		})
		if (ok) {
			this.project = result
			this.createOffer = {
				offerDate: format(new Date(), 'yyyy-MM-dd'),
				expires: format(
					addDays(new Date(), this.$settings.offerValidityDays || 30),
					'yyyy-MM-dd'
				),
				displayGrandTotal: true,
				addressId: this.project.offerAddressId,
			}
		}
		this.loading = false
	},
	methods: {
		async save(callback) {
			this.createOffer.relationId = this.project.relationId
			this.createOffer.projectId = this.projectId
			const result = await sendCommand('CreateOffer', this.createOffer)

			if (result.success) {
				this.$emit('saved')
			}
			callback({ success: result.success })
		},
		downloadExample() {
			if (!this.$refs.form.$refs.form.validate()) {
				return
			}
			var downloadUrl = getExampleOffer.create({
				projectId: this.projectId,
			}).url
			downloadUrl += `?showGrandTotal=${this.createOffer.displayGrandTotal}&offerDate=${this.createOffer.offerDate}&expires=${this.createOffer.expires}`
			if (this.createOffer.addressId) {
				downloadUrl += `&addressId=${this.createOffer.addressId}`
			}
			window.location = downloadUrl
		},
	},
}
</script>
