<template>
	<span v-if="!prices">-</span>
	<span v-else-if="prices.length === 1" class="primary--text">
		{{ $format.currency(prices[0].price) }}
	</span>
	<div v-else>
		<v-simple-table dense style="width: 100%">
			<template v-slot:default>
				<thead>
					<tr>
						<th
							v-if="numRelationGroups > 1"
							style="width: 10%"
						></th>
						<th
							v-for="b in breakpoints"
							:key="b.min"
							class="text-center"
						>
							<template v-if="b.max">
								{{ b.min }} - {{ b.max }}
							</template>
							<template v-else> {{ b.min }} + </template>
						</th>
					</tr>
				</thead>
				<tbody>
					<template v-for="relationGroup in relationGroups">
						<tr
							v-if="
								relationGroupPrices(
									relationGroup.relationGroupId
								).length > 0
							"
							:key="relationGroup.relationGroupId"
						>
							<th v-if="numRelationGroups > 1">
								{{ relationGroup.name }}
							</th>
							<td
								class="text-center primary--text"
								v-for="price in relationGroupPrices(
									relationGroup.relationGroupId
								)"
								:key="`${relationGroup.relationGroupId}-${price.min}`"
								:colspan="priceCellSpan(price)"
							>
								{{ $format.currency(price.price) }}
							</td>
						</tr>
					</template>
				</tbody>
			</template>
		</v-simple-table>
	</div>
</template>
<script>
import { getRelationGroups } from '@/services/api'
export default {
	components: {},
	props: {
		prices: {
			type: Array,
		},
	},
	data() {
		return {
			relationGroups: [],
		}
	},
	computed: {
		allMinMax() {
			return this.relationGroupBreakpoints(null)
		},
		breakpoints() {
			let minMax = this.allMinMax
			let breaks = []
			for (let index = 0; index < minMax.length; index++) {
				if (index + 2 < minMax.length) {
					breaks.push({
						min: minMax[index],
						max: minMax[index + 1],
					})
					index++
				} else {
					breaks.push({
						min: minMax[index],
					})
				}
			}
			return breaks
		},
		numRelationGroups() {
			let relationGroups = []
			for (let index = 0; index < this.prices.length; index++) {
				let price = this.prices[index]
				if (relationGroups.indexOf(price.relationGroupId) === -1) {
					relationGroups.push(price.relationGroupId)
				}
			}
			return relationGroups.length
		},
	},

	async created() {
		const { result, ok } = await getRelationGroups.call()
		if (ok) {
			this.relationGroups = result
		}
	},

	methods: {
		relationGroupBreakpoints(relationGroupId) {
			let minMax = []

			for (let index = 0; index < this.prices.length; index++) {
				let price = this.prices[index]
				if (
					relationGroupId !== null &&
					price.relationGroupId !== relationGroupId
				) {
					continue
				}
				if (price.min !== null && minMax.indexOf(price.min) === -1) {
					minMax.push(price.min)
				}
				if (price.max !== null && minMax.indexOf(price.max) === -1) {
					minMax.push(price.max)
				}
			}
			minMax.sort((a, b) => a > b)
			return minMax
		},
		relationGroupPrices(relationGroupId) {
			return this.prices.filter(
				(x) => x.relationGroupId === relationGroupId
			)
		},
		priceCellSpan(price) {
			let minMax = this.allMinMax

			let minIndex = minMax.indexOf(price.min)
			if (price.max) {
				// if has max
				// find index of min, max
				// span = diff / 2 + 1
				let maxIndex = minMax.indexOf(price.max)

				return (maxIndex - minIndex) / 2 + 1
			} else {
				return (minMax.length - minIndex + 1) / 2
			}
		},
	},
}
</script>

<style lang="scss" scoped>
tr:hover {
	background: none !important;
}

td:hover {
	background-color: #eee;
}
</style>
