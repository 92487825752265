<template>
    <div>
        <v-data-table
            v-model="selectedArticles"
            :show-select="$vuetify.breakpoint.smAndUp"
            item-key="articleId"
            :items="articles"
            :headers="headers"
            @click:row="navArticle($event)"
            :search="search"
            :loading="isLoading"
            :options.sync="options"
            :footer-props="{
                'items-per-page-options': [20, 30, 40, 50],
            }"
            :server-items-length="totalArticleCount"
            dense
        >
            <template
                v-if="$vuetify.breakpoint.smAndUp"
                v-slot:[`header.hasCalculation`]="{}"
            >
                <v-icon>mdi-calculator</v-icon>
            </template>
            <template v-slot:[`item.price`]="{ item, header }">
                <template v-if="item.prices[header.alias]">
                    {{ $format.currency(item.prices[header.alias][0].price) }}
                </template>
                <template v-else></template>
            </template>
            <template v-slot:[`item.attribute`]="{ item, header }">
                <template v-if="item.attributes[header.alias]">
                    {{ item.attributes[header.alias] }}
                </template>
                <template v-else></template>
            </template>
            <template
                v-if="$vuetify.breakpoint.smAndUp"
                v-slot:[`item.hasCalculation`]="{ item }"
            >
                <template v-if="item.calculations.length > 0">
                    <v-icon class="success--text">mdi-check</v-icon>
                </template>
                <template v-else>
                    <v-icon class="error--text">mdi-close</v-icon>
                </template>
            </template>
            <template v-slot:[`item.supplierName`]="{ item }">
                <relation-link
                    :relation-id="item.supplierId"
                    :text="item.supplierName"
                />
            </template>
            <template v-slot:footer="{}">
                <v-divider />
                <div class="d-flex flex-row py-1">
                    <template
                        v-if="
                            $vuetify.breakpoint.smAndUp &&
                            $session.roles.includes('articles_manage')
                        "
                    >
                        <v-icon class="ml-3"
                            >mdi-subdirectory-arrow-right</v-icon
                        >

                        <v-btn
                            :disabled="selectedArticles.length === 0"
                            class="align-self-center mr-1"
                            @click="bulkLinkCalculations = true"
                        >
                            <v-icon>mdi-calculator</v-icon>
                            <span v-if="$vuetify.breakpoint.mdAndUp">
                                {{
                                    $localize(
                                        'ArticleDataTable.linkCalculations'
                                    )
                                }}
                            </span>
                        </v-btn>
                        <v-btn
                            :disabled="selectedArticles.length === 0"
                            class="align-self-center mr-1"
                            @click="bulkLinkSupplier = true"
                        >
                            <v-icon>mdi-truck</v-icon>
                            <span v-if="$vuetify.breakpoint.mdAndUp">
                                {{ $localize('ArticleDataTable.linkSupplier') }}
                            </span>
                        </v-btn>
                        <v-btn
                            :disabled="selectedArticles.length === 0"
                            class="align-self-center mr-1"
                            @click="bulkUpdateCategory = true"
                        >
                            <v-icon>mdi-group</v-icon>
                            <span v-if="$vuetify.breakpoint.mdAndUp">
                                {{
                                    $localize('ArticleDataTable.updateCategory')
                                }}</span
                            ></v-btn
                        >
                        <v-btn
                            v-if="!showTrash"
                            :disabled="selectedArticles.length === 0"
                            class="align-self-center mr-1"
                            @click="bulkTrash = true"
                        >
                            <v-icon>mdi-delete</v-icon>
                            <span v-if="$vuetify.breakpoint.lgAndUp">
                                {{ $localize('ArticleDataTable.trash') }}</span
                            ></v-btn
                        >
                        <v-btn
                            v-if="showTrash"
                            :disabled="selectedArticles.length === 0"
                            class="align-self-center mr-1"
                            @click="bulkUntrash = true"
                        >
                            <v-icon>mdi-delete-restore</v-icon>
                            <span v-if="$vuetify.breakpoint.lgAndUp">
                                {{
                                    $localize('ArticleDataTable.untrash')
                                }}</span
                            ></v-btn
                        >
                        <v-btn
                            :disabled="selectedArticles.length === 0"
                            class="align-self-center mr-1"
                            @click="bulkDelete = true"
                        >
                            <v-icon>mdi-close</v-icon>
                            <span v-if="$vuetify.breakpoint.lgAndUp">
                                {{ $localize('ArticleDataTable.delete') }}</span
                            ></v-btn
                        >
                    </template>
                    <v-checkbox
                        :label="$localize('form.label.trash')"
                        :input-value="showTrash"
                        @click="navTrash()"
                        class="pt-0 my-0 pr-3 align-self-center ml-auto"
                        hide-details
                    />
                </div>
            </template>
        </v-data-table>
        <v-lazy :value="bulkLinkCalculations">
            <bulk-change-article-calculations
                :article-ids="selectedArticleIds"
                v-model="bulkLinkCalculations"
                @saved="
                    selectedArticles = []
                    refresh(true)
                "
            />
        </v-lazy>
        <v-lazy :value="bulkLinkSupplier">
            <bulk-link-supplier
                :article-ids="selectedArticleIds"
                v-model="bulkLinkSupplier"
                @saved="
                    selectedArticles = []
                    refresh(true)
                "
            />
        </v-lazy>
        <v-lazy :value="bulkDelete">
            <bulk-delete-articles
                :article-ids="selectedArticleIds"
                v-model="bulkDelete"
                @saved="
                    selectedArticles = []
                    refresh(true)
                "
            />
        </v-lazy>
        <v-lazy :value="bulkTrash">
            <bulk-trash-articles
                :article-ids="selectedArticleIds"
                v-model="bulkTrash"
                @saved="
                    selectedArticles = []
                    refresh(true)
                "
            />
        </v-lazy>
        <v-lazy :value="bulkUntrash">
            <bulk-untrash-articles
                :article-ids="selectedArticleIds"
                v-model="bulkUntrash"
                @saved="
                    selectedArticles = []
                    refresh(true)
                "
            />
        </v-lazy>
        <v-lazy :value="bulkUpdateCategory">
            <bulk-update-category
                :article-ids="selectedArticleIds"
                v-model="bulkUpdateCategory"
                @saved="
                    selectedArticles = []
                    refresh(true, true)
                "
            />
        </v-lazy>
    </div>
</template>

<script>
import {
    getAllArticles,
    getPriceTypes,
    getAttributeTypes,
} from '@/services/api'
import BulkChangeArticleCalculations from './dialogs/BulkChangeArticleCalculations.vue'
import BulkLinkSupplier from './dialogs/BulkLinkSupplier.vue'
import BulkDeleteArticles from './dialogs/BulkDeleteArticles.vue'
import BulkTrashArticles from './dialogs/BulkTrashArticles.vue'
import BulkUntrashArticles from './dialogs/BulkUntrashArticles.vue'
import BulkUpdateCategory from './dialogs/BulkUpdateCategory.vue'
import RelationLink from '../components/links/RelationLink'
import PubSub from 'pubsub-js'

export default {
    components: {
        BulkChangeArticleCalculations,
        BulkLinkSupplier,
        BulkDeleteArticles,
        BulkTrashArticles,
        BulkUntrashArticles,
        BulkUpdateCategory,
        RelationLink,
    },
    props: {
        category: String,
        trash: {
            type: String,
            default: '',
        },
    },
    computed: {
        showTrash() {
            return this.trash === 'trash'
        },
        selectedArticleIds() {
            return this.selectedArticles.map((x) => x.articleId)
        },
        currentPage() {
            return parseInt(this.$route.query.page) || 1
        },
        headers() {
            if (this.$vuetify.breakpoint.smAndUp) {
                return this.allHeaders
            }
            // Remove first header
            return this.allHeaders.slice(1)
        },
    },
    data() {
        return {
            articles: [],
            priceTypes: {},
            attributeTypes: {},
            allHeaders: [
                {
                    value: 'hasCalculation',
                    sortable: false,
                    width: 24,
                },
                { text: this.$localize('Article.brand'), value: 'brand' },
                { text: this.$localize('Article.name'), value: 'name' },
                {
                    text: this.$localize('Article.supplier'),
                    value: 'supplierName',
                },
            ],
            search: '',
            options: JSON.parse(
                localStorage.getItem('ArticleDataTable:options')
            ) || {
                itemsPerPage: 20,
            },
            totalArticleCount: 0,
            unsubscribe: null,
            isLoading: false,
            selectedArticles: [],

            bulkLinkCalculations: false,
            bulkLinkSupplier: false,
            bulkUpdateCategory: false,
            bulkTrash: false,
            bulkUntrash: false,
            bulkDelete: false,

            previousOptions: null,
        }
    },
    watch: {
        category: {
            handler() {
                this.options.page = 1
                this.refresh()
            },
        },
        trash: {
            handler() {
                this.options.page = 1
                this.refresh()
            },
        },
        options(options, oldOptions) {
            localStorage.setItem(
                'ArticleDataTable:options',
                JSON.stringify(this.options)
            )

            if (this.options.page !== this.currentPage) {
                // if diffrent page, go to that page
                this.$router.push({
                    query: {
                        page: this.options.page,
                    },
                })
            }
            if (
                oldOptions.page !== options.page ||
                oldOptions.itemsPerPage !== options.itemsPerPage ||
                oldOptions.sortBy !== options.sortBy ||
                oldOptions.sortDesc !== options.sortDesc
            ) {
                this.refresh()
            }
        },
    },
    async created() {
        await this.loadPriceTypes()
        await this.loadAttributeTypes()

        this.unsubscribe = PubSub.subscribe('search', (message, data) => {
            if (data.mode === 'page') {
                if (this.search != data.keyword) {
                    this.search = data.keyword
                    this.options.page = 1
                }
            } else {
                this.search = ''
            }
            this.refresh()
        })
        PubSub.publish('searchstatus')
    },
    destroyed() {
        PubSub.unsubscribe(this.unsubscribe)
    },
    methods: {
        async refresh(force, refreshCategories) {
            const category = this.category || '$$all'
            const opts = {
                page: this.options.page,
                itemsPerPage: this.options.itemsPerPage,
                sortBy: this.options.sortBy,
                sortDesc: this.options.sortDesc,
                trash: this.showTrash,
                category: category,
                search: this.search,
            }

            if (
                !force &&
                this.previousOptions !== null &&
                opts.trash === this.previousOptions.trash &&
                opts.search === this.previousOptions.search &&
                opts.page === this.previousOptions.page &&
                opts.itemsPerPage === this.previousOptions.itemsPerPage &&
                this.arraysEqual(opts.sortBy, this.previousOptions.sortBy) &&
                this.arraysEqual(
                    opts.sortDesc,
                    this.previousOptions.sortDesc
                ) &&
                category === this.previousCategory
            ) {
                return // Same options
            }
            let cancelLoading = setTimeout(() => {
                this.isLoading = true
            }, 200)
            this.previousCategory = category
            this.previousOptions = opts
            const { result, ok } = await getAllArticles.call({ q: opts })

            if (ok) {
                this.totalArticleCount = result.totalItemCount
                this.articles = result.items

                let nextHeaderIndex = 4
                if (category !== '$$all') {
                    // Find all unique attributes/prices on all loaded articles
                    for (let priceType in this.priceTypes) {
                        for (let articleIndex in this.articles) {
                            let article = this.articles[articleIndex]
                            if (article.prices[priceType] !== undefined) {
                                this.allHeaders[nextHeaderIndex++] = {
                                    text: this.priceTypes[priceType],
                                    value: `price`,
                                    alias: priceType,
                                    sortable: false,
                                }
                                break
                            }
                        }
                    }
                    for (let attributeType in this.attributeTypes) {
                        for (let articleIndex in this.articles) {
                            let article = this.articles[articleIndex]
                            if (
                                article.attributes[attributeType] !== undefined
                            ) {
                                this.allHeaders[nextHeaderIndex++] = {
                                    text: this.attributeTypes[attributeType],
                                    value: `attribute`,
                                    alias: attributeType,
                                    sortable: false,
                                }
                                break
                            }
                        }
                    }
                }
                // Cleanup old unused headers
                this.allHeaders.splice(
                    nextHeaderIndex,
                    this.headers.length - nextHeaderIndex
                )
            }

            if (cancelLoading) {
                clearTimeout(cancelLoading)
                this.isLoading = false
            }
            localStorage.setItem(
                'ArticleOverview:category',
                JSON.stringify(this.category)
            )
            if (refreshCategories) {
                this.$emit('refresh')
            }
        },
        async loadPriceTypes() {
            const { result, ok } = await getPriceTypes.call()
            if (ok) {
                this.priceTypes = {}
                result.map((x) => (this.priceTypes[x.alias] = x.displayName))
            }
        },
        async loadAttributeTypes() {
            const { result, ok } = await getAttributeTypes.call()
            if (ok) {
                this.attributeTypes = {}
                result.map(
                    (x) => (this.attributeTypes[x.alias] = x.displayName)
                )
            }
        },

        navArticle(article) {
            this.$router.push({
                name: 'articleDetails',
                params: { articleId: article.articleId },
            })
        },

        navTrash() {
            var params = {
                category: this.category,
            }
            if (!this.trash) {
                params.trash = 'trash'
            }
            this.selectedArticles = []
            this.$router.push({
                name: 'articleOverview',
                params: params,
            })
        },
        arraysEqual(a, b) {
            if (a === b) return true
            if (a == null || b == null) return false
            if (a.length !== b.length) return false

            for (var i = 0; i < a.length; ++i) {
                if (a[i] !== b[i]) return false
            }
            return true
        },
    },
}
</script>

<style></style>
