<template>
	<dialog-form
		:value="value"
		@input="$emit('input', $event)"
		:title="$localize('DeleteArticle.title')"
		action="delete"
		@commit="save"
		:loading="loading"
	>
		<p>{{ $localize('DeleteArticle.description') }}</p>
		<p>
			<b>{{ article.name }}</b>
		</p>
		<p>{{ $localize('form.description.actionIsPermanent') }}</p>
	</dialog-form>
</template>

<script>
import DialogForm from '@/shared/components/DialogForm'
import sendCommand from '@/services/sendCommand'
import { isGuid } from '@/services/validation'
import { getArticleById } from '@/services/api'

export default {
	components: { DialogForm },
	props: {
		articleId: {
			required: true,
			validator: isGuid,
		},
		value: {
			type: Boolean,
			default: false,
		},
	},

	data() {
		return {
			loading: false,
			article: {},
		}
	},
	async created() {
		this.loading = true
		const { result, ok } = await getArticleById.call({
			args: { articleId: this.articleId },
		})
		if (ok) {
			this.article = result
		}
		this.loading = false
	},
	methods: {
		async save(callback) {
			const result = await sendCommand('DeleteArticle', {
				articleId: this.articleId,
			})

			if (result.success) {
				this.$emit('saved')
			}
			callback({ success: result.success })
		},
	},
}
</script>
