<template>
	<div>
		<v-text-field
			class="email-field"
			filled
			:label="$localize('Vat.label')"
			:value="inputPreview"
			:readonly="true"
			@click="editing = true"
			prepend-icon="mdi-currency-eur"
		/>
		<dialog-form
			v-model="editing"
			:title="$localize('Vat.title')"
			@commit="save"
			:allow-continue="false"
			:allow-cancel="false"
			:action="null"
			max-width="700"
		>
			<v-btn @click="creating = true">{{
				$localize('Vat.create')
			}}</v-btn>
			<v-data-table
				:items="vat.items"
				:headers="headers"
				:footer-props="{ 'items-per-page-options': [10, 20, 50, -1] }"
				dense
				disable-pagination
				disable-sort
			>
				<template v-slot:[`item.vatPercentage`]="{ item }">
					{{ item.vatPercentage }}%
				</template>
				<template v-slot:[`item.startAt`]="{ item }">
					{{ $format.shortDate(item.startAt) }}
				</template>
				<template v-slot:[`item.isDefault`]="{ item }">
					<template v-if="item.isDefault">
						<v-icon class="success--text">mdi-check</v-icon>
					</template>
					<template v-else>
						<v-icon class="error--text">mdi-close</v-icon>
					</template>
				</template>
				<template v-slot:[`item.actions`]="{ item }">
					<v-btn
						small
						@click="
							deletingVatId = item.vatId
							deleting = true
						"
						>{{ $localize('form.button.delete') }}</v-btn
					>
				</template>
			</v-data-table>
			<template v-slot:actions-right>
				<v-btn class="mt-3" @click="editing = false"> Ok </v-btn>
			</template>
		</dialog-form>
		<create-vat v-model="creating" @saved="refresh" />
		<delete-vat
			v-model="deleting"
			:vat-id="deletingVatId"
			@saved="refresh"
		/>
	</div>
</template>

<script>
import DialogForm from '@/shared/components/DialogForm'
import CreateVat from './CreateVat'
import DeleteVat from './DeleteVat'

import { getAllVat } from '@/services/api'

export default {
	components: { DialogForm, CreateVat, DeleteVat },
	props: {},
	data() {
		return {
			editing: false,
			creating: false,
			deleting: false,
			deletingVatId: null,
			vat: [],
			headers: [
				{
					text: this.$localize('Vat.amount'),
					value: 'vatPercentage',
				},
				{
					text: this.$localize('Vat.startAt'),
					value: 'startAt',
				},
				{
					text: this.$localize('Vat.invoiceText'),
					value: 'invoiceText',
				},
				{
					text: this.$localize('Vat.isDefault'),
					value: 'isDefault',
					width: 100,
				},
				{
					value: 'actions',
					width: 50,
				},
			],
		}
	},
	computed: {
		inputPreview() {
			if (this.vat.default) {
				return `${this.vat.default.vatPercentage}%`
			}
			return this.$localize('Vat.none')
		},
	},
	created() {
		this.refresh()
	},
	methods: {
		async refresh() {
			const { result, ok } = await getAllVat.call()

			if (ok) {
				this.vat = result
			}
		},

		save(callback) {
			this.$emit('input', JSON.stringify(this.data))
			callback({ success: true })
		},
	},
}
</script>
