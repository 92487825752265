<template>
	<div>
		<v-navigation-drawer
			id="main-navigation"
			app
			v-model="drawer"
			:mini-variant="useMini"
			:permanent="useMini"
		>
			<template v-slot:prepend>
				<div
					class="user-button"
					:style="{ height: appBarHeight + 'px' }"
				>
					<v-list-item
						two-line
						:to="navToSelf"
						dark
						:class="mini && 'px-2'"
						:style="{ height: appBarHeight + 'px' }"
					>
						<v-list-item-avatar :color="user.color">{{
							initials
						}}</v-list-item-avatar>

						<v-list-item-content>
							<v-list-item-title>{{
								user.name
							}}</v-list-item-title>
							<v-list-item-subtitle>{{
								user.login
							}}</v-list-item-subtitle>
						</v-list-item-content>
					</v-list-item>
				</div>
			</template>
			<navigation-drawer :mini="useMini" />

			<v-tooltip v-if="$vuetify.breakpoint.mdAndUp" top>
				<template v-slot:activator="{ on }">
					<v-btn
						id="btnNavAccount"
						fab
						small
						absolute
						class="nav-drawer-switcher-mini mr-2 mb-2"
						@click="mini = !mini"
						v-on="on"
					>
						<v-icon v-if="!mini">mdi-chevron-left</v-icon>
						<v-icon v-else>mdi-chevron-right</v-icon>
					</v-btn>
				</template>
				<span v-if="mini">{{
					$localize('AppBar.button.bigVariant')
				}}</span>
				<span v-else>{{ $localize('AppBar.button.miniVariant') }}</span>
			</v-tooltip>
		</v-navigation-drawer>

		<v-app-bar
			app
			flat
			extended
			clipped-right
			:extension-height="extensionHeight"
			id="main-toolbar"
			class="primary"
			:height="appBarHeight"
		>
			<v-app-bar-nav-icon
				id="btnMenu"
				v-if="!useMini"
				@click="toggleMenu"
			/>
			<v-toolbar-items>
				<v-btn
					v-if="$session.roles.includes('projects_manage')"
					id="btnCreateProject"
					class="app-button ml-l"
					text
					@click="createProject = true"
					:width="appBarHeight"
					title="hotkey: Alt-n"
				>
					<v-icon x-large>mdi-plus-circle-outline</v-icon>
					<div v-if="showAppBarTitles">
						{{ $localize('AppBar.newProject') }}
					</div>
				</v-btn>

				<template v-if="$vuetify.breakpoint.mdAndUp">
					<v-btn
						v-if="$session.roles.includes('projects_view')"
						class="app-button"
						:class="{ 'v-btn--active': isActive('request') }"
						text
						:to="{
							name: 'projectOverview',
							params: { phase: 'request' },
						}"
					>
						<v-icon x-large>mdi-file-document-outline</v-icon>
						<div v-if="showAppBarTitles">
							{{ $localize('Project.phaseRequests') }}
						</div>
					</v-btn>

					<v-btn
						v-if="$session.roles.includes('projects_view')"
						id="btnOffers"
						class="app-button"
						:class="{ 'v-btn--active': isActive('offer') }"
						text
						:to="{
							name: 'projectOverview',
							params: { phase: 'offer' },
						}"
					>
						<v-icon x-large>mdi-hammer-wrench</v-icon>
						<div v-if="showAppBarTitles">
							{{ $localize('Project.phaseOffers') }}
						</div>
					</v-btn>

					<v-btn
						v-if="$session.roles.includes('projects_view')"
						id="btnOrders"
						class="app-button"
						:class="{ 'v-btn--active': isActive('order') }"
						text
						:to="{
							name: 'projectOverview',
							params: { phase: 'order' },
						}"
					>
						<v-icon x-large>mdi-handshake</v-icon>
						<div v-if="showAppBarTitles">
							{{ $localize('Project.phaseOrders') }}
						</div>
					</v-btn>

					<v-btn
						v-if="$session.roles.includes('projects_view')"
						id="btnInvoices"
						class="app-button"
						:class="{ 'v-btn--active': isActive('invoice') }"
						text
						:to="{
							name: 'projectOverview',
							params: { phase: 'invoice' },
						}"
					>
						<v-icon x-large>mdi-cash-register</v-icon>
						<div v-if="showAppBarTitles">
							{{ $localize('Project.phaseInvoices') }}
						</div>
					</v-btn>

					<v-btn
						v-if="$session.roles.includes('projects_view')"
						id="btnArchive"
						class="app-button"
						:class="{ 'v-btn--active': isActive('archive') }"
						text
						:to="{
							name: 'projectOverview',
							params: { phase: 'archive' },
						}"
					>
						<v-icon x-large>mdi-archive</v-icon>
						<div v-if="showAppBarTitles">
							{{ $localize('Project.archive') }}
						</div>
					</v-btn>
				</template>

				<v-btn
					id="btnCalendar"
					v-if="
						$session.roles.includes('planning_view')
					"
					class="app-button"
					text
					:to="{ name: 'calendar' }"
				>
					<v-icon x-large>mdi-calendar-month</v-icon>
					<div v-if="showAppBarTitles">
						{{ $localize('NavigationDrawer.link.calendar') }}
					</div>
				</v-btn>
				<v-btn
					id="btnActionList"
					v-if="$vuetify.breakpoint.smAndUp && $session.roles.includes('projects_view')"
					class="app-button"
					text
					:to="{ name: 'home' }"
				>
					<v-icon x-large>mdi-bullhorn</v-icon>
					<div v-if="showAppBarTitles">
						{{ $localize('NavigationDrawer.link.actionList') }}
					</div>
				</v-btn>
				<v-btn
					v-if="
						$session.roles.includes('projects_view') ||
						$session.roles.includes('articles_view') ||
						$session.roles.includes('relations_view')
					"
					id="btnSearch"
					class="app-button"
					text
					@click="showSearchBar = !showSearchBar"
					title="hotkey: /"
				>
					<v-icon x-large>mdi-magnify</v-icon>
					<div v-if="showAppBarTitles">
						{{ $localize('form.label.search') }}
					</div>
				</v-btn>
			</v-toolbar-items>
			<v-spacer></v-spacer>
			<v-toolbar-items>
				<v-menu v-if="$session.roles.includes('projects_view')">
					<template v-slot:activator="{ on }">
						<v-btn
							id="btnDashboards"
							class="app-button"
							text
							v-on="on"
						>
							<v-icon x-large>mdi-monitor-dashboard</v-icon>
							<div v-if="showAppBarTitles">
								{{
									$localize(
										'NavigationDrawer.link.dashboards'
									)
								}}
							</div>
						</v-btn>
					</template>

					<dashboard-menu />
				</v-menu>
				<v-menu
                    v-if="$session.roles.includes('projects_calculate')"
					eager
				>
					<template v-slot:activator="{ on }">
						<v-btn
							id="btnCalculate"
							class="app-button"
							text
							v-on="on"
						>
							<v-icon x-large>mdi-calculator</v-icon>
							<div v-if="showAppBarTitles">
								{{
									$localize('NavigationDrawer.link.calculate')
								}}
							</div>
						</v-btn>
					</template>

					<calculations-menu
						@calculate="
							calculationId = $event
							testCalculation = true
						"
					/>
				</v-menu>
			</v-toolbar-items>
			<template v-slot:extension>
				<search-bar v-model="showSearchBar" />
			</template>
		</v-app-bar>

		<create-project v-if="createProject" v-model="createProject" />
		<test-calculation
			v-if="testCalculation"
			v-model="testCalculation"
			:calculation-id="calculationId"
		/>
	</div>
</template>

<script>
import NavigationDrawer from './NavigationDrawer'
import CreateProject from '@/main/projects/dialogs/CreateProject'
import CalculationsMenu from './CalculationsMenu'
import DashboardMenu from './DashboardMenu'
import TestCalculation from '@/main/projects/dialogs/TestCalculation'

import { getUserById } from '@/services/api'
import PubSub from 'pubsub-js'
import SearchBar from './SearchBar'
import { userNameInitials } from '@/services/functions'

export default {
	components: {
		NavigationDrawer,
		CreateProject,
		SearchBar,
		CalculationsMenu,
		DashboardMenu,
		TestCalculation,
	},
	data() {
		return {
			testCalculation: false,
			calculationId: null,
			createProject: false,
			drawer: JSON.parse(localStorage.getItem('AppBar:drawer')) || false,
			user: {},
			navToSelf: null,
			showSearchBar: false,
			activePhase: null,
			unsubscribe1: null,
			unsubscribe2: null,

			mini: JSON.parse(localStorage.getItem('AppBar:mini')) || false,
		}
	},
	computed: {
		useMini() {
			if (this.$vuetify.breakpoint.smAndDown) {
				// never use mini on xs
				return false
			}
			return this.mini
		},
		extensionHeight() {
			if (this.showSearchBar) {
				return '64'
			}
			return '0'
		},
		appBarHeight() {
			if (this.$vuetify.breakpoint.smAndDown) {
				return 56
			}
			return 73
		},
		initials() {
			if (this.user) {
				return userNameInitials(this.user.name)
			}
			return null
		},
		showAppBarTitles() {
			return this.$vuetify.breakpoint.mdAndUp
		},
	},
	watch: {
		drawer() {
			localStorage.setItem('AppBar:drawer', JSON.stringify(this.drawer))
		},
		mini() {
			localStorage.setItem('AppBar:mini', JSON.stringify(this.mini))
		},
	},
	async created() {
		const { result, ok } = await getUserById.call({
			args: { userId: this.$session.userId },
		})

		if (ok) {
			this.user = result
		}
		this.navToSelf = {
			name: 'userDetails',
			params: { userId: this.$session.userId },
		}
		PubSub.publish('search', {
			keyword: this.searchKeyword,
			mode: this.searchMode,
		})
		this.unsubscribe1 = PubSub.subscribe(
			'ActiveProjectPhase',
			(message, data) => {
				this.activePhase = data.phase
			}
		)
		this.unsubscribe2 = PubSub.subscribe('ClearActiveProjectPhase', () => {
			this.activePhase = null
		})
		window.addEventListener('keydown', this.onKeyDown)
	},
	destroyed() {
		PubSub.unsubscribe(this.unsubscribe1)
		PubSub.unsubscribe(this.unsubscribe2)
		window.removeEventListener('keydown', this.onKeyDown)
	},
	methods: {
		isActive(phase) {
			return this.activePhase == phase
		},
		toggleMenu() {
			if (this.mini) {
				this.mini = false
				this.drawer = true
			} else {
				this.drawer = !this.drawer
			}
		},
		onKeyDown(event) {
			if (event.defaultPrevented) {
				return
			}
			if (
				(event.altKey || event.metaKey) &&
				!event.shiftKey &&
				!event.ctrlKey
			) {
				switch (event.which) {
					case 78: // n
						if (!this.createProject) {
							this.createProject = true
							event.preventDefault()
						}
						break
				}
			}
		},
	},
}
</script>
<style lang="scss">
#app.theme--dark {
	.v-app-bar {
		.v-toolbar__extension {
			background-color: #121212;
		}
	}
}

#main-toolbar {
	.v-toolbar__content {
		z-index: 1;

		.v-icon {
			color: white;
		}
	}

	.v-toolbar__extension {
		background-color: #f5f5f5;
		padding: 0;
	}
}

.app-button {
	padding: 0 8px !important;

	span {
		display: flex;
		flex-direction: column;
		align-items: center;

		div {
			color: #90caf9;
			text-transform: none;
			font-weight: normal;
		}
	}

	&.v-btn--active {
		background-color: white;

		/*box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
                0px 5px 8px 0px rgba(0, 0, 0, 0.14), 
                0px 1px 14px 0px rgba(0, 0, 0, 0.12) !important;*/
		span {
			div {
				color: black;
				//font-weight: 500;
			}

			i.v-icon {
				color: black !important;
			}
		}
	}

	&.theme--dark.v-btn--active {
		background-color: black;

		span {
			div {
				color: white;
				//font-weight: 500;
			}

			i.v-icon {
				color: white !important;
			}
		}
	}
}

.user-button {
	background-color: #0277bd;
	height: 73px;

	.v-list-item {
		color: white;

		.v-list-item__title {
			color: white;
			font-weight: 500;
		}

		.v-list-item__subtitle {
			color: #90caf9;
		}

		.v-avatar {
			text-transform: uppercase;
		}

		&.v-btn--active {
			span {
				div {
					color: white;
					font-weight: 500;
				}
			}
		}
	}
}

.nav-drawer-switcher-mini {
	bottom: 0;
	right: 0;
}
</style>
