
import '@mdi/font/css/materialdesignicons.css'
import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify);

import nl from 'vuetify/es5/locale/nl'
import en from 'vuetify/es5/locale/en'

export default new Vuetify({
  lang: {
    locales: {nl, en}
  },
  icons: {
    iconfont: 'mdi'
  },
  theme: {
    themes: {
      light: {
        primary: colors.lightBlue.darken3, // #0277BD
      },
      dark: {
        primary: colors.lightBlue.darken3 // #0277BD
      }
    }
  }
});
