<template>
  <v-text-field
    :label="label || $localize('InputEmail.label')"
    :value="value"
    @input="$emit('input', $event)"
    :rules="rules"
    prepend-icon="mdi-at"
    :autofocus="autofocus"
    v-bind="$attrs"
  />
</template>

<script>
import { required, email, maxLength } from "@/services/validation";
export default {
  props: {
    value: String,
    autofocus: Boolean,
    required: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      rules: [email, maxLength(50)],
    };
  },
  created() {
    if (this.required) {
      this.rules.unshift(required);
    }
  },
};
</script>
