<template>
	<v-dialog
		:value="value"
		@input="$emit('input', $event)"
		:max-width="maxWidth"
		:fullscreen="fullscreen"
		ref="dialog"
	>
		<v-form ref="form" @submit.prevent="commit(true)" :id="id">
			<v-card>
				<v-card-title @mousedown="onStartDrag" @mouseup="onEndDrag">{{
					title
				}}</v-card-title>
				<v-card-text>
					<v-skeleton-loader
						:type="loadingSkeleton"
						:loading="loading"
					>
						<!-- div is needed otherwise the CopyProject.vue dialog wont show content -->
						<div>
							<slot />
						</div>
					</v-skeleton-loader>
				</v-card-text>
				<v-card-actions v-if="!hideActions">
					<v-skeleton-loader type="actions" :loading="loading">
						<v-btn
                            v-if="allowCancel"
							:loading="saving"
							@click="$emit('input', false)"
							class="mt-3 mr-3 btnCancel"
							>{{ $localize('form.button.cancel') }}</v-btn
						>
						<slot name="actions"></slot>
						<v-btn
							v-if="action === 'save'"
							:loading="saving"
							type="submit"
							class="success mt-3 mr-3 btnSave"
						>
							{{ actionText || $localize('form.button.save') }}
							<v-icon v-if="actionIcon" right>{{
								actionIcon
							}}</v-icon>
						</v-btn>
						<v-btn
							:loading="saving"
							v-if="allowContinue && action === 'save'"
							class="success mt-3 mr-3 btnSaveAndContinue"
							@click="commit(false)"
							>{{
								$localize('form.button.saveAndContinue')
							}}</v-btn
						>
						<v-btn
							:loading="saving"
							v-if="action === 'delete'"
							class="error mt-3 mr-3 btnDelete"
							@click="commit(true)"
						>
							{{ actionText || $localize('form.button.delete') }}
							<v-icon v-if="actionIcon" right>{{
								actionIcon
							}}</v-icon>
						</v-btn>
						<div class="float-right">
							<slot name="actions-right"></slot>
						</div>
					</v-skeleton-loader>
				</v-card-actions>
				<v-btn
					class="close-button"
					fab
					text
					small
					absolute
					@click="$emit('input', false)"
				>
					<v-icon>mdi-close</v-icon>
				</v-btn>
			</v-card>
		</v-form>
	</v-dialog>
</template>

<script>
import { showSnackbar } from '@/services/snackbars'
import backButton from '@/mixins/backButton'

export default {
	mixins: [
		backButton(
			(x) => {
				return !x.isDestroyed && x.value
			},
			(x) => x.$emit('input', false)
		),
	],
	props: {
		value: {
			type: Boolean,
			default: false,
		},
		title: String,
		maxWidth: {
			type: String,
			default: '600',
		},
		fullscreen: {
			type: Boolean,
			default: false,
		},
		allowContinue: {
			type: Boolean,
			default: true,
		},

		allowCancel: {
			type: Boolean,
			default: true,
		},
		/* Can be: save, delete, none */
		action: {
			type: String,
			default: 'save',
		},
		actionText: {
			type: String,
		},
		actionIcon: {
			type: String,
		},
		/* Optional: the commit message to show after commiting */
		commitMessage: {
			type: String,
			default: null,
		},

		/* Optional: disables form functionality */
		hideActions: {
			type: Boolean,
			default: false,
		},

		loading: {
			type: Boolean,
			default: false,
		},
		loadingSkeleton: {
			type: String,
			default: 'list-item-avatar-two-line@4, image',
		},
		id: String,
	},

	data() {
		return {
			saving: false,
			isDestroyed: false,
			dragOffsetX: 0,
			dragOffsetY: 0,
		}
	},

	watch: {
		value(val) {
			if (val && this.$refs.form) {
				this.$refs.form.resetValidation()
			}
		},
	},
	computed: {
		els() {
			return this.$refs.dialog
		},
		dialogElement() {
			return this.$refs.dialog.$refs.content
		},
	},
	created() {
		window.addEventListener('keydown', this.onKeydown)
		window.addEventListener('hashchange', this.onHashchange)
	},
	methods: {
		async onKeydown(e) {
			if (this.value && !e.defaultPrevented) {
				if (this.action === 'delete' && e.keyCode === 13) {
					e.preventDefault()
					await this.commit(true)
				}
			}
		},
		async commit(close) {
			if (!this.$refs.form || !this.$refs.form.validate()) {
				return
			}
			this.saving = true
			this.$emit(
				'commit',
				(result) => {
					if (result.success) {
						this.$emit('reset')
						if (this.$refs.form) this.$refs.form.resetValidation()

						if (close) {
							this.$emit('input', false)
						}
						if (
							this.commitMessage &&
							this.allowContinue !== close
						) {
							showSnackbar({ text: this.commitMessage })
						}
					}

					this.saving = false
				},
				close
			)
		},
		onStartDrag(e) {
			window.addEventListener('mousemove', this.onMouseMove)
			e.preventDefault()
			e.stopPropagation()
		},
		onMouseMove(e) {
			var flags = e.buttons !== undefined ? e.buttons : e.which
			if ((flags & 1) === 1) {
                e.preventDefault()
				e.stopPropagation()
				this.dragOffsetX += e.movementX
				this.dragOffsetY += e.movementY
				this.clampDragOffsets()
				let dialog = this.dialogElement
				dialog.style.marginTop = this.dragOffsetY + 'px'
				dialog.style.marginLeft = this.dragOffsetX + 'px'
			} else {
				this.onEndDrag()
			}
		},
		onEndDrag() {
			window.removeEventListener('mousemove', this.onMouseMove)
		},
		clampDragOffsets() {
			var dialogContent = this.$refs.dialog.$refs.dialog
			var dialogBox = dialogContent.getBoundingClientRect()

			const maxX = (window.innerWidth - dialogBox.width) / 2
			const maxY = (window.innerHeight - dialogBox.height) / 2

			this.dragOffsetX = Math.max(-maxX, Math.min(maxX, this.dragOffsetX))
			this.dragOffsetY = Math.max(-maxY, Math.min(maxY, this.dragOffsetY))
		},
	},
	destroyed() {
		window.removeEventListener('keydown', this.onKeydown)
		window.removeEventListener('hashchange', this.onHashchange)
		window.removeEventListener('mousemove', this.onMouseMove)
		this.isDestroyed = true
	},
}
</script>
<style scoped lang="scss">
.v-skeleton-loader {
	width: 100%;
}
.v-card__title {
	padding-right: 60px;
	word-break: keep-all;
	cursor: grab;
	&:active {
		cursor: grabbing;
	}
}

.close-button {
	top: 8px;
	right: 8px;
}
</style>
