<template>
    <v-container fluid class="pa-0">
        <v-toolbar v-if="$vuetify.breakpoint.xs" flat class="my-3" height="36">
            <v-toolbar-title>
                <relation-link
                    :relation-id="project.relationId"
                    :text="project.relationName"
                />
            </v-toolbar-title>
            <v-toolbar-title>
                <span>
                    , {{ project.project }} ({{ project.projectNumber }})
                </span>
                <v-chip
                    v-if="
                        $settings.defaultRelationGroup !==
                        project.relationGroupId
                    "
                    small
                    >{{ project.relationGroupName }}</v-chip
                >
                <v-chip class="ml-1" v-if="project.phase === 'archive'">{{
                    $localize('ProjectDetails.chip.archived')
                }}</v-chip>
                <v-chip class="ml-1" v-if="project.isTrashed">{{
                    $localize('ProjectDetails.chip.trashed')
                }}</v-chip>
            </v-toolbar-title>
        </v-toolbar>
        <v-toolbar flat dense class="my-3" height="36">
            <v-toolbar-items>
                <v-btn
                    v-if="$session.roles.includes('projects_manage')"
                    @click="enterProjectInformation = true"
                    class="mr-3"
                >
                    <v-icon>mdi-pencil</v-icon>
                </v-btn>
            </v-toolbar-items>
            <template>
                <v-toolbar-title v-if="$vuetify.breakpoint.smAndUp">
                    <span class="text-h5">
                        <relation-link
                            :relation-id="project.relationId"
                            :text="project.relationName"
                        />
                    </span>
                </v-toolbar-title>
                <v-toolbar-title v-if="$vuetify.breakpoint.smAndUp">
                    <span>
                        , {{ project.projectNumber }} {{ project.project }}
                    </span>
                    <v-chip
                        v-if="
                            $settings.defaultRelationGroup !==
                            project.relationGroupId
                        "
                        small
                        >{{ project.relationGroupName }}</v-chip
                    >
                </v-toolbar-title>
                <v-toolbar-items class="align-center">
                    <v-icon v-if="project.isTrashed">mdi-delete</v-icon>

                    <v-tooltip v-if="project.isInvoiceLocked" bottom>
                        <template v-slot:activator="{ on }">
                            <v-icon v-on="on" color="orange">mdi-lock</v-icon>
                        </template>
                        <span>{{
                            $localize('ProjectDetails.tooltip.locked')
                        }}</span>
                    </v-tooltip>
                </v-toolbar-items>
            </template>
            <v-spacer></v-spacer>
            <v-toolbar-items>
                <v-btn
                    v-if="$session.roles.includes('projects_calculate')"
                    @click="createProduct = true"
                    class="mr-3"
                >
                    <v-icon>mdi-cube-unfolded</v-icon>
                    <span v-show="$vuetify.breakpoint.mdAndUp" class="ml-1">{{
                        $localize('ProjectDetails.button.addProduct')
                    }}</span>
                </v-btn>

                <v-menu v-if="$session.roles.includes('projects_manage')">
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" class="primary">
                            <v-icon>mdi-dots-vertical</v-icon>
                        </v-btn>
                    </template>
                    <v-list>
                        <v-list-item
                            v-for="item in menu"
                            :key="item.text"
                            :disabled="
                                !item.action ||
                                (item.disabled && item.disabled())
                            "
                            v-show="item.visible()"
                            @click="item.action()"
                        >
                            <v-list-item-icon>
                                <v-icon :color="item.iconColor">{{
                                    item.icon
                                }}</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>{{
                                item.text
                            }}</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
                <v-btn
                    v-if="!showProjectInfo"
                    @click="showProjectInfo = !showProjectInfo"
                    class="ml-3"
                >
                    <v-icon>mdi-chevron-right</v-icon>
                </v-btn>
            </v-toolbar-items>
        </v-toolbar>
        <v-divider />

        <products-details :project-id="$route.params.projectId" />
        <v-divider />
        <v-row>
            <v-col>
                <project-toolbar
                    :project-id="$route.params.projectId"
                    :project="project"
                    @saved="refresh"
                />
            </v-col>
        </v-row>
        <v-divider />
        <v-row no-gutters>
            <v-col class="col-12 col-sm-6">
                <project-offers
                    :project-id="$route.params.projectId"
                    :project="project"
                />
            </v-col>
            <v-col class="col-12 col-sm-6">
                <project-invoice
                    :project-id="$route.params.projectId"
                    :invoice="project.invoice"
                />
            </v-col>
        </v-row>
        <v-row no-gutters>
            <v-col>
                <project-timeline :project-id="$route.params.projectId" />
            </v-col>
        </v-row>
        <project-information
            v-model="showProjectInfo"
            :project-id="$route.params.projectId"
            :project="project"
        />

        <enter-project-information
            v-if="enterProjectInformation"
            v-model="enterProjectInformation"
            :project-id="$route.params.projectId"
        />
        <switch-project-customer
            v-if="switchProjectCustomer"
            v-model="switchProjectCustomer"
            :project-id="$route.params.projectId"
        />

        <create-product
            v-if="createProduct"
            v-model="createProduct"
            :project-id="$route.params.projectId"
        />
    </v-container>
</template>

<script>
// Libraries
import { getProjectById } from '@/services/api'
import sendCommand from '@/services/sendCommand'
import { subscribe, unsubscribe } from '@/services/websocketHandler'
import PubSub from 'pubsub-js'

// Child components
import ProductsDetails from './components/ProductsDetails'
import ProjectOffers from './components/ProjectOffers'
import ProjectInvoice from './components/ProjectInvoice'
import ProjectTimeline from './components/ProjectTimeline'
import ProjectInformation from './components/ProjectInformation'
import ProjectToolbar from './components/ProjectToolbar'

import RelationLink from '../components/links/RelationLink'

// Dialogs
import EnterProjectInformation from './dialogs/EnterProjectInformation'
import SwitchProjectCustomer from './dialogs/SwitchProjectCustomer'
import CreateProduct from './dialogs/CreateProduct'

import { showSnackbar } from '@/services/snackbars'

export default {
    components: {
        EnterProjectInformation,
        SwitchProjectCustomer,
        CreateProduct,
        ProductsDetails,
        ProjectOffers,
        ProjectInvoice,
        ProjectTimeline,
        ProjectInformation,
        ProjectToolbar,
        RelationLink,
    },
    data() {
        return {
            project: {
                progress: {},
                invoice: {},
            },

            phaseNumber: 1,
            loading: false,
            enterProjectInformation: false,
            createProduct: false,
            addingSavedProduct: false,
            switchProjectCustomer: false,
            showProjectInfo: this.$vuetify.breakpoint.lgAndUp,
            menu: [
                {
                    text: this.$localize(
                        'ProjectDetails.button.changeCustomer'
                    ),
                    icon: 'mdi-account-edit',
                    visible: () => true,
                    action: () => (this.switchProjectCustomer = true),
                },
                {
                    text: this.$localize('ProjectDetails.button.untrash'),
                    icon: 'mdi-delete-restore',
                    visible: () => this.project.isTrashed,
                    action: this.untrash,
                },
                {
                    text: this.$localize('ProjectDetails.button.trash'),
                    icon: 'mdi-delete',
                    visible: () => !this.project.isTrashed,
                    action: this.trash,
                },
                {
                    text: this.$localize('ProjectDetails.button.unlockInvoice'),
                    icon: 'mdi-lock-open',
                    iconColor: 'orange',
                    visible: () => true,
                    action: this.unlockInvoice,
                    disabled: () => !this.project.isInvoiceLocked,
                },
            ],
            subscription1: null,
            subscription2: null,
            subscription3: null,
            subscription4: null,
            subscription5: null,
            subscription6: null,
            subscription7: null,
        }
    },
    async created() {
        await this.refresh()

        this.publishActiveProjectPhase()
        this.subscription1 = subscribe(
            'ProjectStatusChanged',
            this.onMessage,
            (x) => x.projectId === this.$route.params.projectId
        )
        this.subscription2 = subscribe(
            'ProjectInformationEntered',
            this.onMessage,
            (x) => x.projectId === this.$route.params.projectId
        )
        this.subscription3 = subscribe(
            'InvoiceCreated',
            this.onMessage,
            (x) => x.projectId === this.$route.params.projectId
        )
        this.subscription4 = subscribe(
            'ProjectSwitchedCustomer',
            this.onMessage,
            (x) => x.projectId === this.$route.params.projectId
        )
        this.subscription5 = subscribe(
            'InvoiceNumberAssigned',
            this.onInvoiceNumberAssigned,
            (x) => x.projectId === this.$route.params.projectId
        )
        this.subscription6 = subscribe(
            'InvoiceCreated',
            this.onInvoiceCreated,
            (x) => x.projectId === this.$route.params.projectId
        )
        this.subscription7 = subscribe(
            'InvoiceSent',
            this.onInvoiceSent,
            (x) => x.projectId === this.$route.params.projectId
        )
        this.subscription8 = subscribe(
            'ProjectFileDeleted',
            (message) => {
                this.project.files = this.project.files.filter(
                    (fileId) => fileId !== message.fileId
                )
            },
            (x) => x.projectId === this.$route.params.projectId
        )
        this.subscription9 = subscribe(
            'ProjectFileUploaded',
            (message) => {
                this.project.files.push(message.fileId)
            },
            (x) => x.projectId === this.$route.params.projectId
        )
    },
    destroyed() {
        PubSub.publish('ClearActiveProjectPhase')
        unsubscribe(this.subscription1)
        unsubscribe(this.subscription2)
        unsubscribe(this.subscription3)
        unsubscribe(this.subscription4)
        unsubscribe(this.subscription5)
        unsubscribe(this.subscription6)
        unsubscribe(this.subscription7)
        unsubscribe(this.subscription8)
        unsubscribe(this.subscription9)
        document.title = 'TORECS'
    },
    methods: {
        onMessage(message, messageType) {
            if (message.projectId != this.$route.params.projectId) return

            if (messageType === 'ProjectStatusChanged') {
                PubSub.publish('ActiveProjectPhase', {
                    phase: message.phase.toLowerCase(),
                })
            }

            this.refresh()
        },
        onInvoiceNumberAssigned(message) {
            this.project.invoice.number = message.invoiceNumber
            this.project.invoice.date = message.invoiceDate
        },
        onInvoiceCreated(message) {
            this.project.invoice.path = message.physicalPath
        },
        onInvoiceSent(message) {
            this.project.invoice.sentOn = message.sentOn
        },
        async refresh() {
            this.loading = true
            const { result, ok } = await getProjectById.call({
                args: { projectId: this.$route.params.projectId },
            })
            if (ok) {
                this.project = result
                document.title = `TORECS - ${this.$localize(
                    this.project.phase
                )} ${this.project.projectNumber}`
            }
            this.loading = false
        },
        async trash() {
            const response = await sendCommand('TrashProject', {
                projectId: this.$route.params.projectId,
            })
            if (response.success) {
                this.project.isTrashed = true
            }
        },
        async untrash() {
            const response = await sendCommand('UntrashProject', {
                projectId: this.$route.params.projectId,
            })
            if (response.success) {
                this.project.isTrashed = false
            }
        },
        async unlockInvoice() {
            const response = await sendCommand('UnlockInvoice', {
                projectId: this.$route.params.projectId,
            })
            if (response.success) {
                this.project.isInvoiceLocked = false
                showSnackbar({
                    text: this.$localize(
                        'ProjectDetails.snackbar.invoiceUnlocked'
                    ),
                })
            }
        },
        async navCalendar() {
            this.$router.push({
                name: 'calendar',
                query: { projectId: this.$route.params.projectId },
            })
        },
        toggleProjectInformation() {
            this.showInfo = !this.showInfo
        },
        publishActiveProjectPhase() {
            PubSub.publish('ActiveProjectPhase', {
                phase: this.project.phase,
            })
        },
    },
}
</script>
<style scoped lang="scss">
.v-divider {
    &.theme--light {
        border-color: #0277bd;
    }

    border-width: 1px;
}

.showHistoryButton {
    margin-top: 50px;
    text-align: center;
}
</style>
