<template>
	<v-row align="stretch" no-gutters :class="{ last: isLast, first: isFirst }">
		<v-col class="specifications">{{ part.specifications }}</v-col>
		<v-col v-if="$vuetify.breakpoint.smAndUp" class="description">{{
			part.description
		}}</v-col>
		<v-col
            v-if="$session.roles.includes('projects_calculate')"
			cols="auto"
			align="right"
			style="width: 100px"
			class="pr-3 price"
			:class="priceClasses"
			>{{ $format.currency(part.price) }}</v-col
		>
		<v-col cols="auto" :style="'width:' + buttonBarWidth">
			<v-toolbar
                v-if="$session.roles.includes('projects_calculate')"
				flat
				dense
				tile
				class
				style="background: none"
				:height="buttonBarHeight"
			>
				<v-spacer></v-spacer>
				<collapsable-buttons :items="actionBar" :value="{ part }" />
			</v-toolbar>
		</v-col>
		<v-col v-if="$vuetify.breakpoint.xs" cols="12">
			{{ part.description }}
		</v-col>
		<delete-part
			v-if="deletingPart"
			v-model="deletingPart"
			:project-id="part.projectId"
			:product-id="part.productId"
			:part-id="part.partId"
		/>
		<v-lazy>
			<calculate-part
				v-if="calculatingPart"
				v-model="calculatingPart"
				:project-id="part.projectId"
				:part="part"
			/>
		</v-lazy>
	</v-row>
</template>

<script>
import sendCommand from '@/services/sendCommand'
import CollapsableButtons from '@/shared/components/CollapsableButtons'
import DeletePart from '../dialogs/DeletePart'
import CalculatePart from '../dialogs/CalculatePart'

export default {
	components: { DeletePart, CalculatePart, CollapsableButtons },
	props: {
		value: Object,
		buttonBarWidth: String,
		buttonBarHeight: String,
		isFirst: Boolean,
		isLast: Boolean,
	},
	data() {
		return {
			calculatingPart: false,
			deletingPart: false,

			actionBar: [
				{
					icon: 'mdi-pencil',
					text: this.$localize('ProductDetails.part.button.edit'),
					action: () => (this.calculatingPart = true),
				},
				{
					icon: 'mdi-content-copy',
					text: this.$localize('ProductDetails.part.button.copy'),
					action: (item) => this.duplicatePart(item.part),
				},
				{
					icon: 'mdi-arrow-up',
					iconColor: 'success',
					text: this.$localize('ProductDetails.part.button.moveUp'),
					action: (item) => this.movePartUp(item.part),
					disabled: () => this.isFirst,
				},
				{
					icon: 'mdi-arrow-down',
					iconColor: 'success',
					text: this.$localize('ProductDetails.part.button.moveDown'),
					action: (item) => this.movePartDown(item.part),
					disabled: () => this.isLast,
				},
				{
					icon: 'mdi-close',
					text: this.$localize('ProductDetails.part.button.delete'),
					action: () => (this.deletingPart = true),
					textColor: 'error',
				},
			],
		}
	},
	computed: {
		part() {
			return this.value
		},
		priceClasses() {
			return {
				grey: true,
				'lighten-3': this.$settings.theme !== 'dark',
				'darken-3': this.$settings.theme === 'dark',
			}
		},
	},
	methods: {
		async duplicatePart(part) {
			await sendCommand('DuplicatePart', {
				projectId: part.projectId,
				productId: part.productId,
				partToDuplicateId: part.partId,
			})
		},
		async movePartUp(part) {
			await sendCommand('MovePartUp', {
				projectId: part.projectId,
				productId: part.productId,
				partId: part.partId,
			})
		},
		async movePartDown(part) {
			await sendCommand('MovePartDown', {
				projectId: part.projectId,
				productId: part.productId,
				partId: part.partId,
			})
		},
	},
}
</script>
<style lang="scss" scoped>
.col {
	display: flex;
	flex-direction: column;
	justify-content: center;
    height:28px;
	span {
		display: inline-block;
		vertical-align: middle;
	}
}
.row.last {
	.col.price {
		border-bottom: 1px solid #e0e0e0 !important;
	}
}
.row:hover {
	background-color: rgba(0, 0, 0, 0.04);
	transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
</style>
