<template>
	<dialog-form
		:value="value"
		@input="$emit('input', $event)"
        :title="meta.originalFileName"
		action="delete"
		@commit="save"
		:action-text="$localize('ProjectFile.button.delete')"
		:max-width="width"
	>
		<v-img
			v-if="meta?.contentType.startsWith('image')"
			:src="getFile.create({ fileId: fileId }).url"
			:max-width="meta.width"
			:max-height="meta.height"
		/>
		<a v-else :href="getFile.create({ fileId: fileId }).url" target="_blank"
			>Openen</a
		>
	</dialog-form>
</template>

<script>
import DialogForm from '@/shared/components/DialogForm'

import sendCommand from '@/services/sendCommand'
import { isGuid } from '@/services/validation'

import { getFile } from '@/services/api'
export default {
	components: { DialogForm },
	props: {
		value: {
			type: Boolean,
			default: false,
		},
		fileId: {
			validator: isGuid,
			required: true,
		},
		projectId: {
			validator: isGuid,
			required: true,
		},
	},
	data() {
		return {
			getFile: getFile,
			meta: {
				contentType: 'unknown/unknown',
			},
		}
	},
	watch: {
		fileId() {
			this.loadMetadata()
		},
	},

	computed: {
		width() {
			if (this.meta.width) {
				if (this.meta.width > 600) {
					return this.meta.width + ""
				}
			}
			return undefined
		},
	},
	created() {
		this.loadMetadata()
	},
	methods: {
		async loadMetadata() {
			this.meta = await getFile
				.create({ fileId: this.fileId })
				.load({ meta: true })
		},
		async save(callback) {
			const result = await sendCommand('DeleteProjectFile', {
				projectId: this.projectId,
				fileId: this.fileId,
			})

			if (result.success) {
				this.$emit('saved')
			}

			callback({ success: result.success })
		},
	},
}
</script>
