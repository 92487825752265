<template>
	<dialog-form
		:value="value"
		@input="$emit('input', $event)"
		:title="$localize('EditDashboard.title')"
		@commit="save"
		:allow-continue="false"
		:loading="loading"
	>
		<v-text-field
			v-model="editDashboard.name"
			:label="$localize('Dashboard.name')"
			:rules="[validation.required, validation.maxLength(20)]"
		/>

		<select-phases v-model="editDashboard.phases" />

		<select-project-statuses
			v-model="editDashboard.projectStatusIds"
			:label="$localize('Dashboard.projectStatuses')"
			:all-value="null"
		/>
	</dialog-form>
</template>

<script>
import DialogForm from '@/shared/components/DialogForm'
import SelectProjectStatuses from '@/main/components/forms/SelectProjectStatuses'
import SelectPhases from '@/main/components/forms/SelectPhases'
import sendCommand from '@/services/sendCommand'
import PubSub from 'pubsub-js'
import { getDashboardById } from '@/services/api'
import { isGuid } from '@/services/validation'
import { required, maxLength } from '@/services/validation'
export default {
	components: { DialogForm, SelectPhases, SelectProjectStatuses },
	props: {
		value: Boolean,
		dashboardId: {
			validator: isGuid,
			required: true,
		},
	},
	data() {
		return {
			editDashboard: {},
			validation: { required, maxLength },
		}
	},
	async created() {
		this.loading = true
		const { result, ok } = await getDashboardById.call({
			args: { dashboardId: this.dashboardId },
		})
		if (ok) {
			this.editDashboard = result
		}
		this.loading = false
	},
	methods: {
		async save(callback) {
			const result = await sendCommand(
				'EditDashboard',
				this.editDashboard
			)

			if (result.success) {
				this.$emit('saved')
				PubSub.publish('RefreshDashboardMenu')
			}
			callback({ success: result.success })
		},
	},
}
</script>
