<template>
	<dialog-form
		id="formDeleteAddress"
		:value="value"
		@input="$emit('input', $event)"
		@commit="save"
		:title="$localize('DeleteAddress.title')"
		action="delete"
		:loading="loading"
	>
		<p>{{ $localize('DeleteAddress.description') }}</p>
		<p>
			<b>{{ address.street }}</b>
			<br />
			<b>{{ address.zipCode }}</b>
			<br />
			<b>{{ address.city }}</b>
		</p>

		<p>{{ $localize('form.description.actionIsPermanent') }}</p>
	</dialog-form>
</template>

<script>
import DialogForm from '@/shared/components/DialogForm'

import sendCommand from '@/services/sendCommand'
import { getAddressById } from '@/services/api'
import { isGuid } from '@/services/validation'

export default {
	components: { DialogForm },
	props: {
		addressId: {
			required: true,
			validator: isGuid,
		},
		value: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			loading: false,
			address: {},
		}
	},
	async created() {
		this.loading = true
		const { result, ok } = await getAddressById.call({
			args: { addressId: this.addressId },
		})
		if (ok) {
			this.address = result
		}
		this.loading = false
	},
	methods: {
		async save(callback) {
			const result = await sendCommand('DeleteAddress', {
				addressId: this.address.addressId,
			})
			if (result.success) {
				this.$emit('saved')
			}
			callback({ success: result.success })
		},
	},
}
</script>
