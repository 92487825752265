<template>
	<dialog-form
		:value="value"
		@input="$emit('input', $event)"
		:title="$localize('CreateProductTemplate.title')"
		@commit="save"
		:commit-message="$localize('CreateProductTemplate.message.saved')"
		:allow-continue="false"
	>
		<v-checkbox
			v-model="product.addParts"
			:label="$localize('CreateProductTemplate.includeParts')"
		/>
		<v-text-field
			v-model="product.product"
			:label="$localize('CreateProductTemplate.product')"
			:loading="loading"
			:rules="[validation.maxLength(200)]"
		/>
		<v-text-field
			v-model="product.description"
			:label="$localize('CreateProductTemplate.description')"
			:loading="loading"
			:rules="[validation.maxLength(500)]"
		/>
		<v-textarea
			v-model="product.specifications"
			:label="$localize('CreateProductTemplate.specifications')"
			:loading="loading"
			:rules="[validation.maxLength(2000)]"
		/>
		<v-switch
			:label="$localize('Product.offerShowPrice')"
			v-model="product.offerShowPrice"
			hide-details
		/>
		<v-row>
			<v-col>
				<v-switch
					:label="$localize('Product.offerShowParts')"
					v-model="product.offerShowParts"
					hide-details
				/>
			</v-col>
			<v-col>
				<v-switch
					:label="$localize('Product.offerShowPartPrices')"
					v-model="product.offerShowPartPrices"
					:disabled="
						!product.offerShowParts || !product.offerShowPrice
					"
					hide-details
				/>
			</v-col>
		</v-row>
	</dialog-form>
</template>

<script>
import DialogForm from '@/shared/components/DialogForm'

import sendCommand from '@/services/sendCommand'
import { required, number, maxLength } from '@/services/validation'
import { isGuid } from '@/services/validation'
import { getProductById } from '@/services/api'

export default {
	components: { DialogForm },
	props: {
		value: {
			type: Boolean,
			default: false,
		},
		productId: {
			validator: isGuid,
			required: false,
		},
	},

	data() {
		return {
			product: {
				addParts: true,
				description: null,
				specifications: null,
				offerShowParts: false,
				offerShowPartPrices: false,
				offerShowPrice: true,
			},
			validation: { required, number, maxLength },
			loading: false,
		}
	},
	watch: {
		'product.offerShowParts'() {
			if (!this.product.offerShowParts) {
				this.product.offerShowPartPrices = false
			}
		},
		'product.offerShowPrice'() {
			if (!this.product.offerShowPrice) {
				this.product.offerShowPartPrices = false
			}
		},
	},
	async created() {
		this.loading = true
		const { result, ok } = await getProductById.call({
			args: { productId: this.productId },
		})
		if (ok) {
			const product = result
			this.product.description = product.description
			this.product.specifications = product.specifications
			this.product.product = product.product
			this.product.offerShowParts = product.offerShowParts
			this.product.offerShowPartPrices = product.offerShowPartPrices
			this.product.offerShowPrice = product.offerShowPrice
		}
		this.loading = false
	},
	methods: {
		async save(callback) {
			this.product.productId = this.productId
			const result = await sendCommand('SaveProduct', this.product)

			if (result.success) {
				this.$emit('saved')
			}
			callback({ success: result.success })
		},
	},
}
</script>
