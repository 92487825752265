<template>
  <dialog-form
    :value="value"
    @input="$emit('input', $event)"
    :title="$localize('AddVacation.title')"
    @commit="save"
    @reset="addVacation = {}"
    :allow-continue="false"
  >
    <date-select
      :label="$localize('Vacation.start')"
      v-model="addVacation.start"
      :rules="[validation.required]"
      autofocus
    />
    <date-select
      :label="$localize('Vacation.end')"
      v-model="addVacation.end"
      :rules="[validation.required]"
    />

    <v-textarea
      prepend-icon="mdi-note-outline"
      :label="$localize('Vacation.note')"
      v-model="addVacation.note"
      :rules="[validation.maxLength(500)]"
    />
  </dialog-form>
</template>

<script>
import DialogForm from "@/shared/components/DialogForm";

import sendCommand from "@/services/sendCommand";
import DateSelect from "@/shared/components/DateSelect";
import { required, maxLength } from "@/services/validation";
import { isGuid } from "@/services/validation"

export default {
  components: { DateSelect, DialogForm },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    userId: {
      validator: isGuid,
    },
  },

  data() {
    return {
      addVacation: {},
      validation: {
        required,
        maxLength,
      },
    };
  },

  methods: {
    async save(callback) {
      this.addVacation.userId = this.userId;
      const result = await sendCommand("AddUserVacation", this.addVacation);
      if (result.success) {
        this.$emit("saved");
      }
      callback({ success: result.success });
    },
  },
};
</script>
