<template>
  <div>
    <!--<code-events
      v-if="contextType === 'trigger'"
      :code-context-id="codeContextId"
    />-->
    <code-variables
      v-model="variables"
      :code-context-id="codeContextId"
      :allow-in-out="allowInOutVariables"
      @input="emitState()"
    />
    <code-statements v-model="statements" :variables="variables" :code-context-id="codeContextId" @input="emitState()" />
  </div>
</template>

<script>
import CodeVariables from "./CodeVariables";
//import CodeEvents from "./CodeEvents";
import { isGuid } from "@/services/validation"

export default {
  components: {
    //CodeEvents,
    CodeVariables,
    CodeStatements: () => import("./CodeStatements.vue")
  },
  props: {
    codeContextId: {
      validator: isGuid,
      required: true
    },
    allowInOutVariables: {
      type: Boolean,
      default: true
    },
    value: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      contextType: this.value.contextType,
      events: this.value.events,
      statements: this.value.statements,
      variables: this.value.variables,
    };
  },
  watch: {
    value(val){
      this.contextType = val.contextType
      this.events = val.events
      this.statements = val.statements
      this.variables = val.variables
    }
  },
  beforeCreate: function() {
    this.$options.components.CodeStatements = require("./CodeStatements.vue").default;
    this.$options.components.CodeStatement = require("./CodeStatement.vue").default;
  },
  methods: {
    emitState(){
      this.$emit('input', {
        statements: this.statements,
        variables: this.variables
      })
    }
  }
};
</script>
