<template>
  <v-container fluid class="pa-0">
    <v-row>
      <v-col>
        <v-toolbar flat dense>
          <v-tabs>
            <v-tab
              key="calculations"
              :to="{name: 'calculationOverview'}"
            >{{ $localize('Advanced.label.calculations') }}</v-tab>
            <v-tab
              key="article-calculations"
              :to="{name: 'articleCalculationOverview'}"
            >{{ $localize('Advanced.label.articleCalculations') }}</v-tab>
            <v-tab
              key="attributes"
              :to="{name: 'attributeOverview'}"
            >{{ $localize('Advanced.label.attributes') }}</v-tab>
            <v-tab
              key="triggers"
              :to="{name: 'triggerOverview'}"
            >{{ $localize('Advanced.label.triggers') }}</v-tab>
          </v-tabs>
        </v-toolbar>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <router-view :key="$route.path + key" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      key: "calculations"
    };
  }
};
</script>
