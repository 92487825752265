<template>
	<dialog-form
		id="formDeleteRelationNote"
		:value="value"
		@input="$emit('input', $event)"
		@commit="save"
		:title="$localize('DeleteRelationNote.title')"
		action="delete"
		:loading="loading"
	>
		<p>{{ $localize('DeleteRelationNote.description') }}</p>
		<p>{{ note }}</p>
		<p>{{ $localize('form.description.actionIsPermanent') }}</p>
	</dialog-form>
</template>

<script>
import DialogForm from '@/shared/components/DialogForm'

import sendCommand from '@/services/sendCommand'
import { isGuid } from '@/services/validation'
import { getRelationNoteById } from '@/services/api'

export default {
	components: { DialogForm },
	props: {
		relationId: {
			required: true,
			validator: isGuid,
		},
		noteId: {
			required: false,
			validator: isGuid,
		},
		value: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			note: '',
			loading: false,
		}
	},
	async created() {
		this.loading = true
		const { result, ok } = await getRelationNoteById
			.call({ args: { noteId: this.noteId } })

		if (ok) {
			this.note = result.note
		}
		this.loading = false
	},
	methods: {
		async save(callback) {
			const result = await sendCommand('DeleteRelationNote', {
				noteId: this.noteId,
				relationId: this.relationId,
			})
			if (result.success) {
				this.$emit('saved')
			}
			callback({ success: result.success })
		},
	},
}
</script>
