<template>
    <v-container fluid class="fill-height pa-0">
        <v-row>
            <v-col>
                <v-toolbar flat dense class="my-3">
                    <v-btn outlined @click="setToday">{{
                        $localize('calendar.today')
                    }}</v-btn>
                    <v-btn fab text @click="prev()">
                        <v-icon small>mdi-chevron-left</v-icon>
                    </v-btn>
                    <v-btn fab text @click="next()">
                        <v-icon small>mdi-chevron-right</v-icon>
                    </v-btn>

                    <v-toolbar-title>{{ title }}</v-toolbar-title>

                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-menu
                            v-model="showMenu"
                            :close-on-content-click="false"
                        >
                            <template v-slot:activator="{}">
                                <v-btn @click="showMenu = true" class="primary">
                                    <v-icon>mdi-dots-vertical</v-icon>
                                </v-btn>
                            </template>
                            <v-list>
                                <v-list-item
                                    v-if="
                                        $session.roles.includes(
                                            'planning_manage'
                                        )
                                    "
                                    @click="
                                        createAppointment = true
                                        showMenu = false
                                    "
                                >
                                    <v-list-item-icon>
                                        <v-icon>mdi-plus</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-title>{{
                                        $localize(
                                            'Calendar.button.createAppointment'
                                        )
                                    }}</v-list-item-title>
                                </v-list-item>

                                <v-subheader>{{
                                    $localize('Calendar.subtitle.viewType')
                                }}</v-subheader>
                                <v-list-item
                                    v-for="viewType in types"
                                    :key="viewType.value"
                                    @click="type = viewType.value"
                                >
                                    <v-list-item-action>
                                        <v-radio-group v-model="type">
                                            <v-radio :value="viewType.value" />
                                        </v-radio-group>
                                    </v-list-item-action>
                                    <v-list-item-content>
                                        <v-list-item-title>{{
                                            viewType.text
                                        }}</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>

                                <v-subheader>{{
                                    $localize('Calendar.subtitle.overlapMode')
                                }}</v-subheader>
                                <v-list-item
                                    v-for="mode in overlapModes"
                                    :key="mode"
                                    @click="overlapMode = mode"
                                >
                                    <v-list-item-action>
                                        <v-radio-group v-model="overlapMode">
                                            <v-radio :value="mode" />
                                        </v-radio-group>
                                    </v-list-item-action>
                                    <v-list-item-content>
                                        <v-list-item-title>{{
                                            $localize(
                                                'Calendar.overlapMode.' + mode
                                            )
                                        }}</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>

                                <v-subheader>{{
                                    $localize('Calendar.subtitle.colorMode')
                                }}</v-subheader>
                                <v-list-item
                                    v-for="mode in colorModes"
                                    :key="mode"
                                    @click="colorMode = mode"
                                >
                                    <v-list-item-action>
                                        <v-radio-group v-model="colorMode">
                                            <v-radio :value="mode" />
                                        </v-radio-group>
                                    </v-list-item-action>
                                    <v-list-item-content>
                                        <v-list-item-title>{{
                                            $localize(
                                                'Calendar.colorMode.' + mode
                                            )
                                        }}</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>

                                <template v-if="$vuetify.breakpoint.smAndDown">
                                    <v-subheader>Gebruikers</v-subheader>
                                    <v-list-item-group
                                        v-model="selectedUsers"
                                        multiple
                                    >
                                        <v-list-item
                                            v-for="user in $store.state.users
                                                .items"
                                            :key="user.userId"
                                            :value="user.userId"
                                        >
                                            <template
                                                v-slot:default="{ active }"
                                            >
                                                <v-list-item-action>
                                                    <v-checkbox
                                                        :input-value="active"
                                                    >
                                                    </v-checkbox>
                                                </v-list-item-action>
                                                <v-list-item-content>
                                                    <v-list-item-title>
                                                        <v-icon
                                                            :color="user.color"
                                                            >mdi-account-tie</v-icon
                                                        >
                                                        {{
                                                            user.name
                                                        }}</v-list-item-title
                                                    >
                                                </v-list-item-content>
                                            </template>
                                        </v-list-item>
                                    </v-list-item-group>
                                    <v-subheader>Labels</v-subheader>
                                    <v-list-item-group
                                        v-model="selectedLabels"
                                        multiple
                                    >
                                        <v-list-item
                                            v-for="label in $store.state.labels
                                                .items"
                                            :key="label.labelId"
                                            :value="label.labelId"
                                        >
                                            <template
                                                v-slot:default="{ active }"
                                            >
                                                <v-list-item-action>
                                                    <v-checkbox
                                                        :input-value="active"
                                                    >
                                                    </v-checkbox>
                                                </v-list-item-action>
                                                <v-list-item-content>
                                                    <v-list-item-title>
                                                        <v-icon
                                                            :color="label.color"
                                                            >mdi-label</v-icon
                                                        >
                                                        {{
                                                            label.name
                                                        }}</v-list-item-title
                                                    >
                                                </v-list-item-content>
                                            </template>
                                        </v-list-item>
                                    </v-list-item-group>
                                </template>
                            </v-list>
                        </v-menu>
                    </v-toolbar-items>
                </v-toolbar>
            </v-col>
        </v-row>
        <v-row
            no-gutters
            align="stretch"
            justify="space-between"
            class="flex-nowrap fill-height"
        >
            <v-col cols="auto" v-if="$vuetify.breakpoint.mdAndUp">
                <v-list>
                    <v-subheader>Gebruikers</v-subheader>
                    <v-list-item-group v-model="selectedUsers" multiple>
                        <v-list-item
                            v-for="user in $store.state.users.items"
                            :key="user.userId"
                            :value="user.userId"
                        >
                            <template v-slot:default="{ active }">
                                <v-list-item-action>
                                    <v-checkbox :input-value="active">
                                    </v-checkbox>
                                </v-list-item-action>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        <v-icon :color="user.color"
                                            >mdi-account-tie</v-icon
                                        >
                                        {{ user.name }}</v-list-item-title
                                    >
                                </v-list-item-content>
                            </template>
                        </v-list-item>
                    </v-list-item-group>
                    <v-subheader>Labels</v-subheader>
                    <v-list-item-group v-model="selectedLabels" multiple>
                        <v-list-item
                            v-for="label in $store.state.labels.items"
                            :key="label.labelId"
                            :value="label.labelId"
                        >
                            <template v-slot:default="{ active }">
                                <v-list-item-action>
                                    <v-checkbox :input-value="active">
                                    </v-checkbox>
                                </v-list-item-action>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        <v-icon :color="label.color"
                                            >mdi-label</v-icon
                                        >
                                        {{ label.name }}</v-list-item-title
                                    >
                                </v-list-item-content>
                            </template>
                        </v-list-item>
                    </v-list-item-group>
                </v-list>
            </v-col>
            <v-col>
                <v-calendar
                    v-model="focus"
                    ref="calendar"
                    :type="type"
                    :events="renderedEvents"
                    :first-interval="firstInterval"
                    :interval-minutes="30"
                    :interval-count="intervalCount"
                    :event-color="getEventColor"
                    :event-overlap-mode="overlapMode"
                    :weekdays="weekdays"
                    @click:more="viewDay"
                    @click:date="viewDay"
                    @change="updateRange"
                    @mousedown:event="startDrag"
                    @mousedown:time="startTime"
                    @mousemove:time="mouseMove"
                    @mouseup:event="endDrag"
                    @mouseleave.native="cancelDrag"
                    show-week
                    :class="[calendarClasses]"
                >
                    <template
                        v-if="$session.roles.includes('planning_manage')"
                        v-slot:interval="obj"
                    >
                        <v-btn
                            small
                            icon
                            class="btn-add"
                            @click="timeClick(obj)"
                            ><v-icon>mdi-plus</v-icon></v-btn
                        >
                    </template>
                    <template v-slot:event="{ event }">
                        <calendar-event
                            v-if="true"
                            :event="event"
                            :color-mode="colorMode"
                        />
                        <div
                            v-if="
                                event.timed &&
                                $session.roles.includes('planning_manage')
                            "
                            class="v-event-drag-bottom"
                            @mousedown.stop="extendBottom(event)"
                        />
                    </template>
                </v-calendar>
            </v-col>
        </v-row>
        <calendar-card-item
            v-model="selectedOpen"
            :selected-element="selectedElement"
            :selected-event="selectedEvent"
            @refresh="refresh"
        />
        <create-appointment
            v-if="createAppointment"
            v-model="createAppointment"
            @saved="refresh"
            :isEntireDay.sync="createAppointmentIsEntireDay"
            :startAt.sync="createAppointmentStart"
            :endAt.sync="createAppointmentEnd"
            :project-id="this.$route.query.projectId"
        />
    </v-container>
</template>

<script>
import { getCalendarEvents } from '@/services/api'
import {
    format,
    parseISO,
    formatISO,
    setMinutes,
    getHours,
    addHours,
    setHours,
    addDays,
    differenceInHours,
    areIntervalsOverlapping,
    isEqual,
    startOfWeek,
    endOfWeek,
} from 'date-fns'
import {
    formatLongMonth,
    formatLongWeek,
    formatLongDay,
} from '@/services/formatters'
import CreateAppointment from './dialogs/CreateAppointment'
import CalendarCardItem from './components/CalendarCardItem'
import CalendarEvent from './components/CalendarEvent'
import sendCommand from '@/services/sendCommand'
import { subscribe, unsubscribe } from '@/services/websocketHandler'
import { userNameInitials } from '@/services/functions'
import { getEventColor, getEventIcon } from './calendarFunctions'
const eventDateFormat = 'yyyy-MM-dd'
const eventDateTimeFormat = 'yyyy-MM-dd HH:mm'
const fullWeek = [1, 2, 3, 4, 5, 6, 0]
const debug = false
export default {
    components: {
        CreateAppointment,
        CalendarCardItem,
        CalendarEvent,
    },
    data() {
        return {
            createAppointment: false,
            createAppointmentIsEntireDay: false,
            createAppointmentStart: null, // Date
            createAppointmentEnd: null, // Date

            today: new Date(),
            focus: new Date(),
            start: null,
            end: null,
            events: [],

            type: 'week',
            types: [
                {
                    value: 'day',
                    text: this.$localize('Calendar.viewType.day'),
                },
                {
                    value: 'week',
                    text: this.$localize('Calendar.viewType.week'),
                },
                {
                    value: 'month',
                    text: this.$localize('Calendar.viewType.month'),
                },
            ],
            overlapMode:
                localStorage.getItem('Calendar:overlapMode') || 'stack',
            overlapModes: ['stack', 'column'],

            colorMode: localStorage.getItem('Calendar:colorMode') || 'label',
            colorModes: ['label', 'user'],
            weekdays: fullWeek,

            firstInterval: undefined,
            intervalCount: undefined,

            selectedUsers: [],
            selectedLabels: [],
            selectedEvent: {},

            selectedElement: null,
            selectedOpen: false,

            showMenu: false,

            dragOffsetInMinutes: null,
            dragEvent: null,
            dragStart: null,
            createEvent: null,
            createStart: null,
            extendOriginal: null,
            lastEvent: null,

            extendEvent: null,
            extendStart: null,

            subscription1: null,
            subscription2: null,
        }
    },
    computed: {
        title() {
            switch (this.type) {
                case 'day':
                    return formatLongDay(this.focus)
                case 'week':
                    return formatLongWeek(this.focus)
                case 'month':
                    return formatLongMonth(this.focus)
            }
            return ''
        },
        renderedEvents() {
            if (!this.createAppointment) {
                return [...this.events]
            } else {
                let start = this.createAppointmentStart || new Date()
                let end = this.createAppointmentEnd || addHours(start, 1)

                if (this.createAppointmentIsEntireDay) {
                    start = format(start, eventDateFormat)
                    end = format(end, eventDateFormat)
                } else {
                    start = format(start, eventDateTimeFormat)
                    const hours = getHours(end)
                    if (hours < this.calendarStartTime) {
                        end = addHours(end, -(hours + 1))
                    }
                    end = format(end, eventDateTimeFormat)
                }

                return [
                    ...this.events,
                    {
                        start: start,
                        end: end,
                        name: '',
                        details: '',
                        data: {
                            userIds: [],
                            type: 'appointment',
                            color: 'blue',
                        },
                        temp: true,
                        timed: !this.createAppointmentIsEntireDay,
                    },
                ]
            }
        },
        calendarClasses() {
            if (this.createAppointment) {
                return ['creating']
            }
            return []
        },
        calendarStartTime() {
            if (this.$settings.calendarStartTime === '0') return 0
            return parseInt(this.$settings.calendarStartTime) || 7
        },
        calendarEndTime() {
            return parseInt(this.$settings.calendarEndTime) || 20
        },
    },
    watch: {
        selectedUsers() {
            this.refresh()
        },
        selectedLabels() {
            this.refresh()
        },
        overlapMode() {
            localStorage.setItem('Calendar:overlapMode', this.overlapMode)
        },
        colorMode() {
            localStorage.setItem('Calendar:colorMode', this.colorMode)
        },
        '$route.query.projectId': {
            immediate: true,
            handler(value) {
                this.initializeForProject(value)
            },
        },
    },
    async created() {
        if (this.$route.query.projectId) {
            this.createAppointment = true
        }
        this.$store.dispatch('loadUsers')
        this.$store.dispatch('loadLabels')
        this.firstInterval = this.calendarStartTime * 2
        this.intervalCount = (this.calendarEndTime - this.calendarStartTime) * 2

        if (this.$refs.calendar) this.$refs.calendar.checkChange()

        this.subscription1 = subscribe('AppointmentMoved', this.onMessage)
        this.subscription2 = subscribe('AppointmentChanged', this.onMessage)
        //window.addEventListener('keydown', this.onKeydown)
    },
    destroyed() {
        unsubscribe(this.subscription1)
        unsubscribe(this.subscription2)
        //window.removeEventListener('keydown', this.onKeydown)
    },
    methods: {
        async refresh() {
            let start = this.start
            let end = this.end
            if (this.type === 'month') {
                start = format(
                    startOfWeek(parseISO(this.start)),
                    eventDateFormat
                )
                end = format(endOfWeek(parseISO(this.end)), eventDateFormat)
            }
            const { result, ok } = await getCalendarEvents.call({
                args: { start: start, end: end },
                q: {
                    users: this.selectedUsers,
                    labels: this.selectedLabels,
                },
            })

            if (ok) {
                const events = result
                this.events = events.map((x) => {
                    let start = parseISO(x.startAt)
                    let end = parseISO(x.endAt)
                    let eventFormat = x.isTimedEvent
                        ? eventDateTimeFormat
                        : eventDateFormat
                    x.startAt = start
                    x.endAt = end

                    if (x.isTimedEvent) {
                        // Because a timed event that ends before the calendar start time will now show,
                        // move the end time to the end of the previous day.
                        const hours = getHours(end)
                        if (hours < this.calendarStartTime) {
                            end = addHours(end, -(hours + 1))
                        }
                    }
                    const event = {
                        start: format(start, eventFormat),
                        end: format(end, eventFormat),
                        name: x.title,
                        details: x.description,
                        data: x,
                        timed: x.isTimedEvent,
                    }

                    event.data.startAt = parseISO(event.start)
                    event.data.endAt = parseISO(event.end)
                    return event
                })
            }
        },

        onMessage(message) {
            const events = this.events.filter(
                (x) => x.data.appointmentId === message.appointmentId
            )
            const shouldEventBeVisible = areIntervalsOverlapping(
                {
                    start: parseISO(this.start),
                    end: addDays(parseISO(this.end), 1),
                },
                {
                    start: parseISO(message.startAt),
                    end: parseISO(message.endAt),
                }
            )
            if (events.length > 0) {
                const event = events[0]
                if (!shouldEventBeVisible) {
                    this.events.splice(this.events.indexOf(event), 1)
                } else {
                    const start = parseISO(message.startAt)
                    const end = parseISO(message.endAt)

                    event.start = format(start, eventDateTimeFormat)
                    event.end = format(end, eventDateTimeFormat)
                    event.data.startAt = start
                    event.data.endAt = end
                }
            } else {
                if (shouldEventBeVisible) {
                    this.refresh()
                }
            }
        },
        async initializeForProject() {
            this.createAppointmentStart = setHours(new Date(), 9)
            this.createAppointmentEnd = setHours(new Date(), 12)
        },
        updateRange({ start, end }) {
            this.start = start.date
            this.end = end.date

            this.refresh()
        },
        async showEvent({ nativeEvent, event }) {
            if (debug) console.log('Showing event')

            //if (this.dragEvent || this.createEvent) return
            if (this.createAppointment) return
            const open = () => {
                this.selectedEvent = event
                this.selectedElement = nativeEvent.target

                setTimeout(() => (this.selectedOpen = true), 10)
            }

            if (this.selectedOpen) {
                this.selectedOpen = false
                if (debug) console.log('closed')
                setTimeout(open, 10)
            } else {
                open()
            }

            nativeEvent.stopPropagation()
        },

        prev() {
            this.$refs.calendar.prev()
        },
        next() {
            this.$refs.calendar.next()
        },
        setToday() {
            this.focus = this.today
        },
        viewDay(event) {
            if (debug) console.log('viewDay')
            this.focus = parseISO(event.date)
            this.type = 'day'
        },
        toggleUser(userId) {
            const index = this.selectedUsers.indexOf(userId)
            if (index > -1) {
                this.selectedUsers.splice(index, 1)
            } else {
                this.selectedUsers.push(userId)
            }
        },
        timeClick(event) {
            if (debug) console.log('timeClick')
            if (this.dragEvent || this.createEvent || this.createAppointment)
                return
            // round minutes down to 30
            const minutes = parseInt(event.minute / 30) * 30

            let startDate = parseISO(event.date + ' ' + event.time)
            startDate = setMinutes(startDate, minutes)

            let durationHours = 1
            if (this.createAppointmentEnd && this.createAppointmentStart) {
                durationHours = differenceInHours(
                    this.createAppointmentEnd,
                    this.createAppointmentStart
                )
            }
            let endDate = addHours(startDate, durationHours)

            this.createAppointmentStart = startDate
            this.createAppointmentEnd = endDate
            this.createAppointment = true
        },
        getEventColor: function (event) {
            return getEventColor(event, this.colorMode)
        },
        getEventIcon,
        getUserNameInitials(username) {
            return userNameInitials(username)
        },
        startDrag(e) {
            console.log(e)
            if (debug) console.log('startDrag')
            if (e.event && e.event.data.type !== 'holiday') {
                this.dragEvent = e.event
                this.dragTime = null
                this.extendOriginal = null
            }

            this.lastEvent = 'startDrag'
        },
        startTime(e) {
            if (debug) console.log('Event: Clicked on timed event')
            const mouse = this.toDate(e)

            if (this.dragEvent && this.dragTime === null) {
                const start = this.toDate(this.dragEvent.start)

                this.dragTime = mouse.getTime() - start.getTime()
            }
            this.lastEvent = 'startTime'
        },
        extendBottom(event) {
            if (debug) console.log('extendBottom')
            this.extendEvent = event
            this.extendStart = this.toDate(event.start).getTime()
            this.extendOriginal = event.end
        },
        mouseMove(e) {
            if (debug) console.log('onMouseMove')
            if (!this.$session.roles.includes('planning_manage')) {
                return
            }
            if (this.dragEvent && this.dragTime !== null) {
                if (debug) console.log('Moving event')
                const start = this.toDate(this.dragEvent.start)
                const end = this.toDate(this.dragEvent.end)
                const duration = end.getTime() - start.getTime()
                const mouse = this.toDate(e)

                const newStartTime = mouse.getTime() - this.dragTime
                const newStart = new Date(this.roundTime(newStartTime))
                const newEnd = new Date(newStart.getTime() + duration)

                if (this.dragEvent.temp) {
                    this.createAppointmentStart = newStart
                    this.createAppointmentEnd = newEnd
                } else {
                    this.dragEvent.start = format(newStart, eventDateTimeFormat)
                    this.dragEvent.end = format(newEnd, eventDateTimeFormat)
                }
            } else if (this.extendEvent && this.extendStart !== null) {
                if (debug) console.log('Resizing event')
                const mouse = this.toDate(e).getTime()
                const mouseRounded = this.roundTime(mouse, false)
                const min = Math.min(mouseRounded, this.extendStart)
                const max = Math.max(mouseRounded, this.extendStart)

                if (this.extendEvent.temp) {
                    this.createAppointmentStart = new Date(min)
                    this.createAppointmentEnd = new Date(max)
                } else {
                    this.extendEvent.start = format(
                        new Date(min),
                        eventDateTimeFormat
                    )
                    this.extendEvent.end = format(
                        new Date(max),
                        eventDateTimeFormat
                    )
                }
            }
        },
        endDrag(e) {
            if (debug) console.log('enddrag')
            let showingEvent = false
            if (this.dragEvent || this.extendEvent) {
                const event = this.dragEvent || this.extendEvent
                if (this.type === 'month') {
                    if (debug) console.log('view = month')
                    if (e.nativeEvent.button === 0) {
                        showingEvent = true
                        this.showEvent({
                            nativeEvent: e.nativeEvent,
                            event: event,
                        })
                    }
                } else {
                    if (debug)
                        console.log(`IsTimedEvent = ${event.data.isTimedEvent}`)
                    const start = parseISO(event.start)
                    const end = parseISO(event.end)

                    if (debug) console.log('Start/End was:')
                    if (debug) console.log(event.data.startAt)
                    if (debug) console.log(event.data.endAt)
                    if (debug) console.log('Start/End is now:')
                    if (debug) console.log(start)
                    if (debug) console.log(end)
                    if (
                        !isEqual(start, event.data.startAt) ||
                        !isEqual(end, event.data.endAt)
                    ) {
                        if (debug) console.log('Start/End changed')
                        this.selectedElement = null
                        if (!event.temp) {
                            sendCommand('MoveAppointment', {
                                appointmentId: event.data.appointmentId,
                                startAt: formatISO(start),
                                endAt: formatISO(end),
                            })
                        }
                    } else {
                        if (debug) console.log('Start/End did not change')
                        if (e.nativeEvent.button === 0) {
                            showingEvent = true
                            this.showEvent({
                                nativeEvent: e.nativeEvent,
                                event: event,
                            })
                        }
                    }
                }
            }
            this.lastEvent = 'endDrag'
            this.dragTime = null
            this.dragEvent = null
            this.createEvent = null
            this.createStart = null
            this.extendOriginal = null
            if (!showingEvent) this.selectedOpen = false

            this.extendEvent = null
            this.extendStart = null
        },
        cancelDrag() {
            if (debug) console.log('canceldrag')
            if (this.extendEvent) {
                if (this.extendOriginal) {
                    this.extendEvent.end = this.extendOriginal
                } else {
                    const i = this.events.indexOf(this.extendEvent)
                    if (i !== -1) {
                        this.events.splice(i, 1)
                    }
                }
            }

            this.createEvent = null
            this.createStart = null
            this.dragTime = null
            this.dragEvent = null
            this.extendEvent = null
            this.extendStart = null
            //this.selectedOpen = false;
            this.lastEvent = 'cancelDrag'
        },
        roundTime(time, down = true) {
            const roundDownTime = 15 * 60 * 1000 // 15 minutes

            return down
                ? time - (time % roundDownTime)
                : time + (roundDownTime - (time % roundDownTime))
        },
        toDate(tms) {
            if (typeof tms === 'string') {
                return new Date(tms)
            } else {
                return new Date(
                    tms.year,
                    tms.month - 1,
                    tms.day,
                    tms.hour,
                    tms.minute
                )
            }
        },
        onKeydown(e) {
            if (!e.defaultPrevented) {
                if (e.keyCode === 27) {
                    // Escape
                    this.selectedOpen = false
                    this.selectedEvent = null
                    this.selectedElement = null
                }
            }
        },
    },
}
</script>
<style lang="scss" scoped>
.v-event-timed {
    user-select: none;
    -webkit-user-select: none;
}
.v-calendar {
    user-select: none;
    -webkit-user-select: none;
}

.v-event-drag-bottom {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 2px;
    height: 6px;
    cursor: ns-resize;

    &::after {
        display: none;
        position: absolute;
        left: 50%;
        height: 4px;
        border-top: 1px solid white;
        border-bottom: 1px solid white;
        width: 20px;
        margin-left: -10px;
        opacity: 0.8;
        content: '';
    }
}
.v-event-timed:hover .v-event-drag-bottom::after {
    display: block;
}

.v-current-time {
    height: 1px;
    background-color: red;
    position: absolute;
    left: -1px;
    right: 0;
    pointer-events: none;

    &.first::after {
        content: '';
        position: absolute;
        background-color: red;
        width: 7px;
        height: 7px;
        border-radius: 3.5px;
        margin-top: -3px;
        margin-left: -3px;
    }

    .v-current-time-time {
        display: none;
    }

    &.first .v-current-time-time {
        display: block;
        position: absolute;
        left: -60px;
        color: red;
        font-size: 10px;
        padding: 1px;
        background-color: white;
        width: 50px;
        height: 20px;
        text-align: right;
        top: -9px;
    }
}
.whiteOutline {
    outline: 1px solid white;
}
.v-list-item__action {
    margin-right: 10px !important;
}
</style>
<style>
.v-calendar .v-calendar-daily__day-interval .btn-add {
    right: 0;
    position: absolute;
    display: none;
}
.v-calendar:not(.creating) .v-calendar-daily__day-interval:hover .btn-add {
    display: inline-flex;
}
.v-calendar .v-event-timed-container {
    margin-right: 20px;
}
</style>
