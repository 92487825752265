<template>
	<v-card>
		<v-card-title
			>{{ $localize('RelationImportProcess.title') }}:
			{{ relationImport.originalFileName }}</v-card-title
		>
		<v-card-text>
			<v-container no-gutters fluid>
				<v-row>
					<v-col>{{
						$localize('RelationImport.rowsRemaining')
					}}</v-col>
					<v-col>{{ rowsRemaining }}</v-col>
				</v-row>
				<v-row>
					<v-col>{{
						$localize('RelationImport.rowsSucceeded')
					}}</v-col>
					<v-col>{{ rowsSuccess }}</v-col>
				</v-row>
				<v-row>
					<v-col>{{ $localize('RelationImport.rowsFailed') }}</v-col>
					<v-col>{{ rowsFailed }}</v-col>
				</v-row>

				<v-row>
					<v-col>
						<v-progress-linear
							height="30"
							:value="importProgress"
						/>
					</v-col>
				</v-row>
			</v-container>
			<v-simple-table dense>
				<template v-slot:default>
					<thead>
						<tr>
							<th class="text-left">
								{{ $localize('Import.failedRows.row') }}
							</th>
							<th class="text-left">
								{{ $localize('Import.failedRows.status') }}
							</th>
							<th class="text-left">
								{{ $localize('Import.failedRows.reason') }}
							</th>
						</tr>
					</thead>
					<tbody>
						<tr
							v-for="row in relationImport.failedRows"
							:key="row.rowNumber"
						>
							<td>{{ row.rowNumber }}</td>
							<td>{{ row.status }}</td>
							<td>{{ row.reason }}</td>
						</tr>
					</tbody>
				</template>
			</v-simple-table>
		</v-card-text>
		<v-card-actions></v-card-actions>
	</v-card>
</template>

<script>
import Vue from 'vue'
import { getRelationImport } from '@/services/api'
import { subscribe, unsubscribe } from '@/services/websocketHandler'
import { isGuid } from '@/services/validation'

export default {
	props: {
		relationImportId: {
			validator: isGuid,
			required: true,
		},
	},

	data() {
		return {
			relationImport: {},
			cancelManualRefresh: null,
			subscription: null,
		}
	},
	computed: {
		importProgress() {
			return (
				(this.relationImport.processedRows /
					this.relationImport.totalDataRows) *
				100
			)
		},
		rowsRemaining() {
			return (
				this.relationImport.totalDataRows -
				this.relationImport.processedRows
			)
		},
		rowsSuccess() {
			return this.relationImport.processedRows - this.rowsFailed
		},
		rowsFailed() {
			if (this.relationImport.failedRows) {
				return this.relationImport.failedRows.length
			}
			return 0
		},
	},
	async created() {
		await this.refresh()

		if (!this.checkRows()) {
			this.restartManualRefresh()
			this.subscription = subscribe(
				'RelationImportDataRowProcessed',
				this.onMessage,
				(x) => x.relationImportId === this.relationImportId
			)
		}
	},
	destroyed() {
		unsubscribe(this.subscription)
	},
	methods: {
		async refresh() {
			const { result, ok } = await getRelationImport.call({
				args: { relationImportId: this.relationImportId },
			})
			if (ok) {
				this.relationImport = result
			}
		},
		restartManualRefresh() {
			if (this.cancelManualRefresh != null) {
				clearTimeout(this.cancelManualRefresh)
				this.cancelManualRefresh = null
			}
			this.cancelManualRefresh = setTimeout(async () => {
				await this.refresh()
				if (!this.checkRows()) {
					this.restartManualRefresh()
				}
			}, 2000)
		},
		onMessage(message) {
			if (message.relationImportId == this.relationImportId) {
				this.restartManualRefresh()
				Vue.set(this.relationImport, 'processedRows', message.rowNumber)

				if (message.status !== 'success') {
					this.relationImport.failedRows.push({
						rowNumber: message.rowNumber,
						status: message.status,
						reason: message.reason,
					})
				}

				if (this.checkRows()) {
					unsubscribe(this.subscription)
					clearTimeout(this.cancelManualRefresh)
					this.cancelManualRefresh = null
					this.$emit('continue')
				}
			}
		},

		checkRows() {
			if (
				this.relationImport.totalDataRows ===
				this.relationImport.processedRows
			) {
				this.$emit('continue')
				return true
			}
			return false
		},
	},
}
</script>
