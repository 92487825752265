<template>
  <v-layout align-center justify-center>
    <v-flex style="max-width:400px;">
      <h1 class="white--text">{{ $localize('Login.subtitle.forgotPassword') }}</h1>
      <v-form @submit.prevent="recoverPassword" ref="recoverPasswordForm">
        <v-card class="pa-3">
          <v-card-subtitle>{{ $localize('Login.text.chooseNewPassword') }}</v-card-subtitle>
          <v-card-subtitle class="elevation-2 mb-3" v-if="message">{{ message }}</v-card-subtitle>
          <v-card-text>
            <v-text-field
              type="password"
              :label="$localize('Login.field.newPassword')"
              v-model="newPassword"
              :rules="[validation.required]"
              outlined
              hide-details
              autofocus
            />
          </v-card-text>
          <v-card-actions>
            <v-btn type="submit" class="success">{{ $localize('Login.button.saveNewPassword') }}</v-btn>
          </v-card-actions>

          <v-card-text>
            <v-divider />
          </v-card-text>
          <v-card-actions>
            <a @click="$router.push({name: 'login'})">{{ $localize('Login.link.backToLogin') }}</a>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-flex>
  </v-layout>
</template>

<script>
import sendCommand from "@/services/sendCommand";
import { required } from "@/services/validation";

export default {
  data() {
    return {
      newPassword: "",
      message: null,
      validation: { required }
    };
  },
  computed: {
    isLinkValid() {
      return false;
    }
  },
  created() {
    if (!this.$route.query.token || !this.$route.query.login) {
      this.$router.replace({
        name: "forgotPassword",
        query: { errorMessage: this.$localize("Login.message.linkBroken") }
      });
    }
  },
  methods: {
    async recoverPassword() {
      if (!this.$refs.recoverPasswordForm.validate()) return;

      const response = await sendCommand("RecoverUserPassword", {
        newPassword: this.newPassword,
        confirmNewPassword: this.newPassword,
        token: this.$route.query.token,
        login: this.$route.query.login
      });
      if (response.success) {
        this.newPassword = "";
        this.$router.push({
          name: "login",
          query: { message: this.$localize("Login.message.recoverySuccess") }
        });
      } else {
        if (response.code > 1) {
          this.$router.push({
            name: "forgotPassword",
            query: { errorMessage: response.message }
          });
        } else {
          this.message = response.message;
        }
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.v-card__actions {
  justify-content: center;
}
</style>