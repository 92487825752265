<template>
	<div>
		<select-article
			:label="label"
			:value="val"
			@input="$emit('input', $event)"
			:article-calculation-ids="
				articleCalculationIds || inputField.articleCalculationIds
			"
			:required="inputField.isRequired"
			:prepend-icon="null"
			:suffix="inputField.suffix"
			:hint="inputField.hint"
            @update:article="$emit('update:article', $event)"
		/>

	</div>
</template>

<script>
import SelectArticle from '@/main/components/forms/SelectArticle'

export default {
	components: { SelectArticle },
	props: {
		label: String,
		value: String,
		articleCalculationIds: Array,
		inputField: Object,
	},
	data() {
		return {
			val: this.value,
		}
	},
	watch: {
		value(val) {
			this.val = val
		},
	},
}
</script>
