<template>
  <v-card>
    <v-card-title>{{ $localize('ArticleImportUpload.title')}}</v-card-title>
    <v-card-text>
      <v-file-input ref="fileInput" prepend-icon="mdi-file-delimited" v-model="fileInput"></v-file-input>
    </v-card-text>
    <v-card-actions>
      <v-btn color="primary" @click="upload()">{{ $localize('form.button.next') }}</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import sendCommand from "@/services/sendCommand";
import { isGuid } from '@/services/validation'
export default {
  props: {
    relationGroupId: {
      validator: isGuid,
      required: true
    },
  },
  data() {
    return {
      fileInput: null
    };
  },

  methods: {
    async upload() {
      const formData = new FormData();
      var value = this.fileInput;

      if (value) {
        formData.append(value.name, value);
      }
      formData.append("command", JSON.stringify({ 
        relationGroupId: this.relationGroupId,
      }));
      
      const result = await sendCommand("CreateArticleImport", formData, {
        "Content-Type": "multipart/form-data"
      });
      if (result.success) {
        this.$router.push({
          name: "articleImport",
          params: { articleImportId: result.generatedId }
        });
      }
    }
  }
};
</script>
