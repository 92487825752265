<template>
  <div>
    <v-data-table
      :items="currentPage.items"
      :headers="headers"
      :expanded.sync="expanded"
      @click:row="onRowClick"
      :options.sync="options"
      :footer-props="{
        'items-per-page-options': [10,20,50]
      }"
      hide-default-footer
      disable-sort
    >
      <template v-slot:[`item.timestamp`]="{ item }">{{ $format.shortDateTime(item.timestamp) }}</template>
      <template v-slot:[`item.level`]="{ item }">
        <v-chip :color="levelColor(item.level)">{{item.level}}</v-chip>
      </template>

      <template v-slot:[`item.renderedMessage`]="{ item }">{{ renderMessage(item) }}</template>

      <template v-slot:expanded-item="{headers, item}">
        <td :colspan="headers.length">
          <!--<v-data-table :items="item.properties" :headers="Object.keys(item.properties)"></v-data-table>-->
          <table>
            <tr v-if="item.exception">
              <td colspan="2">
                <v-subheader>{{item.exception.message}}</v-subheader>
                <pre>{{item.exception.stackTrace}}</pre>
              </td>
            </tr>
            <tr v-for="(value, key) in item.properties" :key="key">
              <td>{{key}}</td>
              <td>{{value}}</td>
            </tr>
          </table>
        </td>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { getMessageLog } from "@/services/api";
export default {
  data() {
    return {
      messages: [],
      currentPage: {
        items: [],
      },
      options: {
        itemsPerPage: 50,
      },
      expanded: [],
      headers: [
        { text: "Date", value: "timestamp" },
        { text: "Level", value: "level" },

        { text: "Message", value: "renderedMessage" },
        { text: "Exception", value: "exception.Message" },
      ],

      addFilter: false,
      newFilter: {},

      filterRuleId: 0,
    };
  },
  computed: {
    filterRules() {
      let rules = [];
      Object.getOwnPropertyNames(this.$route.query).forEach((key) => {
        const value = this.$route.query[key];

        const keyParts = key.split("-");
        const property = keyParts[0];
        const comparator = keyParts[1];
        rules.push({
          property: property,
          comparator: comparator,
          value: value,
        });
      });

      return rules;
    },
  },
  created: function () {
    this.refresh();
  },
  methods: {
    async refresh() {
      this.currentPage = await getMessageLog.create().load(this.$route.query);
    },

    onRowClick(item, obj) {
      obj.expand(!obj.isExpanded);
    },

    renderMessage(message) {
      const pattern = /\{([\w:]+)\}/gim;
      const renderedMessage = message.renderedMessage.replace(
        pattern,
        function (a, key) {
          var property = key.split(":")[0];
          property = property.charAt(0).toLowerCase() + property.slice(1);
          if (
            !Object.prototype.hasOwnProperty.call(
              message.properties,
              property
            ) ||
            message.properties[property] === undefined
          ) {
            return property;
          }
          return message.properties[property];
        }
      );

      return renderedMessage;
    },

    levelColor(level) {
      if (level === "information") {
        return "blue";
      }
      if (level === "warning") {
        return "yellow";
      }
      if (level === "error") {
        return "warning";
      }
      if (level === "fatal") {
        return "error";
      }

      throw new Error("Unknown level: " + level);
    },
    statusCodeColor(statusCode) {
      if (statusCode < 200) {
        return "grey";
      }
      if (statusCode < 300) {
        return "success";
      }
      if (statusCode < 400) {
        return "blue";
      }
      if (statusCode < 500) {
        return "warning";
      }
      return "error";
    },
    elapsedMillisecondsColor(elapsedMilliseconds) {
      if (elapsedMilliseconds < 50) {
        return "success";
      }
      if (elapsedMilliseconds < 200) {
        return "warning";
      }
      return "error";
    },
  },
};
</script>
<style lang="scss" scoped>
pre{
  white-space:pre-wrap;
}
</style>