<template>
	<dialog-form
		:value="value"
		@input="$emit('input', $event)"
		:title="$localize('DeleteDashboard.title')"
		@commit="save"
		action="delete"
	>
		<p>{{ $localize('DeleteDashboard.description') }}</p>
	</dialog-form>
</template>

<script>
import DialogForm from '@/shared/components/DialogForm'

import sendCommand from '@/services/sendCommand'
import PubSub from 'pubsub-js'
import { isGuid } from '@/services/validation'
export default {
	components: { DialogForm },

	props: {
		value: {
			type: Boolean,
			default: false,
		},
		dashboardId: {
			validator: isGuid,
			required: true,
		},
	},

	methods: {
		async save(callback) {
			const result = await sendCommand('DeleteDashboard', {
				dashboardId: this.dashboardId,
			})

			if (result.success) {
				this.$emit('saved')
				PubSub.publish('RefreshDashboardMenu')
			}
			callback({ success: result.success })
		},
	},
}
</script>
