<template>
	<dialog-form
		:value="value"
		@input="$emit('input', $event)"
		:title="$localize('SelectArticleCalculations.title')"
		@commit="save"
		:allow-continue="false"
		max-width="1000"
	>
		<v-skeleton-loader type="card" :loading="loading">
			<v-simple-table dense>
				<template v-slot:default>
					<thead>
						<tr>
							<th>{{ $localize('Calculation.name') }}</th>
							<th colspan="4">
								{{
									$localize(
										'ChangeArticleCalculation.articleCalculations'
									)
								}}
							</th>
						</tr>
					</thead>
					<tbody>
						<tr
							v-for="calculation in calculations"
							:key="calculation.calculationId"
						>
							<td>{{ calculation.name }}</td>
							<template v-for="input in calculation.input">
								<td
									v-if="input.articleCalculationIds"
									:key="input.key"
								>
									<v-checkbox
										v-for="(
											articleCalculationId, index
										) in input.articleCalculationIds"
										:key="`${input.key}-${index}`"
										v-model="enabledCalculations"
										:value="articleCalculationId"
										:label="
											getCalculationFieldName(
												articleCalculationId
											)
										"
										hide-details
										dense
										class="ma-0"
									/>
								</td>
							</template>
						</tr>
					</tbody>
				</template>
			</v-simple-table>
		</v-skeleton-loader>
	</dialog-form>
</template>

<script>
import DialogForm from '@/shared/components/DialogForm'
import { isGuid } from '@/services/validation'
import sendCommand from '@/services/sendCommand'
import {
	getArticleById,
	getCalculationTypes,
	getAllPublishedCalculations,
} from '@/services/api'
export default {
	components: { DialogForm },
	props: {
		value: {
			type: Boolean,
			default: false,
		},
		articleId: {
			validator: isGuid,
		},
	},
	data() {
		return {
			loading: false,
			article: {},
			calculations: [],
			calculationFields: [],
			enabledCalculations: [],
		}
	},
	async created() {
		this.loading = true
		await this.loadArticle()
		await this.loadAllPublishedCalculations()
		await this.loadCalculationFields()
		this.loading = false
	},
	methods: {
		async loadArticle() {
			const { result, ok } = await getArticleById.call({
				args: { articleId: this.articleId },
			})
			if (ok) {
				this.article = result
                this.enabledCalculations = this.article.calculations
			}
		},
		async loadAllPublishedCalculations() {
			const { result, ok } = await getAllPublishedCalculations.call()

			if (ok) {
				this.calculations = result.filter((calculation) =>
					calculation.input.find(
						(input) => input.articleCalculationIds
					)
				)
			}
		},
		async loadCalculationFields() {
			const { result, ok } = await getCalculationTypes.call()
			if (ok) {
				this.calculationFields = result
			}
		},
		async save(callback) {
			const result = await sendCommand('ChangeArticleCalculations', {
				articleId: this.articleId,
				calculations: this.enabledCalculations,
			})
			if (result.success) {
				this.$emit('saved')
			}
			callback({ success: result.success })
		},
		getCalculationFieldName(calculationFieldId) {
			const calculationField = this.calculationFields.find(
				(x) => x.calculationFieldId === calculationFieldId
			)
			if (calculationField) {
				return calculationField.name
			}
			return ''
		},
	},
}
</script>
