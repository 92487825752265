<template>
	<dialog-form
		:value="value"
		@input="$emit('input', $event)"
		:title="$localize('CreateSavedProduct.title')"
		:allow-continue="false"
		@commit="save"
	>
		<v-text-field
			:label="$localize('Product.product')"
			v-model="createSavedProduct.product"
			autofocus
			:rules="[validation.maxLength(200)]"
		/>
		<v-text-field
			:label="$localize('Product.description')"
			v-model="createSavedProduct.description"
			:rules="[validation.maxLength(500)]"
		/>
		<input-number
			:label="$localize('Product.discount')"
			v-model="createSavedProduct.discount"
			:rules="[validation.number, validation.numberRange(5,2)]"
		/>
		<input-number
			:label="$localize('Product.price')"
			v-model="createSavedProduct.price"
		/>
		<v-textarea
			:label="$localize('Product.specifications')"
			v-model="createSavedProduct.specifications"
			:rules="[validation.maxLength(2000)]"
		/>
		<v-switch
			:label="$localize('Product.offerShowPrice')"
			v-model="createSavedProduct.offerShowPrice"
			hide-details
		/>
		<v-row>
			<v-col>
				<v-switch
					:label="$localize('Product.offerShowParts')"
					v-model="createSavedProduct.offerShowParts"
					hide-details
				/>
			</v-col>
			<v-col>
				<v-switch
					:label="$localize('Product.offerShowPartPrices')"
					v-model="createSavedProduct.offerShowPartPrices"
					:disabled="
						!createSavedProduct.offerShowParts ||
						!createSavedProduct.offerShowPrice
					"
					hide-details
				/>
			</v-col>
		</v-row>
        <select-vat v-model="createSavedProduct.vatPercentage" clearable />
	</dialog-form>
</template>

<script>
import DialogForm from '@/shared/components/DialogForm'
import InputNumber from '@/main/components/forms/InputNumber'
import SelectVat from '@/main/components/forms/SelectVat'

import sendCommand from '@/services/sendCommand'
import { required, number, numberRange, maxLength } from '@/services/validation'

export default {
	components: { DialogForm, InputNumber, SelectVat },
	props: {
		value: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			createSavedProduct: {
				offerShowParts: false,
				offerShowPartPrices: false,
				offerShowPrice: true,
			},
			validation: { required, number, numberRange, maxLength },
		}
	},
	watch: {
		'createSavedProduct.offerShowParts'() {
			if (!this.createSavedProduct.offerShowParts) {
				this.createSavedProduct.offerShowPartPrices = false
			}
		},
		'createSavedProduct.offerShowPrice'() {
			if (!this.createSavedProduct.offerShowPrice) {
				this.createSavedProduct.offerShowPartPrices = false
			}
		},
	},
	methods: {
		async save(callback) {
			const result = await sendCommand(
				'CreateSavedProduct',
				this.createSavedProduct
			)
			if (result.success) {
				this.$emit('saved')
                this.$router.push({
                    name: 'savedProductDetails',
                    params: { savedProductId: result.generatedId },
                })
			}

			callback({ success: result.success })
		},
	},
}
</script>
