<template>
  <v-select
    :label="$localize('SelectDeliveryMethod.field.deliveryMethod')"
    :items="items"
    :item-text="(item) => $localize('DeliveryMethod.' + item.key)"
    item-value="key"
    :value="selectedDeliveryMethod"
    @input="onItemSelected($event)"
    :rules="rules"
    prepend-icon="mdi-truck"
    :autofocus="autofocus"
    :clearable="!required"
  />
</template>

<script>
import { required } from "@/services/validation";

export default {
  props: {
    value: String,
    autofocus: Boolean,
    required: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      selectedDeliveryMethod: this.value,
      items: [
        { key: "pickup" },
        { key: "delivery" },
        { key: "montage" },
        { key: "notapplicable" }
      ],
    };
  },
  computed : {
    rules(){
      if(this.required){
        return [required]
      }
      return [];
    }
  },
  watch: {
    value(){
      this.selectedDeliveryMethod = this.value;
    }
  },
  async created() {
    if (!this.value) {
      this.onItemSelected(this.$settings.initialDeliveryMethod);
    }
  },
  methods: {
    onItemSelected(item) {
      this.selectedDeliveryMethod = item;
      this.$emit("input", item);
    }
  }
};
</script>
