<template>
	<div>
		<v-alert v-if="!smtpConfigured" type="error">
			<div class="mb-3">
				Uw smtp-instellingen zijn nog niet geconfigureerd. Klik op de
				onderstaande knop om uw smtp te configureren.
			</div>
			<v-btn @click="navSmtpSettings">
				SMTP configureren
				<v-icon>mdi-arrow-right</v-icon>
			</v-btn>
		</v-alert>

		<v-select
			:items="fromItems"
			:item-text="formatAddress"
			:label="$localize('SendEmailForm.from')"
			prepend-icon="mdi-at"
			v-model="data.from"
			@input="emit()"
			:rules="[rules.required]"
		/>
		<v-combobox
			:items="fromItems"
			:item-text="formatAddress"
			:label="$localize('SendEmailForm.cc')"
			prepend-icon="mdi-at"
			multiple
			clearable
			v-model="data.cc"
			@input="emit()"
			:rules="[rules.email]"
		/>
		<v-combobox
			:items="toItems"
			:item-text="formatAddress"
			item-value="email"
			:label="$localize('SendEmailForm.to')"
			prepend-icon="mdi-at"
			v-model="data.to"
			@input="emit()"
			:rules="[rules.required, rules.email]"
			multiple
		/>

		<v-text-field
			:label="$localize('SendEmailForm.subject')"
			:value="data.subject"
			@input="
				data.subject = $event
				emit()
			"
			:rules="[rules.required]"
		/>
		<v-textarea
			:label="$localize('SendEmailForm.message')"
			:value="data.body"
			@input="
				data.body = $event
				emit()
			"
			:rules="[rules.required]"
		/>
	</div>
</template>

<script>
import { isGuid } from '@/services/validation'
import { getRelationEmails, getEmails } from '@/services/api'
import { required, email } from '@/services/validation'

export default {
	props: {
		value: {
			type: Object,
		},
		relationId: {
			validator: isGuid,
		},
		contactId: {
			validator: isGuid,
		},
	},
	data() {
		return {
			data: { cc: [], to: [] },
			toItems: [],
			fromItems: [],

			rules: { required, email: this.validateEmail },
			smtpConfigured: false,
		}
	},
	watch: {
		value: {
			immediate: true,
			handler(val) {
				this.data = val || {}
				if (!this.data.cc) {
					this.data.cc = []
				}
				if (!this.data.to) {
					this.data.to = []
				}
			},
		},
		relationId() {
			this.refresh()
		},
		contactId() {
			this.refresh()
		},
		template() {
			this.refresh()
		},
	},
	async created() {
		const { result, ok } = await getEmails.call()
		if (ok) {
			this.smtpConfigured = result.isSmtpConfigured
			this.fromItems = result.emails
			this.fromItems = this.fromItems.map((x) => ({
				...x,
				displayName: x.name,
			}))
		}
		this.refresh()
	},
	methods: {
		formatAddress(x) {
			if (x.name) {
				return x.name + ' <' + x.email + '>'
			}
			if (x.email) {
				return x.email
			}
			return x
		},
		validateEmail(value) {
			for (let index in value) {
				const emailString = value[index]
				if (typeof emailString === 'string') {
					const emailValidation = email(emailString)
					if (emailValidation !== true) {
						return emailValidation
					}
				}
			}
			return true
		},
		emit() {
			this.$emit('input', this.data)
		},
		async refresh() {
			if (this.relationId) {
				const { result, ok } = await getRelationEmails
					.call({ args: { relationId: this.relationId } })

				if (ok) {
					this.toItems = result.map((x) => ({
						...x,
						displayName: x.name,
					}))
				}
			}
			const contactEmail = this.toItems.filter(
				(x) => x.contactId === this.contactId
			)[0]

			if (contactEmail && this.data.to.length === 0) {
				this.data.to.push(contactEmail)
			}
			if (!this.data.from) {
				// automatically get a from address
				const company = this.fromItems.filter(
					(x) => x.type === 'company'
				)
				if (company.length > 0) {
					this.data.from = company[0].email
				} else if (this.fromItems.length > 0) {
					this.data.from = this.fromItems[0]
				}
			} else {
				if (typeof this.data.from === 'string') {
					// Add display name to from email
					const fromWithDisplayName = this.fromItems.filter(
						(x) => x.email === this.data.from
					)
					if (fromWithDisplayName.length > 0) {
						this.data.from = fromWithDisplayName[0]
					}
				}
			}

			// Add display name to cc
			for (let index = 0; index < this.data.cc.length; index++) {
				var cc = this.data.cc[index]
				if (typeof cc === 'string') {
					const ccWithDisplayName = this.fromItems.filter(
						(x) => x.email === cc
					)
					if (ccWithDisplayName.length > 0) {
						this.data.cc[index] = ccWithDisplayName[0]
					}
				}
			}
		},
		navSmtpSettings() {
			this.$emit('nav', {
				name: 'settingsOverviewCategory',
				params: { category: 'smtp' },
			})
		},
	},
}
</script>
