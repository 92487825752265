<template>
	<div>
		<v-row>
			<v-col>
				<v-toolbar flat height="36">
					<v-toolbar-items
						v-if="$session.roles.includes('relations_manage')"
					>
						<v-btn
							@click="enterContactInformation = true"
							class="mr-3"
						>
							<v-icon>mdi-pencil</v-icon>
						</v-btn>
					</v-toolbar-items>
					<v-toolbar-title>{{ contact.name }}</v-toolbar-title>
					<v-spacer></v-spacer>
					<v-toolbar-items
						v-if="$session.roles.includes('relations_manage')"
					>
						<v-btn
							v-if="!contact.isTrashed"
							@click="trash()"
							class="ml-3 btnTrashContact"
						>
							<span v-if="$vuetify.breakpoint.smAndUp">{{
								$localize('form.button.trash')
							}}</span>
							<v-icon>mdi-delete</v-icon>
						</v-btn>
						<v-btn
							v-if="contact.isTrashed"
							@click="untrash()"
							class="ml-3 btnUntrashContact"
						>
							<span v-if="$vuetify.breakpoint.smAndUp">{{
								$localize('form.button.untrash')
							}}</span>
							<v-icon>mdi-delete-restore</v-icon>
						</v-btn>
						<v-btn
							@click="deleteContact = true"
							class="ml-3 btnDeleteContact"
						>
							<span v-if="$vuetify.breakpoint.smAndUp">{{
								$localize('form.button.delete')
							}}</span>
							<v-icon>mdi-close</v-icon>
						</v-btn>
					</v-toolbar-items>
				</v-toolbar>
			</v-col>
		</v-row>
		<v-row>
			<v-col class="col-12 col-md-6">
				<v-row wrap dense class="pa-4" cols="6">
					<v-col cols="6">{{ $localize('Contact.name') }}</v-col>
					<v-col cols="6">{{ contact.name }}</v-col>
					<v-col cols="6">{{
						$localize('Contact.salutation')
					}}</v-col>
					<v-col cols="6">{{ contact.salutation }}</v-col>
					<v-col cols="6">{{ $localize('Contact.bornDate') }}</v-col>
					<v-col cols="6">{{
						$format.shortDate(contact.bornDate)
					}}</v-col>
					<v-col cols="6">{{
						$localize('Contact.mobileNumber')
					}}</v-col>
					<v-col cols="6">{{ contact.mobileNumber }}</v-col>
					<v-col cols="6">{{
						$localize('Contact.alternativeNumber')
					}}</v-col>
					<v-col cols="6">{{ contact.alternativeNumber }}</v-col>
					<v-col cols="6">{{ $localize('Contact.email') }}</v-col>
					<v-col cols="6">{{ contact.email }}</v-col>
					<v-col cols="6">{{ $localize('Contact.website') }}</v-col>
					<v-col cols="6">{{ contact.website }}</v-col>
					<v-col cols="6">{{ $localize('Contact.position') }}</v-col>
					<v-col cols="6">{{ contact.position }}</v-col>
					<v-col cols="6">{{ $localize('Contact.note') }}</v-col>
					<v-col cols="6">{{ contact.note }}</v-col>
				</v-row>
			</v-col>
		</v-row>
		<delete-contact
			v-if="deleteContact"
			v-model="deleteContact"
			:contact-id="contactId"
			@saved="$emit('closed')"
		/>
		<enter-contact-information
			v-if="enterContactInformation"
			v-model="enterContactInformation"
			:contact-id="contactId"
			@saved="
				refresh()
				$emit('saved')
			"
		/>
	</div>
</template>

<script>
import { getContactById } from '@/services/api'
import sendCommand from '@/services/sendCommand'
import DeleteContact from './dialogs/DeleteContact'
import EnterContactInformation from './dialogs/EnterContactInformation'
import { isGuid } from '@/services/validation'

export default {
	components: { DeleteContact, EnterContactInformation },
	props: {
		contactId: {
			required: true,
			validator: isGuid,
		},
	},
	data() {
		return {
			contact: {},
			deleteContact: false,
			enterContactInformation: false,
		}
	},

	created() {
		this.refresh()
	},

	methods: {
		async refresh() {
			const { result, ok } = await getContactById.call({
				args: { contactId: this.contactId },
			})
			if (ok) {
				this.contact = result
			}
		},

		async trash() {
			const result = await sendCommand('TrashContact', {
				contactId: this.contact.contactId,
			})
			if (result.success) {
				this.refresh()
				this.$emit('saved')
				this.$emit('closed')
			}
		},
		async untrash() {
			const result = await sendCommand('UntrashContact', {
				contactId: this.contact.contactId,
			})
			if (result.success) {
				this.refresh()
				this.$emit('saved')
				this.$emit('closed')
			}
		},
	},
}
</script>
