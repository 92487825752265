<template>
	<v-row>
		<v-col class="cols-4">
			<v-list>
				<v-subheader>{{
					$localize('Project.phaseRequest')
				}}</v-subheader>
				<v-list-item-group v-model="target">
					<v-list-item
						v-for="item in requestStatusses"
						:key="item.projectStatus.projectStatusId"
						:value="item"
						@click="confirmingStatus = true"
					>
						<v-list-item-content>
							<v-list-item-title>{{
								item.projectStatus.name
							}}</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
				</v-list-item-group>
			</v-list>
		</v-col>
		<v-col class="cols-4">
			<v-list>
				<v-subheader>{{ $localize('Project.phaseOffer') }}</v-subheader>
				<v-list-item-group v-model="target">
					<v-list-item
						v-for="item in offerStatusses"
						:key="item.projectStatus.projectStatusId"
						:value="item"
						@click="confirmingStatus = true"
					>
						<v-list-item-content>
							<v-list-item-title>{{
								item.projectStatus.name
							}}</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
				</v-list-item-group>
			</v-list>
		</v-col>
		<v-col class="cols-4">
			<v-list>
				<v-subheader>{{ $localize('Project.phaseOrder') }}</v-subheader>
				<v-list-item-group v-model="target">
					<v-list-item
						v-for="item in orderStatusses"
						:key="item.projectStatus.projectStatusId"
						:value="item"
						@click="confirmingStatus = true"
					>
						<v-list-item-content>
							<v-list-item-title>{{
								item.projectStatus.name
							}}</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
				</v-list-item-group>
			</v-list>
		</v-col>
		<v-col class="cols-4" v-if="project.phaseNumber > 0">
			<v-list>
				<v-subheader>{{
					$localize('Project.phaseInvoice')
				}}</v-subheader>
				<v-list-item-group v-model="target">
					<v-list-item
						v-for="item in invoiceStatusses"
						:key="item.projectStatus.projectStatusId"
						:value="item"
						@click="confirmingStatus = true"
					>
						<v-list-item-content>
							<v-list-item-title>{{
								item.projectStatus.name
							}}</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
				</v-list-item-group>
			</v-list>
		</v-col>
		<v-col class="cols-4" v-if="project.phaseNumber > 0">
			<v-list>
				<v-subheader>{{
					$localize('Project.phaseArchive')
				}}</v-subheader>
				<v-list-item-group v-model="target">
					<v-list-item
						v-for="item in archiveStatusses"
						:key="item.projectStatus.projectStatusId"
						:value="item"
						@click="confirmingStatus = true"
					>
						<v-list-item-content>
							<v-list-item-title>{{
								item.projectStatus.name
							}}</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
				</v-list-item-group>
			</v-list>
		</v-col>
		<confirm-status
			v-if="confirmingStatus && target"
			v-model="confirmingStatus"
			:project-id="projectId"
			:target-phase="target.phase"
			:project-status="target.projectStatus"
			:existing-note="project.projectStatusNote"
			@saved="$emit('saved', $event)"
		/>
	</v-row>
</template>

<script>
import { isGuid } from '@/services/validation'

import { getEnabledProjectStatuses } from '@/services/api'
import ConfirmStatus from '../../dialogs/ConfirmStatus'

export default {
	components: { ConfirmStatus },
	props: {
		value: Boolean,
		projectId: {
			validator: isGuid,
			required: true,
		},
		project: {
			type: Object,
			required: true,
		},
	},

	data() {
		return {
			loading: false,
			saving: false,

			statusses: [],
			note: null,
			target: {},
			targetPhase: null,
			targetProjectStatus: null,
			productionDate: null,
			deadline: null,

			confirmingStatus: false,
		}
	},
	computed: {
		requestStatusses() {
			return this.statusses
				.filter((x) => x.request)
				.map((x) => ({
					phase: 'request',
					projectStatus: x,
				}))
		},
		offerStatusses() {
			return this.statusses
				.filter((x) => x.offer)
				.map((x) => ({
					phase: 'offer',
					projectStatus: x,
				}))
		},
		orderStatusses() {
			return this.statusses
				.filter((x) => x.order)
				.map((x) => ({
					phase: 'order',
					projectStatus: x,
				}))
		},
		invoiceStatusses() {
			return this.statusses
				.filter((x) => x.invoice)
				.map((x) => ({
					phase: 'invoice',
					projectStatus: x,
				}))
		},
		archiveStatusses() {
			return this.statusses
				.filter((x) => x.archive)
				.map((x) => ({
					phase: 'archive',
					projectStatus: x,
				}))
		},
		allStatusses() {
			return [
				...this.requestStatusses,
				...this.offerStatusses,
				...this.orderStatusses,
				...this.invoiceStatusses,
				...this.archiveStatusses,
			]
		},
	},
	async created() {
		this.loading = true
		this.productionDate = this.project.productionDate
		this.deadline = this.project.deadline
		this.newStatus = this.project.projectStatusId
		const { result, ok } = await getEnabledProjectStatuses.call()
		if (ok) {
			this.statusses = result
            this.target = this.allStatusses.filter(
                (x) =>
                    x.projectStatus.projectStatusId ===
                        this.project.projectStatusId &&
                        x.phase === this.project.phase
            )[0]
		}
		this.loading = false
	},
	watch: {
		target: {
			immediate: true,
			handler() {
				this.$emit('target', this.target)
			},
		},
	},
	methods: {
		projectStatusIcon(projectStatus) {
			if (projectStatus.sendEmailNotification) {
				return 'mdi-email-send'
			}
			if (projectStatus.actionListRecallDays > 0) {
				return 'mdi-clock-alert'
			}
			if (projectStatus.showInActionList) {
				return 'mdi-bullhorn'
			}
			return null
		},
	},
}
</script>

<style lang="scss" scoped>
.v-item-group {
	flex-wrap: wrap;
}
</style>
