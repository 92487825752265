<template>
	<v-card>
		<v-card-text>
			<v-row>
				<v-col>
					<v-form ref="form">
						<select-relation-group
							v-model="input.$$relationGroup"
						/>
						<v-divider />
						<template v-for="inputField in calculation.input">
							<template v-if="inputField.type === 'number'">
								<number-field
									:key="inputField.key"
									:label="inputField.label"
									:input-field="inputField"
									v-model="input[inputField.key]"
								/>
							</template>
							<template v-if="inputField.type === 'text'">
								<text-field
									:key="inputField.key"
									:label="inputField.label"
									:input-field="inputField"
									v-model="input[inputField.key]"
								/>
							</template>
							<template v-else-if="inputField.type === 'article'">
								<article-field
									:key="inputField.key"
									:label="inputField.label"
									:input-field="inputField"
									v-model="input[inputField.key]"
									:article-calculation-ids="
										inputField.articleCalculationIds
									"
								/>
							</template>
						</template>
					</v-form>
				</v-col>
				<v-col>
					<calculation-output
						:state="output"
						:grid="calculation.outputGrid"
						test
					/>
					<div
						v-for="(logEntry, index) in logs"
						:key="index"
						:class="logLevelColor(logEntry.level) + '--text'"
					>
						<div>{{ logEntry.message }}</div>
						at:
						<div
							v-for="(stackFrame, index) in logEntry.stack"
							:key="index"
							class="pl-3"
						>
							{{ stackFrame }}
						</div>
					</div>
				</v-col>
			</v-row>
			<v-row>
				<v-col>
					<v-btn color="primary" @click="testCalculation()">{{
						$localize('CalculationTester.button.test')
					}}</v-btn>
					<v-checkbox
						:label="
							$localize('CalculationTester.label.displayRawData')
						"
						v-model="showRawData"
					/>
				</v-col>
			</v-row>
			<v-row v-if="showRawData">
				<v-col>
					<v-subheader>{{
						$localize('CalculationTester.label.rawInput')
					}}</v-subheader>
					<pre>{{ JSON.stringify(input, undefined, 2) }}</pre>
				</v-col>
				<v-col>
					<v-subheader>{{
						$localize('CalculationTester.label.rawOutput')
					}}</v-subheader>
					<pre>{{ JSON.stringify(output, undefined, 2) }}</pre>
				</v-col>
			</v-row>
		</v-card-text>
		<v-card-actions>
			<v-btn @click="$emit('continue')">{{
				$localize('form.button.next')
			}}</v-btn>
		</v-card-actions>
	</v-card>
</template>

<script>
import { isGuid } from '@/services/validation'
import { getCalculationById, testCalculation } from '@/services/api'
import NumberField from './components/NumberField'
import ArticleField from './components/ArticleField'
import TextField from './components/TextField'
import SelectRelationGroup from '@/main/components/forms/SelectRelationGroup'
import CalculationOutput from './components/CalculationOutput'

export default {
	components: {
		NumberField,
		ArticleField,
		TextField,
		SelectRelationGroup,
		CalculationOutput,
	},
	props: {
		calculationId: {
			validator: isGuid,
			required: true,
		},
	},

	data() {
		return {
			showRawData: false,
			calculation: {},
			input: {},
			output: {},
			logs: [],
		}
	},

	async created() {
		const { result, ok } = await getCalculationById.call({
			args: { calculationId: this.calculationId, version: -1 },
		})

		if (ok) {
			this.calculation = result
		}
	},
	methods: {
		logLevelColor(level) {
			if (level === 3) {
				return 'warning'
			}
		},

		async testCalculation() {
			if (!this.$refs.form.validate()) {
				return
			}
			const { result, ok } = await testCalculation.call({
				args: { calculationId: this.calculationId, version: -1 },
				payload: this.input,
			})

			if (ok) {
				this.output = result.state
				this.logs = result.logs
			}
		},
	},
}
</script>
