<template>
  <div>
    <v-menu v-model="editing" :close-on-content-click="false">
      <template v-slot:activator="{ on }">
        <v-text-field
          :label="label || $localize('InputColor.label')"
          :hint="hint || $localize('InputColor.hint')"
          :value="value"
          prepend-icon="mdi-palette"
          v-on="on"
          persistent-hint
          :filled="filled"
          :rules="[validation.maxLength(7)]"
        >
          <template v-slot:append>
            <v-chip :color="value" fab small flat></v-chip>
          </template>
        </v-text-field>
      </template>
      <v-form ref="form">
        <v-card>
          <v-card-title>{{
            label || $localize("InputColor.label")
          }}</v-card-title>
          <v-card-text>
            <v-color-picker show-swatches v-model="color"></v-color-picker>
          </v-card-text>
          <v-card-actions>
            <v-btn @click="editing = false">{{
              $localize("form.button.cancel")
            }}</v-btn>
            <v-btn @click="saveColor()" class="success">{{
              $localize("form.button.save")
            }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-menu>
    <v-menu></v-menu>
  </div>
</template>

<script>
import { maxLength } from "@/services/validation";
export default {
  props: {
    value: String,
    label: String,
    hint: String,
    filled: Boolean,
  },

  data() {
    return {
      editing: false,
      color: "",
      validation: {maxLength}
    };
  },
  watch: {
    value: {
      immediate: true,
      handler(val) {
        if (val) {
          this.color = val + "FF";
        }
      },
    },
  },
  methods: {
    saveColor() {
      this.$emit("input", this.color.substr(0, 7));
      this.editing = false;
    },
  },
};
</script>