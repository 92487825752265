<template>
  <v-dialog :value="value" @input="$emit('input', $event)" max-width="600px">
    <v-card>
      <v-card-title>Set Password</v-card-title>
      <v-form id="formSetUserPassword" @submit.prevent="save()" class="pa-3">
        <v-text-field class="inputPassword" type="password" label="Password" v-model="setUserPasswordCommand.newPassword" />
        <v-text-field
          class="inputPasswordRepeat"
          type="password"
          label="Repeat password"
          v-model="setUserPasswordCommand.newPasswordRepeat"
        />

        <v-btn @click="$emit('input', false)" class="mt-3 mr-3 btnClose">{{ $localize('form.button.cancel') }}</v-btn>
        <v-btn type="submit" class="success mt-3 mr-3 btnSave">{{ $localize('form.button.save') }}</v-btn>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { isGuid } from "@/services/validation"

import { getTenantUserById } from "@/services/api";
import sendCommand from "@/services/sendCommand";

export default {
  props: {
    userId: {
      validator: isGuid,
      required: true
    },
    value: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      setUserPasswordCommand: {}
    };
  },
  created() {
    this.reload();
  },

  methods: {
    async reload() {
      this.user = await getTenantUserById
        .create({ userId: this.$route.params.userId })
        .load();

      this.setUserPasswordCommand.userId = this.user.userId;
    },

    async save() {
      const result = await sendCommand(
        "SetUserPassword",
        this.setUserPasswordCommand
      );
      if (result.success) {
        this.$emit("input", false);
        this.$emit("saved");
      }
    }
  }
};
</script>