<template>
	<v-card>
		<v-card-text>
			<v-flex>
				<v-subheader>
					{{ $localize('CalculationInputEditor.title') }}
					<v-menu
						v-model="creating"
						:close-on-content-click="false"
						offset-y
					>
						<template v-slot:activator="{ on }">
							<v-btn small v-on="on" @click="createNew()">{{
								$localize('form.button.add')
							}}</v-btn>
						</template>
						<edit-input-field
							v-model="newInputField"
							:field-count="inputFields.length"
							:calculation-id="value.calculationId"
							@saved="addField()"
						/>
					</v-menu>
				</v-subheader>
				<draggable
					:list="inputFields"
					handle=".grip"
					@start="dragging = true"
					@end="
						dragging = false
						isDirty = true
					"
					class="list-group"
					ghost-class="ghost"
				>
					<v-row
						dense
						v-for="(field, index) in inputFields"
						:key="field.key"
						cols="auto"
					>
						<v-col cols="auto" class="grip">
							<v-icon>mdi-drag</v-icon>
						</v-col>
						<v-col class="blue--text">{{
							$localize(
								'CalculationInputEditor.subtitle.inputField'
							)
						}}</v-col>
						<v-col class="teal--text">
							{{ field.type }}
							<span v-if="field.isRequired">required</span>
							<span v-else>optional</span>
						</v-col>
						<v-col class="black--text">{{ field.key }}</v-col>
						<v-col class="black--text">{{ field.label }}</v-col>
						<v-col cols="auto">
							<v-menu
								v-model="editing[index]"
								:close-on-content-click="false"
								offset-y
							>
								<template v-slot:activator="{ on }">
									<v-btn v-on="on" small class="mx-1">{{
										$localize('form.button.edit')
									}}</v-btn>
								</template>
								<edit-input-field
									v-if="inputFields[index]"
									v-model="inputFields[index]"
									:calculation-id="value.calculationId"
									:field-count="inputFields.length"
									@saved="
										editing[index] = false
										isDirty = true
									"
								/>
							</v-menu>
							<v-btn
								small
								class="mx-1"
								@click="deleteInputField(index)"
								>{{ $localize('form.button.remove') }}</v-btn
							>
						</v-col>
					</v-row>
				</draggable>
			</v-flex>
		</v-card-text>
		<v-card-actions>
			<v-btn :disabled="!isDirty" @click="resetInputFields()">{{
				$localize('form.button.cancel')
			}}</v-btn>
			<v-btn :disabled="!isDirty" @click="saveInputFields(false)">{{
				$localize('form.button.save')
			}}</v-btn>

			<v-btn color="primary" @click="saveInputFields(true)">{{
				$localize('form.button.saveAndContinue')
			}}</v-btn>
		</v-card-actions>
	</v-card>
</template>

<script>
import { required } from '@/services/validation'
import sendCommand from '@/services/sendCommand'
import draggable from 'vuedraggable'
import EditInputField from '@/main/advanced/components/EditInputField'

export default {
	components: { draggable, EditInputField },
	props: {
		value: {
			type: Object,
			required: true,
		},
	},

	data() {
		return {
			dragging: false,
			creating: false,
			editing: [],
			calculation: {},
			calculationTypes: [],
			inputFields: {},
			newInputField: { isNew: true },
			formFieldTypes: ['text', 'number', 'article'],
			validation: { required },

			isDirty: false,
		}
	},

	watch: {
		value: {
			immediate: true,
			handler(val) {
				this.calculation = val
				this.inputFields = val.input
				this.inputFields = [...this.calculation.input]
			},
		},
	},

	methods: {
		createNew() {
			this.newInputField = {
				isRequired: true,
                isNew: true
			}
			if (this.$refs.form) {
				this.$refs.form.resetValidation()
			}
		},

		addField() {
			console.log(this.newInputField)
			this.inputFields.push(this.newInputField)
			this.newInputField = { isNew: true}
			this.creating = false
			this.isDirty = true
		},

		deleteInputField(index) {
			this.inputFields.splice(index, 1)
			this.isDirty = true
		},

		resetInputFields() {
			this.inputFields = [...this.calculation.input]
			this.isDirty = false
		},
		async saveInputFields(nextStep) {
			if (this.isDirty) {
				this.isDirty = false
				const result = await sendCommand('ChangeCalculationInput', {
					calculationId: this.calculation.calculationId,
					input: this.inputFields,
				})
				if (result.success) {
					this.calculation.inputFields = this.inputFields
					this.$emit('input', this.calculation)
				}
			}
			this.$emit('saved')
			if (nextStep) {
				this.$emit('continue')
			}
		},
	},
}
</script>
