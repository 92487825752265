<template>
	<dialog-form
		id="formCreateArticle"
		:value="value"
		@input="$emit('input', $event)"
		:title="$localize('CreateArticle.title')"
		:commit-message="$localize('CreateArticle.message.saved')"
		@commit="save"
		@reset="resetForm"
		:loading="loading"
	>
		<v-text-field
			v-model="name"
			:label="$localize('Article.name')"
			:rules="[validation.required, validation.maxLength(200)]"
			prepend-icon="mdi-cube"
			class="inputName"
			autofocus
		/>

		<select-relation
			v-model="supplierId"
			:customers="false"
			:suppliers="true"
			:label="$localize('Article.supplier')"
			:rules="{}"
			@input="focusRef('selectBrand')"
		/>

		<v-combobox
			ref="selectBrand"
			v-model="brand"
			:items="brands"
			:label="$localize('Article.brand')"
			prepend-icon="mdi-format-list-bulleted"
			@input.native="setBrand"
			class="selectBrand"
			:rules="[validation.maxLength(200)]"
		/>
		<v-text-field
			v-model="url"
			:label="$localize('Article.url')"
			prepend-icon="mdi-link"
			:rules="[validation.maxLength(200)]"
		/>
		<v-combobox
			v-model="category"
			:items="categories"
			:label="$localize('Article.category')"
			prepend-icon="mdi-group"
			@input.native="setCategory"
			class="selectCategory"
			:rules="[validation.maxLength(200)]"
		/>
		<v-textarea
			v-model="note"
			:label="$localize('Article.note')"
			prepend-icon="mdi-note"
			class="inputNote"
			:rules="[validation.maxLength(500)]"
		/>
		<v-switch
			v-model="isDefault"
			:label="$localize('Article.isDefault')"
			:hint="$localize('Article.isDefault.hint')"
		/>
	</dialog-form>
</template>

<script>
import DialogForm from '@/shared/components/DialogForm'

import sendCommand from '@/services/sendCommand'
import { getArticleBrands, getArticleCategories } from '@/services/api'
import { required, maxLength } from '@/services/validation'
import { focusFirstDeep } from '@/services/domHelper'
import SelectRelation from '@/main/components/forms/SelectRelation'

import Vue from 'vue'
export default {
	components: { SelectRelation, DialogForm },
	props: {
		value: {
			type: Boolean,
			default: false,
		},
	},

	data() {
		return {
			loading: false,

			name: null,
			supplierId: null,
			brand: null,
			url: null,
			category: null,
			note: null,
			attributes: {},

			brands: [],
			categories: [],
			validation: { required, maxLength },
			isDefault: false,
		}
	},
	async created() {
		this.loading = true
		await this.loadBrands()
		await this.loadCategories()
		this.loading = false
	},
	methods: {
		async loadBrands() {
			const { result, ok } = await getArticleBrands.call()
			if (ok) {
				this.brands = result
			}
		},
		async loadCategories() {
			const { result, ok } = await getArticleCategories.call()
			if (ok) {
				this.categories = result.filter((category) => category !== null)
			}
		},
		resetForm() {
			this.name = null
			this.supplierId = null
			this.brand = null
			this.category = null
			this.note = null
			this.attributes = {}
		},
		setCategory(e) {
			if (!e) {
				this.category = ''
			} else if (typeof e === 'object') {
				this.category = e.srcElement.value
			}
		},
		setBrand(e) {
			if (!e) {
				this.brand = ''
			} else if (typeof e === 'object') {
				this.brand = e.srcElement.value
			}
		},
		async save(callback) {
			const result = await sendCommand('CreateArticle', {
				name: this.name,
				supplierId: this.supplierId,
				brand: this.brand,
				url: this.url,
				category: this.category,
				note: this.note,
				isDefault: this.isDefault,
			})
			if (result.success) {
				this.$emit('saved')
			}

			callback({ success: result.success })

			if (result.success) {
                await this.loadBrands();
                await this.loadCategories();
			}
		},
		focusRef(ref) {
			const obj = this.$refs[ref]
			const el = obj.$el ?? obj
			Vue.nextTick(() => {
				focusFirstDeep(el)
			})
		},
	},
}
</script>
