<template>
  <v-container fluid>
    <v-navigation-drawer app right>
      <log-filter v-model="filter" @input="refresh()" />
    </v-navigation-drawer>

    <v-data-table
      :items="entries"
      :headers="entryHeaders"
      disable-sort
      :disable-pagination="true"
      dense
      hide-default-footer
    >
      <template v-slot:[`item.timestamp`]="{ item }">{{ formatDate(item.timestamp) }}</template>
      <template v-slot:[`item.level`]="{ item }">
        <v-chip small :color="logLevelColor(item.level)">{{item.level}}</v-chip>
      </template>
      <template v-slot:[`item.renderedMessage`]="{ item }">
        <span class="text-no-wrap text-truncate">{{ formatMessage(item) }}</span>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { format, parseISO } from "date-fns";
import { getExceptions } from "@/services/api";
import LogFilter from './LogFilter'

export default {
  components: {LogFilter},
  data() {
    return {
      entries: [],
      expanded: [],
      headers: [
        { value: "startAt" },
        { value: "title" },
        { value: "response" },
        { value: "duration" }
      ],
      entryHeaders: [
        { text: "occurredAt", value: "timestamp", width: 170 },
        { text: "level", value: "level" },
        { text: "context", value: "properties.sourceContext" },
        { text: "message", value: "renderedMessage" }
      ],
      filter: {},
    };
  },
  created: function() {
    this.refresh()
  },
  methods: {
    async refresh(){
      this.entries = await getExceptions.create().load(this.filter);
    },
    formatDate(date) {
      return format(parseISO(date), "dd MMM yyyy hh:ii:ss");
    },
    expandRow(item) {
      const currentIndex = this.expanded.indexOf(item);
      if (currentIndex === -1) {
        this.expanded = [item];
      } else {
        this.expanded = [];
      }
    },
    elapsedColor(elapsedMilliseconds) {
      if (elapsedMilliseconds > 2000) {
        return "error";
      }
      if (elapsedMilliseconds > 500) {
        return "warning";
      }
      return "success";
    },
    statusColor(status) {
      if (status >= 500) {
        return "error";
      }
      if (status >= 400) {
        return "warning";
      }
      if (status >= 200) {
        return "success";
      }
      return "info";
    },
    logLevelColor(logLevel) {
      if (logLevel === "critical" || logLevel === "error") {
        return "error";
      }
      if (logLevel === "warning") {
        return "warning";
      }
      if (logLevel === "information") {
        return "info";
      }
      if (logLevel === "debug") {
        return "grey";
      }
      if (logLevel === "trace") {
        return "grey lighten-2";
      }
      return "white";
    },
    isParameter(renderedMessage, key) {
      const regex = new RegExp(/{@?(\w+)(?::\w+)?}/gi);
      const matches = [...renderedMessage.matchAll(regex)];
      for (let index = 0; index < matches.length; index++) {
        const match = matches[index];
        if (match.length >= 2) {
          if (match[1].toLowerCase() === key.toLowerCase()) {
            return true;
          }
        }
      }
      return false;
    },
    formatMessage(entry) {
      const regex = new RegExp(/{@?(\w+)(?::\w+)?}/gi);
      const formattedMessage = entry.renderedMessage.replace(
        regex,
        (match, key) => {
          const property = key.charAt(0).toLowerCase() + key.slice(1);
          var value = entry.properties[property];
          if (typeof value === "object") {
            return JSON.stringify(value);
          }
          return value;
        }
      );
      return formattedMessage;
    }
  }
};
</script>