var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-row',{staticClass:"project-header-row",attrs:{"no-gutters":""}},[_c('v-col',[_vm._v(" "+_vm._s(_vm.title)+" ")])],1),_c('v-skeleton-loader',{attrs:{"loading":_vm.loading,"type":"list-item"}},[(_vm.offers.length > 0)?_c('v-card',{staticClass:"pl-3 pt-3 pb-3",attrs:{"flat":""}},[_c('v-flex',[_c('v-row',{staticClass:"font-weight-bold",attrs:{"no-gutters":"","align":"center"}},[_c('v-col',[_vm._v(_vm._s(_vm.$localize('Offer.created')))]),_c('v-col',[_vm._v(_vm._s(_vm.$localize('Offer.expires')))]),_c('v-col',{staticStyle:{"width":"185px"},attrs:{"cols":"auto"}})],1),_vm._l((_vm.offers),function(offer){return _c('v-row',{key:offer.offerId,attrs:{"no-gutters":"","align":"center"}},[_c('v-col',[_vm._v(_vm._s(_vm.$format.shortDate(offer.offerDate)))]),_c('v-col',[_vm._v(_vm._s(_vm.$format.shortDate(offer.expires)))]),_c('v-col',{staticStyle:{"width":"185px"},attrs:{"cols":"auto"}},[_c('v-toolbar',{staticStyle:{"background":"none"},attrs:{"flat":"","height":"28"}},[_c('v-spacer'),_c('v-toolbar-items',[_c('v-btn',{attrs:{"x-small":""},on:{"click":function($event){return _vm.downloadOffer(offer)}}},[_c('v-icon',{attrs:{"color":"red","small":""}},[_vm._v("mdi-adobe-acrobat")])],1),(
                                        _vm.$session.roles.includes(
                                            'offers_manage'
                                        )
                                    )?_c('v-btn',{attrs:{"x-small":""},on:{"click":function($event){_vm.sendOffer = true
                                        _vm.sendOfferId = offer.offerId}}},[_c('v-icon',{attrs:{"color":"success","small":""}},[_vm._v("mdi-email-send")])],1):_vm._e(),(
                                        _vm.$session.roles.includes(
                                            'offers_manage'
                                        )
                                    )?_c('v-btn',{attrs:{"x-small":""},on:{"click":function($event){_vm.deleteOffer = true
                                        _vm.deleteOfferId = offer.offerId}}},[_c('v-icon',{attrs:{"color":"error","small":""}},[_vm._v("mdi-close")])],1):_vm._e()],1)],1)],1)],1)})],2)],1):_vm._e(),_c('v-card',{staticClass:"text-center",attrs:{"flat":""}},[(_vm.$session.roles.includes('offers_manage'))?_c('v-btn',{staticClass:"ma-5",on:{"click":function($event){_vm.createOffer = true}}},[_c('v-icon',{attrs:{"color":"success"}},[_vm._v("mdi-offer")]),_c('span',{staticClass:"ml-1"},[_vm._v(_vm._s(_vm.$localize('ProjectOffers.btn.createOffer')))])],1):_vm._e()],1)],1),(_vm.createOffer)?_c('create-offer',{attrs:{"project-id":_vm.$route.params.projectId},model:{value:(_vm.createOffer),callback:function ($$v) {_vm.createOffer=$$v},expression:"createOffer"}}):_vm._e(),(_vm.deleteOffer)?_c('delete-offer',{attrs:{"project-id":_vm.$route.params.projectId,"offer-id":_vm.deleteOfferId},model:{value:(_vm.deleteOffer),callback:function ($$v) {_vm.deleteOffer=$$v},expression:"deleteOffer"}}):_vm._e(),(_vm.sendOffer)?_c('send-offer',{attrs:{"project-id":_vm.$route.params.projectId,"offer-id":_vm.sendOfferId},model:{value:(_vm.sendOffer),callback:function ($$v) {_vm.sendOffer=$$v},expression:"sendOffer"}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }