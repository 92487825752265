<template>
	<v-container fluid>
		<v-row class="mx-4">
			<v-col>
				<v-stepper v-model="step" alt-labels>
					<v-stepper-header>
						<v-stepper-step
							:step="1"
							:complete="step > 1"
							:editable="true"
							@click="navToOverview"
							>{{ $localize('ArticleImport.title.stepOverview') }}
						</v-stepper-step>
						<v-stepper-step :step="2" :complete="step > 2">{{
							$localize('ArticleImport.title.stepUpload')
						}}</v-stepper-step>
						<v-stepper-step
							:step="3"
							:complete="step > 3"
							:editable="step === 4"
							>{{
								$localize('ArticleImport.title.options')
							}}</v-stepper-step
						>
						<v-stepper-step :step="4" :complete="step > 4">{{
							$localize('ArticleImport.title.stepLink')
						}}</v-stepper-step>
						<v-stepper-step :step="5" :complete="step > 5">{{
							$localize('ArticleImport.title.stepProcess')
						}}</v-stepper-step>
					</v-stepper-header>

					<v-stepper-items>
						<v-stepper-content :step="1">
							<article-import-overview
								v-model="method"
								@continue="step = 2"
							/>
						</v-stepper-content>
						<v-stepper-content :step="2">
							<article-import-upload
								v-if="step === 2"
								:relation-group-id="relationGroupId"
							/>
						</v-stepper-content>
						<v-stepper-content :step="3">
							<article-import-options
								v-if="step === 3"
								:article-import-id="articleImportId"
								@continue="step = 4"
								@back="navToOverview"
							/>
						</v-stepper-content>
						<v-stepper-content :step="4">
							<article-import-link
								v-if="step === 4"
								:article-import-id="articleImportId"
								@continue="step = 5"
								@back="step = 3"
							/>
						</v-stepper-content>
						<v-stepper-content :step="5">
							<article-import-process
								v-if="step === 5"
								:article-import-id="articleImportId"
								@continue="done = true"
							/>
						</v-stepper-content>
					</v-stepper-items>
				</v-stepper>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import ArticleImportOverview from './components/ArticleImportOverview'
import ArticleImportUpload from './components/ArticleImportUpload'
import ArticleImportOptions from './components/ArticleImportOptions'
import ArticleImportLink from './components/ArticleImportLink'
import ArticleImportProcess from './components/ArticleImportProcess'

import { getArticleImport } from '@/services/api'
import { isGuid } from '@/services/validation'
import { required } from '@/services/validation'
export default {
	components: {
		ArticleImportOverview,
		ArticleImportOptions,
		ArticleImportUpload,
		ArticleImportLink,
		ArticleImportProcess,
	},
	props: {
		articleImportId: {
			validator: isGuid,
			required: false,
		},
	},
	data() {
		return {
			step: 0,
			done: false,
			method: '',
			relationGroupId: this.$settings.defaultRelationGroup,
			articleImport: {},

			validation: { required },
			error: false,
		}
	},
	watch: {
		articleImportId: {
			immediate: true,
			async handler() {
				if (this.articleImportId) {
					const { result, ok } = await getArticleImport.call({
						args: { articleImportId: this.articleImportId },
					})

					if (ok) {
						this.articleImport = result
						if (
							this.articleImport.totalDataRows ===
							this.articleImport.processedRows
						) {
							this.step = 5
							this.done = true
						} else if (this.articleImport.isConfirmed) {
							this.step = 5
						} else {
							this.step = 3
						}
					}
				} else {
					this.step = 1
				}
			},
		},
	},
	methods: {
		navToOverview() {
			if (this.articleImportId) {
				this.$router.push({
					name: 'articleImport',
				})
			}
		},
	},
}
</script>
<style scoped lang="scss">
::v-deep .v-stepper__label {
	text-align: center;
}
</style>
