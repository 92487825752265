import { format, parseISO, getHours, getMinutes, getYear } from 'date-fns'

import { chooseLanguage } from './session'

import { nl, enGB } from 'date-fns/locale'

function getLocale() {
    if (chooseLanguage().substring(0, 2) === 'en') {
        return enGB
    }
    return nl
}

export function formatBytes(bytes) {
    if (!bytes || typeof bytes !== 'number') return ''

    let b = bytes
    let kb = Math.floor(bytes / 1024)
    let mb = Math.floor(kb / 1024)
    let gb = Math.floor(mb / 1024)
    let output = ''
    if (gb > 0) {
        output += gb + 'Gb'
        mb -= gb * 1024
        kb -= gb * 1024 * 1024
        b -= gb * 1024 * 1024 * 1024
    }

    if (mb > 0) {
        output += ' ' + mb + 'Mb'
        kb -= mb * 1024
        b -= mb * 1024 * 1024
    }

    if (kb > 0) {
        output += ' ' + kb + 'Kb'
        b -= kb * 1024
    }
    if (b > 0) {
        output += ' ' + b + 'b'
    }
    return output
}

export function formatBytesShort(bytes) {
    if (!bytes || typeof bytes !== 'number') return ''

    let kb = Math.floor(bytes / 1024)
    let mb = Math.floor(kb / 1024)
    let gb = Math.floor(mb / 1024)
    if (gb > 0) {
        return gb + ' Gb'
    }

    if (mb > 0) {
        return mb + ' Mb'
    }

    if (kb > 0) {
        return kb + ' Kb'
    }
    return bytes + ' b'
}
export function formatCurrency(value) {
    if (!value) {
        return '€ 0,00'
    }
    if (typeof value === 'string') {
        value = parseFloat(value)
    }
    const fixed = value.toFixed(2)
    const split = fixed.split('.')

    return `€ ${split[0]},${split[1]}`
}

export function formatShortTime(value) {
    if (!value) {
        return ''
    }
    const dateValue = parseISO(value)
    const hours = getHours(dateValue)
    const minutes = getMinutes(dateValue)

    if (minutes === 0) {
        return hours + ''
    } else {
        return hours + ':' + minutes
    }
}

export function formatShortDate(value) {
    if (!value) {
        return ''
    }
    if (value instanceof Date) {
        return format(value, 'dd-MM-yyyy')
    } else {
        return format(parseISO(value), 'dd-MM-yyyy')
    }
}

export function formatShortDateTime(value) {
    if (!value) {
        return ''
    }
    const dateValue = parseISO(value)

    if (getHours(dateValue) > 0 || getMinutes(dateValue)) {
        return format(dateValue, 'dd-MM-yyyy HH:mm')
    }
    return format(dateValue, 'dd-MM-yyyy')
}

export function formatDateTime(value) {
    if (!value) {
        return ''
    }
    const dateValue = parseISO(value)

    return format(dateValue, 'dd-MM-yyyy HH:mm:ss')
}

export function formatLongMonth(value) {
    if (!value) {
        return ''
    }
    return format(value, 'LLLL yyyy', {
        locale: getLocale(),
    })
}

export function formatLongWeek(value) {
    if (!value) {
        return ''
    }
    return format(value, "LLLL yyyy 'Week' w", {
        locale: getLocale(),
    })
}

export function formatLongDay(value) {
    if (!value) {
        return ''
    }
    return format(value, "dd LLLL yyyy 'Week' w", {
        locale: getLocale(),
    })
}

export function year(value) {
    if (!value) {
        return ''
    }
    return getYear(parseISO(value))
}

export function formatTimespan(value) {
    // 00:00:03.0154208
    if (typeof value !== 'string') return ''

    const parts = value.split('.')

    return parts[0]
}
