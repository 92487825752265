<template>
  <div>
    <v-container>
      <v-toolbar flat dense>
        <v-btn icon @click="navBack()">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        <v-toolbar-title>{{user.name}}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn icon @click="enterUserDetails = true">
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
    </v-container>

    <v-btn id="btnSetUserPassword" @click="setUserPassword = true">Set Password</v-btn>
    <v-btn id="btnActivateUser" v-if="!user.isActivated" @click="activateUser()">Activate</v-btn>
    <v-btn id="btnDeactivateUser" class="info" v-if="user.isActivated" @click="deactivateUser()">Deactivate</v-btn>
    <v-btn id="btnImpersonateUser" v-if="user.isTenant" @click="impersonateUser()">Impersonate</v-btn>

    <table>
      <tr>
        <td>TenantId</td>
        <td>{{user.tenantId}}</td>
      </tr>
      <tr>
        <td>UserId</td>
        <td>{{user.userId}}</td>
      </tr>
      <tr>
        <td>Login</td>
        <td>{{user.login}}</td>
      </tr>
      <tr>
        <td>IsAdministrator</td>
        <td>{{user.isAdministrator}}</td>
      </tr>
      <tr>
        <td>IsTenant</td>
        <td>{{user.isTenant}}</td>
      </tr>
    </table>
    <enter-user-details
      v-model="enterUserDetails"
      :user-id="$route.params.userId"
      @saved="refresh()"
    />
    <set-user-password
      v-model="setUserPassword"
      :user-id="$route.params.userId"
      @saved="refresh()"
    />
  </div>
</template>

<script>
import { getTenantUserById } from "@/services/api";
import sendCommand from "@/services/sendCommand";
import { currentSession } from '@/services/session'
import EnterUserDetails from '../components/tenants/EnterUserDetails.vue'
import SetUserPassword from '../components/tenants/SetUserPassword.vue'

export default {
  components:{EnterUserDetails, SetUserPassword},
  data() {
    return {
      user: {},
      enterUserDetails: false,
      setUserPassword: false
    };
  },
  async created() {
    await this.refresh();
  },

  methods: {
    async refresh() {
      this.user = await getTenantUserById
        .create({ userId: this.$route.params.userId })
        .load();
    },

    async activateUser() {
      const result = await sendCommand("AdminActivateUser", {
        userId: this.$route.params.userId
      });
      if(result.success){
        await this.refresh();
      }
    },
    async deactivateUser() {
      const result = await sendCommand("AdminDeactivateUser", {
        userId: this.$route.params.userId
      });
      if(result.success){
        await this.refresh();
      }
    },
    async impersonateUser(){
      const result = await sendCommand('ImpersonateUser', {
        impersonateUserId: this.$route.params.userId
      });
      if(result.success){
        await currentSession(true)
        this.$router.push({
          name: 'startup'
        });
      }
    },
    navBack() {
      if(this.user.isTenant){
        this.$router.push({
          name: "tenantDetails",
          params: { tenantId: this.user.tenantId }
        });
      }
      else {
        this.$router.push({name: 'admins'})
      }
    }
  }
};
</script>