<template>
  <v-container fluid>
    <v-text-field v-model="search" outlined placeholder="Search" clearable />
    <v-data-table
      :items="keys"
      :headers="headers"
      :loading="loading"
      dense
      :search="search"
      :custom-filter="filter"
      :options.sync="options"
    >
      <template v-slot:item="{ item, index }">
        <tr>
          <td>{{item}}</td>
          <td v-for="language in languages" :key="language">
            <v-textarea
              :rows="1"
              clearable
              class="mt-0 pt-0"
              :hide-details="true"
              v-model="translations[language][item].translation"
              @change="fieldChanged(translations[language][item])"
            />
          </td>
          <td>
            <v-btn
              small
              :disabled="keyChanged[item] != 'changed'"
              :loading="keyChanged[item] == 'saving'"
              @click="saveTranslation(item)"
            >{{ $localize('form.button.save') }}</v-btn>
            <v-btn small @click="deleteKey(item, index)" color="error" class="ml-1">
              <v-icon small>mdi-close</v-icon>
            </v-btn>
          </td>
        </tr>
      </template>
    </v-data-table>
    <v-checkbox v-model="onlyShowMissing" label="Only show missing"></v-checkbox>
    <v-checkbox v-model="showEmpty" label="Show empty"></v-checkbox>
  </v-container>
</template>

<script>
import {
  getTranslations,
  getLanguages,
  getTranslationKeys
} from "@/services/api";
import sendCommand from "@/services/sendCommand";
import Vue from "vue";

export default {
  data() {
    return {
      search: '',
      loading: false,
      options: {
        itemsPerPage: 50
      },
      onlyShowMissing: true,
      showEmpty: false,
      headers: [],
      allKeys: [],
      keys: [],
      languages: [],

      translations: {},

      keyChanged: {}
    };
  },
  watch: {
    onlyShowMissing(){
      this.refreshFilter();
    },
    showEmpty(){
      this.refreshFilter();
    }
  },
  async created() {
    this.loading = true;
    this.languages = await getLanguages.create().load();
    const keys = await getTranslationKeys.create().load();

    this.headers.push({ text: "Key", value: "key", sortable: false });
    for (let index = 0; index < this.languages.length; index++) {
      const lang = this.languages[index];

      const translations = await getTranslations
        .create({ language: lang })
        .load();
      this.translations[lang] = {};
      keys.map(key => {
        this.translations[lang][key] = {
          language: lang,
          key: key,
          translation: translations[key],
          changed: translations[key] === null
        };
        if (translations[key] === null) {
          Vue.set(this.keyChanged, key, "changed");
        }
      });
      this.headers.push({ text: lang, sortable: false });
    }
    this.headers.push({ sortable: false });

    this.keys = keys;
    this.allKeys = keys;
    this.refreshFilter();
    this.loading = false;
  },

  methods: {
    refreshFilter(){
      const nl = "nl-NL"
      const en = "en-US"
      this.loading = true;
      if(this.onlyShowMissing){
        if(this.showEmpty){
          this.keys = this.allKeys.filter(x => 
            (this.translations[nl][x].translation || null) === null ||
            (this.translations[en][x].translation || null) === null);
        }
        else{
          this.keys = this.allKeys.filter(x => 
            this.translations[nl][x].translation === null ||
            this.translations[en][x].translation === null);
        }
      }
      else{
        this.keys = this.allKeys;
      }
      this.loading = false;
    },
    async saveTranslation(key) {
      Vue.set(this.keyChanged, key, "saving");
      for (let index = 0; index < this.languages.length; index++) {
        const lang = this.languages[index];
        const model = this.translations[lang][key];

        await sendCommand("SaveTranslation", {
          language: lang,
          key: key,
          translation: model.translation || ""
        });
      }
      Vue.set(this.keyChanged, key, null);
    },
    async deleteKey(key, index) {
      const result = await sendCommand("DeleteTranslationKey", {
        key: key
      });
      if (result.success) {
        this.keys.splice(index, 1);
      }
    },
    fieldChanged(model) {
      model.changed = true;
      Vue.set(this.keyChanged, model.key, "changed");
    },
    filter(value, search, item) {
      const searchFor = search.toLowerCase();
      if (item.toLowerCase().includes(searchFor)) {
        return true;
      }
      for (let index = 0; index < this.languages.length; index++) {
        const lang = this.languages[index];
        const model = this.translations[lang][item];

        if (
          model.translation &&
          model.translation.toLowerCase().includes(searchFor)
        ) {
          return true;
        }
      }

      return false;
    }
  }
};
</script>