import axios from 'axios'
import { showSnackbar, showError } from '@/services/snackbars'
import { addGlobalHeader } from '@/services/endpoints/apiEndPoint'

const port = process.env.VUE_APP_API_PORT
let portPart = ':' + window.location.port
if (port) {
    portPart = ':' + port
}

const host = `${window.location.protocol}//${window.location.hostname}${portPart}`

var axiosConfig = {
    withCredentials: true,
    timeout: 60000,
    validateStatus: function (status) {
        return status < 500 // Reject only if the status code is greater than or equal to 500
    },
}
axios.interceptors.response.use(
    function (response) {
        return response
    },
    function (error) {
        return Promise.reject(error)
    }
)

/**
 * Send a command to the backend.
 * @param {String} commandName The name of the command
 * @param {Object} commanObject The command object
 * @return {Promise} Returns a promise, that when resolved returns the response data.
 */
export default function sendCommand(commandName, commandObject, headers) {
    commandObject = commandObject || {}
    headers = headers || {}
    var promise = new Promise(function (resolve) {
        axios
            .post(host + '/command/send/' + commandName, commandObject, {
                ...axiosConfig,
                headers,
            })
            .then(function (response) {
                if (response.data.redirect) {
                    window.location = response.data.redirect
                }

                if (response.data.success) {
                    if (response.data.position) {
                        if (response.data.position != '0/0') {
                            addGlobalHeader(
                                'Iris-Position',
                                response.data.position
                            )
                        }
                    }
                } else if (response.status >= 400) {
                    showSnackbar({
                        title: 'Command Failed',
                        text: response.data.message,
                        color: 'error',
                    })
                }
                resolve(response.data)
            })
            .catch(function (error) {
                let errorObj
                if (!error.response) {
                    showSnackbar({
                        title: 'Disconnected',
                        text: 'Unable to connect',
                        color: 'error',
                    })
                } else if (
                    error.response.status >= 400 &&
                    error.response.status < 500
                ) {
                    const errorMessage = error.response.data.message
                    errorObj = {
                        title: 'Command Failed',
                        text: errorMessage,
                        color: 'error',
                    }
                } else {
                    errorObj = {
                        title: 'Server Error',
                        text: error.response.data,
                        color: 'error',
                    }
                }
                if (error.response.status >= 500) {
                    showError({
                        data: error.response.data,
                        status: error.status,
                        statusText: error.statusText,
                    })
                } else if (errorObj) {
                    showSnackbar(errorObj)
                }

                resolve(error)
            })
    })

    return promise
}
