<template>
  <v-select
    :value="value"
    @input="$emit('input', $event)"
    :items="phases"
    :multiple="true"
    :label="$localize('ProjectOverview.phases')"
  />
</template>

<script>
export default {
  props: {
    value: Array
  },
  data() {
    return {
      phases: [
        { value: "request", text: this.$localize("Project.phaseRequests") },
        { value: "offer", text: this.$localize("Project.phaseOffers") },
        { value: "order", text: this.$localize("Project.phaseOrders") },
        { value: "invoice", text: this.$localize("Project.phaseInvoices") }
      ]
    };
  }
};
</script>
