<template>
	<dialog-form
		:value="value"
		@input="$emit('input', $event)"
		:title="$localize('WelcomeUser.title')"
		@commit="save"
		:allow-continue="false"
		:action-text="$localize('WelcomeUser.button')"
	>
		<p>{{ $localize('WelcomeUser.description') }}</p>
	</dialog-form>
</template>

<script>
import DialogForm from '@/shared/components/DialogForm'

import sendCommand from '@/services/sendCommand'
import { isGuid } from '@/services/validation'

export default {
	components: { DialogForm },
	props: {
		value: {
			type: Boolean,
			default: false,
		},
		userId: {
			validator: isGuid,
			required: true,
		},
	},

	methods: {
		async save(callback) {
			const result = await sendCommand('WelcomeUser', {
				userId: this.userId,
			})
			if (result.success) {
				this.$emit('saved')
			}
			callback({ success: result.success })
		},
	},
}
</script>
