<template>
  <dialog-form
    :value="value"
    @input="$emit('input', $event)"
    :title="$localize('DeleteVacation.title')"
    @commit="save"
    @reset="addVacation = {}"
    :allow-continue="false"
  >
    <p>{{ $localize("DeleteVacation.description") }}</p>
  </dialog-form>
</template>

<script>
import DialogForm from "@/shared/components/DialogForm";

import sendCommand from "@/services/sendCommand";
import { required } from "@/services/validation";
import { isGuid } from "@/services/validation"

export default {
  components: { DialogForm },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    userId: {
      validator: isGuid,
      required: true,
    },
    vacationId: {
      validator: isGuid,
      required: true,
    },
  },

  data() {
    return {
      addVacation: {},
      validation: {
        required,
      },
    };
  },

  methods: {
    async save(callback) {
      const result = await sendCommand("DeleteUserVacation", {
        userId: this.userId,
        vacationId: this.vacationId,
      });
      if (result.success) {
        this.$emit("saved");
      }
      callback({ success: result.success });
    },
  },
};
</script>
