<template>
	<div>
		<v-toolbar flat height="36" class="my-3">
			<v-spacer></v-spacer>
			<v-toolbar-items>
				<v-btn class="primary" @click="createArticleCalculation = true">
					{{
						$localize(
							'ArticleCalculationsOverview.button.createArticleCalculation'
						)
					}}
					<v-icon>mdi-plus</v-icon>
				</v-btn>
			</v-toolbar-items>
		</v-toolbar>

		<v-data-table
			:items="articleCalculations"
			:headers="headers"
			dense
			disable-pagination
			hide-default-footer
		>
			<template v-slot:[`item.prices`]="{ item }">
				<span v-for="price in item.prices" :key="price.alias">{{
					price.displayName
				}}</span>
			</template>
			<template v-slot:[`item.attributes`]="{ item }">
				<span
					v-for="attribute in item.attributes"
					:key="attribute.alias"
					>{{ attribute.displayName }}</span
				>
			</template>
			<template v-slot:[`item.actions`]="{ item }">
				<v-btn small depressed @click="edit(item)" class="mr-2">
					<v-icon dense>mdi-pencil</v-icon>
				</v-btn>
				<v-btn small depressed @click="delItem(item)">
					<v-icon>mdi-close</v-icon>
				</v-btn>
			</template>
		</v-data-table>

		<create-article-calculation
			v-if="createArticleCalculation"
			v-model="createArticleCalculation"
			@saved="refresh()"
		/>
		<edit-article-calculation
			v-if="editArticleCalculation"
			v-model="editArticleCalculation"
			:article-calculation-id="editArticleCalculationId"
			@saved="refresh()"
		/>
		<delete-article-calculation
			v-if="deleteArticleCalculation"
			v-model="deleteArticleCalculation"
			:article-calculation-id="deleteArticleCalculationId"
			@saved="refresh()"
		/>
	</div>
</template>

<script>
import CreateArticleCalculation from './dialogs/CreateArticleCalculation'
import EditArticleCalculation from './dialogs/EditArticleCalculation'
import DeleteArticleCalculation from './dialogs/DeleteArticleCalculation'

import { getCalculationTypes } from '@/services/api'
export default {
	components: {
		CreateArticleCalculation,
		EditArticleCalculation,
		DeleteArticleCalculation,
	},

	data() {
		return {
			createArticleCalculation: false,

			editArticleCalculation: false,
			editArticleCalculationId: null,

			deleteArticleCalculation: false,
			deleteArticleCalculationId: null,

			loading: false,
			articleCalculations: [],
			headers: [
				{
					text: this.$localize('ArticleCalculation.name'),
					value: 'name',
				},
				{
					text: this.$localize('ArticleCalculation.prices'),
					value: 'prices',
				},
				{
					text: this.$localize('ArticleCalculation.attributes'),
					value: 'attributes',
				},
				{ text: '', value: 'actions', width: 150 },
			],
		}
	},

	created() {
		this.refresh()
	},

	methods: {
		async refresh() {
			this.loading = true
			const { result, ok } = await getCalculationTypes.call()
			if (ok) {
				this.articleCalculations = result
			}
			this.loading = false
		},

		edit(articleCalculation) {
			this.editArticleCalculationId =
				articleCalculation.calculationFieldId
			this.editArticleCalculation = true
		},

		delItem(articleCalculation) {
			this.deleteArticleCalculationId =
				articleCalculation.calculationFieldId
			this.deleteArticleCalculation = true
		},
	},
}
</script>
