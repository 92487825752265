<template>
    <v-container :key="overviewId">
        <v-toolbar flat dense>
            <v-btn icon @click="$router.push({ name: 'tenantsOverview' })">
                <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
            <v-toolbar-title>{{ tenant.name }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
                <v-btn
                    id="btnActivateTenant"
                    depressed
                    @click="activateTenant()"
                    >Activate</v-btn
                >
                <v-btn
                    id="btnDeactivateTenant"
                    depressed
                    @click="deactivateTenant()"
                    >Deactivate</v-btn
                >
                <v-btn
                    id="btnDeleteTenant"
                    depressed
                    @click="deleteTenant = true"
                    >Delete</v-btn
                >
                <v-btn text class="primary" @click="createUser = true"
                    >New user</v-btn
                >
            </v-toolbar-items>
        </v-toolbar>
        <user-overview :tenant-id="this.$route.params.tenantId" />
        <license-overview :tenant-id="this.$route.params.tenantId" />
        <invoice-overview :tenant-id="this.$route.params.tenantId" />
        <create-user
            v-model="createUser"
            @saved="refresh()"
            :tenant-id="this.$route.params.tenantId"
        />
        <delete-tenant
            v-model="deleteTenant"
            @saved="navTenantOverview()"
            :tenant-id="this.$route.params.tenantId"
        />
    </v-container>
</template>

<script>
import { getTenantById } from '@/services/api'
import sendCommand from '@/services/sendCommand'
import CreateUser from '@/admin/components/tenants/CreateUser'
import DeleteTenant from '@/admin/components/tenants/DeleteTenant'
import UserOverview from '@/admin/components/tenants/UserOverview'
import LicenseOverview from '@/admin/components/tenants/LicenseOverview'
import InvoiceOverview from '@/admin/components/tenants/InvoiceOverview'

export default {
    components: {
        CreateUser,
        UserOverview,
        DeleteTenant,
        LicenseOverview,
        InvoiceOverview,
    },
    data() {
        return {
            tenant: {},
            createUser: false,
            deleteTenant: false,
            overviewId: 1,
        }
    },
    async created() {
        this.refresh()
    },
    methods: {
        async refresh() {
            this.overviewId++
            const tenantId = this.$route.params.tenantId
            this.tenant = await getTenantById
                .create({ tenantId: tenantId })
                .load()
        },
        navTenantOverview() {
            this.$router.push({
                name: 'tenantsOverview',
            })
        },
        async activateTenant() {
            await sendCommand('ActivateTenant', {
                tenantId: this.$route.params.tenantId,
            })
        },
        async deactivateTenant() {
            await sendCommand('DeactivateTenant', {
                tenantId: this.$route.params.tenantId,
            })
        },
    },
}
</script>
