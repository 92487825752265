<template>
  <div>
    <v-text-field
      class="email-field"
      filled
      :label="label"
      :hint="hint"
      :persistent-hint="true"
      :value="inputPreview"
      :readonly="true"
      @click="editing = true"
      prepend-icon="mdi-bank"
    />
    <dialog-form
      v-model="editing"
      :title="$localize('EditBankInfo.title')"
      @commit="save"
      :allow-continue="false"
    >
      <v-subheader>{{ $localize('EditBankInfo.subheader') }}</v-subheader>

      <template v-for="(row, index) in data">
        <v-container no-gutters :key="'container-'+index">
          <v-row>
            <v-col cols="5">
              <v-text-field outlined hide-details v-model="row[0]" />
            </v-col>
            <v-col cols="7">
              <v-text-field outlined hide-details v-model="row[1]" />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="5">
              <v-text-field outlined hide-details v-model="row[2]" />
            </v-col>
            <v-col cols="7">
              <v-text-field outlined hide-details v-model="row[3]" />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="5">
              <v-text-field outlined hide-details v-model="row[4]" />
            </v-col>
            <v-col cols="7">
              <v-text-field outlined hide-details v-model="row[5]" />
            </v-col>
          </v-row>
        </v-container>
        <v-btn
          v-if="index + 1 < data.length"
          fab
          x-small
          right
          absolute
          :key="'delete-'+index"
          color="error"
          class="mt-n4"
          @click="removeBank(index + 1)"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>

      <v-btn fab x-small right absolute @click="addBank()" color="success" class="mt-n4">
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </dialog-form>
  </div>
</template>

<script>
import DialogForm from "@/shared/components/DialogForm";

export default {
  components: { DialogForm },
  props: {
    value: String,
    label: String,
    hint: String
  },
  data() {
    return {
      editing: false,
      data: []
    };
  },
  computed: {
    inputPreview() {
      let preview = ''
      for(let index in this.data){
        let bank = this.data[index];
        preview += ` ${bank[1]} ${bank[3]} ${bank[5]}`;
  
      }
      return preview;
    }
  },
  watch: {
    value: {
      immediate: true,
      handler() {
        if (this.value) {
          this.data = JSON.parse(this.value);
        }
        if (this.data.length === 0) {
          this.addBank();
        }
      }
    }
  },
  methods: {
    addBank() {
      this.data.push(["Bank", "", "bic", "", "iban/sepa", ""]);
    },
    removeBank(index) {
      this.data.splice(index, 1);
    },
    save(callback) {
      this.$emit("input", JSON.stringify(this.data));
      callback({ success: true });
    }
  }
};
</script>
