<template>
  <dialog-form
    :value="value"
    @input="$emit('input', $event)"
    max-width="700"
    :fullscreen="$vuetify.breakpoint.xsOnly"
    :title="$localize('CreateOrder.title')"
    @commit="save"
    :allow-continue="false"
    @reset="createOrderCommand = {}"
  >
    <v-text-field
      v-model="createOrderCommand.project"
      :rules="[validation.required]"
      :label="$localize('Project.project')"
    />

    <select-relation v-model="createOrderCommand.relationId" />
    <select-contact
      :relation-id="createOrderCommand.relationId"
      v-model="createOrderCommand.contactId"
    />

    <select-delivery-method
      v-model="createOrderCommand.deliveryMethod"
      required
    />

    <select-address
      :label="$localize('Project.deliveryMontageAddress')"
      v-model="createOrderCommand.deliveryAddressId"
      :relation-id="createOrderCommand.relationId"
      :required="false"
    />

    <date-select
      v-model="createOrderCommand.productionDate"
      :label="$localize('Project.productionDate')"
      :rules="[validation.required]"
    />
    <date-select
      v-model="createOrderCommand.deadline"
      :label="$localize('Project.deadline')"
    />
  </dialog-form>
</template>

<script>
import DialogForm from "@/shared/components/DialogForm";
import sendCommand from "@/services/sendCommand";
import DateSelect from "@/shared/components/DateSelect";
import { required } from "@/services/validation";
import { format, startOfToday, addDays } from "date-fns";
import SelectContact from "@/main/components/forms/SelectContact";
import SelectRelation from "@/main/components/forms/SelectRelation";
import SelectAddress from "@/main/components/forms/SelectAddress";
import SelectDeliveryMethod from "@/main/components/forms/SelectDeliveryMethod";
import Vue from "vue";
import { isGuid } from "@/services/validation"

export default {
  components: {
    DateSelect,
    SelectContact,
    SelectRelation,
    SelectDeliveryMethod,
    SelectAddress,
    DialogForm,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    calculationId: {
      validator: isGuid,
      required: true,
    },
    calculationInput: {
      type: Object,
    },
  },

  data() {
    return {
      createOrderCommand: {},
      validation: { required },
      loading: false,
    };
  },
  async created() {
    this.loading = true;
    if (this.$settings.productionLeadTime) {
      this.createOrderCommand.productionDate = format(
        addDays(startOfToday(), this.$settings.productionLeadTime || 0),
        "yyyy-MM-dd"
      );
    }
    this.loading = false;
  },
  methods: {
    async save(callback, close) {
      this.createOrderCommand.calculationId = this.calculationId;
      this.createOrderCommand.calculationInput = this.calculationInput;
      const result = await sendCommand("CreateOrder", this.createOrderCommand);
      if (result.success) {
        this.$emit("saved");
        if (close) {
          Vue.nextTick(() => {
            Vue.nextTick(() => {
              this.$router.push({
                name: "projectDetails",
                params: { projectId: result.generatedId },
              });
            });
          });
        }
      }

      callback({ success: result.success });
    },
  },
};
</script>
