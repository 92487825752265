<template>
	<v-container fluid class="pa-0">
		<v-toolbar flat class="my-3" height="36">
			<v-toolbar-title>{{
				$localize('UserOverview.title')
			}}</v-toolbar-title>

			<v-spacer></v-spacer>
			<v-toolbar-items>
				<v-btn
					class="primary"
					@click="createUser = true"
					@saved="refresh()"
				>
					<v-icon class="mr-1">mdi-plus</v-icon>
					{{ $localize('UserOverview.button.createUser') }}</v-btn
				>
			</v-toolbar-items>
		</v-toolbar>

		<v-data-table
			:headers="headers"
			:items="users"
			@click:row="navUser($event)"
			:loading="loading"
			:options.sync="options"
			:search="search"
			:footer-props="{
				'items-per-page-options': [10, 20, 50, -1],
			}"
			dense
		>
			<template v-slot:[`item.isActivated`]="{ item }">
				<v-icon v-if="item.isActivated" class="success--text"
					>mdi-check</v-icon
				>
				<v-icon v-else class="error--text">mdi-close</v-icon>
			</template>
			<template v-slot:footer="{}">
				<v-divider />
				<v-row no-gutters class="mx-3">
					<v-col cols="auto">
						<v-checkbox
							:label="$localize('form.label.trash')"
							v-model="viewTrash"
							@change="navTrash"
							class="pt-0 my-1"
							hide-details
						/>
					</v-col>
				</v-row>
			</template>
		</v-data-table>

		<create-user
			v-if="createUser"
			v-model="createUser"
			@saved="refresh()"
		/>
	</v-container>
</template>

<script>
import CreateUser from './dialogs/CreateUser'
import { getUsers } from '@/services/api'

export default {
	components: { CreateUser },
	props: {
		trash: Boolean,
	},
	data() {
		return {
			viewTrash: this.trash,
			createUser: false,
			headers: [
				{ text: this.$localize('User.login'), value: 'login' },
				{
					text: this.$localize('User.recoveryEmail'),
					value: 'recoveryEmail',
				},
				{
					text: this.$localize('User.salutation'),
					value: 'salutation',
				},
				{ text: this.$localize('User.name'), value: 'name' },
				{ text: this.$localize('User.location'), value: 'location' },
				{
					text: this.$localize('User.isActivated'),
					value: 'isActivated',
				},
			],
			users: [],
			options: JSON.parse(
				localStorage.getItem('UserOverview:options')
			) || {
				itemsPerPage: -1,
			},
			loading: false,
			search: '',
		}
	},
	created() {
		this.refresh()
	},
	watch: {
		options() {
			localStorage.setItem(
				'UserOverview:options',
				JSON.stringify(this.options)
			)
		},
	},
	methods: {
		async refresh() {
			this.loading = true
			const { result, ok } = await getUsers.call({
				q: { trash: this.trash },
			})
			if (ok) {
				this.users = result
			}
			this.loading = false
		},
		navUser(user) {
			this.$router.push({
				name: 'userDetails',
				params: { userId: user.userId },
			})
		},
		navTrash() {
			if (this.viewTrash) {
				this.$router.push({
					name: 'userOverviewTrash',
				})
			} else {
				this.$router.push({
					name: 'userOverview',
				})
			}
		},
	},
}
</script>
