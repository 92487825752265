<template>
    <div>
        <v-toolbar flat dense>
            <v-toolbar-title>Invioces</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items> </v-toolbar-items>
        </v-toolbar>
        <v-data-table :items="periods" :headers="headers">
            <template v-slot:[`item.invoice.invoiceId`]="{ item }">
                <div v-if="item.invoice" class="id-col">
                    <v-icon x-small @click="copyId(item.invoice.invoiceId)"
                        >mdi-content-copy</v-icon
                    >
                    {{ item.invoice.invoiceId }}
                </div>
            </template>
            <template v-slot:[`item.periodStart`]="{ item }">
                {{ $format.shortDate(item.periodStart) }}
                tot
                {{ $format.shortDate(item.periodEnd) }}
            </template>
            <template v-slot:[`item.invoice.created`]="{ item }">
                <span v-if="item.invoice">
                    {{ $format.shortDateTime(item.invoice.created) }}
                </span>
            </template>
            <template v-slot:[`item.invoice.amount`]="{ item }">
                <span v-if="item.invoice">
                    {{ $format.currency(item.invoice.amount) }}
                </span>
            </template>
            <template v-slot:[`item.invoice.paidAt`]="{ item }">
                <template v-if="item.invoice">
                    <template v-if="item.invoice.paid">
                        {{ $format.shortDateTime(item.invoice.paidAt) }}
                    </template>
                    <template v-else> - </template>
                </template>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
                <div>
                    <v-btn
                        v-if="!item.invoice"
                        @click="addInvoice(item.year, item.month)"
                    >
                        Add Invoice
                    </v-btn>
                    <template v-else>
                        <v-btn @click="editInvoice(item.invoice.invoiceId)">
                            Edit
                        </v-btn>
                        <v-btn
                            v-if="
                                item.invoice.amount === 0 ||
                                item.invoice.paid === false
                            "
                            @click="deleteInvoice(item.invoice.invoiceId)"
                        >
                            Delete
                        </v-btn>
                    </template>
                </div>
            </template>
        </v-data-table>
        <edit-invoice
            v-if="editInvoiceId && editingInvoice"
            v-model="editingInvoice"
            :tenant-id="tenantId"
            :invoice-id="editInvoiceId"
            @saved="refresh"
        />
    </div>
</template>

<script>
import {
    parseISO,
    compareAsc,
    getYear,
    getMonth,
    getDaysInMonth,
} from 'date-fns'
import { isGuid } from '@/services/validation'
import sendCommand from '@/services/sendCommand'
import EditInvoice from '../dialogs/EditInvoice'
import { getTenantInvoices, getTenantLicenses } from '@/services/api'

export default {
    components: { EditInvoice },
    props: {
        tenantId: {
            validator: isGuid,
            required: true,
        },
    },
    data: function () {
        return {
            editingInvoice: false,
            editInvoiceId: null,
            headers: [
                { text: 'Id', value: 'invoice.invoiceId' },
                { text: 'Datum', value: 'invoice.created' },
                { text: 'Periode', value: 'periodStart' },
                { text: 'Bedrag', value: 'invoice.amount' },
                { text: 'Betaald', value: 'invoice.paidAt' },
                { text: 'Acties', value: 'actions', width: 200 },
            ],
            invoices: [],
            periods: [],
            licenses: [],
        }
    },
    watch: {
        tenantId: {
            immediate: true,
            handler: function () {
                this.refresh()
            },
        },
    },
    methods: {
        async refresh() {
            if (this.tenantId) {
                this.invoices = await getTenantInvoices
                    .create({ tenantId: this.tenantId })
                    .load()
                this.licenses = await getTenantLicenses
                    .create({ tenantId: this.tenantId })
                    .load()

                let firstLicenseStart = null
                for (const index in this.licenses) {
                    const license = this.licenses[index]
                    const startDate = parseISO(license.start)
                    if (
                        firstLicenseStart === null ||
                        compareAsc(startDate, firstLicenseStart) === -1
                    ) {
                        firstLicenseStart = startDate
                    }
                }
                this.periods = []
                const startYear = getYear(firstLicenseStart)
                const currentYear = getYear(new Date())
                const now = new Date()
                let month = getMonth(firstLicenseStart)
                for (let year = startYear; year <= currentYear; year++) {
                    for (; month < 12; month++) {
                        const periodStart = new Date(year, month, 1)
                        const days = getDaysInMonth(periodStart)
                        const periodEnd = new Date(year, month, days)
                        if (compareAsc(periodEnd, now) >= 1) {
                            break
                        }

                        let periodInvoice = null
                        for (const index in this.invoices) {
                            const invoice = this.invoices[index]
                            const invoiceStart = parseISO(invoice.periodStart)
                            if (
                                getYear(invoiceStart) === year &&
                                getMonth(invoiceStart) === month
                            ) {
                                periodInvoice = invoice
                                break
                            }
                        }

                        this.periods.push({
                            year: year,
                            month: month + 1,
                            days: days,
                            periodStart: periodStart,
                            periodEnd: new Date(year, month, days),
                            invoice: periodInvoice,
                        })
                    }
                    month = 0
                }
            } else {
                this.invoices = []
            }
        },
        async addInvoice(year, month) {
            const result = await sendCommand('AddInvoiceForPeriod', {
                tenantId: this.tenantId,
                year: year,
                month: month,
            })
            if (result.success) {
                this.refresh()
            }
        },
        async editInvoice(invoiceId) {
            this.editInvoiceId = invoiceId
            this.editingInvoice = true
        },
        async deleteInvoice(invoiceId) {
            const result = await sendCommand('DeleteLicenseInvoice', {
                tenantId: this.tenantId,
                invoiceId: invoiceId,
            })
            if (result.success) {
                this.refresh()
            }
        },
        copyId(licenseId) {
            navigator.clipboard.writeText(licenseId)
        },
    },
}
</script>
