<template>
  <v-card-actions v-if="prompt">
    <a @click="installer">{{ $localize('Login.link.installApp') }}</a>
  </v-card-actions>
</template>

<script>
export default {
  data() {
    return {
      installer: undefined,
      prompt: null,
    };
  },
  created() {
    window.addEventListener("beforeinstallprompt", (e) => {
      e.preventDefault();
      this.prompt = e;
    });

    this.installer = () => {
      this.prompt.prompt();
      this.prompt.userChoice.then((result) => {
        if (result.outcome === "accepted") {
          console.log("User Accepted");
        } else {
          console.log("User denied");
        }
        this.prompt = null;
      });
    };
  }
};
</script>