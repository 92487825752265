<template>
	<v-row no-gutters>
		<v-col>
			<v-autocomplete
				:value="selectedRelationId"
				@input="onRelationSelected($event)"
				:label="
					label ??
					(customers
						? $localize('SelectRelation.field.customer')
						: $localize('SelectRelation.field.supplier'))
				"
				:items="relations"
				item-text="name"
				item-value="relationId"
				:clearable="true"
				:rules="validation"
				:prepend-icon="customers ? 'mdi-office-building' : 'mdi-truck'"
				:autofocus="autofocus"
				:loading="loading"
				class="selectRelation"
				@update:search-input="onSearchInput"
			/>
		</v-col>
		<v-col
			v-if="$session.roles.includes('relations_manage')"
			align-self="center"
			cols="auto"
			class="ml-1"
		>
			<v-btn
				small
				outlined
				fab
				color="success createRelation"
				@click="createRelation = true"
			>
				<v-icon>mdi-plus</v-icon>
			</v-btn>
		</v-col>

		<create-relation
			v-if="createRelation"
			v-model="createRelation"
			:allow-continue="false"
			@saved="onRelationSaved($event)"
			:customer="customers"
			:supplier="suppliers"
			:close-on-save="false"
			:company-name="lastSearchInput"
		/>
	</v-row>
</template>

<script>
import { isGuid } from '@/services/validation'
import { required } from '@/services/validation'
import { getRelationsWithContactsSimple } from '@/services/api'
import CreateRelation from '@/main/relations/dialogs/CreateRelation'

export default {
	components: { CreateRelation },
	props: {
		value: {
			validator: isGuid,
		},
		customers: {
			type: Boolean,
			default: true,
		},
		suppliers: {
			type: Boolean,
			default: false,
		},
		autofocus: Boolean,
		rules: {
			type: Object,
			default: null,
		},
		label: String,
	},
	data() {
		return {
			selectedRelationId: this.value,
			createRelation: false,
			relations: [],
			loading: false,
			lastSearchInput: null,
		}
	},
	computed: {
		validation() {
			if (Array.isArray(this.rules)) {
				return this.rules
			}
			if (this.rules === null) {
				return [required] // default
			}
			return []
		},
	},
	watch: {
		value() {
			this.selectedRelationId = this.value
		},
	},
	async created() {
		this.refresh()
	},
	methods: {
		async refresh() {
			this.loading = true
			const { result, ok } = await getRelationsWithContactsSimple.call({
				q: { customers: this.customers, suppliers: this.suppliers },
			})
			if (ok) {
				this.relations = result
			}
			this.loading = false
		},
		async onRelationSaved(response) {
			this.selectedRelationId = response.generatedId
			await this.refresh()
			this.$emit('input', this.selectedRelationId)
		},
		onRelationSelected(relationId) {
			this.selectedRelationId = relationId
			this.$emit('input', relationId)
		},
		onSearchInput(searchInput) {
			if (searchInput !== null) {
				this.lastSearchInput = searchInput
			}
		},
	},
}
</script>
