<template>
	<dialog-form
		:value="value"
		@input="$emit('input', $event)"
		:title="$localize('EditCalculationStartingCosts.title')"
		@commit="save"
		:allow-continue="false"
	>
		<v-container no-gutters fluid>
			<v-row no-gutters>
				<v-col>
					<v-subheader>{{
						$localize('StartingCosts.minimumPrice')
					}}</v-subheader>
				</v-col>
				<v-col>
					<v-subheader>{{
						$localize('StartingCosts.maximumPrice')
					}}</v-subheader>
				</v-col>
				<v-col>
					<v-subheader>{{
						$localize('StartingCosts.cost')
					}}</v-subheader>
				</v-col>
				<v-col cols="1"></v-col>
			</v-row>
			<v-form ref="startingCostForm">
				<v-row
					v-for="startingCost in startingCosts"
					:key="startingCost.id"
				>
					<v-col>
						<v-text-field
							v-model="startingCost.minimumPrice"
							@input="isDirty = true"
							:rules="[validation.required, validation.number]"
							type="number"
							outlined
							dense
						/>
					</v-col>
					<v-col>
						<v-text-field
							v-model="startingCost.maximumPrice"
							@input="isDirty = true"
							:rules="[validation.required, validation.number]"
							type="number"
							outlined
							dense
						/>
					</v-col>
					<v-col>
						<v-text-field
							v-model="startingCost.cost"
							@input="isDirty = true"
							:rules="[validation.required, validation.number]"
							type="number"
							outlined
							dense
						/>
					</v-col>
					<v-col cols="1">
						<v-btn
							fab
							small
							color="error"
							@click="deleteStartingCost(startingCost.id)"
						>
							<v-icon>mdi-close</v-icon>
						</v-btn>
					</v-col>
				</v-row>
			</v-form>
			<v-form ref="newStartingCostForm">
				<v-row>
					<v-col>
						<v-text-field
							ref="newStartingCostMinimumPrice"
							v-model="newStartingCost.minimumPrice"
							:rules="[validation.required, validation.number]"
							type="number"
							outlined
							dense
						/>
					</v-col>
					<v-col>
						<v-text-field
							v-model="newStartingCost.maximumPrice"
							:rules="[validation.required, validation.number]"
							type="number"
							outlined
							dense
						/>
					</v-col>
					<v-col>
						<v-text-field
							v-model="newStartingCost.cost"
							:rules="[validation.required, validation.number]"
							type="number"
							outlined
							dense
							@blur="addStartingCost"
						/>
					</v-col>
					<v-col cols="1">
						<v-btn
							fab
							small
							color="success"
							@click="addStartingCost"
						>
							<v-icon>mdi-plus</v-icon>
						</v-btn>
					</v-col>
				</v-row>
			</v-form>
		</v-container>
	</dialog-form>
</template>

<script>
import DialogForm from '@/shared/components/DialogForm'
import { getCalculationById } from '@/services/api'

import sendCommand from '@/services/sendCommand'
import { required, maxLength, number } from '@/services/validation'
import { isGuid } from '@/services/validation'
export default {
	components: { DialogForm },
	props: {
		value: Boolean,
		calculationId: {
			validator: isGuid,
			required: true,
		},
	},
	data() {
		return {
			isDirty: false,
			startingCosts: [],
			newStartingCost: {},
			validation: { required, maxLength, number },
		}
	},
	async created() {
		const { result, ok } = await getCalculationById.call({
			args: { calculationId: this.calculationId, version: -1 },
		})

		if (ok) {
			this.startingCosts = result.startingCosts
		}
	},
	methods: {
		async save(callback) {
			const result = await sendCommand('ChangeCalculationStartingCosts', {
				startingCosts: this.startingCosts,
				calculationId: this.calculationId,
			})

			if (result.success) {
				this.$emit('saved')
			}
			callback({ success: result.success })
		},
		addStartingCost() {
			if (!this.$refs.newStartingCostForm.validate()) {
				return
			}
			this.newStartingCost.id = this.startingCostId++
			this.startingCosts.push(this.newStartingCost)

			this.newStartingCost = {}

			this.$refs.newStartingCostMinimumPrice.focus()
			this.$refs.newStartingCostForm.resetValidation()
			this.isDirty = true
		},
		deleteStartingCost(id) {
			this.startingCosts = this.startingCosts.filter((x) => x.id !== id)
			this.isDirty = true
		},
	},
}
</script>
