<template>
	<v-container>
		<v-card>
			<v-card-title>{{
				$localize('Startup.calculations.title')
			}}</v-card-title>
			<v-card-text>
				<v-data-table
					:items="availableCalculations"
					:headers="installmentHeaders"
					selectable-key="canInstall"
					show-select
					v-model="selectedCalculations"
				>
					<template v-slot:[`item.name`]="{ item }">
						{{ item.name }}
					</template>
					<template v-slot:[`item.isInstalled`]="{ item }">
						<v-icon v-if="item.isInstalled" class="success--text"
							>mdi-check</v-icon
						>
						<v-icon v-else class="error--text">mdi-close</v-icon>
					</template>
				</v-data-table>
			</v-card-text>
			<v-card-actions>
				<v-btn
					:disabled="selectedCalculations.length === 0"
					@click="
						send('InstallComponents', {
							componentIds: selectedCalculations.map((x) => x.id),
						})
					"
					>{{
						$localize('Startup.calculations.installCalculations', {
							count: selectedCalculations.length,
						})
					}}</v-btn
				>
			</v-card-actions>
		</v-card>
	</v-container>
</template>

<script>
import sendCommand from '@/services/sendCommand'
import { getInstallments } from '@/services/api'
import { reloadSession } from '@/services/session'

export default {
	data() {
		return {
			loading: false,

			availableCalculations: [],
			selectedCalculations: [],
			installmentHeaders: [
				{ text: this.$localize('Calculation.name'), value: 'name' },
				{
					text: this.$localize('Calculation.description'),
					value: 'description',
				},
				{
					text: this.$localize('Calculation.isInstalled'),
					value: 'isInstalled',
				},
			],
			selectedInstallments: [],
		}
	},
	created() {
		this.refresh()
	},

	methods: {
		async refresh() {
			this.loading = true
			const { result, ok } = await getInstallments.call()
			if (ok) {
				this.availableCalculations = result.filter(
					(x) => x.type === 'Calculation'
				)
			}

			this.loading = false
		},
		async send(command, args) {
			args = args || {}
			const result = await sendCommand(command, args)
			if (result.success) {
				await this.refresh()
				await reloadSession()
			}
		},
	},
}
</script>

<style lang="scss" scoped>
.v-card {
	margin-top: 8px;
	margin-bottom: 8px;
}

.v-chip {
	margin-top: 4px;
}

.v-chip + .v-chip {
	margin-left: 4px;
}
</style>
