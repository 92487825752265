import { getPhaseIcon } from '@/services/icons'
import store from '@/store/store.js'

export function getEventColor(event, colorMode) {
    if (event.temp) {
        return 'primary'
    }

    switch (colorMode) {
        case 'label':
            if (event.data.labels !== null && event.data.labels.length === 1) {
                const label = store.getters.getLabelById(event.data.labels[0])
                if (label !== null) {
                    return label.color
                }
            }
            break
        case 'user':
            if (event.data.users !== null && event.data.users.length === 1) {
                const user = store.getters.getUserById(event.data.users[0])
                if (user !== null) {
                    return user.color
                }
            }

            break
    }

    return '#aaa'
}
export function getEventIcon(event) {
    if (event.data && event.data.project) {
        return getPhaseIcon(event.data.project.phase)
    }
    if (event.data.type === 'holiday') {
        return 'mdi mdi-party-popper'
    }
    return null
}
