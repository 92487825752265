<template>
  <dialog-form
    :value="value"
    @input="$emit('input', $event)"
    :allow-continue="allowContinue"
    :title="$localize('CreateArticleCalculation.title')"
    @commit="save"
    @reset="
      selectedPrices = [];
      selectedAttributes = [];
      name = '';
    "
  >
    <v-text-field
      :label="$localize('ArticleCalculation.name')"
      v-model="name"
      :hint="$localize('ArticleCalculation.name.hint')"
      :persistent-hint="true"
      prepend-icon="mdi-shape"
      autofocus
      :rules="[validation.required, validation.maxLength(50)]"
    />
    <select-prices v-model="selectedPrices" />
    <select-attributes v-model="selectedAttributes" />
  </dialog-form>
</template>

<script>
import DialogForm from "@/shared/components/DialogForm";
import sendCommand from "@/services/sendCommand";
import SelectPrices from "@/main/components/forms/SelectPrices";
import SelectAttributes from "@/main/components/forms/SelectAttributes";
import { required, maxLength } from "@/services/validation";
export default {
  components: { SelectPrices, SelectAttributes, DialogForm },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    allowContinue: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      name: "",

      selectedPrices: [],
      selectedAttributes: [],
      validation: { required, maxLength },
    };
  },
  methods: {
    async save(callback) {
      const result = await sendCommand("CreateArticleCalculation", {
        name: this.name,
        attributes: [...this.selectedPrices, ...this.selectedAttributes],
      });

      if (result.success) {
        this.$emit("saved", result.generatedId);
      }
      callback({ success: result.success });
    },
  },
};
</script>
