<template>
  <v-toolbar flat height="26" class="table-toolbar">
    <v-spacer></v-spacer>
    <v-toolbar-items>
      <slot />
    </v-toolbar-items>
  </v-toolbar> 
</template>

<script>
export default {

}
</script>

<style scoped lang="scss">
  .v-toolbar{
    background:none !important;
  }
</style>

<style lang="scss">
  .table-toolbar{
    .v-toolbar__content{
      padding-left:0;
      padding-right:0;
    }
  }
</style>
