<template>
  <div>
    <v-btn
      v-if="$session.isAdministrator && $session.impersonated"
      v-bind="$attrs"
      @click="navBackToAdmin()"
      class="mb-3 btnBackToAdmin"
    >Back to Admin</v-btn>
    <v-btn @click="logout()" v-bind="$attrs" class="btnLogout">
      {{ $localize('LogoutButton.text') }}
      <v-icon class="ml-3">mdi-logout-variant</v-icon>
    </v-btn>
  </div>
</template>

<script>
import sendCommand from "@/services/sendCommand";
import { currentSession, clearSession } from "@/services/session";

export default {
  methods: {
    async logout() {
      const result = await sendCommand("Logout", {});
      if (result.success) {
        clearSession();
        this.$router.push({ name: "login" });
      }
    },
    async navBackToAdmin() {
      const result = await sendCommand("LoginToImpersonator", {});
      if (result.success) {
        this.session = await currentSession(true);
        this.$router.push({ name: "adminHome" });
      }
    }
  }
};
</script>