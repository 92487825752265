<template>
    <dialog-form
        :value="value"
        @input="$emit('input', $event)"
        @commit="save"
        :title="$localize('CancelLicense.title')"
        :allow-continue="false"
        action="delete"
        :action-text="$localize('CancelLicense.title')"
        >{{ $localize('CancelLicense.message') }}</dialog-form
    >
</template>

<script>
import DialogForm from '@/shared/components/DialogForm'
import sendCommand from '@/services/sendCommand'
import { isGuid } from '@/services/validation'

export default {
    components: { DialogForm },
    props: {
        value: {
            type: Boolean,
            default: false,
        },
        licenseId: {
            validator: isGuid,
            required: true,
        },
    },
    methods: {
        async save(callback) {
            const result = await sendCommand('CancelLicense', {
                licenseId: this.licenseId,
            })

            if (result.success) {
                this.$emit('saved')
            }

            callback({ success: result.success })
        },
    },
}
</script>
