<template>
    <v-dialog :value="value" @input="$emit('input', $event)" max-width="600px">
        <v-card>
            <v-card-title>Edit Invoice</v-card-title>
            <v-form @submit.prevent="save()" class="pa-3" ref="form">
                <v-switch label="Betaald" v-model="editInvoice.paid" />
                <date-select
                    label="Betaald op"
                    v-model="editInvoice.paidAt"
                    :enabled="editInvoice.paid"
                    clearable
                />
                <v-text-field
                    label="Bedrag"
                    v-model="editInvoice.amount"
                    type="number"
                />
                <v-text-field
                    label="PaymentId"
                    v-model="editInvoice.paymentId"
                />
                <v-select
                    label="Status"
                    v-model="editInvoice.status"
                    :items="statusses"
                    clearable
                />

                <v-btn @click="$emit('input', false)" class="mt-3 mr-3">{{
                    $localize('form.button.cancel')
                }}</v-btn>
                <v-btn type="submit" class="success mt-3 mr-3">{{
                    $localize('form.button.save')
                }}</v-btn>
            </v-form>
        </v-card>
    </v-dialog>
</template>

<script>
import sendCommand from '@/services/sendCommand'
import { isGuid, required } from '@/services/validation'
import { getTenantInvoiceById } from '@/services/api'
import DateSelect from '@/shared/components/DateSelect'

export default {
    components: { DateSelect },
    props: {
        value: {
            type: Boolean,
            default: false,
        },
        tenantId: {
            validator: isGuid,
            required: true,
        },
        invoiceId: {
            validator: isGuid,
            required: true,
        },
    },
    data() {
        return {
            editInvoice: {
                paid: false,
                paidAt: null,
                amount: 0,
                paymentId: null,
                status: null,
            },
            statusses: [
                'open',
                'canceled',
                'pending',
                'expired',
                'failed',
                'paid',
            ],
            validation: { required },
        }
    },
    async created() {
        const invoice = await getTenantInvoiceById
            .create({ tenantId: this.tenantId, invoiceId: this.invoiceId })
            .load()
        this.editInvoice.paid = invoice.paid
        this.editInvoice.paidAt = invoice.paidAt
        this.editInvoice.amount = invoice.amount
        this.editInvoice.paymentId = invoice.paymentId
        this.editInvoice.status = invoice.status
    },
    methods: {
        async save() {
            if (!this.$refs.form || !this.$refs.form.validate()) {
                return
            }
            this.editInvoice.tenantId = this.tenantId
            this.editInvoice.invoiceId = this.invoiceId
            const result = await sendCommand(
                'EditLicenseInvoice',
                this.editInvoice
            )
            if (result.success) {
                this.addLicenseCommand = {}
                this.$emit('input', false)
                this.$emit('saved')
            }
        },
    },
}
</script>
