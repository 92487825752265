<template>
	<div>
		<v-row>
			<v-col>
				<v-toolbar flat height="36">
					<v-toolbar-items>
						<v-btn
							v-if="$session.roles.includes('relations_manage')"
							@click="enterAddressInformation = true"
							class="mr-3"
						>
							<v-icon>mdi-pencil</v-icon>
						</v-btn>
					</v-toolbar-items>
					<v-toolbar-title>{{ address.location }}</v-toolbar-title>
					<v-spacer></v-spacer>
					<v-toolbar-items
						v-if="$session.roles.includes('relations_manage')"
					>
						<v-btn
							v-if="!address.isTrashed"
							@click="trash()"
							class="ml-3 btnTrashAddress"
						>
							<span v-if="$vuetify.breakpoint.smAndUp">{{
								$localize('form.button.trash')
							}}</span>
							<v-icon>mdi-delete</v-icon>
						</v-btn>
						<v-btn
							v-if="address.isTrashed"
							@click="untrash()"
							class="ml-3 btnUntrashAddress"
						>
							<span v-if="$vuetify.breakpoint.smAndUp">{{
								$localize('form.button.untrash')
							}}</span>
							<v-icon>mdi-delete-restore</v-icon>
						</v-btn>
						<v-btn @click="deleteAddress = true" class="ml-3">
							<span v-if="$vuetify.breakpoint.smAndUp">{{
								$localize('form.button.delete')
							}}</span>
							<v-icon>mdi-close</v-icon>
						</v-btn>
					</v-toolbar-items>
				</v-toolbar>
			</v-col>
		</v-row>
		<v-row class="px-4">
			<v-col class="col-12 col-md-6">
				<v-row wrap dense>
					<v-col cols="6">{{ $localize('Address.location') }}</v-col>
					<v-col cols="6">{{ address.location }}</v-col>
					<v-col cols="6">{{ $localize('Address.typeName') }}</v-col>
					<v-col cols="6">{{ address.typeName }}</v-col>
					<v-col cols="6">{{ $localize('Address.street') }}</v-col>
					<v-col cols="6">{{ address.street }}</v-col>
					<v-col cols="6">{{
						$localize('Address.extraAddressLine')
					}}</v-col>
					<v-col cols="6">{{ address.extraAddressLine }}</v-col>
					<v-col cols="6">{{ $localize('Address.zipCode') }}</v-col>
					<v-col cols="6">{{ address.zipCode }}</v-col>
					<v-col cols="6">{{ $localize('Address.city') }}</v-col>
					<v-col cols="6">{{ address.city }}</v-col>
					<v-col cols="6">{{ $localize('Address.email') }}</v-col>
					<v-col cols="6">{{ address.email }}</v-col>
				</v-row>
			</v-col>
		</v-row>
		<delete-address
			v-if="deleteAddress"
			v-model="deleteAddress"
			:address-id="addressId"
			@saved="$emit('closed')"
		/>
		<enter-address-information
			v-if="enterAddressInformation"
			v-model="enterAddressInformation"
			:address-id="addressId"
			@saved="
				refresh()
				$emit('saved')
			"
		/>
	</div>
</template>

<script>
import { isGuid } from '@/services/validation'
import { getAddressById } from '@/services/api'
import sendCommand from '@/services/sendCommand'
import DeleteAddress from './dialogs/DeleteAddress'
import EnterAddressInformation from './dialogs/EnterAddressInformation'

export default {
	components: { DeleteAddress, EnterAddressInformation },
	props: {
		addressId: {
			required: true,
			validator: isGuid,
		},
	},
	data() {
		return {
			address: {},
			enterAddressInformation: false,
			deleteAddress: false,
		}
	},
	created() {
		this.refresh()
	},

	methods: {
		async refresh() {
			const { result, ok } = await getAddressById.call({
				args: { addressId: this.addressId },
			})
			if (ok) {
				this.address = result
			}
		},
		async trash() {
			const result = await sendCommand('TrashAddress', {
				addressId: this.addressId,
			})
			if (result.success) {
				this.refresh()
				this.$emit('saved')
			}
		},
		async untrash() {
			const result = await sendCommand('UntrashAddress', {
				addressId: this.addressId,
			})
			if (result.success) {
				this.refresh()
				this.$emit('saved')
			}
		},
	},
}
</script>
