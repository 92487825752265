<template>
	<v-card>
		<v-card-text>
			<v-select
				v-model="selectedEventTypes"
				:items="eventTypes"
				item-text="name"
				item-value="key"
				multiple
				@input="isDirty = true"
			></v-select>
		</v-card-text>
		<v-card-actions>
			<v-btn :disabled="!isDirty" @click="resetCodeContext()">{{
				$localize('form.button.cancel')
			}}</v-btn>
			<v-btn :disabled="!isDirty" @click="saveEventTypes(false)">{{
				$localize('form.button.save')
			}}</v-btn>
			<v-btn color="primary" @click="saveEventTypes(true)">{{
				$localize('form.button.saveAndContinue')
			}}</v-btn>
		</v-card-actions>
	</v-card>
</template>

<script>
import sendCommand from '@/services/sendCommand'
import { getEventTypes } from '@/services/api'

export default {
	props: {
		value: Object, // trigger
	},
	data() {
		return {
			eventTypes: [],
			selectedEventTypes: [],
			isDirty: false,
		}
	},
	watch: {
		value: {
			immediate: true,
			handler() {
				this.selectedEventTypes = this.value.eventTypes
			},
		},
	},
	async created() {
		const { result, ok } = await getEventTypes.call()
		if (ok) {
			this.eventTypes = result
		}
	},
	methods: {
		async saveEventTypes(nextStep) {
			if (this.isDirty) {
				this.isDirty = false
				const result = await sendCommand('ChangeTriggerEvents', {
					triggerId: this.$route.params.triggerId,
					eventTypes: this.selectedEventTypes,
				})

				if (!result.success) {
					return
				}
			}
			this.$emit('saved')
			if (nextStep) {
				this.$emit('continue')
			}
		},
	},
}
</script>
