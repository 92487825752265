import Home from './Home'
import Startup from './settings/Startup'

//import StartupHelper from './settings/StartupHelper'

import RelationOverview from './relations/RelationOverview'
import RelationImport from './relations/RelationImport'
import RelationDetails from './relations/RelationDetails'
import ContactOverview from './relations/ContactOverview'
import AddressOverview from './relations/AddressOverview'

import UserOverview from './users/UserOverview'
import UserDetails from './users/UserDetails'

import ArticleOverview from './articles/ArticleOverview'
import ArticleDetails from './articles/ArticleDetails'
import ArticleImport from './articles/ArticleImport'
import ArticleDataTable from './articles/ArticleDataTable'

import ProjectOverview from './projects/ProjectOverview'
import ProjectDashboard from './projects/ProjectDashboard'
import ActionList from './projects/ActionList'
import ProjectDetails from './projects/ProjectDetails'

import SettingsOverview from './settings/SettingsOverview'
import Calendar from './calendar/Calendar'

import Advanced from './advanced/AdvancedOverview'
import CalculationOverview from './advanced/CalculationOverview'
import CalculationDetails from './advanced/CalculationDetails'
import AttributeOverview from './advanced/AttributeOverview'
import ArticleCalculationOverview from './advanced/ArticleCalculationOverview'

import TriggerOverview from './advanced/TriggerOverview'
import TriggerDetails from './advanced/TriggerDetails'

import SavedProductsOverview from './products/SavedProductsOverview'
import SavedProductDetails from './products/SavedProductDetails'

import Error404 from './404'

export default [
	{
		path: '/',
		component: Home,
		meta: { policy: 'tenant' },

		children: [
			/* Relations*/
			{
				props: true,
				name: 'relationOverview',
				path: '/relations/:type/:trash?',
				component: RelationOverview,
				meta: { policy: 'tenant', helpCategory: 'relations' },
			},
			{
				props: true,
				name: 'relationImport',
				path: '/relation/import/:relationImportId?',
				component: RelationImport,
				meta: { policy: 'tenant', helpCategory: 'relations' },
			},
			{
				name: 'relationDetails',
				path: '/relation/:relationId',
				redirect: '/relation/:relationId/contacts',
				component: RelationDetails,
				meta: { policy: 'tenant' },
				children: [
					{
						name: 'contactOverview',
						path: '/relation/:relationId/contacts',
						component: ContactOverview,
						meta: {
							policy: 'tenant',
							helpCategory: 'relations',
							helpItem: 'infoContacts',
						},
					},
					{
						name: 'addressOverview',
						path: '/relation/:relationId/addresses',
						component: AddressOverview,
						meta: {
							policy: 'tenant',
							helpCategory: 'relations',
							helpItem: 'infoAddresses',
						},
					},
					{
						props: true,
						name: 'relationProjectOverview',
						path: '/relation/:relationId/projects',
						component: ProjectOverview,
						meta: { policy: 'tenant', helpCategory: 'relations' },
					},
				],
			},

			/* Users */
			{
				props: { trash: false },
				name: 'userOverview',
				path: '/users',
				component: UserOverview,
				meta: { policy: 'tenant', helpCategory: 'users' },
			},
			{
				props: { trash: true },
				name: 'userOverviewTrash',
				path: '/users/trash',
				component: UserOverview,
				meta: { policy: 'tenant', helpCategory: 'users' },
			},
			{
				name: 'userDetails',
				path: '/users/:userId',
				component: UserDetails,
				meta: { policy: 'tenant', helpCategory: 'users' },
			},

			/* Articles */
			{
				props: true,
				path: '/articles',
				component: ArticleOverview,
				meta: { policy: 'tenant', helpCategory: 'articles' },
				children: [
					{
						props: true,
						path: ':category?/:trash?',
						name: 'articleOverview',
						component: ArticleDataTable,
						meta: { policy: 'tenant', helpCategory: 'articles' },
					},
				],
			},
			{
				props: true,
				name: 'articleImport',
				path: '/article/import/:articleImportId?',
				component: ArticleImport,
				meta: { policy: 'tenant', helpCategory: 'articles' },
			},
			{
				name: 'articleCategoryOverview',
				path: '/articles/:category',
				component: ArticleOverview,
				meta: { policy: 'tenant', helpCategory: 'articles' },
			},
			{
				name: 'articleCategoryOverviewTrash',
				path: '/articles/:category/trash',
				component: ArticleOverview,
				meta: { policy: 'tenant', helpCategory: 'articles' },
			},
			{
				name: 'articleDetails',
				path: '/article/:articleId',
				component: ArticleDetails,
				meta: { policy: 'tenant', helpCategory: 'articles' },
			},

			/* Projects */
			{
				props: true,
				name: 'projectOverview',
				path: '/projects/:phase',
				component: ProjectOverview,
				meta: { policy: 'tenant', helpCategory: 'offers' },
			},
			{
				props: true,
				name: 'dashboard',
				path: '/projects/dashboard/:dashboardId',
				component: ProjectDashboard,
				meta: { policy: 'tenant' },
			},
			{
				name: 'projectDetails',
				path: '/project/:projectId',
				component: ProjectDetails,
				meta: { policy: 'tenant' },
			},
			/* Settings */
			{
				name: 'settingsOverview',
				path: '/settings',
				component: SettingsOverview,
				meta: { policy: 'tenant' },
				children: [
					{
						name: 'settingsOverviewCategory',
						path: '/settings/:category',
						component: SettingsOverview,
						meta: { policy: 'tenant' },
					},
				],
			},
			/* Advanced */
			{
				path: '/advanced',
				component: Advanced,
				meta: { policy: 'tenant' },
				children: [
					{
						name: 'calculationOverview',
						path: 'calculations',
						component: CalculationOverview,
						meta: { policy: 'tenant' },
					},
					{
						name: 'calculationDetails',
						path: 'calculations/:calculationId',
						component: CalculationDetails,
						meta: { policy: 'tenant' },
					},
					{
						name: 'attributeOverview',
						path: 'attributes',
						component: AttributeOverview,
						meta: { policy: 'tenant' },
					},
					{
						name: 'articleCalculationOverview',
						path: 'article-calculations',
						component: ArticleCalculationOverview,
						meta: { policy: 'tenant' },
					},
					{
						name: 'triggerOverview',
						path: 'triggers',
						component: TriggerOverview,
						meta: { policy: 'tenant' },
					},
					{
						name: 'triggerDetails',
						path: 'triggers/:triggerId',
						component: TriggerDetails,
						meta: { policy: 'tenant' },
					},
					{
						name: 'advanced',
						path: '',
						component: CalculationOverview,
						meta: { policy: 'tenant' },
					},
				],
			},

			/* Calendar */
			{
				name: 'calendar',
				path: '/calendar',
				component: Calendar,
				meta: {
					policy: 'tenant',
					helpCategory: 'calendar',
					helpItem: 'intoPlanning',
				},
			},

			/* Action List */
			{
				props: true,
				name: 'home',
				path: '/actionList/:userId?',
				component: ActionList,
				meta: {
					policy: 'tenant',
					helpCategory: 'statuses',
					helpItem: '',
				},
			},

			/* Startup */
			{
				name: 'startup',
				path: '/startup',
				component: Startup,
				meta: { policy: 'tenant' },
			},

			/* Saved Products */
			{
				name: 'savedProductsOverview',
				path: '/products',
				component: SavedProductsOverview,
				meta: { policy: 'tenant' },
			},
			{
				name: 'savedProductDetails',
				path: '/products/:savedProductId',
				component: SavedProductDetails,
				meta: { policy: 'tenant' },
			},
		],
	},
	{
		name: 'homeRoot',
		path: '/home',
		component: Home,
		meta: { policy: 'tenant' },
		children: [
			{
				name: '404',
				path: '*',
				component: Error404,
				meta: { policy: 'tenant' },
			},
		],
	},
]
