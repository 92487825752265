<template>
	<div>
		<v-row class="project-header-row" no-gutters>
			<v-col>
				{{ $localize('ProjectNotes.title.customerNotes') }}
			</v-col>
		</v-row>
		<div class="relation-notes">
			<v-row
				v-for="(note, index) in relation.notes"
				:key="note.noteId"
				class="pre py-2"
				:class="{ first: index == 0 }"
				dense
			>
				<v-col>
					<v-btn
						v-if="$session.roles.includes('relations_manage')"
						icon
						small
						@click="
							deleteRelationNoteId = note.noteId
							deleteRelationNote = true
						"
						class="float-right mr-2"
					>
						<v-icon color="error">mdi-close</v-icon>
					</v-btn>
					<v-btn
						v-if="$session.roles.includes('relations_manage')"
						icon
						small
						@click="
							editRelationNoteId = note.noteId
							editRelationNote = true
						"
						class="float-right"
					>
						<v-icon>mdi-pencil</v-icon> </v-btn
					>{{ note.note }}
				</v-col>
			</v-row>
			<v-row dense v-if="$session.roles.includes('relations_manage')">
				<v-col class="text-center">
					<v-btn
						icon
						color="success"
						large
						@click="createRelationNote = true"
					>
						<v-icon>mdi-plus</v-icon>
					</v-btn>
				</v-col>
			</v-row>
		</div>
		<create-relation-note
			v-if="createRelationNote"
			v-model="createRelationNote"
			:relation-id="relationId"
			@saved="refresh"
		/>

		<delete-relation-note
			v-if="deleteRelationNote"
			v-model="deleteRelationNote"
			:relation-id="relationId"
			:note-id="deleteRelationNoteId"
			@saved="refresh"
		/>

		<edit-relation-note
			v-if="editRelationNote"
			v-model="editRelationNote"
			:relation-id="relationId"
			:note-id="editRelationNoteId"
			@saved="refresh"
		/>
	</div>
</template>

<script>
import { getRelationNotes } from '@/services/api'
import CreateRelationNote from '@/main/relations/dialogs/CreateRelationNote'
import EditRelationNote from '@/main/relations/dialogs/EditRelationNote'
import DeleteRelationNote from '@/main/relations/dialogs/DeleteRelationNote'

import { isGuid } from '@/services/validation'

export default {
	components: { CreateRelationNote, EditRelationNote, DeleteRelationNote },
	props: {
		relationId: {
			required: true,
			validator: isGuid,
		},
	},

	data() {
		return {
			relation: {
				notes: [],
			},
			createRelationNote: false,

			deleteRelationNoteId: null,
			deleteRelationNote: false,
			editRelationNoteId: null,
			editRelationNote: false,
		}
	},
	created() {
		this.refresh()
	},
	methods: {
		async refresh() {
			const { result, ok } = await getRelationNotes.call({
				args: { relationId: this.relationId },
			})

			if (ok) {
                this.relation.notes = result || []
			}
		},
	},
}
</script>

<style lang="scss" scoped>
.v-card {
	overflow-y: auto;
}

.relation-notes {
	.row:not(.first) {
		border-top: 1px solid #e0e0e0;
	}
}

.row {
	padding-left: 12px;
}
</style>
