import {getSession} from '@/services/api'
import {addMinutes, compareAsc} from 'date-fns'

const maxSessionAge = 5;

let sessionDate = null
let session = null

export async function reloadSession(){

  session = await getSession.create().load();
  sessionDate = new Date();
}

export async function currentSession(forceReload){
  if(!session || forceReload){
    await reloadSession();
  }
  else{
    const expirationDate = addMinutes(sessionDate, maxSessionAge);
    const comparison = compareAsc(expirationDate, new Date());
    if(comparison === -1){
      await reloadSession();
    }
  }
  return session;
}
export function clearSession(){
  session = null;
  sessionDate = null;
}
    
export function chooseLanguage(){
  if(session){
    if(session.settings.langauge){
      return session.settings.language;
    }
    else{
      return 'nl-NL';
    }
  }
  return 'en-US';
}