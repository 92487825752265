<template>
	<div>
		<v-toolbar flat height="36" class="my-3">
			<v-spacer></v-spacer>
			<v-toolbar-items>
				<v-btn class="primary" @click="createCalculation = true">
					{{
						$localize(
							'CalculationOverview.button.createCalculation'
						)
					}}
					<v-icon>mdi-plus</v-icon>
				</v-btn>
			</v-toolbar-items>
		</v-toolbar>

		<v-data-table
			:items="calculations"
			:headers="headers"
			@click:row="navCalculation($event.calculationId)"
			:loading="loading"
			disable-pagination
			hide-default-footer
			dense
		>
			<template v-slot:footer="{}">
				<v-divider />
				<v-row no-gutters class="mx-3">
					<v-col cols="auto">
						<v-checkbox
							:label="$localize('form.label.trash')"
							v-model="trash"
							@change="refresh"
							hide-details
							class="pt-0 my-1 btnTrash"
						/>
					</v-col>
				</v-row>
			</template>
		</v-data-table>

		<create-calculation
			v-if="createCalculation"
			v-model="createCalculation"
		/>
	</div>
</template>

<script>
import { getAllCalculations } from '@/services/api'
import CreateCalculation from './dialogs/CreateCalculation'

export default {
	components: { CreateCalculation },
	data() {
		return {
			createCalculation: false,
			headers: [
				{ text: this.$localize('Calculation.name'), value: 'name' },
				{
					text: this.$localize('Calculation.description'),
					value: 'description',
				},
			],
			calculations: [],
			trash: false,
		}
	},
	created() {
		this.refresh()
	},
	methods: {
		async refresh() {
			this.loading = true
			const { result, ok } = await getAllCalculations.call({
				q: { trash: this.trash },
			})

			if (ok) {
				this.calculations = result
			}

			this.loading = false
		},
		navCalculation(calculationId) {
			this.$router.push({
				name: 'calculationDetails',
				params: { calculationId: calculationId },
			})
		},
	},
}
</script>
