<template>
	<v-container class="pa-0">
		<v-row>
			<v-col>
				<v-toolbar flat class="my-3" height="36">
					<v-toolbar-title>E-Boekhouden</v-toolbar-title>
				</v-toolbar>
				<e-boekhouden-settings />
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import EBoekhoudenSettings from './dialogs/EBoekhoudenSettings'
export default {
	components: { EBoekhoudenSettings },

	methods: {
		save() {},
	},
}
</script>

<style></style>
