<template>
  <dialog-form
    id="formRemoveRelationGroup"
    :value="value"
    @input="$emit('input', $event)"
    @commit="save"
    :title="$localize('RemoveRelationGroup.title')"
    :allow-continue="false"
    :loading="false"
    action="delete"
  >
    <select-relation-group v-model="moveExistingTo" :exclude-group-id="groupId" autofocus :hint="$localize('RemoveRelationGroup.moveExistingTo')" />
  </dialog-form>
</template>

<script>
import DialogForm from "@/shared/components/DialogForm";
import SelectRelationGroup from '@/main/components/forms/SelectRelationGroup'

import sendCommand from "@/services/sendCommand";
import { isGuid } from "@/services/validation"

export default {
  components: { DialogForm, SelectRelationGroup },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    groupId: {
      validator: isGuid
    }
  },
  data() {
    return {
      moveExistingTo: null
    };
  },

  methods: {
    async save(callback) {
      const result = await sendCommand("RemoveRelationGroup", {
        groupId: this.groupId,
        moveExistingTo: this.moveExistingTo
      });
      if (result.success) {
        this.$emit("saved");
      }
      callback({ success: result.success });
    }
  }
};
</script>