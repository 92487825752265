<template>
    <div
        :class="{
            'v-event-draggable': event.data.isTimedEvent,
            'event-multiline': event.data.isTimedEvent,
            'event-singleline': !event.data.isTimedEvent,
            'pl-1': true,
        }"
    >
        <span class="event-title">
            <v-icon v-if="eventIcon" small class="white--text">{{
                eventIcon
            }}</v-icon>
            {{ event.name }}
        </span>
        <span
            v-for="user in $store.getters.getUsersByIds(event.data.users)"
            :key="user.userId"
        >
            <v-icon
                small
                :color="
                    colorMode === 'user' && event.data.users.length > 1
                        ? user.color
                        : '#fff'
                "
                >mdi-account-tie</v-icon
            >
            {{ user.name }}
        </span>
        <span
            v-for="label in $store.getters.getLabelsByIds(event.data.labels)"
            class="white--text"
            small
            :key="label.labelId"
        >
            <v-icon
                small
                :color="
                    colorMode === 'label' && event.data.labels.length > 1
                        ? label.color
                        : '#fff'
                "
                >mdi-label</v-icon
            >
            {{ label.name }}
        </span>
    </div>
</template>
<script>
import { getEventIcon } from '../calendarFunctions.js'
export default {
    props: {
        event: Object,
        colorMode: String,
    },
    computed: {
        eventIcon() {
            return getEventIcon(this.event)
        },
    },
}
</script>
<style scoped>
.event-multiline > span {
    display: block;
    max-width: 100%;
}
.event-multiline .event-title {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 100%;
    display: inline-block;
    margin-bottom:-5px;
}
.event-singleline{
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
</style>
