<template>
	<div>
		<v-toolbar flat height="36" class="my-3">
			<v-spacer></v-spacer>
			<v-toolbar-items>
				<v-btn class="primary" @click="createTrigger = true">
					{{ $localize('TriggerOverview.button.createTrigger') }}
					<v-icon>mdi-plus</v-icon>
				</v-btn>
			</v-toolbar-items>
		</v-toolbar>

		<v-data-table
			:items="triggers"
			:headers="headers"
			@click:row="navTrigger($event.triggerId)"
			:loading="loading"
			disable-pagination
			hide-default-footer
			dense
		>
			<template v-slot:[`item.isEnabled`]="{ item }">
				<v-icon v-if="item.isEnabled" class="success--text"
					>mdi-check</v-icon
				>
				<v-icon v-else class="error--text">mdi-close</v-icon>
			</template>
			<template v-slot:[`item.eventTypes`]="{ item }">
				<span
					v-for="(eventType, index) in item.eventTypes"
					:key="eventType"
				>
					<span v-if="index > 0">, </span>
					{{ eventType }}
				</span>
			</template>
			<template v-slot:footer="{}">
				<v-divider />
				<v-row no-gutters class="mx-3">
					<v-col cols="auto">
						<v-checkbox
							:label="$localize('form.label.trash')"
							v-model="trash"
							@change="refresh"
							hide-details
							class="pt-0 my-1 btnTrash"
						/>
					</v-col>
				</v-row>
			</template>
		</v-data-table>

		<create-trigger v-if="createTrigger" v-model="createTrigger" />
	</div>
</template>

<script>
import { getAllTriggers } from '@/services/api'
import CreateTrigger from './dialogs/CreateTrigger'

export default {
	components: { CreateTrigger },
	data() {
		return {
			createTrigger: false,
			headers: [
				{ text: this.$localize('Trigger.name'), value: 'name' },
				{ text: this.$localize('Trigger.events'), value: 'eventTypes' },
				{
					text: this.$localize('Trigger.isEnabled'),
					value: 'isEnabled',
				},
			],
			triggers: [],
			trash: false,
		}
	},
	created() {
		this.refresh()
	},
	methods: {
		async refresh() {
			this.loading = true
			const { result, ok } = await getAllTriggers.call()
			if (ok) {
				this.triggers = result
			}
			this.loading = false
		},
		navTrigger(triggerId) {
			this.$router.push({
				name: 'triggerDetails',
				params: { triggerId: triggerId },
			})
		},
	},
}
</script>
