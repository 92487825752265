<template>
  <dialog-form
    :value="value"
    @input="$emit('input', $event)"
    :title="$localize('DeletePart.title')"
    action="delete"
    @commit="save"
  >{{ $localize('DeletePart.description') }}</dialog-form>
</template>

<script>
import DialogForm from "@/shared/components/DialogForm";

import sendCommand from "@/services/sendCommand";
import { isGuid } from "@/services/validation"

export default {
  components: { DialogForm },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    savedProductId: {
      validator: isGuid
    },
    savedPartId: {
      validator: isGuid
    }
  },

  methods: {
    async save(callback) {
      const result = await sendCommand("DeleteSavedPart", {
        savedProductId: this.savedProductId,
        savedPartId: this.savedPartId
      });

      if (result.success) {
        this.$emit("saved");
      }

      callback({success: result.success})
    }
  }
};
</script>