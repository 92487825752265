
import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)
import { users } from './users.js'
import { labels } from './labels.js'

const store = new Vuex.Store({
	modules: {
		users: users,
		labels: labels,
	},
})
export default store
