<template>
	<dialog-form
		id="formEnterContactInformation"
		:value="value"
		@input="$emit('input', $event)"
		@commit="save"
		:title="$localize('EnterContactInformation.title')"
		:allow-continue="false"
		:loading="loading"
	>
		<v-text-field
			:label="$localize('Contact.name')"
			v-model="enterContactInformation.name"
			:rules="[validation.required, validation.maxLength(100)]"
			prepend-icon="mdi-account"
			class="inputName"
		/>

		<select-salutation v-model="enterContactInformation.salutation" />

		<date-select
			:label="$localize('Contact.bornDate')"
			v-model="enterContactInformation.bornDate"
			clearable
		/>

		<v-text-field
			:label="$localize('Contact.mobileNumber')"
			v-model="enterContactInformation.mobileNumber"
			:rules="[validation.maxLength(50)]"
			prepend-icon="mdi-cellphone"
			class="inputMobileNumber"
		/>
		<v-text-field
			:label="$localize('Contact.alternativeNumber')"
			v-model="enterContactInformation.alternativeNumber"
			:rules="[validation.maxLength(50)]"
			prepend-icon="mdi-phone-forward"
			class="inputAlternativeNumber"
		/>
		<input-email
			v-model="enterContactInformation.email"
			class="inputEmail"
		/>

		<v-text-field
			:label="$localize('Contact.website')"
			v-model="enterContactInformation.website"
			:rules="[validation.maxLength(50)]"
			prepend-icon="mdi-web"
			class="inputWebsite"
		/>
		<v-text-field
			:label="$localize('Contact.position')"
			v-model="enterContactInformation.position"
			:rules="[validation.maxLength(50)]"
			prepend-icon="mdi-account-question"
			class="inputPosition"
		/>
		<v-textarea
			:label="$localize('Contact.note')"
			v-model="enterContactInformation.note"
			:rules="[validation.maxLength(2000)]"
			prepend-icon="mdi-note"
			class="inputNote"
		/>
	</dialog-form>
</template>

<script>
import DialogForm from '@/shared/components/DialogForm'
import SelectSalutation from '@/main/components/forms/SelectSalutation'

import { isGuid } from '@/services/validation'
import { getContactById } from '@/services/api'
import sendCommand from '@/services/sendCommand'
import DateSelect from '@/shared/components/DateSelect'
import { required, email, maxLength } from '@/services/validation'

export default {
	components: { DateSelect, DialogForm, SelectSalutation },
	props: {
		contactId: {
			required: true,
			validator: isGuid,
		},
		value: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			loading: false,
			enterContactInformation: {},
			validation: { required, email, maxLength },
		}
	},
	async created() {
		this.loading = true
		const { result, ok } = await getContactById.call({
			args: { contactId: this.contactId },
		})
		if (ok) {
			const contact = result
			this.contact = contact
            this.enterContactInformation = {
                contactId: this.contactId,
                name: contact.name,
                salutation: contact.salutation,
                bornDate: contact.bornDate,
                mobileNumber: contact.mobileNumber,
                alternativeNumber: contact.alternativeNumber,
                email: contact.email,
                website: contact.website,
                position: contact.position,
                note: contact.note,
            }
		}

		this.loading = false
	},

	methods: {
		async save(callback) {
			const result = await sendCommand(
				'EnterContactInformation',
				this.enterContactInformation
			)

			if (result.success) {
				this.$emit('saved')
			}
			callback({ success: result.success })
		},
	},
}
</script>
