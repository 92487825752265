<template>
	<dialog-form
		:value="value"
		@input="$emit('input', $event)"
		:title="$localize('CopyProject.title')"
		:allow-continue="false"
		@commit="save"
		:loading="loading"
	>
		<div v-for="product in products" :key="product.productId">
			<v-radio-group
				v-model="product.action"
				:mandatory="true"
				hide-details
			>
				<v-row>
					<v-col>{{ product.name }}</v-col>
					<v-col cols="auto">
						<v-radio value="none" label="Overslaan" hide-details />
					</v-col>
					<v-col cols="auto">
						<v-radio value="cut" label="Knippen" hide-details />
					</v-col>
					<v-col cols="auto">
						<v-radio value="copy" label="Kopiëren" hide-details />
					</v-col>
				</v-row>
			</v-radio-group>
		</div>
	</dialog-form>
</template>

<script>
import Vue from 'vue'
import { isGuid } from '@/services/validation'
import sendCommand from '@/services/sendCommand'
import DialogForm from '@/shared/components/DialogForm'
import { getProjectProducts } from '@/services/api'
export default {
	components: { DialogForm },
	props: {
		value: {
			type: Boolean,
			default: false,
		},
		projectId: {
			validator: isGuid,
			required: true,
		},
		productId: {
			validator: isGuid,
		},
	},
	data() {
		return {
			loading: false,
			products: [],
		}
	},
	async created() {
		this.loading = true
		const { result, ok } = await getProjectProducts.call({
			args: { projectId: this.projectId },
		})

		if (ok) {
			this.products = result.map((x) => {
				return {
					productId: x.productId,
					action: 'none',
					name: x.product,
				}
			})
		}
		if (this.productId) {
			const product = this.products.find(
				(x) => x.productId === this.productId
			)
			if (product) {
				product.action = 'copy'
			}
		}
		this.loading = false
	},
	methods: {
		async save(callback, close) {
			const productsToCut = this.products
				.filter((x) => x.action === 'cut')
				.map((x) => x.productId)
			const productsToCopy = this.products
				.filter((x) => x.action === 'copy')
				.map((x) => x.productId)

			const result = await sendCommand('CopyProject', {
				projectId: this.projectId,
				productsToCut: productsToCut,
				productsToCopy: productsToCopy,
			})

			if (result.success) {
				this.$emit('saved')
				if (close) {
					Vue.nextTick(() => {
						Vue.nextTick(() => {
							this.$router.push({
								name: 'projectDetails',
								params: { projectId: result.generatedId },
							})
						})
					})
				}
			}

			callback({ success: result.success })
		},
	},
}
</script>
