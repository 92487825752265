<template>
  <div>
    <div class="panel-left">
      <div>
        <div><em>Subscriptions</em></div>
        <div v-for="(sub, messageType) in ws.s" :key="messageType">
          {{ messageType }} = {{ sub.length }}
        </div>
        <div><em>Session</em></div>
        <div>UserId = {{ $session.userId }}</div>
        <div>Login = {{ $session.login }}</div>
        <div>Email = {{ $session.email }}</div>
      </div>
    </div>
    <div class="panel-bottom">
      <table>
        <tr v-for="(m, index) in incomingMessageLog" :key="index">
          <!--<td>{{ $format.dateTime(new Date()) }}</td>-->
          <td>{{ m.messageType }}</td>
          <td>
            <div v-for="(value) in Object.keys(m.message)" :key="value">
              <div v-if="value !== 'info'">
                {{value}} = {{m.message[value]}}
              </div>
            </div>
            </td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import { getInfo, onGlobalIncoming } from "@/services/websocketHandler";

export default {
  data() {
    return {
      interval: null,
      ws: {},
      incomingMessageLog: [],
      clearGlobalIncoming: null,
    };
  },

  created() {
    this.interval = setInterval(this.refresh, 500);
    this.clearGlobalIncoming = onGlobalIncoming(this.globalIncoming);
  },
  destroyed() {
    clearInterval(this.interval);
    if (this.clearGlobalIncoming) {
      this.clearGlobalIncoming();
    }
  },
  methods: {
    refresh() {
      this.ws = getInfo();
    },
    globalIncoming(message, messageType) {
      this.incomingMessageLog.push({ message, messageType });
    },
  },
};
</script>
<style lang="scss" scoped>
.panel-left {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 400px;
  z-index: 1000;
  background-color: rgba($color: white, $alpha: 0.5);
  color: black;
  font-size: 16px;
  pointer-events: none;
}
.panel-bottom {
  position: absolute;
  left: 400px;
  right: 0;
  bottom: 0;
  max-height: 400px;
  z-index: 1000;
  background-color: rgba($color: white, $alpha: 0.5);
  color: black;
  font-size: 16px;
  pointer-events: none;
}
</style>