<template>
  <div class="message">
    <h1>
      <message-icon :message-category="$props.message.category" />
      {{ $props.message.type }}
    </h1>
    <v-btn @click="showMessage = !showMessage">Show Message</v-btn>
    <div v-if="!showMessage">
      <div class="detail-label">Created</div>
      <div class="detail-value">{{ $props.message.created }}</div>
      <div class="detail-label">messageId</div>
      <div class="detail-value">{{ $props.message.messageId }}</div>
      <div class="detail-label">causationId</div>
      <div class="detail-value">{{ $props.message.causationId }}</div>
      <div class="detail-label">correlationId</div>
      <div class="detail-value">{{ $props.message.correlationId }}</div>

      <div v-for="(value, key) in $props.message.metadata" :key="key">
        <div class="detail-label">{{ key }}</div>
        <div class="detail-value">{{ value }}</div>
      </div>
    </div>
    <div v-if="showMessage">
      <pre>{{ $props.message.message }}</pre>
    </div>
  </div>
</template>

<script>
import MessageIcon from "./MessageIcon";

export default {
  components: { MessageIcon },
  props: {
    message: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      showMessage: false
    };
  }
};
</script>

