<template>
  <dialog-form
    :value="value"
    @input="$emit('input', $event)"
    :title="$localize('DeleteProduct.title')"
    action="delete"
    @commit="save"
  >{{ $localize('DeleteProduct.description') }}</dialog-form>
</template>

<script>
import DialogForm from "@/shared/components/DialogForm";

import sendCommand from "@/services/sendCommand";
import { isGuid } from "@/services/validation"

export default {
  components: { DialogForm },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    projectId: {
      validator: isGuid,
      required: true
    },
    productId: {
      validator: isGuid
    }
  },

  methods: {
    async save(callback) {
      const result = await sendCommand("DeleteProduct", {
        projectId: this.projectId,
        productId: this.productId
      });
      if (result.success) {
        this.$emit("saved");
      } 

      callback({success: result.success})
    }
  }
};
</script>