<template>
	<div>
		<v-btn color="primary" @click="newImport()">{{
			$localize('RelationImport.newImport')
		}}</v-btn>

		<v-data-table
			:items="relationImports"
			:headers="relationImportsHeaders"
			@click:row="navRelationImport"
		>
			<template v-slot:[`item.isConfirmed`]="{ item }">
				<v-icon v-if="!item.isConfirmed">mdi-pencil</v-icon>
				<v-icon v-else-if="item.processedRows == item.totalDataRows"
					>mdi-check</v-icon
				>
				<v-icon v-else>mdi-sync</v-icon>
			</template>
			<template v-slot:[`item.created`]="{ item }">{{
				$format.shortDateTime(item.created)
			}}</template>
			<template v-slot:[`item.failedRows`]="{ item }">{{
				item.failedRows ? item.failedRows.length : 0
			}}</template>
			<template v-slot:[`item.actions`]="{ item }">
				<v-btn
					small
					color="error"
					@click.native.stop="
						deleteRelationImportId = item.relationImportId
						deleteRelationImport = true
					"
				>
					<v-icon>mdi-close</v-icon>
				</v-btn>
			</template>
		</v-data-table>
		<delete-relation-import
			v-if="deleteRelationImport"
			v-model="deleteRelationImport"
			:relation-import-id="deleteRelationImportId"
			@saved="refresh()"
		/>
	</div>
</template>

<script>
import DeleteRelationImport from '../dialogs/DeleteRelationImport'
import { getRelationImports } from '@/services/api'

export default {
	components: { DeleteRelationImport },
	data() {
		return {
			deleteRelationImport: false,
			deleteRelationImportId: null,

			relationImports: [],
			relationImportsHeaders: [
				{
					value: 'isConfirmed',
					width: 48,
				},
				{
					text: this.$localize('RelationImport.originalFileName'),
					value: 'originalFileName',
				},
				{
					text: this.$localize('RelationImport.created'),
					value: 'created',
				},
				{
					text: this.$localize('RelationImport.totalRows'),
					value: 'totalDataRows',
				},
				{
					text: this.$localize('RelationImport.rowsProcessed'),
					value: 'processedRows',
				},
				{
					text: this.$localize('RelationImport.rowsFailed'),
					value: 'failedRows',
				},
				{
					text: this.$localize('RelationImport.actions'),
					value: 'actions',
				},
			],
		}
	},
	created() {
		this.refresh()
	},
	methods: {
		async refresh() {
			const { result, ok } = await getRelationImports.call()

			if (ok) {
				this.relationImports = result
			}
		},
		newImport() {
			this.$emit('continue')
		},
		navRelationImport(relationImport) {
			this.$router.push({
				name: 'relationImport',
				params: { relationImportId: relationImport.relationImportId },
			})
		},
	},
}
</script>
