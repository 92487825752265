var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[(_vm.isDashboard)?_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('v-toolbar',{staticClass:"primary",attrs:{"flat":""}},[_c('v-toolbar-title',{staticClass:"text-uppercase mr-3"},[_c('span',{staticClass:"font-weight-light white--text text-h3"},[_vm._v(_vm._s(_vm.dashboardName))])])],1)],1)],1):_vm._e(),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.projects,"item-class":"text-no-wrap text-truncate","loading":_vm.isLoading,"options":_vm.options,"server-items-length":_vm.totalItemCount,"footer-props":{
					'items-per-page-options': [20, 30, 40, 50],
				},"dense":""},on:{"click:row":function($event){return _vm.navProject($event)},"update:options":function($event){_vm.options=$event},"contextmenu:row":_vm.openContextMenu},scopedSlots:_vm._u([{key:`item.created`,fn:function({ item }){return [_vm._v(_vm._s(_vm.$format.shortDate(item.created)))]}},{key:`item.progress.created`,fn:function({ item }){return [_vm._v(_vm._s(_vm.$format.shortDate(item.progress.created)))]}},{key:`item.productionDate`,fn:function({ item }){return [(item.productionDate)?_c('span',{class:_vm.getProjectColorClasses(item)},[_vm._v(_vm._s(_vm.$format.shortDate(item.productionDate)))]):_vm._e()]}},{key:`item.deadline`,fn:function({ item }){return [(item.deadline)?_c('span',{class:_vm.getProjectColorClasses(item)},[_vm._v(_vm._s(_vm.$format.shortDate(item.deadline)))]):_vm._e()]}},{key:`item.relationName`,fn:function({ item }){return [_c('relation-link',{attrs:{"relation-id":item.relationId,"text":item.relationName}})]}},{key:`item.userName`,fn:function({ item }){return [_c('router-link',{attrs:{"to":{
							name: 'userDetails',
							params: { userId: item.userId },
						}},nativeOn:{"click":function($event){$event.stopPropagation();}}},[_vm._v(_vm._s(item.userName))])]}},{key:"footer",fn:function({}){return [_c('v-divider'),_c('v-row',{staticClass:"mx-3",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-checkbox',{staticClass:"pt-0 my-1",attrs:{"label":_vm.$localize('form.label.autoRefresh'),"hide-details":""},on:{"change":function($event){return _vm.setLive()}},model:{value:(_vm.live),callback:function ($$v) {_vm.live=$$v},expression:"live"}})],1),_c('v-col'),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-menu',{attrs:{"close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({attrs:{"fab":"","x-small":""}},on),[_c('v-icon',[_vm._v("mdi-cog")])],1)]}}],null,true)},[_c('v-list',_vm._l((_vm.allHeaders),function(header){return _c('v-list-item',{key:header.text,on:{"click":function($event){return _vm.toggleHeader(header)}}},[_c('v-list-item-action',[_c('v-checkbox',{attrs:{"input-value":_vm.activeHeaders[
														header.value
													]}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(header.text))])],1)],1)}),1)],1)],1)],1)]}}],null,true)})],1)],1),(_vm.contextMenuItem)?_c('v-menu',{attrs:{"absolute":"","position-x":_vm.contextMenuX,"position-y":_vm.contextMenuY,"transition":false},model:{value:(_vm.showContextMenu),callback:function ($$v) {_vm.showContextMenu=$$v},expression:"showContextMenu"}},[_c('v-list',[_vm._l((_vm.contextMenu),function(item){return [(typeof item.if !== 'function' || item.if())?_c('v-list-item',{key:item.value,on:{"click":function($event){return item.action()}}},[_c('v-list-item-icon',[(typeof item.icon === 'function')?_c('v-icon',{attrs:{"color":item.iconColor}},[_vm._v(_vm._s(item.icon()))]):_c('v-icon',{attrs:{"color":item.iconColor}},[_vm._v(_vm._s(item.icon))])],1),_c('v-list-item-title',[_vm._v(_vm._s(item.text))])],1):_vm._e()]})],2)],1):_vm._e(),(_vm.enterProjectInformation)?_c('enter-project-information',{attrs:{"project-id":_vm.contextMenuItem.projectId},on:{"saved":function($event){return _vm.refresh(true)}},model:{value:(_vm.enterProjectInformation),callback:function ($$v) {_vm.enterProjectInformation=$$v},expression:"enterProjectInformation"}}):_vm._e(),(_vm.exportPdfs)?_c('pdf-exports',{attrs:{"project-id":_vm.contextMenuItem.projectId},model:{value:(_vm.exportPdfs),callback:function ($$v) {_vm.exportPdfs=$$v},expression:"exportPdfs"}}):_vm._e(),(_vm.changeStatus)?_c('change-status-wizard',{attrs:{"project-id":_vm.contextMenuItem.projectId},on:{"saved":function($event){return _vm.refresh(true)}},model:{value:(_vm.changeStatus),callback:function ($$v) {_vm.changeStatus=$$v},expression:"changeStatus"}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }