<template>
  <dialog-form
    id="formRenameRelationGroup"
    :value="value"
    @input="$emit('input', $event)"
    @commit="save"
    :title="$localize('RenameRelationGroup.title')"
    :allow-continue="false"
  >
    <v-text-field
      :label="$localize('RelationGroup.name')"
      v-model="newName"
      class="inputName"
      :rules="[validation.required, validation.maxLength(20)]"
    />
  </dialog-form>
</template>

<script>
import DialogForm from "@/shared/components/DialogForm";
import sendCommand from "@/services/sendCommand";
import { isGuid } from "@/services/validation"
import { required, maxLength } from "@/services/validation";

export default {
  components: { DialogForm },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    groupId: {
      validator: isGuid,
    },
    oldName: {
      type: String,
    },
  },
  data() {
    return {
      newName: this.oldName,
      validation: { required, maxLength },
    };
  },
  watch: {
    oldName() {
      this.newName = this.oldName;
    },
  },
  methods: {
    async save(callback) {
      const result = await sendCommand("RenameRelationGroup", {
        groupId: this.groupId,
        newName: this.newName,
      });
      if (result.success) {
        this.$emit("saved");
      }
      callback({ success: result.success });
    },
  },
};
</script>