import PubSub from 'pubsub-js'

let id = 1

export function showSnackbar(options) {
	options.color = options.color || 'success'
	options.show = true
	if (typeof options.timeout !== 'number') {
		options.timeout = 4000
	}
	options.id = id++
	options.icon =
		options.icon || options.color === 'success' ? 'mdi-check' : null
	options.loader = options.loader || false

	PubSub.publish('showSnackbar', options)
}

export function showError(options) {
	options.color = options.color || 'success'
	options.show = true
	if (typeof options.timeout !== 'number') {
		options.timeout = 4000
	}
	options.id = id++
	options.icon =
		options.icon || options.color === 'success' ? 'mdi-check' : null
	options.loader = options.loader || false

	PubSub.publish('showError', options)
}

export function clearSnackbar() {
	PubSub.publish('clearSnackbar')
}
