import AdminHome from './pages/AdminHome.vue'
import TenantsOverview from './pages/TenantsOverview.vue'
import TenantDetails from './pages/TenantDetails.vue'
import UserDetails from './pages/UserDetails.vue'
import ExceptionLog from './pages/ExceptionLog.vue'
import CommandLog from './pages/CommandLog.vue'
import MessageLog from './pages/MessageLog.vue'
import MessageView from './pages/MessageView.vue'
import TranslationOverview from './pages/TranslationOverview.vue'
import AdminsOverview from './pages/AdminsOverview.vue'
import OperationsOverview from './pages/OperationsOverview.vue'
export default [
    {
        name: 'adminHome',
        path: '/admin',
        component: AdminHome,
        meta: {policy: 'admin'},

        children : [
            {
                name: 'tenantsOverview',
                path: '/admin/tenants',
                component: TenantsOverview,
                meta: {policy: 'admin'}
            },
            {
                name: 'tenantDetails',
                path: '/admin/tenants/:tenantId',
                component: TenantDetails,
                meta: {policy: 'admin'}
            },
            {
                name: 'admin.userDetails',
                path: '/admin/users/:userId',
                component: UserDetails,
                meta: {policy: 'admin'}
            },
            {
                name: 'exceptionLog',
                path: '/admin/ops/exceptions',
                component: ExceptionLog,
                meta: {policy: 'admin'}
            },
            {
                name: 'commandLog',
                path: '/admin/ops/commands',
                component: CommandLog,
                meta: {policy: 'admin'}
            },       
            {
                name: 'messageLog',
                path: '/admin/ops/logs/messages',
                component: MessageLog,
                meta: {policy: 'admin'}
            },
            {
                name: 'message',
                path: '/admin/ops/message/:messageId',
                component: MessageView,
                meta: {policy: 'admin'}
            },
            {
                name: 'language',
                path: '/admin/language',
                component: TranslationOverview,
                meta: {policy: 'admin'}
            },
            {
                name: 'admins',
                path: '/admin/admins',
                component: AdminsOverview,
                meta: {policy: 'admin'}
            },
            {
                name: 'operations',
                path: '/admin/operations',
                component: OperationsOverview,
                meta: {policy: 'admin'}
            }
       ]

    },



]