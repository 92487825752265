<script>
export default {
  props: {
    messageCategory: {
      type: String,
      required: true
    }
  },
  render: function(createElement) {
    var faIcon;
    switch (this.$props.messageCategory) {
      case "command":
        faIcon = "terminal";
        break;
      case "event":
        faIcon = "bolt";
        break;
      case "exception":
        faIcon = "exclamation-triangle";
        break;
      case "request":
        faIcon = "globe";
        break;
      default:
        faIcon = "question-circle";
        break;
    }

    return createElement("i", {
      class: ["fas", "fa-" + faIcon, "message-category"],
      attrs: {
        title: this.$props.messageCategory
      }
    });
  }
};
</script>
