<template>
	<dialog-form
		id="formCreateRelation"
		:value="value"
		@input="$emit('input', $event)"
		@commit="save"
		:commit-message="$localize('CreateRelation.message.saved')"
		:title="$localize('CreateRelation.title')"
		:allow-continue="allowContinue"
		@reset="reset"
	>
		<v-text-field
			class="inputCompanyName"
			ref="companyName"
			:label="$localize('Relation.companyName')"
			v-model="createRelationCommand.companyName"
			:rules="[validation.required, validation.maxLength(50)]"
			prepend-icon="mdi-office-building"
            autofocus
		/>
		<v-text-field
			:label="$localize('Relation.phone')"
			v-model="createRelationCommand.phoneNumber"
			:rules="[validation.maxLength(50)]"
			prepend-icon="mdi-phone"
			class="inputPhone"
		/>
		<input-email v-model="createRelationCommand.email" class="inputEmail" />
		<input-email
			v-model="createRelationCommand.invoiceEmail"
			:label="$localize('Relation.invoiceEmail')"
			class="inputInvoiceEmail"
		/>

		<v-text-field
			class="inputWebsite"
			:label="$localize('Relation.website')"
			v-model="createRelationCommand.website"
			:rules="[validation.maxLength(50)]"
			prepend-icon="mdi-web"
		/>
		<v-text-field
			:label="$localize('Relation.fax')"
			v-model="createRelationCommand.faxNumber"
			:rules="[validation.maxLength(50)]"
			prepend-icon="mdi-fax"
			class="inputFax"
		/>
		<v-text-field
			:label="$localize('Relation.tax')"
			v-model="createRelationCommand.taxNumber"
			:rules="[validation.maxLength(50)]"
			prepend-icon="mdi-bank"
			class="inputTax"
		/>

		<select-relation-group
			:label="$localize('Relation.group')"
			v-model="createRelationCommand.groupId"
		/>

		<v-layout row class="mx-3">
			<v-flex xs12 sm6>
				<v-switch
					:label="$localize('Relation.isCustomer')"
					v-model="createRelationCommand.isCustomer"
					:rules="[validation.customerOrSupplier]"
					class="switchIsCustomer"
				/>
			</v-flex>
			<v-flex xs12 sm6>
				<v-switch
					:label="$localize('Relation.isSupplier')"
					v-model="createRelationCommand.isSupplier"
					:rules="[validation.customerOrSupplier]"
					class="switchIsSupplier"
				/>
			</v-flex>
		</v-layout>
	</dialog-form>
</template>

<script>
import DialogForm from '@/shared/components/DialogForm'
import SelectRelationGroup from '@/main/components/forms/SelectRelationGroup'

import sendCommand from '@/services/sendCommand'
import { required, email, maxLength } from '@/services/validation'
import Vue from 'vue'

export default {
	components: { DialogForm, SelectRelationGroup },
	props: {
		value: {
			type: Boolean,
			default: false,
		},
		allowContinue: {
			type: Boolean,
			default: true,
		},
		customer: {
			type: Boolean,
			default: true,
		},
		supplier: {
			type: Boolean,
			default: false,
		},
		closeOnSave: {
			type: Boolean,
			default: true,
		},
		companyName: {
            type: String,
            default: null
        },
	},
	data() {
		return {
			createRelationCommand: {
				isCustomer: this.customer,
				isSupplier: this.supplier,
				groupId: this.$settings.defaultRelationGroup,
                companyName: this.companyName
			},
			validation: {
				required,
				email,
				customerOrSupplier: () => {
					if (
						!this.createRelationCommand.isCustomer &&
						!this.createRelationCommand.isSupplier
					) {
						return 'Relation must be a customer or a supplier'
					}
					return true
				},
				maxLength,
			},
		}
	},

	methods: {
		reset() {
			this.createRelationCommand = {
				isCustomer: this.customer,
				isSupplier: this.supplier,
				groupId: this.$settings.defaultRelationGroup,
			}
			this.$refs.companyName.focus()
		},
		async save(callback, close) {
			const result = await sendCommand(
				'CreateRelation',
				this.createRelationCommand
			)
			if (result.success) {
				this.$emit('saved', result)
				if (close && this.closeOnSave) {
					Vue.nextTick(() => {
						this.$router.push({
							name: 'relationDetails',
							params: { relationId: result.generatedId },
						})
					})
				}
			}

			callback({ success: result.success })
		},
	},
}
</script>
