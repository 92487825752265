<template>
	<v-card>
		<v-card-title
			>{{ $localize('RelationImportLink.title') }}:
			{{ relationImport.originalFileName }}</v-card-title
		>
		<v-card-text v-if="relationImport">
			<v-form ref="linkingForm">
				<v-row class="linkingOptions">
					<v-col>
						<v-select
							v-model="contactCount"
							:items="[1, 2, 3, 4, 5]"
							label="Aantal contact personen"
							outlined
							hide-details
							dense
							@change="loadLinks"
						>
						</v-select>
					</v-col>
					<v-col>
						<v-select
							v-model="addressCount"
							:items="[1, 2, 3, 4, 5]"
							label="Aantal adressen"
							outlined
							hide-details
							dense
							@change="loadLinks"
						>
						</v-select>
					</v-col>
				</v-row>
				<div class="import-wrapper">
					<table class="import-table">
						<thead>
							<tr>
								<th
									v-for="index in columnCount"
									:key="index - 1"
								>
									<v-autocomplete
										solo
										:items="links"
										item-value="id"
										item-text="text"
										v-model="
											relationImport.links[index - 1]
										"
										:rules="[
											validation.nonDuplicate,
											validation.required,
										]"
										clearable
										@change="saveLinks()"
									/>
								</th>
							</tr>
							<tr
								class="preview-header"
								v-if="relationImport.hasHeader"
							>
								<th
									v-for="(column, columnIndex) in data[0]"
									:key="columnIndex"
									:class="{
										excluded:
											relationImport.links[
												columnIndex
											] === '-1',
									}"
								>
									{{ column }}
								</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="(row, index) in data" :key="index">
								<template
									v-if="
										index > 0 || !relationImport.hasHeader
									"
								>
									<td
										v-for="(column, columnIndex) in row"
										:key="index + '-' + columnIndex"
										:class="{
											excluded:
												relationImport.links[
													columnIndex
												] === '-1',
										}"
									>
										<span :title="row[column]">{{
											column
										}}</span>
									</td>
								</template>
							</tr>
						</tbody>
					</table>
				</div>
			</v-form>
		</v-card-text>
		<v-card-actions>
			<v-btn @click="$emit('back')">{{
				$localize('form.button.back')
			}}</v-btn>
			<v-btn class="success" @click="confirm">{{
				$localize('form.button.confirm')
			}}</v-btn>
		</v-card-actions>
	</v-card>
</template>

<script>
import {
	getRelationImport,
	getRelationImportData,
	getRelationImportLinks,
} from '@/services/api'
import sendCommand from '@/services/sendCommand'
import { isGuid } from '@/services/validation'
import { required } from '@/services/validation'

export default {
	props: {
		relationImportId: {
			validator: isGuid,
			required: true,
		},
	},

	data() {
		return {
			relationImport: {},
			data: [],
			links: [],
			contactCount: 1,
			addressCount: 1,

			validation: {
				required,
				nonDuplicate: (link) => {
					if (link === '-1' || !link) {
						return true
					}
					if (this.relationImport.links) {
						const linkCount = this.relationImport.links.filter(
							(x) => x === link
						).length
						if (linkCount > 1) {
							return 'Dubbele koppeling'
						}
					}
					return true
				},
			},
		}
	},
	computed: {
		columnCount() {
			if (this.data.length > 0) {
				return this.data[0].length
			}
			return 0
		},
		dataStartIndex() {
			if (this.relationImport.hasHeader) {
				return 1
			}
			return 0
		},
	},
	async created() {
		this.step = this.relationImportId ? 3 : 1
		if (this.relationImportId) {
			const { result, ok } = await getRelationImport.call({
				args: { relationImportId: this.relationImportId },
			})
			if (ok) {
				this.relationImport = result
				await this.loadData()
				await this.loadLinks()
			}
		}
	},
	methods: {
		async loadLinks() {
			const { result, ok } = await getRelationImportLinks.call({
				args: {
					relationImportId: this.relationImportId,
				},
				q: {
					contactCount: this.contactCount,
					addressCount: this.addressCount,
				},
			})
			if (ok) {
				this.links = result
				this.links.splice(0, 0, {
					id: '-1',
					text: 'Niet importeren',
				})
			}
		},
		async loadData() {
			const { result, ok } = await getRelationImportData.call({
				args: { relationImportId: this.relationImportId },
			})
			if (ok) {
				this.data = result
			}
		},
		async saveLinks() {
			await sendCommand('ChangeRelationImportLinks', {
				relationImportId: this.relationImportId,
				links: this.relationImport.links,
			})
		},
		async confirm() {
			if (this.$refs.linkingForm.validate()) {
				const result = await sendCommand('ConfirmRelationImport', {
					relationImportId: this.relationImportId,
				})
				if (result.success) {
					this.$emit('continue')
				}
			}
		},
	},
}
</script>
<style lang="scss">
.import-wrapper {
	overflow-x: scroll;
}

.import-table {
	border-collapse: collapse;

	th,
	td {
		padding: 2px 4px;
		border-width: 1px 0;
		border-style: solid;
		border-color: #bdbdbd;
	}

	td {
		max-width: 150px;
		white-space: nowrap;
		overflow: hidden;
	}

	thead th {
		min-width: 150px;
		font-weight: normal;
		text-align: left;
	}

	tr td:nth-child(even),
	tr th:nth-child(even) {
		background-color: #eeeeee;
	}

	tr.preview-header th {
		font-weight: 600;
	}
}

.excluded {
	text-decoration: line-through;
}

.linkingOptions {
	margin-bottom: 8px;

	.col {
		max-width: 200px;
	}
}
</style>
