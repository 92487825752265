<template>
	<div>
		<v-toolbar flat class="my-3" height="36">
			<v-toolbar-title>{{
				$localize('ProjectStatusOverview.title')
			}}</v-toolbar-title>

			<v-spacer></v-spacer>
			<v-toolbar-items>
				<v-btn class="primary" @click="createProjectStatus = true">
					<v-icon class="mr-1">mdi-plus</v-icon>
					{{
						$localize('ProjectStatusOverview.button.create')
					}}</v-btn
				>
			</v-toolbar-items>
		</v-toolbar>
		<v-data-table
			item-key="projectStatusId"
			:items="statuses"
			:headers="headers"
			:loading="loading"
			:options.sync="options"
			@update:options="onUpdate()"
			:footer-props="{ 'items-per-page-options': [10, 20, 50, -1] }"
			dense
		>
			<template v-slot:[`item.isEnabled`]="{ item }">
				<span v-show="item.isEnabled">
					<v-icon class="success--text">mdi-check</v-icon>
				</span>
				<span v-show="!item.isEnabled">
					<v-icon class="error--text">mdi-close</v-icon>
				</span>
			</template>
			<template v-slot:[`item.request`]="{ item }">
				<span v-show="item.request">
					<v-icon class="success--text">mdi-check</v-icon>
				</span>
			</template>
			<template v-slot:[`item.offer`]="{ item }">
				<span v-show="item.offer">
					<v-icon class="success--text">mdi-check</v-icon>
				</span>
			</template>
			<template v-slot:[`item.order`]="{ item }">
				<span v-show="item.order">
					<v-icon class="success--text">mdi-check</v-icon>
				</span>
			</template>
			<template v-slot:[`item.invoice`]="{ item }">
				<span v-show="item.invoice">
					<v-icon class="success--text">mdi-check</v-icon>
				</span>
			</template>
			<template v-slot:[`item.archive`]="{ item }">
				<span v-show="item.archive">
					<v-icon class="success--text">mdi-check</v-icon>
				</span>
			</template>
			<template v-slot:[`item.showInActionList`]="{ item }">
				<span v-show="item.showInActionList">
					<v-icon class="success--text">mdi-check</v-icon>
					<span v-if="item.actionListRecallDays !== null">{{
						$localize('ProjectStatusOverview.afterXDays', {
							days: item.actionListRecallDays,
						})
					}}</span>
				</span>
				<span v-show="!item.showInActionList">
					<v-icon class="error--text">mdi-close</v-icon>
				</span>
			</template>
			<template v-slot:[`item.sendEmailNotification`]="{ item }">
				<span v-show="item.sendEmailNotification">
					<v-icon class="success--text">mdi-check</v-icon>
				</span>
				<span v-show="!item.sendEmailNotification">
					<v-icon class="error--text">mdi-close</v-icon>
				</span>
			</template>
			<template v-slot:[`item.action`]="{ item }">
				<table-toolbar>
					<v-btn
						v-if="item.isEnabled"
						small
						depressed
						class="mr-2"
						@click="disable(item)"
						>{{ $localize('form.button.disable') }}</v-btn
					>
					<v-btn
						v-else
						small
						depressed
						class="mr-2"
						@click="enable(item)"
						>{{ $localize('form.button.enable') }}</v-btn
					>
					<v-btn
						small
						depressed
						class="mr-2"
						@click="
							renameProjectStatusId = item.projectStatusId
							renameProjectStatus = true
						"
					>
						<v-icon dense>mdi-pencil</v-icon>
					</v-btn>

					<v-btn
						small
						depressed
						@click="
							deleteProjectStatusId = item.projectStatusId
							deleteProjectStatus = true
						"
					>
						<v-icon dense>mdi-close</v-icon>
					</v-btn>
				</table-toolbar>
			</template>
			<template v-slot:footer="{}">
				<v-divider />
				<v-row no-gutters class="mx-3">
					<v-col cols="auto">
						<v-checkbox
							:label="
								$localize(
									'ProjectStatusOverview.label.disabledStatuses'
								)
							"
							v-model="showDisabledStatuses"
							class="pt-0 my-1"
							hide-details
						/>
					</v-col>
				</v-row>
			</template>
		</v-data-table>

		<create-project-status
			v-if="createProjectStatus"
			v-model="createProjectStatus"
			@saved="refresh()"
		/>
		<delete-project-status
			v-if="deleteProjectStatus"
			v-model="deleteProjectStatus"
			:project-status-id="deleteProjectStatusId"
			@saved="refresh()"
		/>
		<edit-project-status
			v-if="renameProjectStatus"
			v-model="renameProjectStatus"
			:project-status-id="renameProjectStatusId"
			@saved="refresh()"
		/>
	</div>
</template>

<script>
import { getProjectStatuses } from '@/services/api'
import CreateProjectStatus from './dialogs/CreateProjectStatus'
import DeleteProjectStatus from './dialogs/DeleteProjectStatus'
import EditProjectStatus from './dialogs/EditProjectStatus'
import sendCommand from '@/services/sendCommand'

export default {
	components: { CreateProjectStatus, DeleteProjectStatus, EditProjectStatus },
	data() {
		return {
			createProjectStatus: false,
			deleteProjectStatus: null,
			deleteProjectStatusId: null,
			renameProjectStatus: null,
			renameProjectStatusId: null,
			headers: [
				{ text: this.$localize('ProjectStatus.name'), value: 'name' },
				{
					text: this.$localize('Project.phaseRequest'),
					value: 'request',
				},
				{ text: this.$localize('Project.phaseOffer'), value: 'offer' },
				{ text: this.$localize('Project.phaseOrder'), value: 'order' },
				{
					text: this.$localize('Project.phaseInvoice'),
					value: 'invoice',
				},
				{
					text: this.$localize('Project.phaseArchive'),
					value: 'archive',
				},
				{
					text: this.$localize('form.label.enabled'),
					value: 'isEnabled',
				},
				{
					text: this.$localize('ProjectStatus.showInActionList'),
					value: 'showInActionList',
				},
				{
					text: this.$localize('ProjectStatus.notification'),
					value: 'sendEmailNotification',
				},
				{
					value: 'action',
					sortable: false,
					width: 270,
				},
			],
			allStatuses: [],
			loading: false,
			options: JSON.parse(
				localStorage.getItem('ProjectStatusOverview:options')
			) || { itemsPerPage: -1 },
			showDisabledStatuses: false,
		}
	},
	computed: {
		statuses() {
			if (this.showDisabledStatuses) {
				return this.disabledStatuses
			}
			return this.enabledStatuses
		},
		enabledStatuses() {
			return this.allStatuses.filter((x) => x.isEnabled)
		},
		disabledStatuses() {
			return this.allStatuses.filter((x) => !x.isEnabled)
		},
	},
	created() {
		this.refresh()
	},
	watch: {
		options() {
			localStorage.setItem(
				'ProjectStatusOverview:options',
				JSON.stringify(this.options)
			)
		},
	},

	methods: {
		async refresh() {
			this.loading = true
			const { result, ok } = await getProjectStatuses.call()
			if (ok) {
				this.allStatuses = result
			}
			this.loading = false
		},
		onUpdate() {
			localStorage.setItem(
				'ProjectStatusOverview:options',
				JSON.stringify(this.options)
			)
		},
		async disable(projectStatus) {
			const result = await sendCommand('DisableProjectStatus', {
				projectStatusId: projectStatus.projectStatusId,
			})
			if (result.success) {
				await this.refresh()
			}
		},
		async enable(projectStatus) {
			const result = await sendCommand('EnableProjectStatus', {
				projectStatusId: projectStatus.projectStatusId,
			})
			if (result.success) {
				await this.refresh()
			}
		},
	},
}
</script>
