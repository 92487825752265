<template>
	<dialog-form
		:value="value"
		@input="$emit('input', $event)"
		:title="$localize('EnterUserInformation.title')"
		@commit="save"
		:allow-continue="false"
		:loading="loading"
	>
		<v-text-field
			prepend-icon="mdi-text-short"
			:label="$localize('User.name')"
			v-model="enterUserInformationCommand.name"
			:rules="[validation.required, validation.maxLength(100)]"
		/>
		<v-text-field
			prepend-icon="mdi-account-key"
			:label="$localize('User.login')"
			v-model="enterUserInformationCommand.login"
			:rules="[validation.required]"
			:suffix="'@' + $session.domain"
		/>
		<input-email
			:label="$localize('User.recoveryEmail')"
			v-model="enterUserInformationCommand.recoveryEmail"
		/>
		<select-salutation
			:label="$localize('User.salutation')"
			v-model="enterUserInformationCommand.salutation"
		/>
		<date-select
			:label="$localize('User.bornDate')"
			v-model="enterUserInformationCommand.bornDate"
		/>
		<v-text-field
			prepend-icon="mdi-map-marker"
			:label="$localize('User.location')"
			v-model="enterUserInformationCommand.location"
			:rules="[validation.maxLength(50)]"
		/>
		<v-text-field
			prepend-icon="mdi-cellphone"
			:label="$localize('User.mobilePhoneNumber')"
			v-model="enterUserInformationCommand.mobilePhoneNumber"
			:rules="[validation.maxLength(50)]"
		/>
		<input-color v-model="enterUserInformationCommand.color" />
	</dialog-form>
</template>

<script>
import DialogForm from '@/shared/components/DialogForm'

import { getUserById } from '@/services/api'
import sendCommand from '@/services/sendCommand'
import { isGuid } from '@/services/validation'
import DateSelect from '@/shared/components/DateSelect'
import { required, email, maxLength } from '@/services/validation'
import InputColor from '@/main/components/forms/InputColor'
import SelectSalutation from '@/main/components/forms/SelectSalutation'
export default {
	components: { DateSelect, DialogForm, InputColor, SelectSalutation },
	props: {
		userId: {
			required: true,
			validator: isGuid,
		},
		value: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			loading: false,
			enterUserInformationCommand: {},
			color: null,
			validation: { required, email, maxLength },
		}
	},
	async created() {
		this.loading = true
		const { result, ok } = await getUserById.call({
			args: { userId: this.userId },
		})
		if (ok) {
			const user = result
			this.enterUserInformationCommand = {
				userId: this.userId,
				name: user.name,
				salutation: user.salutation,
				bornDate: user.bornDate,
				location: user.location,
				mobilePhoneNumber: user.mobilePhoneNumber,
				color: user.color,
				login: user.login.split('@')[0],
				recoveryEmail: user.recoveryEmail,
			}
			this.color = user.color
		}
		this.loading = false
	},
	methods: {
		async save(callback) {
			const result = await sendCommand(
				'EnterUserInformation',
				this.enterUserInformationCommand
			)
			if (result.success) {
				this.$emit('saved')
			}
			callback({ success: result.success })
		},
	},
}
</script>
