var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',[_c('v-snackbar',{attrs:{"app":"","color":_vm.snackbarRight.color,"timeout":_vm.snackbarRight.timeout,"bottom":"","right":""},model:{value:(_vm.snackbarRight.show),callback:function ($$v) {_vm.$set(_vm.snackbarRight, "show", $$v)},expression:"snackbarRight.show"}},[_vm._v(" "+_vm._s(_vm.snackbarRight.text)+" "),(_vm.snackbarRight.icon)?_c('v-icon',[_vm._v(_vm._s(_vm.snackbarRight.icon))]):_vm._e(),(_vm.snackbarRight.timeout === -1)?_c('v-btn',{staticClass:"snackbar-close",attrs:{"icon":""},on:{"click":function($event){_vm.snackbarRight.show = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1):_vm._e()],1),(
                (_vm.$settings.showLicenseLimitApproach &&
                    _vm.$session.licenseStatus === 'license-limit-approach') ||
                (_vm.$settings.showLicenseLimitReached &&
                    _vm.$session.licenseStatus === 'license-limit-reached')
            )?_c('v-snackbar',{attrs:{"app":"","color":_vm.snackbarLeft.color,"timeout":_vm.snackbarLeft.timeout,"bottom":"","left":""},model:{value:(_vm.snackbarLeft.show),callback:function ($$v) {_vm.$set(_vm.snackbarLeft, "show", $$v)},expression:"snackbarLeft.show"}},[(_vm.$session.licenseStatus === 'license-limit-approach')?[_vm._v(" U heeft 90% van het project limiet van uw licentie berijkt "),(_vm.$session.roles.indexOf('account_admin') !== -1)?_c('router-link',{attrs:{"to":{
                        name: 'settingsOverviewCategory',
                        params: { category: 'administration' },
                    }}},[_vm._v("Klik hier om uw licentie te bekijken.")]):_vm._e()]:(
                    _vm.$session.licenseStatus === 'license-limit-reached'
                )?[_vm._v(" U heeft het maximale project limiet bereikt van uw licentie. "),_c('br'),(_vm.$session.roles.indexOf('account_admin') !== -1)?_c('router-link',{attrs:{"to":{
                        name: 'settingsOverviewCategory',
                        params: { category: 'administration' },
                    }}},[_vm._v("Klik hier om uw licentie te bekijken.")]):[_vm._v(" Neem contact op met uw account administrator ")]]:_vm._e(),(_vm.snackbarLeft.icon)?_c('v-icon',[_vm._v(_vm._s(_vm.snackbarLeft.icon))]):_vm._e(),(_vm.snackbarLeft.timeout === -1)?_c('v-btn',{staticClass:"snackbar-close",attrs:{"icon":""},on:{"click":function($event){_vm.snackbarLeft.show = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1):_vm._e()],2):_vm._e()],1),_c('v-bottom-sheet',{attrs:{"value":_vm.showError,"persistent":""}},[_c('v-card',[_c('v-card-title',{staticClass:"error--text"},[_vm._v(_vm._s(_vm.error.data.message))]),_c('v-card-title',[_vm._v(_vm._s(_vm.error.data.className))]),_c('v-card-text',[_c('div',{staticClass:"pre"},[_vm._v(_vm._s(_vm.error.data.stacktrace))])]),_c('v-card-actions',[_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.errors.length === 0),expression:"errors.length === 0"}],on:{"click":function($event){return _vm.closeError()}}},[_vm._v("close")]),_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.errors.length > 0),expression:"errors.length > 0"}],on:{"click":function($event){return _vm.closeError()}}},[_vm._v("close ("+_vm._s(_vm.errors.length)+" more)")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }