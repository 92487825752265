<template>
  <dialog-form
    :value="value"
    @input="$emit('input', $event)"
    :title="$localize('DeleteOffer.title')"
    action="delete"
    @commit="save"
    :action-text="$localize('DeleteOffer.button.save')"
  >{{ $localize('DeleteOffer.description') }}</dialog-form>
</template>

<script>
import DialogForm from "@/shared/components/DialogForm";

import sendCommand from "@/services/sendCommand";
import { isGuid } from "@/services/validation"

export default {
  components: { DialogForm },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    projectId: {
      validator: isGuid,
      required: true
    },
    offerId: {
      validator: isGuid,
      required: false
    }
  },

  methods: {
    async save(callback) {
      const result = await sendCommand("DeleteOffer", {
        projectId: this.projectId,
        offerId: this.offerId
      });

      if (result.success) {
        this.$emit("saved");
      }

      callback({success: result.success})
    }
  }
};
</script>