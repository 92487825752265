<template>
  <v-menu
    ref="datePicker"
    v-model="show"
    :return-value.sync="val"
    :close-on-content-click="false"
    min-width="290px"
    
  >
    <template v-slot:activator="{ on }">
      <v-text-field
        v-on="on"
        :label="label"
        :value="formattedDate"
        @input="save($event)"
        readonly
        :clearable="clearable"
        :rules="rules"
        :prepend-icon="prependIcon"
        :autofocus="autofocus"
        class="dateSelect"
      >
        <template v-slot:label>
          <slot name="label"></slot>
        </template>
      </v-text-field>
    </template>

    <v-date-picker v-model="val" @input="$refs.datePicker.save(val); save(val)" />
  </v-menu>
</template>

<script>
import { format, parseISO } from "date-fns";

export default {
  props: {
    value: {},
    label: {
      type: String,
      default: "",
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: Array,
      default: () => [],
    },
    prependIcon: {
      type: String,
      default: "mdi-calendar",
    },
    autofocus: Boolean,
  },
  data() {
    let dateValue = "";
    if (this.value) dateValue = format(parseISO(this.value), "yyyy-MM-dd");

    return {
      show: false,
      val: dateValue,
    };
  },
  computed: {
    formattedDate() {
      if (this.val) {
        return this.$format.shortDate(this.val);
      }
      return "";
    },
  },
  watch: {
    value(val) {
      if (val) {
        this.val = format(parseISO(val), "yyyy-MM-dd");
      }
      else{
        this.val = ''
      }
    },
  },
  methods: {
    save(value) {
      this.val = value;
      this.$emit("input", value);
    },
  },
};
</script>
