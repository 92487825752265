var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"py-0 pr-0 pl-1",attrs:{"fluid":""}},[(_vm.search)?_c('v-row',[_c('v-col',[_vm._l((_vm.visibleEditors),function(settingCategory){return [_vm._l((settingCategory),function(setting){return [_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.filter(setting)),expression:"filter(setting)"}],key:setting.prop},[(setting.component)?_c('div',[(setting.prop)?_c(setting.component,_vm._b({tag:"component",attrs:{"label":_vm.$localize(
                                        'Settings.prop.' + setting.prop
                                    ),"hint":_vm.$localize(
                                        'Settings.prop.' +
                                            setting.prop +
                                            '.hint'
                                    ),"filled":""},on:{"input":function($event){return _vm.saveSetting(setting, $event)}},model:{value:(_vm.settings[setting.prop]),callback:function ($$v) {_vm.$set(_vm.settings, setting.prop, $$v)},expression:"settings[setting.prop]"}},'component',setting.props,false)):_c(setting.component,{tag:"component"})],1):(setting.file)?_c('div',[_c('v-file-input',{key:_vm.settings[setting.prop],attrs:{"value":_vm.settings[setting.prop]
                                        ? { name: _vm.settings[setting.prop] }
                                        : null,"prepend-icon":setting.icon,"label":_vm.$localize(
                                        'Settings.prop.' + setting.prop
                                    ),"hint":_vm.$localize(
                                        'Settings.prop.' +
                                            setting.prop +
                                            '.hint'
                                    ),"persistent-hint":"","hide-details":!_vm.$localize(
                                        'Settings.prop.' +
                                            setting.prop +
                                            '.hint'
                                    ),"filled":"","clearable":setting.allowClear},on:{"change":function($event){return _vm.saveFileSetting(setting, $event)}},scopedSlots:_vm._u([{key:"selection",fn:function({ text }){return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-chip',_vm._g({attrs:{"small":"","label":"","color":"primary"}},on),[_vm._v(_vm._s(text))])]}}],null,true)},[(_vm.settings[setting.prop])?_c('v-img',{attrs:{"src":_vm.getSystemFile.create().url +
                                                `?name=${
                                                    setting.prop
                                                }&r=${Math.random()}`}}):_vm._e()],1)]}}],null,true)})],1):(typeof setting.items === 'function')?_c('div',[_c('v-select',{attrs:{"filled":"","items":setting.items(),"label":_vm.$localize(
                                        'Settings.prop.' + setting.prop
                                    ),"hint":_vm.$localize(
                                        'Settings.prop.' +
                                            setting.prop +
                                            '.hint'
                                    ),"persistent-hint":"","hide-details":!_vm.$localize(
                                        'Settings.prop.' +
                                            setting.prop +
                                            '.hint'
                                    ),"multiple":setting.multiple,"item-value":setting.itemValue,"item-text":setting.itemText,"clearable":setting.allowClear,"prepend-icon":setting.icon},on:{"input":function($event){return _vm.saveSetting(setting, $event)}},model:{value:(_vm.settings[setting.prop]),callback:function ($$v) {_vm.$set(_vm.settings, setting.prop, $$v)},expression:"settings[setting.prop]"}})],1):(setting.type === 'textarea')?_c('div',[_c('v-textarea',{attrs:{"value":_vm.settings[setting.prop],"filled":"","label":_vm.$localize(
                                        'Settings.prop.' + setting.prop
                                    ),"hint":_vm.$localize(
                                        'Settings.prop.' +
                                            setting.prop +
                                            '.hint'
                                    ),"persistent-hint":"","hide-details":!_vm.$localize(
                                        'Settings.prop.' +
                                            setting.prop +
                                            '.hint'
                                    ),"clearable":setting.allowClear,"prepend-icon":setting.icon,"rules":setting.rules || []},on:{"change":function($event){return _vm.saveSetting(setting, $event)}}})],1):(setting.type === 'boolean')?_c('div',[_c('v-switch',{staticClass:"mb-3",attrs:{"value":_vm.settings[setting.prop],"label":_vm.$localize(
                                        'Settings.prop.' + setting.prop
                                    ),"hint":_vm.$localize(
                                        'Settings.prop.' +
                                            setting.prop +
                                            '.hint'
                                    ),"persistent-hint":"","hide-details":!_vm.$localize(
                                        'Settings.prop.' +
                                            setting.prop +
                                            '.hint'
                                    ),"prepend-icon":setting.icon},on:{"change":function($event){return _vm.saveSetting(setting, $event)}}})],1):_c('div',[_c('v-text-field',{attrs:{"value":_vm.settings[setting.prop],"filled":"","label":_vm.$localize(
                                        'Settings.prop.' + setting.prop
                                    ),"hint":_vm.$localize(
                                        'Settings.prop.' +
                                            setting.prop +
                                            '.hint'
                                    ),"persistent-hint":"","hide-details":!_vm.$localize(
                                        'Settings.prop.' +
                                            setting.prop +
                                            '.hint'
                                    ),"clearable":setting.allowClear,"prepend-icon":setting.icon,"rules":setting.rules || []},on:{"change":function($event){return _vm.saveSetting(setting, $event)}}})],1)])]})]})],2)],1):_vm._e(),(!_vm.search && !_vm.$vuetify.breakpoint.mdAndUp)?_c('v-row',{staticClass:"mx-4"},[_c('v-col',[_c('v-select',{attrs:{"items":Object.keys(_vm.visibleEditors).map((x) => {
                        return { key: x, editor: _vm.visibleEditors[x] }
                    }),"value":_vm.categoryKey,"outlined":"","rounded":"","hide-details":"","item-text":(x) => _vm.$localize('Settings.tab.' + x.key),"item-value":"key"},on:{"input":function($event){return _vm.$router.replace({
                        name: 'settingsOverviewCategory',
                        params: { category: $event },
                    })}}})],1)],1):_vm._e(),(!_vm.search)?_c('v-row',[(_vm.$vuetify.breakpoint.mdAndUp)?_c('v-col',{staticStyle:{"width":"250px"},attrs:{"cols":"auto"}},[_c('v-tabs',{key:_vm.search,attrs:{"vertical":""}},_vm._l((_vm.visibleEditors),function(settingCategory,key){return _c('v-tab',{key:key,attrs:{"to":{
                        name: 'settingsOverviewCategory',
                        params: { category: key },
                    },"replace":true}},[_vm._v(_vm._s(_vm.$localize('Settings.tab.' + key)))])}),1)],1):_vm._e(),_c('v-col',_vm._l((_vm.category),function(setting,index){return _c('div',{key:index},[(setting.component)?_c('div',[(setting.prop)?_c(setting.component,_vm._b({tag:"component",attrs:{"label":_vm.$localize('Settings.prop.' + setting.prop),"hint":_vm.$localize(
                                'Settings.prop.' + setting.prop + '.hint'
                            ),"filled":""},on:{"input":function($event){return _vm.saveSetting(setting, $event)}},model:{value:(_vm.settings[setting.prop]),callback:function ($$v) {_vm.$set(_vm.settings, setting.prop, $$v)},expression:"settings[setting.prop]"}},'component',setting.props,false)):_c(setting.component,{tag:"component"})],1):(setting.file)?_c('div',{key:_vm.settings[setting.prop]},[_c('v-file-input',{key:_vm.settings[setting.prop],attrs:{"value":_vm.settings[setting.prop]
                                ? { name: _vm.settings[setting.prop] }
                                : null,"prepend-icon":setting.icon,"label":_vm.$localize('Settings.prop.' + setting.prop),"hint":_vm.$localize(
                                'Settings.prop.' + setting.prop + '.hint'
                            ),"persistent-hint":"","hide-details":!_vm.$localize(
                                'Settings.prop.' + setting.prop + '.hint'
                            ),"filled":"","clearable":setting.allowClear},on:{"change":function($event){return _vm.saveFileSetting(setting, $event)}},scopedSlots:_vm._u([{key:"selection",fn:function({ text }){return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-chip',_vm._g({attrs:{"small":"","label":"","color":"primary"}},on),[_vm._v(_vm._s(text))])]}}],null,true)},[(_vm.settings[setting.prop])?_c('v-img',{attrs:{"src":_vm.getSystemFile.create().url +
                                        `?name=${
                                            setting.prop
                                        }&r=${Math.random()}`}}):_vm._e()],1)]}}],null,true)})],1):(typeof setting.items === 'function')?_c('div',[_c('v-select',{attrs:{"filled":"","items":setting.items(),"label":_vm.$localize('Settings.prop.' + setting.prop),"hint":_vm.$localize(
                                'Settings.prop.' + setting.prop + '.hint'
                            ),"persistent-hint":"","hide-details":!_vm.$localize(
                                'Settings.prop.' + setting.prop + '.hint'
                            ),"multiple":setting.multiple,"item-value":setting.itemValue,"item-text":setting.itemText,"clearable":setting.allowClear,"prepend-icon":setting.icon},on:{"input":function($event){return _vm.saveSetting(setting, $event)}},model:{value:(_vm.settings[setting.prop]),callback:function ($$v) {_vm.$set(_vm.settings, setting.prop, $$v)},expression:"settings[setting.prop]"}})],1):(setting.type === 'textarea')?_c('div',[_c('v-textarea',{attrs:{"value":_vm.settings[setting.prop],"filled":"","label":_vm.$localize('Settings.prop.' + setting.prop),"hint":_vm.$localize(
                                'Settings.prop.' + setting.prop + '.hint'
                            ),"persistent-hint":"","hide-details":!_vm.$localize(
                                'Settings.prop.' + setting.prop + '.hint'
                            ),"clearable":setting.allowClear,"prepend-icon":setting.icon,"rules":setting.rules || []},on:{"change":function($event){return _vm.saveSetting(setting, $event)}}})],1):(setting.type === 'boolean')?_c('div',[_c('v-switch',{staticClass:"mb-3",attrs:{"value":_vm.settings[setting.prop],"label":_vm.$localize('Settings.prop.' + setting.prop),"hint":_vm.$localize(
                                'Settings.prop.' + setting.prop + '.hint'
                            ),"persistent-hint":"","hide-details":!_vm.$localize(
                                'Settings.prop.' + setting.prop + '.hint'
                            ),"prepend-icon":setting.icon},on:{"change":function($event){return _vm.saveSetting(setting, $event)}}})],1):_c('div',[_c('v-text-field',{attrs:{"value":_vm.settings[setting.prop],"filled":"","label":_vm.$localize('Settings.prop.' + setting.prop),"hint":_vm.$localize(
                                'Settings.prop.' + setting.prop + '.hint'
                            ),"persistent-hint":"","hide-details":!_vm.$localize(
                                'Settings.prop.' + setting.prop + '.hint'
                            ),"type":setting.type || 'text',"clearable":setting.allowClear,"prepend-icon":setting.icon,"rules":setting.rules || []},on:{"change":function($event){return _vm.saveSetting(setting, $event)}}})],1)])}),0)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }