<template>
  <v-layout align-center justify-center>
    <v-flex style="max-width:400px;">
      <h1 class="white--text">{{ $localize('AlreadyLoggedIn.title') }}</h1>
      <v-form @submit.prevent="login" ref="logoutForm">
        <v-card class="pa-3">
          <v-card-text>
            <v-text-field
              id="username"
              :label="$localize('Login.field.login')"
              v-model="$session.login"
              disabled
              outlined
              hide-details
            />
          </v-card-text>
          <v-card-actions>
            <v-btn type="submit" class="success" :loading="processing">{{ $localize('AlreadyLoggedIn.button.continue') }}</v-btn>
          </v-card-actions>
          <v-card-text>
            <v-divider />
          </v-card-text>
          <v-card-actions>
            <a
              id="btnOtherUser"
              @click="switchUser"
            >{{ $localize('AlreadyLoggedIn.link.switchUser') }}</a>
          </v-card-actions>
          <app-installer />
        </v-card>
      </v-form>
    </v-flex>
  </v-layout>
</template>
<script>
import sendCommand from '@/services/sendCommand'
import { currentSession } from "@/services/session";
import AppInstaller from "./AppInstaller";

export default { 
  components: {AppInstaller},
  data(){
    return {
      processing: false
    }
  },
  methods: {
    async switchUser(){
      await sendCommand('Logout')
      this.$router.push({name: 'login'})
    },
    async login(){
      this.processing = true;
       var session = await currentSession(true);
        if (session.isAdministrator) {
          this.$router.push({ name: "adminHome" });
        } else {
          this.$router.push({ name: "home" });
        }

        this.loginCommand = {};
        this.processing = false;
    }
  }
}
</script>
<style lang="scss" scoped>
.v-card__actions {
  justify-content: center;
}
</style>
