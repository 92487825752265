<template>
  <dialog-form
    :value="value"
    @input="$emit('input', $event)"
    :title="$localize('SmtpSettings.title')"
    @commit="save"
    :allow-continue="false"
  >
    <input-email 
      outlined
      v-model="data.fromAddress"
      :label="$localize('Settings.prop.smtp.fromAddress')"
      :hint="$localize('Settings.prop.smtp.fromAddress.hint')"
      persistent-hint
      required
    />
    <v-text-field
      outlined
      v-model="data.host"
      :label="$localize('Settings.prop.smtp.host')"
      :hint="$localize('Settings.prop.smtp.host.hint')"
      :rules="[rules.required, rules.maxLength(100)]"
      persistent-hint
    />
    <v-text-field
      outlined
      v-model="data.port"
      :label="$localize('Settings.prop.smtp.port')"
      :hint="$localize('Settings.prop.smtp.port.hint')"
      :rules="[rules.required, rules.integer]"
      persistent-hint
      type="number"
    />
    <v-switch
      outlined
      v-model="data.secure"
      :label="$localize('Settings.prop.smtp.secure')"
      :hint="$localize('Settings.prop.smtp.secure.hint')"
    />
    <v-text-field
      outlined
      v-model="data.username"
      :label="$localize('Settings.prop.smtp.username')"
      :hint="$localize('Settings.prop.smtp.username.hint')"
      :rules="[rules.required, rules.maxLength(100)]"
    />
    <v-text-field
      outlined
      type="password"
      v-model="data.password"
      :label="$localize('Settings.prop.smtp.password')"
      :hint="$localize('Settings.prop.smtp.password.hint')"
      :rules="[rules.maxLength(100)]"
    />
  </dialog-form>
</template>

<script>
import DialogForm from "@/shared/components/DialogForm";
import sendCommand from "@/services/sendCommand";
import { required, maxLength, integer } from "@/services/validation";
export default {
  components: { DialogForm },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      data: {},
      rules: { required, maxLength, integer },
    };
  },

  methods: {
    async save(callback) {
      const result = await sendCommand("CreateSmtpSetting", this.data);

      if (result.success) {
        this.$emit("saved");
      }

      callback({ success: result.success });
    },
  },
};
</script>
