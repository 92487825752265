<template>
	<v-row>
		<v-col>
			<v-select
				v-model="selectedProjectStatuses"
				:items="projectStatuses"
				:multiple="true"
				:label="label || $localize('ProjectStatus.name')"
				item-text="name"
				item-value="projectStatusId"
				:loading="loading"
				@input="onInput"
				:disabled="isAllSelected"
			/>
		</v-col>
		<v-col cols="auto" class="d-flex align-center">
			<v-checkbox
				:label="$localize('form.label.all')"
				v-model="isAllSelected"
				@change="onInput"
			/>
		</v-col>
	</v-row>
</template>

<script>
import { getProjectStatuses } from '@/services/api'

export default {
	props: {
		value: {
			type: [Array],
		},
		label: String,
		allowAll: Boolean,
		allValue: String,
	},
	watch: {
		value(val) {
			this.isAllSelected = val === this.allValue
			this.selectedProjectStatuses = this.createSelectionArray(val)
		},
	},
	data() {
		return {
			isAllSelected: this.value === this.allValue,
			selectedProjectStatuses: this.createSelectionArray(this.value),
			projectStatuses: [],
			loading: false,
		}
	},
	async created() {
		this.loading = true
		const { result, ok } = await getProjectStatuses.call()
		if (ok) {
			this.projectStatuses = result
		}
		this.loading = false
	},
	methods: {
		createSelectionArray(val) {
			if (!val) {
				return []
			}
			if (Array.isArray(val)) {
				return val
			}
			return [val]
		},
		onInput() {
			if (this.isAllSelected) {
				this.$emit('input', this.allValue)
			} else {
				this.$emit('input', this.selectedProjectStatuses)
			}
		},
	},
}
</script>
