import { getUsers } from '@/services/api'

export const users = {
	state: {
		items: [],
		loaded: false,
	},
	getters: {
		getUserById: (state) => (userId) => {
			if (!userId) return null
			const user = state.items.filter((u) => u.userId === userId)
			if (user.length > 0) {
				return user[0]
			}

			return null
		},
		getUsersByIds: (state) => (userIds) => {
			if (!userIds) return []
			const filteredUsers = userIds
				.map((userId) => {
					const user = state.items.filter((u) => u.userId === userId)
					if (user.length > 0) {
						return user[0]
					}
					return null
				})
				.filter((x) => x != null)
			return filteredUsers
		},
	},
	mutations: {
		setUsers(state, users) {
			state.items = users
			state.loaded = true
		},
	},
	actions: {
		async loadUsers(context) {
			if (context.state.loaded) return
			const { result, ok } = await getUsers.call()
			if (ok) {
				context.commit('setUsers', result)
			}
		},
	},
}
