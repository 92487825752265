import VueRouter from 'vue-router'
import axios from 'axios'

// Services
import { currentSession } from '@/services/session'
import { reload } from '@/plugins/Mountain'
import { tryOpenWebsocket } from '@/services/websocketHandler'
// Routing
import sharedRoutes from '@/routes.js'
import adminRoutes from '@/admin/routes.js'
import mainRoutes from '@/main/routes.js'

const allRoutes = sharedRoutes.concat(adminRoutes).concat(mainRoutes)
let csrfToken = ''

export const router = new VueRouter({
	routes: allRoutes,
	mode: 'history',
})

function goToLogin(from) {
	if (from.name !== 'login') router.push({ name: 'login' })
}
function readCsrfCookieAndSetHeader() {
	if (document.cookie.indexOf('CSRF-TOKEN') > -1) {
		const v = document.cookie.match(
			'(^|;) ?' + 'CSRF-TOKEN' + '=([^;]*)(;|$)'
		)
		const r = v ? v[2] : ''
		csrfToken = r
		axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken
	} else {
		csrfToken = ''
	}
}
router.beforeEach(async (to, from, next) => {
	await reload()
	var session = await currentSession()
	tryOpenWebsocket()

	if (to.meta.policy) {
		if (!session.isLoggedIn) {
			goToLogin(from)
			return
		}
		switch (to.meta.policy) {
			case 'admin':
				if (!session.isAdministrator) {
					goToLogin(from)
					return
				}
				break
			case 'tenant':
				if (!session.isTenant) {
					goToLogin(from)
					return
				}
				break
		}
	}
	next()
})

router.afterEach(() => {
	readCsrfCookieAndSetHeader()
})
