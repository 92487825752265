<template>
    <dialog-form :value="value" @input="$emit('input', $event)"
        :title="$localize('CalculatePart.title', { part: calculation.name })" :allow-continue="false" @commit="save"
        max-width="900" :loading="loading">
        <v-row v-if="updatingCalculation || !calculation.isLatestPublishedVersion">
            <v-col class="text-center">
                <v-alert type="info">
                    <div v-if="!updatingCalculation">
                        <p>
                            Er is een nieuwe versie beschikbaar van deze
                            berekening. Klik op updaten om de nieuwe berekening
                            te gebruiken.
                        </p>
                        <v-btn @click="updateCalculation">
                            Updaten
                            <v-icon>mdi-update</v-icon>
                        </v-btn>
                    </div>
                    <div v-else>
                        Berekening geupdate. Er is nog niks opgeslagen.
                    </div>
                </v-alert>
            </v-col>
        </v-row>
        <v-row v-if="updatingArticles || result.oldArticleUsed">
            <v-col class="text-center">
                <v-alert type="info">
                    <div v-if="!updatingArticles">
                        <p>
                            Deze berekening is van
                            {{ $format.shortDateTime(part.versionDate) }}.
                            Sommige prijzen zijn daarna gewijzigd. <br />Klik op
                            updaten om de nieuwste prijzen te gebruiken.
                        </p>
                        <v-btn @click="updateArticles">
                            Updaten
                            <v-icon>mdi-update</v-icon>
                        </v-btn>
                    </div>
                    <div v-else>
                        Berekening gebruikt nu de nieuwste prijzen. Er is nog
                        niks opgeslagen.
                    </div>
                </v-alert>
            </v-col>
        </v-row>
        <v-row>
            <v-col class="col-12 col-sm-6">
                <template v-for="inputField in calculation.input">
                    <template v-if="inputField.type === 'number'">
                        <number-field :key="inputField.key" :label="inputField.label" :input-field="inputField"
                            v-model="input[inputField.key]" />
                    </template>
                    <template v-if="inputField.type === 'text'">
                        <text-field :key="inputField.key" :label="inputField.label" :input-field="inputField"
                            v-model="input[inputField.key]" />
                    </template>
                    <template v-else-if="inputField.type === 'article'">
                        <article-field :key="inputField.key" :label="inputField.label" :input-field="inputField"
                            :article-calculation-id="inputField.articleCalculationIds
                                " v-model="input[inputField.key]" />
                    </template>
                </template>
            </v-col>
            <v-col class="col-12 col-sm-6">
                <calculation-output :state="output" :grid="calculation.outputGrid"></calculation-output>
            </v-col>
        </v-row>

        <template v-slot:actions>
            <v-btn class="mt-3 mr-3" @click="test()">{{
                $localize('Part.button.calculate')
                }}</v-btn>
        </template>
    </dialog-form>
</template>

<script>
import DialogForm from '@/shared/components/DialogForm'

import { isGuid } from '@/services/validation'
import { getCalculationById, testCalculation } from '@/services/api'

import NumberField from '@/main/advanced/components/NumberField'
import ArticleField from '@/main/advanced/components/ArticleField'
import TextField from '@/main/advanced/components/TextField'
import CalculationOutput from '@/main/advanced/components/CalculationOutput'

import sendCommand from '@/services/sendCommand'
import { formatISO } from 'date-fns'

export default {
    components: {
        NumberField,
        ArticleField,
        TextField,
        CalculationOutput,
        DialogForm,
    },
    props: {
        value: {
            type: Boolean,
            default: false,
        },
        part: {
            validator: Object,
            required: true,
        },
        savedProductId: {
            valudator: isGuid,
        },
    },
    data() {
        return {
            loading: false,
            calculation: {},

            input: {},
            output: {},
            result: {},
            updatingCalculation: false,
            updatingArticles: false,

            newVersionDate: null,
        }
    },
    watch: {
        part: {
            immediate: true,
            async handler(part) {
                if (part) {
                    this.loading = true
                    const { result, ok } = await getCalculationById.call({
                        args: {
                            calculationId: part.calculationId,
                            version: part.calculationVersion,
                        },
                    })
                    if (ok) {
                        this.calculation = result
                    }

                    this.input = this.part.input || {}
                    this.output = this.part.displayInfo || {}
                    this.loading = false
                }
            },
        },
    },
    methods: {
        updateCalculation() {
            this.updatingCalculation = true
            this.loadCalculation()
        },
        updateArticles() {
            this.updatingArticles = true
            this.newVersionDate = formatISO(new Date())
        },
        async test() {
            const { result, ok } = await testCalculation.call({
                args: {
                    calculationId: this.calculation.calculationId,
                    version: this.calculation.version,
                },
                payload: {
                    ...this.input,
                    $$versionDate: this.newVersionDate || this.part.versionDate,
                },
            })

            if (ok) {
                this.output = result.state
                this.result = result
            }
        },
        async save(callback) {
            const result = await sendCommand('EditSavedPart', {
                savedProductId: this.savedProductId,
                savedPartId: this.part.savedPartId,
                input: this.input,
                versionDate:
                    this.newVersionDate ||
                    this.part.versionDate ||
                    formatISO(new Date()),
                updateCalculationVersion: this.updatingCalculation,
            })

            if (result.success) {
                this.$emit('saved')
            }

            callback({ success: result.success })
        },
    },
}
</script>
