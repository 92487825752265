<template>
    <dialog-form
        :value="value"
        @input="$emit('input', $event)"
        :title="$localize('Label.createLabel')"
        :allow-continue="false"
        @commit="save"
        max-width="800"
    >
        <v-text-field
            v-model="name"
            :label="$localize('Label.name')"
            :rules="[validation.required, validation.maxLength(50)]"
            prepend-icon="mdi-label"
            autofocus
        />

        <input-color v-model="color" />
    </dialog-form>
</template>

<script>
import DialogForm from '@/shared/components/DialogForm'
import sendCommand from '@/services/sendCommand'
import InputColor from '@/main/components/forms/InputColor'
import { required, maxLength } from '@/services/validation'

export default {
    components: { DialogForm, InputColor },
    props: {
        value: Boolean,
        label: Object,
    },
    data() {
        return {
            validation: { required, maxLength },

            name: this.label.name,
            color: this.label.color,
        }
    },
    watch: {
        label() {
            this.name = this.label.name
            this.color = this.label.color
        },
    },
    methods: {
        async save(callback) {
            const result = await sendCommand('EditLabel', {
                labelId: this.label.labelId,
                name: this.name,
                color: this.color,
            })
            this.$store.dispatch('loadLabels', { force: true })
            if (result.success) {
                this.$emit('saved')
            }
            callback({ success: result.success })

        },
    },
}
</script>
