<template>
    <dialog-form
        :value="value"
        @input="$emit('input', $event)"
        :title="$localize('ChangeAdministrationNotifications.title')"
        :allow-continue="false"
        @commit="save"
        :loading="loading"
    >
        <v-switch
            v-model="notificationSettings.sendInvoiceReadyEmail"
            :label="$localize('ChangeAdministrationNotifications.sendInvoiceReadyEmail')"
            :messages="$localize('ChangeAdministrationNotifications.sendInvoiceReadyEmail.hint')"
            prepend-icon="mdi-email-alert-outline"
        />
        <v-switch
            v-model="notificationSettings.sendInvoiceReminderEmail"
            :label="$localize('ChangeAdministrationNotifications.sendInvoiceReminderEmail')"
            :messages="$localize('ChangeAdministrationNotifications.sendInvoiceReminderEmail.hint')"
            prepend-icon="mdi-email-alert-outline"
        />
        <v-switch
            v-model="notificationSettings.sendInvoicePaidEmail"
            :label="$localize('ChangeAdministrationNotifications.sendInvoicePaidEmail')"
            :messages="$localize('ChangeAdministrationNotifications.sendInvoicePaidEmail.hint')"
            prepend-icon="mdi-email-alert-outline"
        />
        <v-switch
            v-model="notificationSettings.sendInvoicePaymentFailedEmail"
            :label="$localize('ChangeAdministrationNotifications.sendInvoicePaymentFailedEmail')"
            :messages="$localize('ChangeAdministrationNotifications.sendInvoicePaymentFailedEmail.hint')"
            prepend-icon="mdi-email-alert-outline"
        />
        <v-switch
            v-model="notificationSettings.sendLicenseLimitApproach"
            :label="$localize('ChangeAdministrationNotifications.sendLicenseLimitApproach')"
            :messages="$localize('ChangeAdministrationNotifications.sendLicenseLimitApproach.hint')"
            prepend-icon="mdi-email-alert-outline"
        />
        <v-switch
            v-model="notificationSettings.sendLicenseLimitReached"
            :label="$localize('ChangeAdministrationNotifications.sendLicenseLimitReached')"
            :messages="$localize('ChangeAdministrationNotifications.sendLicenseLimitReached.hint')"
            prepend-icon="mdi-email-alert-outline"
        />
        <v-switch
            v-model="notificationSettings.showLicenseLimitApproach"
            :label="$localize('ChangeAdministrationNotifications.showLicenseLimitApproach')"
            :messages="$localize('ChangeAdministrationNotifications.showLicenseLimitApproach.hint')"
            prepend-icon="mdi-comment-alert-outline"
        />
        <v-switch
            v-model="notificationSettings.showLicenseLimitReached"
            :label="$localize('ChangeAdministrationNotifications.showLicenseLimitReached')"
            :messages="$localize('ChangeAdministrationNotifications.showLicenseLimitReached.hint')"
            prepend-icon="mdi-comment-alert-outline"
        />
    </dialog-form>
</template>

<script>
import DialogForm from '@/shared/components/DialogForm'
import sendCommand from '@/services/sendCommand'

export default {
    components: { DialogForm },

    props: {
        value: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            loading: false,
            notificationSettings: {
                sendInvoiceReadyEmail: false,
                sendInvoiceReminderEmail: false,
                sendInvoicePaidEmail: false,
                sendInvoicePaymentFailedEmail: false,
                sendLicenseLimitApproach: false,
                sendLicenseLimitReached: false,
                showLicenseLimitApproach: false,
                showLicenseLimitReached: false,
            }
        }
    },

    async created() {
        this.loading = true
        this.notificationSettings.sendInvoiceReadyEmail = this.$settings.sendInvoiceReadyEmail
        this.notificationSettings.sendInvoiceReminderEmail = this.$settings.sendInvoiceReminderEmail
        this.notificationSettings.sendInvoicePaidEmail = this.$settings.sendInvoicePaidEmail
        this.notificationSettings.sendInvoicePaymentFailedEmail = this.$settings.sendInvoicePaymentFailedEmail
        this.notificationSettings.sendLicenseLimitApproach = this.$settings.sendLicenseLimitApproach
        this.notificationSettings.sendLicenseLimitReached = this.$settings.sendLicenseLimitReached
        this.notificationSettings.showLicenseLimitApproach = this.$settings.showLicenseLimitApproach
        this.notificationSettings.showLicenseLimitReached = this.$settings.showLicenseLimitReached
        this.loading = false
    },
    methods: {
        async save(callback) {
            const result = await sendCommand(
                'ChangeNotificationSettings',
                this.notificationSettings
            )

            if (result.success) {
                this.$emit('saved')
            }
            callback({ success: result.success })
        },
    },
}
</script>
