<template>
  <div class="my-1">
    <code-logic
      :variables="variables"
      :definition="definition"
      :arguments="statement.arguments"
      :code-context-id="codeContextId"
      @input:arguments="onInput($event)"
    ></code-logic>
    <v-btn small @click="deleteStatement()">{{ $localize('form.button.delete') }}</v-btn>
    <v-btn small @click="copyStatement()">{{ $localize('form.button.copy') }}</v-btn>
    <div v-for="(scope, index) in definition.scopes" :key="index">
      <code-statements
        v-model="statement.scopes[index]"
        :variables="variables"
        :code-context-id="codeContextId"
        :scope="scope"
        @input="refresh()"
        @delete="refresh()"
      />
    </div>
  </div>
</template>

<script>
import { getStatementDefinitionById } from "@/services/code";
import { isGuid } from "@/services/validation"

export default {
  components: {
    CodeStatements: () => import("./CodeStatements.vue"),
    CodeLogic: () => import("./CodeLogic.vue"),
  },
  props: {
    codeContextId: {
      validator: isGuid,
      required: true,
    },
    value: {
      type: Object,
      required: true,
      validator(value) {
        if (
          value.id === undefined ||
          value.arguments === undefined ||
          value.scopes === undefined
        ) {
          console.error(
            'Invalid prop: type check failed for prop "value". Expected item to have to following properties defined: id, arguments, scopes'
          );
          console.log(value);
          return false;
        }

        return true;
      },
    },
    variables: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      statement: this.value,
      definition: {},
    };
  },
  watch: {
    value(val) {
      this.statement = val;
    },
  },
  created() {
    this.definition = getStatementDefinitionById(this.statement.id);
  },
  methods: {
    onInput(values) {
      this.statement.arguments = values;
      this.$emit("input", this.statement);
    },
    deleteStatement() {
      this.$emit("delete");
    },
    copyStatement() {
      this.$emit("copy");
    },
    refresh() {
      this.$emit("input", this.statement);
    },
  },
};
</script>