<template>
	<v-container fluid>
		<v-row class="mx-4">
			<v-col>
				<v-stepper v-model="step" alt-labels>
					<v-stepper-header>
						<v-stepper-step
							:step="1"
							:complete="step > 1"
							editable
							@click="navToOverview"
							>{{
								$localize('RelationImport.title.stepOverview')
							}}
						</v-stepper-step>
						<v-stepper-step :step="2" :complete="step > 2">{{
							$localize('RelationImport.title.stepUpload')
						}}</v-stepper-step>
						<v-stepper-step
							:step="3"
							:complete="step > 3"
							:editable="step === 4"
							>{{
								$localize('RelationImport.title.stepOptions')
							}}</v-stepper-step
						>
						<v-stepper-step :step="4" :complete="step > 4">{{
							$localize('RelationImport.title.stepLink')
						}}</v-stepper-step>
						<v-stepper-step :step="5" :complete="step > 5">{{
							$localize('RelationImport.title.stepProcess')
						}}</v-stepper-step>
					</v-stepper-header>

					<v-stepper-items>
						<v-stepper-content :step="1">
							<relation-import-overview
								v-if="step === 1"
								@continue="step++"
							/>
						</v-stepper-content>
						<v-stepper-content :step="2">
							<relation-import-upload
								v-if="step === 2"
								:relation-group-id="relationGroupId"
							/>
						</v-stepper-content>
						<v-stepper-content :step="3">
							<relation-import-options
								v-if="step === 3"
								@back="navToOverview"
								@continue="step = 4"
								:relation-import-id="relationImportId"
							/>
						</v-stepper-content>
						<v-stepper-content :step="4">
							<relation-import-link
								v-if="step === 4"
								@back="step = 3"
								@continue="step = 5"
								:relation-import-id="relationImportId"
							/>
						</v-stepper-content>
						<v-stepper-content :step="5">
							<relation-import-process
								v-if="step === 5"
								@continue="done = true"
								:relation-import-id="relationImportId"
							/>
						</v-stepper-content>
					</v-stepper-items>
				</v-stepper>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import RelationImportOverview from './components/RelationImportOverview'
import RelationImportUpload from './components/RelationImportUpload'
import RelationImportOptions from './components/RelationImportOptions'
import RelationImportLink from './components/RelationImportLink'
import RelationImportProcess from './components/RelationImportProcess'
import { isGuid } from '@/services/validation'
import { required } from '@/services/validation'
import { getRelationImport } from '@/services/api'

export default {
	components: {
		RelationImportOverview,
		RelationImportUpload,
		RelationImportOptions,
		RelationImportLink,
		RelationImportProcess,
	},
	props: {
		relationImportId: {
			validator: isGuid,
			required: false,
		},
	},
	data() {
		return {
			step: 0,
			done: false,
			method: '',
			relationGroupId: this.$settings.defaultRelationGroup,
			relationImport: {},

			relationGroups: [],
			validation: { required },
			error: false,
		}
	},
	watch: {
		relationImportId: {
			immediate: true,
			async handler() {
				if (this.relationImportId) {
					const { result, ok } = await getRelationImport.call({
						args: { relationImportId: this.relationImportId },
					})

					if (ok) {
						this.relationImport = result
						if (
							this.relationImport.totalDataRows ===
							this.relationImport.processedRows
						) {
							this.step = 5
							this.done = true
						} else if (this.relationImport.isConfirmed) {
							this.step = 5
						} else {
							this.step = 3
						}
					}
				} else {
					this.step = 1
				}
			},
		},
	},
	methods: {
		navToOverview() {
			if (this.relationImportId) {
				this.$router.push({
					name: 'relationImport',
				})
			}
		},
	},
}
</script>
<style scoped lang="scss">
::v-deep .v-stepper__label {
	text-align: center;
}
</style>
