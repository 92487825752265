var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',[_c('v-toolbar',{staticClass:"my-3",attrs:{"flat":"","height":"36"}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.$localize('ArticleOverview.title')))]),_c('v-spacer'),(_vm.$session.roles.includes('articles_manage'))?_c('v-toolbar-items',[_c('v-btn',{staticClass:"mr-3 btnArticleImport",on:{"click":function($event){return _vm.navImport()}}},[_c('v-icon',{staticClass:"mr-1"},[_vm._v("mdi-database-import")]),_vm._v(" "+_vm._s(_vm.$localize('ArticleOverview.import'))+" ")],1),_c('v-btn',{staticClass:"mr-3 btnDownloadCsv",on:{"click":function($event){return _vm.downloadCsv()}}},[_c('v-icon',{staticClass:"mr-1"},[_vm._v("mdi-database-export")]),_vm._v(" "+_vm._s(_vm.$localize('ArticleOverview.export'))+" ")],1),_c('v-btn',{staticClass:"primary btnCreateArticle",on:{"click":function($event){_vm.createArticle = true}}},[_c('v-icon',{staticClass:"mr-1"},[_vm._v("mdi-plus")]),_vm._v(" "+_vm._s(_vm.$localize('CreateArticle.title'))+" ")],1)],1):_vm._e()],1)],1)],1),(_vm.$vuetify.breakpoint.smAndDown)?_c('v-row',[_c('v-col',[_c('v-select',{staticClass:"mx-4 selectCategory",attrs:{"items":_vm.categories,"item-text":"text","item-value":"value","value":_vm.category,"outlined":"","rounded":"","hide-details":""},on:{"change":function($event){return _vm.navCategory($event)}}})],1)],1):_vm._e(),_c('v-row',{staticClass:"flex-nowrap",attrs:{"no-gutters":"","align":"stretch","justify":"space-between"}},[(_vm.$vuetify.breakpoint.mdAndUp)?_c('v-col',{attrs:{"cols":"auto"}},[_c('v-tabs',{attrs:{"vertical":""}},_vm._l((_vm.categories),function(category){return _c('v-tab',{key:category.value,attrs:{"to":{
						name: 'articleOverview',
						params: {
							category: category.value,
							trash: _vm.$route.params.trash,
						},
					}}},[(category === '$$all')?_c('span',[_vm._v(_vm._s(_vm.$localize('ArticleOverview.label.allArticles')))]):(category)?_c('span',[_vm._v(_vm._s(category.text))]):_c('span',[_vm._v(_vm._s(_vm.$localize('ArticleOverview.label.noCategory')))])])}),1)],1):_vm._e(),_c('v-col',[_c('router-view',{key:_vm.updateKey,on:{"refresh":_vm.refresh}})],1)],1),(_vm.createArticle)?_c('create-article',{on:{"saved":function($event){_vm.updateKey++
			_vm.refresh()}},model:{value:(_vm.createArticle),callback:function ($$v) {_vm.createArticle=$$v},expression:"createArticle"}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }