<template>
  <dialog-form
    :value="value"
    @input="$emit('input', $event)"
    :title="$localize('CreateProjectStatus.title')"
    @commit="save"
    :commit-message="$localize('CreateProjectStatus.message.saved')"
    @reset="createProjectStatusCommand = {enable: true}"
  >
    <v-text-field
      :label=" $localize('ProjectStatus.name')"
      v-model="createProjectStatusCommand.name"
      :rules="[validation.required, validation.maxLength(50)]"
      autofocus
    />
    <v-row>
      <v-col>
        <v-switch
          :label="$localize('Project.phaseRequests')"
          v-model="createProjectStatusCommand.request"
        />
      </v-col>
      <v-col>
        <v-switch
          :label="$localize('Project.phaseOffers')"
          v-model="createProjectStatusCommand.offer"
        />
      </v-col>
      <v-col>
        <v-switch
          :label="$localize('Project.phaseOrders')"
          v-model="createProjectStatusCommand.order"
        />
      </v-col>
      <v-col>
        <v-switch
          :label="$localize('Project.phaseInvoices')"
          v-model="createProjectStatusCommand.invoice"
        />
      </v-col>
      <v-col>
        <v-switch
          :label="$localize('Project.phaseArchive')"
          v-model="createProjectStatusCommand.archive"
        />
      </v-col>
    </v-row>
    <v-switch
      v-model="createProjectStatusCommand.showInActionList"
      :label="$localize('ProjectStatus.showInActionList')"
    />
    <v-text-field
      v-model="createProjectStatusCommand.actionListRecallDays"
      :label="$localize('ProjectStatus.recallDays')"
      :rules="[validation.integer]"
    />
    <v-switch
      v-model="createProjectStatusCommand.sendEmailNotification"
      :label="$localize('ProjectStatus.sendEmailNotification')"
    />
  </dialog-form>
</template>

<script>
import DialogForm from "@/shared/components/DialogForm";

import sendCommand from "@/services/sendCommand";
import { required, maxLength, integer } from "@/services/validation";
export default {
  components: { DialogForm },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      createProjectStatusCommand: {
        enable: true,
      },
      validation: { required, maxLength, integer },
    };
  },

  methods: {
    async save(callback) {
      const result = await sendCommand(
        "CreateProjectStatus",
        this.createProjectStatusCommand
      );

      if (result.success) {
        this.$emit("saved");
      }
      callback({ success: result.success });
    },
  },
};
</script>
