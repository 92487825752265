import App from './App.vue'

// Vue framework
import Vue from 'vue'
Vue.config.productionTip = false

import VueRouter from 'vue-router'
Vue.use(VueRouter)

// axios
import axios from 'axios'
axios.defaults.withCredentials = true
axios.defaults.timeout = 30000

// Assets
import vuetify from './plugins/vuetify'
import 'typeface-roboto/index.css'

import { router } from './router.js'

// Register global components
import InputEmail from '@/main/components/forms/InputEmail'
import TableToolbar from '@/shared/components/TableToolbar'

Vue.component('input-email', InputEmail)
Vue.component('table-toolbar', TableToolbar)

import Mountain from './plugins/Mountain.js'
Vue.use(Mountain)
import './shared/master.scss'

import '@/services/websocketHandler.js'
import './registerServiceWorker'
import store from './store/store.js'
new Vue({
    el: '#app',
    router: router,
    store,
    vuetify,
    render: (h) => h(App),
})
