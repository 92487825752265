<template>
	<div>
		<v-toolbar flat class="my-3" height="36">
			<v-toolbar-title>{{
				$localize('Dashboards.title')
			}}</v-toolbar-title>

			<v-spacer></v-spacer>
			<v-toolbar-items>
				<v-btn class="primary" @click="creatingDashboard = true">
					<v-icon class="mr-1">mdi-plus</v-icon>
					{{ $localize('Dashboard.button.create') }}</v-btn
				>
			</v-toolbar-items>
		</v-toolbar>
		<v-data-table
			:items="dashboards"
			:headers="headers"
			:loading="loading"
			:footer-props="{ 'items-per-page-options': [10, 20, 50, -1] }"
			:options.sync="options"
			dense
		>
			<template v-slot:[`item.name`]="{ item }">
				<v-icon v-if="item.icon">{{ item.icon }}</v-icon>
				{{ item.name }}
			</template>
			<template v-slot:[`item.action`]="{ item }">
				<table-toolbar>
					<v-btn
						small
						depressed
						class="mr-2"
						@click="navDashboard(item)"
					>
						<v-icon dense>mdi-magnify</v-icon>
					</v-btn>
					<v-btn
						small
						depressed
						class="mr-2"
						@click="changeDashboardColumns(item)"
					>
						<v-icon dense>mdi-table-column</v-icon>
					</v-btn>
					<v-btn small depressed class="mr-2" @click="editItem(item)">
						<v-icon dense>mdi-pencil</v-icon>
					</v-btn>
					<v-btn
						small
						depressed
						class="mr-2"
						@click="deleteItem(item)"
					>
						<v-icon dense>mdi-close</v-icon>
					</v-btn>
				</table-toolbar>
			</template>
		</v-data-table>
		<create-dashboard
			v-if="creatingDashboard"
			v-model="creatingDashboard"
			@saved="refresh"
		/>
		<edit-dashboard
			v-if="editingDashboard"
			v-model="editingDashboard"
			:dashboard-id="editingDashboardId"
			@saved="refresh"
		/>
		<change-dashboard-columns
			v-if="changingDashboardColumns"
			v-model="changingDashboardColumns"
			:dashboard-id="changingDashboardColumnsId"
			@saved="refresh"
		/>
		<delete-dashboard
			v-if="deletingDashboard"
			v-model="deletingDashboard"
			:dashboard-id="deletingDashboardId"
			@saved="refresh"
		/>
	</div>
</template>

<script>
import { getDashboards } from '@/services/api'
import CreateDashboard from './dialogs/CreateDashboard'
import EditDashboard from './dialogs/EditDashboard'
import DeleteDashboard from './dialogs/DeleteDashboard'
import ChangeDashboardColumns from './dialogs/ChangeDashboardColumns'

export default {
	components: {
		CreateDashboard,
		EditDashboard,
		ChangeDashboardColumns,
		DeleteDashboard,
	},
	data() {
		return {
			creatingDashboard: false,
			editingDashboard: false,
			editingDashboardId: null,
			deletingDashboard: false,
			deletingDashboardId: null,
			changingDashboardColumns: false,
			changingDashboardColumnsId: null,
			loading: false,
			dashboards: [],
			headers: [
				{ text: this.$localize('Dashboard.name'), value: 'name' },

				{ text: '', value: 'action', sortable: false, width: 225 },
			],
			options: JSON.parse(
				localStorage.getItem('DashboardsOverview:options')
			) || { itemsPerPage: -1 },
		}
	},
	created() {
		this.refresh()
	},
	watch: {
		options() {
			localStorage.setItem(
				'DashboardsOverview:options',
				JSON.stringify(this.options)
			)
		},
	},
	methods: {
		async refresh() {
			this.loading = true
			const { result, ok } = await getDashboards.call()
			if (ok) {
				this.dashboards = result
			}
			this.loading = false
		},
		deleteItem(item) {
			this.deletingDashboardId = item.dashboardId
			this.deletingDashboard = true
		},
		editItem(item) {
			this.editingDashboardId = item.dashboardId
			this.editingDashboard = true
		},
		changeDashboardColumns(item) {
			this.changingDashboardColumnsId = item.dashboardId
			this.changingDashboardColumns = true
		},
		navDashboard(dashboard) {
			this.$router.push({
				name: 'dashboard',
				params: {
					dashboardId: dashboard.dashboardId,
				},
			})
		},
	},
}
</script>
