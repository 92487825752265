<template>
  <div>
    <v-row no-gutters v-for="record in item.data" :key="record.field">
      <v-col cols="4">{{ $localize("Project." + record.field) }}</v-col>
      <v-col cols="8">{{ renderValue(record.field, record.new) }}</v-col>
    </v-row>
  </div>
</template>

<script>
import { parseISO } from "date-fns";

export default {
  props: {
    item: Object,
  },
  methods: {
    renderValue(field, value) {
      if(field === 'deliveryMethod'){
        return this.$localize('DeliveryMethod.' + value)
      }
      if (!value) {
        return "";
      }
      try {
        if (parseISO(value)) {
          return this.$format.shortDateTime(value);
        }
      } catch {
        return value;
      }
    },
  },
};
</script>