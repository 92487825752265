<template>
	<v-row no-gutters>
		<v-col>
			<v-autocomplete
				:value="selectedArticleCalculationId"
				@input="onArticleCalculationSelected($event)"
				:label="$localize('SelectArticleCalculation.name')"
				:hint="$localize('SelectArticleCalculation.name.hint')"
				:persistent-hint="true"
				:items="calculationTypes"
				item-text="name"
				item-value="calculationFieldId"
				:clearable="true"
				:rules="[validation.required]"
				:loading="loading"
				prepend-icon="mdi-shape"
				:autofocus="autofocus"
				multiple
			/>
		</v-col>
		<v-col align-self="center" cols="auto" class="ml-1">
			<v-btn
				small
				outlined
				fab
				color="success"
				@click="createArticleCalculation = true"
			>
				<v-icon>mdi-plus</v-icon>
			</v-btn>
		</v-col>
		<create-article-calculation
			v-if="createArticleCalculation"
			:allow-continue="false"
			v-model="createArticleCalculation"
			@saved="onArticleCalculationSaved($event)"
		/>
	</v-row>
</template>

<script>
import { getCalculationTypes } from '@/services/api'
import { required } from '@/services/validation'
import CreateArticleCalculation from '@/main/advanced/dialogs/CreateArticleCalculation'

export default {
	components: { CreateArticleCalculation },
	props: {
		value: {
			type: Array,
		},
		autofocus: Boolean,
	},
	data() {
		return {
			loading: false,
			createArticleCalculation: false,
			selectedArticleCalculationId: this.value,
			calculationTypes: [],
			validation: { required },
		}
	},
	watch: {
		value: {
			handler(val) {
				this.selectedArticleCalculationId = val
			},
		},
	},
	created() {
		this.refresh()
	},

	methods: {
		async refresh() {
			this.loading = true
			const { result, ok } = await getCalculationTypes.call()
			if (ok) {
				this.calculationTypes = result
			}
			this.loading = false
		},
		async onArticleCalculationSaved(articleCalculationId) {
			this.selectedArticleCalculationId = articleCalculationId
			await this.refresh()
			this.$emit('input', articleCalculationId)
		},
		onArticleCalculationSelected(articleCalculationId) {
			this.selectedArticleCalculationId = articleCalculationId
			this.$emit('input', articleCalculationId)
		},
	},
}
</script>
