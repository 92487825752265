<template>
	<dialog-form
		:value="value"
		@input="$emit('input', $event)"
		:title="$localize('SmtpSettings.title')"
		@commit="save"
		:allow-continue="false"
	>
		<v-text-field
			outlined
			v-model="data.fromAddress"
			:label="$localize('Settings.prop.smtp.fromAddress')"
			:hint="$localize('Settings.prop.smtp.fromAddress.hint')"
			:rules="[rules.required]"
			persistent-hint
		/>
		<v-text-field
			outlined
			v-model="data.host"
			:label="$localize('Settings.prop.smtp.host')"
			:hint="$localize('Settings.prop.smtp.host.hint')"
			:rules="[rules.required]"
			persistent-hint
		/>
		<v-text-field
			outlined
			v-model="data.port"
			:label="$localize('Settings.prop.smtp.port')"
			:hint="$localize('Settings.prop.smtp.port.hint')"
			:rules="[rules.required]"
			persistent-hint
			type="number"
		/>
		<v-switch
			outlined
			v-model="data.secure"
			:label="$localize('Settings.prop.smtp.secure')"
			:hint="$localize('Settings.prop.smtp.secure.hint')"
		/>
		<v-text-field
			outlined
			v-model="data.username"
			:label="$localize('Settings.prop.smtp.username')"
			:hint="$localize('Settings.prop.smtp.username.hint')"
			:rules="[rules.required]"
		/>
		<v-alert type="info">{{
			$localize('EditSmtpSetting.alert.passwordHidden')
		}}</v-alert>
		<v-text-field
			outlined
			type="password"
			v-model="data.password"
			:label="$localize('Settings.prop.smtp.password')"
			:hint="$localize('Settings.prop.smtp.password.hint')"
			:rules="[rules.required]"
		/>
	</dialog-form>
</template>

<script>
import DialogForm from '@/shared/components/DialogForm'
import sendCommand from '@/services/sendCommand'
import { required } from '@/services/validation'
import { isGuid } from '@/services/validation'
import { getSmtpSettingById } from '@/services/api'

export default {
	components: { DialogForm },
	props: {
		value: {
			type: Boolean,
			default: false,
		},
		smtpSettingId: {
			validator: isGuid,
			required: true,
		},
	},
	data() {
		return {
			data: {},
			rules: { required },
		}
	},
	async created() {
		const { result, ok } = await getSmtpSettingById.call({
			args: { smtpSettingId: this.smtpSettingId },
		})
		if (ok) {
			this.data = result
		}
	},
	methods: {
		async save(callback) {
			const result = await sendCommand('EditSmtpSetting', this.data)

			if (result.success) {
				this.$emit('saved')
			}

			callback({ success: result.success })
		},
	},
}
</script>
