<template>
	<v-card flat>
		<v-text-field
			ref="subject"
			:label="$localize('EmailEditor.subject')"
			:hint="$localize('EmailEditor.subject.hint')"
			:persistent-hint="true"
			v-model="email.subject"
			@keyup="trackCursor"
			@blur="selectedRef = 'subject'"
			@focus="trackCursor"
			@click="trackCursor"
			@input="$emit('input', email)"
			:autofocus="autofocus"
		/>
		<v-textarea
			ref="message"
			:label="$localize('EmailEditor.message')"
			:hint="$localize('EmailEditor.message.hint')"
			:persistent-hint="true"
			v-model="email.message"
			@keyup="trackCursor"
			@blur="selectedRef = 'message'"
			@focus="trackCursor"
			@click="trackCursor"
			@input="$emit('input', email)"
		/>
		<p>
			{{ $localize('EmailEditor.messages.clickVariableToAdd') }}
			<v-chip
				v-for="key in enrichment"
				@click="add($event, key)"
				:key="key"
				>{{ key }}</v-chip
			>
		</p>
		<p>
			Selecteer tekst en klik op de gewenste nadruk:
			<v-chip
				v-for="key in styles"
				@click="wrap($event, key)"
				:key="key"
				>{{ key }}</v-chip
			>
		</p>
	</v-card>
</template>

<script>
import { getEnrichment } from '@/services/api'

export default {
	props: {
		value: Object,
		autofocus: Boolean,
	},
	data() {
		return {
			selectionStart: 0,
			selectionEnd: 0,
			selectedRef: 'message',

			email: {
				message: '',
				subject: '',
			},
			enrichment: [],
			styles: ['bold', 'italic'],
		}
	},

	watch: {
		value: {
			immediate: true,
			handler(val) {
				if (val) {
					this.email.message = val.message || ''
					this.email.subject = val.subject || ''
				}
			},
		},
	},
	async created() {
		const { result, ok } = await getEnrichment.call()
		if (ok) {
			this.enrichment = result
		}
	},
	methods: {
		trackCursor(event) {
			this.selectionStart = event.target.selectionStart
			this.selectionEnd = event.target.selectionEnd
		},
		wrap(evt, markup) {
			var ref = this.$refs[this.selectedRef]
			if (ref) {
				let text = this.email[this.selectedRef].substring(
					this.selectionStart,
					this.selectionEnd
				)
				if (markup === 'bold') {
					text = `**${text}**`
				} else if (markup === 'italic') {
					text = `*${text}*`
				}

				this.email[this.selectedRef] =
					this.email[this.selectedRef].slice(0, this.selectionStart) +
					text +
					this.email[this.selectedRef].slice(
						Math.abs(this.selectionEnd)
					)
				ref.focus()

				this.selectionStart += text.length
				this.selectionEnd += text.length

				this.$emit('input', this.email)
			}
		},
		add(evt, field) {
			var ref = this.$refs[this.selectedRef]
			if (ref) {
				const syntax = '[[' + field + ']]'

				this.email[this.selectedRef] =
					this.email[this.selectedRef].slice(0, this.selectionStart) +
					syntax +
					this.email[this.selectedRef].slice(
						Math.abs(this.selectionEnd)
					)
				ref.focus()

				this.selectionStart += syntax.length
				this.selectionEnd += syntax.length

				this.$emit('input', this.email)
			}
		},
	},
}
</script>
