<template>
  <v-dialog :value="value" @input="$emit('input', $event)" max-width="600px">
    <v-card>
      <v-card-title>Create Administrator</v-card-title>
      <v-form @submit.prevent="save()" class="pa-3">
        <v-text-field label="FullName" v-model="createAdministrator.fullName" />
        <v-text-field label="Email" v-model="createAdministrator.email" />

        <v-btn
          @click="$emit('input', false)"
          class="mt-3 mr-3"
        >{{ $localize('form.button.cancel') }}</v-btn>
        <v-btn type="submit" class="success mt-3 mr-3">{{ $localize('form.button.save') }}</v-btn>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import sendCommand from "@/services/sendCommand";

export default {
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },

  data: function() {
    return {
      createAdministrator: {}
    };
  },

  methods: {
    save: async function() {
      const result = await sendCommand(
        "CreateAdministrator",
        this.createAdministrator
      );
      if (result.success) {
        this.createAdministrator = {};
        this.$emit("input", false);
        this.$emit("saved");
      }
    }
  }
};
</script>
