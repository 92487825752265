import { ApiEndPoint } from './apiEndPoint'

// users
export const getUsers = new ApiEndPoint('users')
export const getUserById = new ApiEndPoint('users/{userId}')
export const getEmails = new ApiEndPoint('users/emails')

export const getSystemSettings = new ApiEndPoint('system/settings')

export const getPermissions = new ApiEndPoint('permissions')
