<template>
	<v-select
		:value="selectedVatPercentage"
		@input="onVatSelected($event)"
		:label="$localize('SelectVat.label')"
		:items="vat"
		:item-text="getItemText"
		item-value="vatPercentage"
		:clearable="clearable"
		:loading="loading"
		:autofocus="autofocus"
		:rules="rules"
	/>
</template>

<script>
import { required } from '@/services/validation'
import { number } from '@/services/validation'
import { getAllVat } from '@/services/api'

export default {
	props: {
		value: {
			validator: number,
		},
		label: String,
		autofocus: Boolean,
		clearable: Boolean,
		required: Boolean,
	},

	data() {
		return {
			vat: [],
			defaultVatPercentage: null,
			selectedVatPercentage: this.value,
			rules: this.required ? [required] : [],
		}
	},
	watch: {
		value(newValue) {
			this.selectedVatPercentage = newValue
			this.setDefault()
		},
	},
	async created() {
		await this.refresh()
	},
	methods: {
		async refresh() {
			this.loading = true
			const { result, ok } = await getAllVat.call()
			if (ok) {
				this.vat = result.items
                this.defaultVatPercentage = result.default.vatPercentage
			}
			this.loading = false

			this.selectedVatPercentage = this.value
			this.setDefault()
		},
		setDefault() {
			if (
				!this.clearable &&
				(this.selectedVatPercentage === null ||
					this.selectedVatPercentage === undefined)
			) {
				this.selectedVatPercentage = this.defaultVatPercentage
				this.$emit('input', this.selectedVatPercentage)
			}
		},
		onVatSelected(vatPercentage) {
			this.selectedVatPercentage = vatPercentage
			this.$emit('input', vatPercentage)
		},
		getItemText(vat) {
			if (vat.invoiceText) {
				return `${vat.vatPercentage}% (${vat.invoiceText})`
			}
			return `${vat.vatPercentage}%`
		},
	},
}
</script>
