<template>
  <v-app id="adminApp">
    <snackbar />

    <v-navigation-drawer app v-model="drawer">
      <v-list>
        <v-list-item v-for="item in links" :key="item.text" :to="item.to">
          <v-list-item-icon>
            <v-icon>{{item.icon}}</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{item.text}}</v-list-item-title>
        </v-list-item>
      </v-list>
      <div class="pa-2">
        <logout-button v-bind="{'block': true}" />
      </div>
    </v-navigation-drawer>

    <v-app-bar app flat class="ph-0">
      <v-toolbar flat>
              <v-app-bar-nav-icon @click="drawer = !drawer" />

        <v-toolbar-title class="text-uppercase grey--text ml-3">
          <span class="font-weight-light">Thijs</span>
          <span>Manage</span>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <div class="flex-grow-1"></div>
        <v-btn @click="navEventStoreAdmin()">
          <v-img src="/images/event-store-logo.svg" width="100" />
        </v-btn>
      </v-toolbar>
    </v-app-bar>
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>
<script>
import LogoutButton from "@/shared/components/LogoutButton";
import Snackbar from "@/main/Snackbar";

export default {
  components: { LogoutButton, Snackbar },
  data() {
    return {
      links: [
        { text: "Home", to: { name: "adminHome" } },
        { text: "Tenants", to: { name: "tenantsOverview" } },
        { text: "Operations", to: { name: "operations" } },
        { text: "Languages", to: { name: "language" } }
      ],
      drawer: true
    };
  },
  methods: {
    navEventStoreAdmin() {
      let url;
      if(window.location.port){
        // localhost
        url = window.location.protocol + "//" + window.location.hostname + ":2113/";
      }
      else if(window.location.host.includes("staging")){
        // staging
        url = "es-staging.mountainwebs.nl";
      }
      else{
        // production
        url = "es.mountainwebs.nl";
      }
      window.open(url);
    }
  }
};
</script>
