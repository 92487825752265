<template>
	<dialog-form
		:value="value"
		@input="$emit('input', $event)"
		@commit="save"
		:title="$localize('DeleteSmtpSetting.title')"
		:allow-continue="false"
		action="delete"
		>{{ $localize('DeleteSmtpSetting.message') }}</dialog-form
	>
</template>

<script>
import DialogForm from '@/shared/components/DialogForm'
import sendCommand from '@/services/sendCommand'
import { isGuid } from '@/services/validation'
import { getSmtpSettingById } from '@/services/api'

export default {
	components: { DialogForm },
	props: {
		value: {
			type: Boolean,
			default: false,
		},
		smtpSettingId: {
			validator: isGuid,
			required: true,
		},
	},
	data() {
		return {
			smtpSetting: {},
		}
	},
	async created() {
		const { result, ok } = await getSmtpSettingById.call({
			args: { smtpSettingId: this.smtpSettingId },
		})

		if (ok) {
			this.smtpSetting = result
		}
	},
	methods: {
		async save(callback) {
			const result = await sendCommand('DeleteSmtpSetting', {
				smtpSettingId: this.smtpSettingId,
			})

			if (result.success) {
				this.$emit('saved')
			}

			callback({ success: result.success })
		},
	},
}
</script>
