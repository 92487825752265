<template>
	<dialog-form
		:value="value"
		@input="$emit('input', $event)"
		:title="$localize('CreateInvoice.title')"
		@commit="save"
		:allow-continue="false"
		:loading="loading"
		loading-skeleton="list-item-avatar-two-line"
		:action-text="$localize('CreateInvoice.button.createInvoice')"
	>
		<div v-if="project.isInvoiceLocked">
			<v-alert type="error">{{
				$localize('CreateInvoice.message.locked')
			}}</v-alert>
		</div>
		<div v-else-if="project.invoice.path">
			<v-alert type="warning">{{
				$localize('CreateInvoice.message.overwriteExisting')
			}}</v-alert>
		</div>
		<select-address
			v-model="selectedAddressId"
			:relation-id="project.relationId"
			default-type="$invoice"
			autofocus
		/>
	</dialog-form>
</template>

<script>
import DialogForm from '@/shared/components/DialogForm'
import sendCommand from '@/services/sendCommand'
import { isGuid } from '@/services/validation'
import SelectAddress from '@/main/components/forms/SelectAddress'
import { getProjectById } from '@/services/api'

export default {
	components: { DialogForm, SelectAddress },
	props: {
		value: {
			type: Boolean,
			default: false,
		},
		projectId: {
			validator: isGuid,
			required: true,
		},
	},
	data() {
		return {
			loading: false,
			project: {
				invoice: {},
			},
			selectedAddressId: null,
		}
	},

	async created() {
		this.loading = true
		const { result, ok } = await getProjectById.call({
			args: { projectId: this.projectId },
		})
		if (ok) {
			this.project = result
			this.selectedAddressId = this.project.invoice.addressId
		}
		this.loading = false
	},
	methods: {
		async save(callback) {
			const result = await sendCommand('CreateInvoice', {
				projectId: this.projectId,
				addressId: this.selectedAddressId,
			})

			if (result.success) {
				this.$emit('saved')
			}
			callback({ success: result.success })
		},
	},
}
</script>
