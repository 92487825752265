import { render, staticRenderFns } from "./CreateSavedProduct.vue?vue&type=template&id=42adf208"
import script from "./CreateSavedProduct.vue?vue&type=script&lang=js"
export * from "./CreateSavedProduct.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports