<template>
  <dialog-form
    :value="value"
    @input="$emit('input', $event)"
    :allow-continue="false"
    :title="$localize('EditArticleCalculation.title')"
    @commit="save"
    @reset="articleCalculation = {}"
  >
    <v-text-field
      :label="$localize('ArticleCalculation.name')"
      v-model="articleCalculation.name"
      :hint="$localize('ArticleCalculation.name.hint')"
      :persistent-hint="true"
      prepend-icon="mdi-shape"
      autofocus
      :rules="[validation.required, validation.maxLength(50)]"
    />
    <select-prices v-model="selectedPrices" />
    <select-attributes v-model="selectedAttributes" />
  </dialog-form>
</template>

<script>
import DialogForm from "@/shared/components/DialogForm";

import SelectPrices from "@/main/components/forms/SelectPrices";
import SelectAttributes from "@/main/components/forms/SelectAttributes";

import sendCommand from "@/services/sendCommand";
import {
  getPriceTypes,
  getAttributeTypes,
  getCalculationType,
} from "@/services/api";
import { isGuid } from "@/services/validation"
import { required, maxLength } from "@/services/validation";
export default {
  components: { SelectPrices, SelectAttributes, DialogForm },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    articleCalculationId: {
      validator: isGuid,
      required: true,
    },
  },

  data() {
    return {
      articleCalculation: {},

      priceTypes: [],
      attributeTypes: [],

      selectedPrices: [],
      selectedAttributes: [],
      validation: { required, maxLength },
    };
  },

  async created() {
    this.priceTypes = await getPriceTypes.create().load();
    this.attributeTypes = await getAttributeTypes.create().load();

    this.articleCalculation = await getCalculationType
      .create({ articleCalculationId: this.articleCalculationId })
      .load();
    this.selectedPrices = this.articleCalculation.prices.map((x) => x.alias);
    this.selectedAttributes = this.articleCalculation.attributes.map(
      (x) => x.alias
    );
  },
  methods: {
    async save(callback) {
      const cmd = {
        articleCalculationId: this.articleCalculationId,
        name: this.articleCalculation.name,
        attributes: [...this.selectedPrices, ...this.selectedAttributes],
      };
      const result = await sendCommand("ModifyArticleCalculation", cmd);

      if (result.success) {
        this.$emit("saved");
      }

      callback({ success: result.success });
    },
  },
};
</script>
