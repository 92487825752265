<template>
    <dialog-form
        :value="value"
        @input="$emit('input', $event)"
        :title="$localize('ChangePaymentInformation.title')"
        :allow-continue="false"
        @commit="save"
        :loading="loading"
    >
        <v-text-field
            v-model="changePaymentInformation.companyName"
            :label="$localize('ChangePaymentInformation.companyName')"
            :rules="[validation.required]"
            autofocus
        />
        <v-text-field
            v-model="changePaymentInformation.name"
            :label="$localize('ChangePaymentInformation.name')"
            :rules="[validation.required]"
        />
        <v-text-field
            v-model="changePaymentInformation.email"
            :label="$localize('ChangePaymentInformation.email')"
            :rules="[validation.required]"
        />
        <v-text-field
            v-model="changePaymentInformation.street"
            :label="$localize('ChangePaymentInformation.street')"
            :rules="[validation.required]"
        />
        <v-text-field
            v-model="changePaymentInformation.zipCode"
            :label="$localize('ChangePaymentInformation.zipCode')"
            :rules="[validation.required]"
        />
        <v-text-field
            v-model="changePaymentInformation.city"
            :label="$localize('ChangePaymentInformation.city')"
            :rules="[validation.required]"
        />
    </dialog-form>
</template>

<script>
import DialogForm from '@/shared/components/DialogForm'

import sendCommand from '@/services/sendCommand'
import { getPaymentInformation } from '@/services/api'
import { required } from '@/services/validation'

export default {
    components: { DialogForm },

    props: {
        value: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            changePaymentInformation: {
                compnayName:'',
                name: '',
                email: '',
                street: '',
                zipCode: '',
                city: '',
            },
            validation: { required },
            loading: false,
        }
    },

    async created() {
        this.loading = true
        const { result, ok } = await getPaymentInformation.call()

        if (ok) {
            this.changePaymentInformation = {
                companyName: result.companyName,
                name: result.name,
                email: result.email,
                street: result.street,
                zipCode: result.zipCode,
                city: result.city,
            }
        }
        this.loading = false
    },
    methods: {
        async save(callback) {
            const result = await sendCommand(
                'ChangePaymentInformation',
                this.changePaymentInformation
            )

            if (result.success) {
                this.$emit('saved')
            }
            callback({ success: result.success })
        },
    },
}
</script>
