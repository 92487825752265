<template>
    <v-container fluid class="pa-0">
        <v-row>
            <v-col>
                <v-toolbar flat height="36" class="my-3">
                    <v-toolbar-title>{{
                        $localize('RelationOverview.title')
                    }}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-btn
                            v-if="$session.roles.includes('relations_manage')"
                            @click="navImport()"
                            class="mr-3 btnRelationImport"
                        >
                            <v-icon class="mr-1">mdi-database-import</v-icon>
                            {{ $localize('RelationOveriew.import') }}
                        </v-btn>
                        <v-btn
                            v-if="$session.roles.includes('relations_manage')"
                            class="primary btnCreateRelation"
                            @click="createRelation = true"
                        >
                            <v-icon class="mr-1">mdi-plus</v-icon>
                            {{
                                $localize(
                                    'RelationOverview.button.createRelation'
                                )
                            }}
                        </v-btn>
                    </v-toolbar-items>
                </v-toolbar>
            </v-col>
        </v-row>
        <v-row no-gutters>
            <v-col>
                <v-data-table
                    v-model="selectedRelations"
                    :show-select="$vuetify.breakpoint.smAndUp"
                    item-key="relationId"
                    :items="relations"
                    :headers="headers"
                    @click:row="navRelation($event)"
                    :options.sync="options"
                    :loading="isLoading"
                    :server-items-length="totalRelationCount"
                    :search="search"
                    :footer-props="{
                        'items-per-page-options': [20, 30, 40, 50],
                    }"
                    dense
                >
                    <template
                        v-if="$session.roles.includes('relations_manage')"
                        v-slot:footer="{}"
                    >
                        <v-divider />
                        <div class="d-flex flex-row py-1">
                            <template v-if="$vuetify.breakpoint.smAndUp">
                                <v-icon class="ml-3"
                                    >mdi-subdirectory-arrow-right</v-icon
                                >
                                <v-btn
                                    v-if="!isTrash"
                                    :disabled="selectedRelations.length === 0"
                                    class="align-self-center mr-1"
                                    @click="bulkTrash = true"
                                >
                                    <v-icon>mdi-delete</v-icon>
                                    <span v-if="$vuetify.breakpoint.lgAndUp">
                                        {{
                                            $localize('ArticleDataTable.trash')
                                        }}</span
                                    ></v-btn
                                >
                                <v-btn
                                    v-if="isTrash"
                                    :disabled="selectedRelations.length === 0"
                                    class="align-self-center mr-1"
                                    @click="bulkUntrash = true"
                                >
                                    <v-icon>mdi-delete-restore</v-icon>
                                    <span v-if="$vuetify.breakpoint.lgAndUp">
                                        {{
                                            $localize(
                                                'ArticleDataTable.untrash'
                                            )
                                        }}</span
                                    ></v-btn
                                >
                                <v-btn
                                    :disabled="selectedRelations.length === 0"
                                    class="align-self-center mr-1 me-auto"
                                    @click="bulkDelete = true"
                                >
                                    <v-icon>mdi-close</v-icon>
                                    <span v-if="$vuetify.breakpoint.lgAndUp">
                                        {{
                                            $localize('ArticleDataTable.delete')
                                        }}</span
                                    ></v-btn
                                >
                            </template>
                            <v-btn-toggle
                                v-model="customerOrSupplier"
                                class="mr-1"
                            >
                                <v-btn>
                                    {{
                                        $localize('RelationOverview.customers')
                                    }}
                                </v-btn>
                                <v-btn>
                                    {{
                                        $localize('RelationOverview.suppliers')
                                    }}
                                </v-btn>
                            </v-btn-toggle>
                            <v-checkbox
                                :label="$localize('form.label.trash')"
                                v-model="isTrash"
                                @change="navTrash()"
                                class="pt-0 pr-3 my-0 btnTrash align-self-center"
                                hide-details
                            />
                        </div>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>

        <create-relation
            v-if="createRelation"
            v-model="createRelation"
            @saved="refresh()"
        />
        <v-lazy :value="bulkTrash">
            <bulk-trash-relations
                v-model="bulkTrash"
                :relation-ids="selectedRelationIds"
                @saved="
                    selectedRelations = []
                    refresh()
                "
            />
        </v-lazy>
        <v-lazy :value="bulkUntrash">
            <bulk-untrash-relations
                v-model="bulkUntrash"
                :relation-ids="selectedRelationIds"
                @saved="
                    selectedRelations = []
                    refresh()
                "
            />
        </v-lazy>
        <v-lazy :value="bulkDelete">
            <bulk-delete-relations
                v-model="bulkDelete"
                :relation-ids="selectedRelationIds"
                @saved="
                    selectedRelations = []
                    refresh()
                "
            />
        </v-lazy>
    </v-container>
</template>

<script>
import { getRelationsPaged } from '@/services/api'
import CreateRelation from './dialogs/CreateRelation'
import BulkTrashRelations from './dialogs/BulkTrashRelations'
import BulkUntrashRelations from './dialogs/BulkUntrashRelations'
import BulkDeleteRelations from './dialogs/BulkDeleteRelations'

import PubSub from 'pubsub-js'
const relationTypes = ['customers', 'suppliers']
const relationTypeIndices = { customers: 0, suppliers: 1 }
export default {
    props: {
        type: {
            type: String,
            default: 'customers',
        },
        trash: String,
    },
    components: {
        CreateRelation,
        BulkTrashRelations,
        BulkUntrashRelations,
        BulkDeleteRelations,
    },
    data() {
        return {
            createRelation: false,
            headers: [
                {
                    text: this.$localize('Relation.companyName'),
                    value: 'companyName',
                },
                { text: this.$localize('Relation.website'), value: 'website' },
                { text: this.$localize('Relation.email'), value: 'email' },
                {
                    text: this.$localize('Relation.phone'),
                    value: 'phoneNumber',
                },
            ],
            relations: [],
            totalRelationCount: 0,
            options: JSON.parse(
                localStorage.getItem('RelationOverview:options')
            ) || { itemsPerPage: 20 },
            isLoading: false,
            isTrash: this.trash === 'trash',
            customerOrSupplier: relationTypeIndices[this.type],
            search: '',
            selectedRelations: [],
            bulkTrash: false,
            bulkUntrash: false,
            bulkDelete: false,
        }
    },
    computed: {
        selectedRelationIds() {
            return this.selectedRelations.map((x) => x.relationId)
        },
    },
    watch: {
        options(options, oldOptions) {
            localStorage.setItem(
                'RelationOverview:options',
                JSON.stringify(this.options)
            )
            if (
                oldOptions.page !== options.page ||
                oldOptions.itemsPerPage !== options.itemsPerPage ||
                oldOptions.sortBy !== options.sortBy ||
                oldOptions.sortDesc !== options.sortDesc
            ) {
                this.refresh()
            }
        },
        customerOrSupplier() {
            this.$router.push({
                name: 'relationOverview',
                params: {
                    type: relationTypes[this.customerOrSupplier],
                    trash: this.trash,
                },
            })
        },
    },
    created() {
        this.unsubscribe = PubSub.subscribe('search', (message, data) => {
            if (data.mode === 'page') {
                this.search = data.keyword
            } else {
                this.search = ''
            }
            this.refresh()
        })
        PubSub.publish('searchstatus')
    },
    methods: {
        async refresh() {
            let cancelLoading = setTimeout(() => {
                this.isLoading = true
            }, 200)
            const q = {
                ...this.options,
                search: this.search,
                trash: this.isTrash,
                customers: this.type === 'customers',
                suppliers: this.type === 'suppliers',
            }
            const { result, ok } = await getRelationsPaged.call({ q })
            if (ok) {
                this.relations = result.items
                this.totalRelationCount = result.totalItemCount
            }
            if (cancelLoading) {
                clearTimeout(cancelLoading)
                this.isLoading = false
            }
        },
        navRelation(relation) {
            this.$router.push({
                name: 'contactOverview',
                params: { relationId: relation.relationId },
            })
        },
        navTrash() {
            if (this.trash !== 'trash') {
                this.$router.push({
                    name: 'relationOverview',
                    params: { type: this.type, trash: 'trash' },
                })
            } else {
                this.$router.push({
                    name: 'relationOverview',
                    params: { type: this.type, trash: null },
                })
            }
        },
        navImport() {
            this.$router.push({
                name: 'relationImport',
            })
        },
    },
    beforeDestroy() {
        PubSub.unsubscribe(this.unsubscribe)
    },
}
</script>
<style lang="scss" scoped>
.v-btn-toggle:not(.v-btn-toggle--dense) .v-btn.v-btn.v-size--default {
    height: 36px;
}
</style>
