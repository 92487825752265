<template>
	<dialog-form
		form="formCreateAddress"
		:value="value"
		@input="$emit('input', $event)"
		@commit="save"
		:commit-message="$localize('CreateAddress.message.saved')"
		@reset="createAddress = {}"
		:title="$localize('CreateAddress.title')"
		:allow-continue="allowContinue"
	>
		<v-select
			:label="$localize('Address.typeName')"
			v-model="createAddress.type"
			:items="addressTypes"
			item-text="name"
			item-value="key"
			:rules="[validation.required]"
			prepend-icon="mdi-map-marker-question"
			:loading="loading"
			class="selectAddressType"
		/>
		<v-text-field
			:label="$localize('Address.location')"
			v-model="createAddress.location"
			:rules="[validation.required, validation.maxLength(100)]"
			prepend-icon="mdi-home-city"
			:loading="loading"
			class="inputLocation"
		/>
		<v-text-field
			:label="$localize('Address.street')"
			v-model="createAddress.street"
			:rules="[validation.required, validation.maxLength(100)]"
			prepend-icon="mdi-map"
			class="inputStreet"
			autofocus
		/>
		<v-text-field
			:label="$localize('Address.extraAddressLine')"
			v-model="createAddress.extraAddressLine"
			:rules="[validation.maxLength(100)]"
			prepend-icon="mdi-map"
			class="inputExtraAddressLine"
		/>
		<v-text-field
			:label="$localize('Address.zipCode')"
			v-model="createAddress.zipCode"
			:rules="[validation.required, validation.maxLength(20)]"
			prepend-icon="mdi-compass"
			class="inputZipCode"
		/>
		<v-text-field
			:label="$localize('Address.city')"
			v-model="createAddress.city"
			:rules="[validation.required, validation.maxLength(100)]"
			prepend-icon="mdi-city"
			class="inputCity"
		/>

		<input-email v-model="createAddress.email" class="inputEmail" />

		<v-textarea
			:label="$localize('Address.note')"
			v-model="createAddress.note"
			:rules="[validation.maxLength(500)]"
			prepend-icon="mdi-note"
			class="inputNote"
		/>
	</dialog-form>
</template>

<script>
import DialogForm from '@/shared/components/DialogForm'

import { isGuid } from '@/services/validation'
import sendCommand from '@/services/sendCommand'
import { getAddressTypes, getRelationById } from '@/services/api'
import { required, email, maxLength } from '@/services/validation'

export default {
	components: { DialogForm },
	props: {
		relationId: {
			required: true,
			validator: isGuid,
		},
		value: {
			type: Boolean,
			default: false,
		},
		allowContinue: {
			type: Boolean,
			default: true,
		},
		name: {
			type: String,
			default: null,
		},
	},

	data() {
		return {
			loading: false,
			createAddress: {
				// Define properties for reactivity
				name: this.name,
				type: null,
				location: null,
			},
			addressTypes: [],
			validation: { required, email, maxLength },
		}
	},
	async created() {
		this.loading = true

        this.loadAddressTypes();
		// set default location to company name
		const { result, ok } = await getRelationById.call({
			args: { relationId: this.relationId },
		})
		if (ok) {
			this.createAddress.location = result.companyName
		}
        this.createAddress.type = '$company'
		this.loading = false
	},
	methods: {
		async loadAddressTypes() {
			const { result, ok } = await getAddressTypes.call()
			if (ok) {
				this.addressTypes = result
			}
		},
		async save(callback) {
			this.createAddress.relationId = this.relationId
			const result = await sendCommand(
				'CreateAddress',
				this.createAddress
			)
			if (result.success) {
				this.$emit('saved', result)
			}
			callback({ success: result.success })
		},
	},
}
</script>
