<template>
	<div>
		<v-toolbar flat class="my-3" height="36">
			<v-toolbar-title>{{
				$localize('Permissions.title')
			}}</v-toolbar-title>

			<v-spacer></v-spacer>
		</v-toolbar>
		<v-alert type="info">
			{{ $localize('Permissions.explanation') }}
		</v-alert>
		<v-data-table
			:items="permissions"
			:headers="headers"
			:loading="loading"
			:footer-props="{
				'items-per-page-options': [10, 20, 50, -1],
			}"
			:options.sync="options"
			dense
		>
			<template v-slot:[`item.name`]="{ item }">
				{{ $localize(`Permissions.${item.permissionKey}.name`) }}
			</template>
			<template v-slot:[`item.permission`]="{ item, header }">
				<v-switch
					v-model="header.user.permissions"
					:value="item.permissionKey"
					dense
					hide-details
					@change="permissionChanged(header.user)"
					:disabled="
						item.permissionKey === 'settings_rights' &&
						header.user.userId === $session.userId
					"
				/>
			</template>
		</v-data-table>
	</div>
</template>

<script>
import { getUsers, getPermissions } from '@/services/api'
import sendCommand from '@/services/sendCommand'
export default {
	data() {
		return {
			users: [],
			permissions: [],
			headers: [],
			baseHeaders: [{ text: '', value: 'name' }],
			loading: false,
			options: JSON.parse(
				localStorage.getItem('Permissions:options')
			) || {
				itemsPerPage: -1,
			},
		}
	},
	watch: {
		options() {
			localStorage.setItem(
				'Permissions:options',
				JSON.stringify(this.options)
			)
		},
	},
	async created() {
		this.loading = true
		await this.loadUsers()
		await this.loadPermissions()

		this.headers = []
		for (const index in this.baseHeaders) {
			this.headers.push(this.baseHeaders[index])
		}
		for (const index in this.users) {
			const user = this.users[index]
			this.headers.push({
				text: user.name,
				value: 'permission',
				sortable: false,
				user: user,
			})
		}
		this.loading = false
	},
	methods: {
		async loadUsers() {
			const { result, ok } = await getUsers.call()
			if (ok) {
				this.users = result
			}
		},
		async loadPermissions() {
			const { result, ok } = await getPermissions.call()
			if (ok) {
				this.permissions = result
			}
		},
		async permissionChanged(user) {
			await sendCommand('SetUserPermissions', {
				userId: user.userId,
				permissions: user.permissions,
			})
		},
	},
}
</script>

<style scoped>
.v-input--selection-controls {
	margin-top: 0;
}
</style>
