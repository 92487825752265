<template>
    <v-chip small @click="openFile">
        <v-icon small>{{ icon }}</v-icon>
        {{ metadata.originalFileName }}
    </v-chip>
</template>
<script>
import { getFile } from '@/services/api'
import { isGuid } from '@/services/validation'
export default {
    props: {
        fileId: {
            validator: isGuid,
            required: true,
        },
    },
    data() {
        return {
            metadata: {},
        }
    },
    computed: {
        icon() {
            const contentType = this.metadata.contentType
            if (contentType) {
                if (contentType.startsWith('image')) {
                    return 'mdi mdi-image'
                }

                switch (contentType) {
                    case 'application/pdf':
                        return 'mdi mdi-adobe-acrobat'
                }
            }
            return 'mdi mdi-attachment'
        },
    },
    async created() {
        const { result, ok } = await getFile.call({
            args: { fileId: this.fileId },
            q: { meta: true },
        })
        if (ok) {
            this.metadata = result
        }
    },
    methods: {
        openFile() {
            const url = getFile.create({ fileId: this.fileId }).url

            window.open(url)
        },
    },
}
</script>
<style scoped></style>
