<template>
    <dialog-form
        :value="value"
        @input="$emit('input', $event)"
        :allow-continue="false"
        :title="$localize('EditAppointment.title')"
        @commit="save"
        @reset="editAppointment = {}"
        :loading="loading"
    >
        <v-text-field
            :label="$localize('Appointment.title')"
            v-model="editAppointment.title"
            :rules="[validation.required, validation.maxLength(100)]"
        />
        <v-textarea
            :label="$localize('Appointment.description')"
            v-model="editAppointment.description"
            :rules="[validation.maxLength(4000)]"
        />
        <select-user
            v-model="editAppointment.users"
            :multiple="true"
            :required="false"
            :label="$localize('Appointment.users')"
        />
        <select-labels v-model="editAppointment.labels" />
        <v-switch
            :label="$localize('Appointment.isEntireDay')"
            v-model="editAppointment.isEntireDay"
        />
        <date-time-select
            :label="$localize('Appointment.startdate')"
            v-model="editAppointment.startAt"
            :enable-time="!editAppointment.isEntireDay"
        />
        <date-time-select
            :label="$localize('Appointment.enddate')"
            v-model="editAppointment.endAt"
            :enable-time="!editAppointment.isEntireDay"
        />
        <v-chip v-for="(file, index) in existingFiles" :key="file.fileId">
            <span class="pr-2">
                {{ file.metadata.originalFileName }}
            </span>
            <v-icon small @click="removeExistingFile(index)">$delete</v-icon>
        </v-chip>
        <v-file-input
            :label="$localize('Appointment.files')"
            v-model="files"
            accept="image/png, image/jpeg, application/pdf"
            multiple
            chips
        >
            <template v-slot:selection="{ index, text }">
                <v-chip>
                    <span class="pr-2">
                        {{ text }}
                    </span>
                    <v-icon small @click.stop.prevent="removeFile(index)"
                        >$delete</v-icon
                    >
                </v-chip>
            </template>
        </v-file-input>
    </dialog-form>
</template>

<script>
import DialogForm from '@/shared/components/DialogForm'

import sendCommand from '@/services/sendCommand'
import DateTimeSelect from '@/shared/components/DateTimeSelect'
import SelectUser from '@/main/components/forms/SelectUser'
import SelectLabels from '@/main/components/forms/SelectLabels'
import { required, maxLength } from '@/services/validation'
import { isGuid } from '@/services/validation'
import { getAppointmentById, getFile } from '@/services/api'

import { formatISO, parseISO } from 'date-fns'
export default {
    components: { DateTimeSelect, SelectUser, DialogForm, SelectLabels },
    props: {
        value: {
            type: Boolean,
            default: false,
        },
        appointmentId: {
            validator: isGuid,
            required: true,
        },
    },

    data() {
        return {
            editAppointment: {},
            validation: { required, maxLength },
            existingFiles: [],
            files: [],
        }
    },
    computed: {
        allFiles() {
            return this.files.concat(this.uploadedFiles)
        },
    },

    async created() {
        this.loading = true
        const { result, ok } = await getAppointmentById.call({
            args: { appointmentId: this.appointmentId },
        })

        if (ok) {
            const appointment = result
            this.editAppointment = {
                appointmentId: appointment.appointmentId,
                projectId: appointment.projectId,
                startAt: appointment.startAt,
                endAt: appointment.endAt,
                title: appointment.title,
                description: appointment.description,
                users: appointment.users,
                labels: appointment.labels,
                isEntireDay: !appointment.isTimedEvent,
                files: appointment.files,
            }

            for (const index in appointment.files) {
                const fileId = appointment.files[index]
                const { result, ok } = await getFile.call({
                    args: { fileId: fileId },
                    q: { meta: true },
                })

                if (ok) {
                    this.existingFiles.push({
                        fileId: fileId,
                        metadata: result,
                    })
                }
            }
        }
        this.loading = false
    },
    methods: {
        dateInput(value) {
            if (!this.end.date) {
                this.end.date = value
            }
        },
        removeFile(index) {
            if (this.files.length > index) {
                this.files.splice(index, 1)
            }
        },
        removeExistingFile(index) {
            if (this.existingFiles.length > index) {
                this.existingFiles.splice(index, 1)
                this.editAppointment.files.splice(index, 1)
            }
        },
        async save(callback) {
            this.editAppointment.startAt = formatISO(
                parseISO(this.editAppointment.startAt)
            )
            this.editAppointment.endAt = formatISO(
                parseISO(this.editAppointment.endAt)
            )
            const formData = new FormData()
            for (const index in this.files) {
                const attachment = this.files[index]
                formData.append(attachment.name, attachment)
            }
            formData.append('command', JSON.stringify(this.editAppointment))

            const result = await sendCommand('EditAppointment', formData, {
                'Content-Type': 'multipart/form-data',
            })
            if (result.success) {
                this.$emit('saved')
            }
            callback({ success: result.success })
        },
    },
}
</script>
