<template>
	<span>
		<router-link
			v-if="$session.roles.includes('relations_view')"
			:to="{
				name: 'relationProjectOverview',
				params: { relationId: this.relationId },
			}"
			@click.native.stop
		>
			{{ text }}
		</router-link>
		<template v-else>{{ text }}</template>
	</span>
</template>
<script>
import { isGuid } from '@/services/validation'
export default {
	props: {
		relationId: {
			validator: isGuid,
			required: true,
		},
		text: String,
	},
}
</script>
