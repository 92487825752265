<template>
  <dialog-form
    :value="value"
    @input="$emit('input', $event)"
    :allow-continue="allowContinue"
    :title="title"
    @commit="save"
    @reset="createAttribute.name = ''"
    :commit-message="$localize('CreateAttribute.message.saved')"
  >
    <v-select
      v-if="attributeType === null"
      :items="types"
      v-model="createAttribute.isPrice"
      prepend-icon="mdi-format-list-bulleted"
      autofocus
    />

    <v-text-field
      :label="$localize('Attribute.displayName')"
      v-model="createAttribute.displayName"
      prepend-icon="mdi-text"
      :rules="[validation.required, validation.maxLength(50)]"
    />
    {{ alias }}
  </dialog-form>
</template>

<script>
import DialogForm from "@/shared/components/DialogForm";
import sendCommand from "@/services/sendCommand";
import { required, maxLength } from "@/services/validation";
export default {
  components: { DialogForm },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    allowContinue: {
      type: Boolean,
      default: true,
    },
    attributeType: {
      type: Boolean,
      default: null,
    },
  },

  data() {
    return {
      createAttribute: {
        isPrice: this.attributeType === null ? true : this.attributeType,
      },
      validation: { required, maxLength },

      types: [
        { text: this.$localize("Attribute.type.price"), value: true },
        { text: this.$localize("Attribute.type.attribute"), value: false },
      ],
    };
  },
  computed: {
    alias() {
      if (this.createAttribute.displayName) {
        return this.createAttribute.displayName
          .replace(/ /g, "_")
          .replace(/[^\w]+/gi, "")
          .toLowerCase();
      }
      return "";
    },
    title() {
      if (this.attributeType === null) {
        return this.$localize("CreateAttribute.title");
      } else if (this.attributeType === true) {
        return this.$localize("CreateAttribute.title.prices");
      } else {
        return this.$localize("CreateAttribute.title.attributes");
      }
    },
  },
  methods: {
    async save(callback) {
      this.createAttribute.alias = this.alias;

      const result = await sendCommand("CreateAttribute", this.createAttribute);

      if (result.success) {
        this.$emit("saved", this.createAttribute.alias);
      }

      callback({ success: result.success });
    },
  },
};
</script>