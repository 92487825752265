<template>
	<dialog-form
		:value="value"
		@input="$emit('input', $event)"
		:title="
			$localize('BulkUpdateCategories.title', {
				count: articleIds.length,
			})
		"
		@commit="save"
		:commit-message="$localize('BulkUpdateCategories.commitMessage')"
		:allow-continue="false"
	>
		<v-combobox
			v-model="category"
			:items="categories"
			:label="$localize('Article.category')"
			prepend-icon="mdi-group"
			@input.native="setCategory"
			:rules="[validation.maxLength(50)]"
			autofocus
		/>
	</dialog-form>
</template>

<script>
import DialogForm from '@/shared/components/DialogForm'
import sendCommand from '@/services/sendCommand'
import { getArticleCategories } from '@/services/api'
import { maxLength } from '@/services/validation'
export default {
	components: { DialogForm },
	props: {
		value: {
			type: Boolean,
			default: false,
		},
		articleIds: {
			type: Array,
			default: () => [],
		},
	},
	data() {
		return {
			categories: [],
			validation: { maxLength },
			category: null,
		}
	},
	async created() {
		const { result, ok } = await getArticleCategories.call()
		if (ok) {
			this.categories = result
		}
	},
	methods: {
		setCategory(e) {
			if (!e) {
				this.category = ''
			} else if (typeof e === 'object') {
				this.category = e.srcElement.value
			}
		},
		async save(callback) {
			const result = await sendCommand('BulkUpdateCategory', {
				articleIds: this.articleIds,
				category: this.category,
			})
			if (result.success) {
				this.$emit('saved')
			}
			callback({ success: result.success })
		},
	},
}
</script>
