<template>
    <dialog-form
        :value="value"
        @input="$emit('input', $event)"
        :title="$localize('ConfirmStatus.title')"
        :allow-continue="false"
        @commit="save"
    >
        <v-textarea
            v-model="note"
            :label="$localize('ChangeStatusStep.label.note')"
            :rules="[validation.maxLength(2000)]"
        ></v-textarea>
        <template slot="actions-right">
            <v-btn
                type="button"
                class="success mt-3 btnSave"
                @click="saveAndSendUpdate"
            >
                {{ $localize('ConfirmStatus.button.saveAndSendUpdate') }}
            </v-btn>
        </template>
    </dialog-form>
</template>

<script>
import { isGuid } from '@/services/validation'
import sendCommand from '@/services/sendCommand'
import DialogForm from '@/shared/components/DialogForm'
import { maxLength } from '@/services/validation'
export default {
    components: { DialogForm },
    props: {
        value: {
            type: Boolean,
            default: false,
        },
        projectId: {
            validator: isGuid,
            required: true,
        },
        projectStatus: {
            type: Object,
            required: true,
        },
        targetPhase: {
            type: String,
            required: true,
        },
        existingNote: {
            type: String,
        },
    },
    data() {
        return {
            note: this.existingNote,
            validation: { maxLength },
        }
    },
    methods: {
        async save(callback) {
            const result = await sendCommand('UpdateProjectStatus', {
                projectId: this.projectId,
                projectStatusId: this.projectStatus.projectStatusId,
                phase: this.targetPhase,
                note: this.note,
            })

            if (result.success) {
                this.$emit('saved', false)
            }

            callback({ success: result.success })
        },
        async saveAndSendUpdate() {
            const result = await sendCommand('UpdateProjectStatus', {
                projectId: this.projectId,
                projectStatusId: this.projectStatus.projectStatusId,
                phase: this.targetPhase,
                note: this.note,
            })

            if (result.success) {
                this.$emit('saved', true)
            }

        },
    },
}
</script>
