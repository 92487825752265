<script>
import Login from './Login'
import AlreadyLoggedIn from './AlreadyLoggedIn'

export default {
  render(createElement){
    if(this.$session.isLoggedIn){
      return createElement(AlreadyLoggedIn);
    }
    else{
      return createElement(Login);
    }
  }
}
</script>