<template>
	<v-layout align-center justify-center>
		<v-flex style="max-width: 400px">
			<h1 class="white--text">{{ $localize('Login.subtitle.login') }}</h1>
			<v-form @submit.prevent="login" ref="loginForm">
				<v-card class="pa-3">
					<v-card-subtitle
						class="elevation-2 mb-3"
						v-if="$route.query.message"
						>{{ $route.query.message }}</v-card-subtitle
					>
					<v-card-subtitle class="elevation-2 mb-3" v-if="message">{{
						message
					}}</v-card-subtitle>
					<v-card-text>
						<v-text-field
							:label="$localize('Login.field.login')"
							v-model="loginCommand.login"
							:rules="[validation.required]"
							outlined
							hide-details
							class="mb-3"
							autofocus
							id="username"
						/>
						<v-text-field
							:label="$localize('Login.field.password')"
							type="password"
							v-model="loginCommand.password"
							:rules="[validation.required]"
							outlined
							hide-details
							id="password"
						/>
					</v-card-text>
					<v-card-actions>
						<v-btn
							type="submit"
							class="success"
							:loading="processing"
							>{{ $localize('Login.button.login') }}</v-btn
						>
					</v-card-actions>
					<v-card-text>
						<v-divider />
					</v-card-text>
					<v-card-actions>
						<a @click="$router.push({ name: 'forgotPassword' })">{{
							$localize('Login.link.forgotPassword')
						}}</a>
					</v-card-actions>
					<app-installer />
				</v-card>
			</v-form>
		</v-flex>
	</v-layout>
</template>

<script>
import sendCommand from '@/services/sendCommand'
import { currentSession } from '@/services/session'
import { required } from '@/services/validation'
import AppInstaller from './AppInstaller'

export default {
	components: { AppInstaller },
	data() {
		return {
			loginCommand: {},
			message: null,
			validation: { required },
			processing: false,
		}
	},
	methods: {
		async login() {
			if (!this.$refs.loginForm.validate()) return
			this.processing = true

			this.pleaseWait = setTimeout(() => {
                this.message = this.$localize('Login.pleaseWait');
			}, 10000)
			const response = await sendCommand('UserLogin', this.loginCommand)
            clearTimeout(this.pleaseWait);

			if (response.success) {
				var session = await currentSession(true)
				if (session.isAdministrator) {
					this.$router.push({ name: 'adminHome' })
				} else {
					this.$router.push({ name: 'home' })
				}

				this.loginCommand = {}
			} else {
				this.message = response.message
				this.loginCommand.password = ''
			}

			this.processing = false
		},
	},
}
</script>

<style lang="scss" scoped>
.v-card__actions {
	justify-content: center;
}
</style>
