<template>
  <div>
    <v-text-field
      filled
      :label="label"
      :hint="hint"
      :persistent-hint="true"
      :value="inputPreview"
      :readonly="true"
      @click="editing = true"
      prepend-icon="mdi-file-delimited"
    />
    <dialog-form
      v-model="editing"
      :title="$localize('CsvSettings.title')"
      @commit="save"
      :allow-continue="false"
    >
      <v-text-field
        outlined
        v-model="data.delimiter"
        :label="$localize('Settings.prop.csv.delimiter')"
        :hint="$localize('Settings.prop.csv.delimiter.hint')"
        :rules="[rules.required]"
        persistent-hint
      />
      <v-text-field
        outlined
        v-model="data.quote"
        :label="$localize('Settings.prop.csv.quote')"
        :hint="$localize('Settings.prop.csv.quote.hint')"
        :rules="[rules.required, rules.minLength(1), rules.maxLength(1)]"
        persistent-hint
      />
      <v-text-field
        outlined
        v-model="data.escape"
        :label="$localize('Settings.prop.csv.escape')"
        :hint="$localize('Settings.prop.csv.escape.hint')"
        :rules="[rules.required, rules.minLength(1), rules.maxLength(1)]"
        persistent-hint
      />
    </dialog-form>
  </div>
</template>

<script>
import DialogForm from "@/shared/components/DialogForm";
import { required, minLength, maxLength } from "@/services/validation";

export default {
  components: { DialogForm },
  props: {
    value: String,
    label: String,
    hint: String,
  },
  data() {
    return {
      editing: false,
      data: {},
      rules: { required, minLength, maxLength },
    };
  },
  computed: {
    inputPreview() {
      let preview = `delimiter ${this.data.delimiter} quote ${this.data.quote} escape ${this.data.escape}`

      return preview;
    }
  },
  watch: {
    value: {
      immediate: true,
      handler() {
        if (this.value) {
          this.data = JSON.parse(this.value);
        }
        if (Array.isArray(this.data)) {
          this.data = {}; // Remove this later
        }
        if (!this.data) {
          this.data = {};
        }
      }
    }
  },
  methods: {
    save(callback) {
      this.$emit("input", JSON.stringify(this.data));
      callback({ success: true });
    }
  }
};
</script>
