<template>
	<div>
		<v-toolbar flat class="my-3" height="36">
			<v-toolbar-title>{{
				$localize('SmtpSettings.title')
			}}</v-toolbar-title>

			<v-spacer></v-spacer>
			<v-toolbar-items>
				<v-btn class="primary" @click="createSmtpSetting = true">
					<v-icon class="mr-1">mdi-plus</v-icon>
					{{ $localize('SmtpSettings.button.create') }}</v-btn
				>
			</v-toolbar-items>
		</v-toolbar>

		<v-data-table
			:items="items"
			:headers="headers"
			:footer-props="{ 'items-per-page-options': [10, 20, 50, -1] }"
			:options.sync="options"
			dense
		>
			<template v-slot:[`item.secure`]="{ item }">
				<v-icon v-if="item.secure" class="success--text"
					>mdi-check</v-icon
				>
				<v-icon v-else class="error--text">mdi-close</v-icon>
			</template>

			<template v-slot:[`item.isVerified`]="{ item }">
				<v-icon v-if="item.isVerified" class="success--text"
					>mdi-check</v-icon
				>

				<v-tooltip v-else bottom>
					<template v-slot:activator="{ on, attrs }">
						<v-icon class="warning--text" v-bind="attrs" v-on="on"
							>mdi-alert</v-icon
						>
					</template>
					<span>{{
						$localize('SmtpSettings.tooltip.notVerified')
					}}</span>
				</v-tooltip>
			</template>

			<template v-slot:[`item.actions`]="{ item }">
				<table-toolbar>
					<v-btn
						small
						depressed
						class="mr-2"
						@click="verifySmtpSetting(item)"
					>
						<v-icon>mdi-email-check</v-icon>
					</v-btn>
					<v-btn
						small
						depressed
						class="mr-2"
						@click="editSmtpSetting(item)"
					>
						<v-icon>mdi-pencil</v-icon>
					</v-btn>
					<v-btn
						small
						depressed
						color="error"
						class="mr-2"
						@click="deleteSmtpSetting(item)"
					>
						<v-icon>mdi-close</v-icon>
					</v-btn>
				</table-toolbar>
			</template>
		</v-data-table>

		<create-smtp-setting v-model="createSmtpSetting" @saved="refresh" />
		<edit-smtp-setting
			v-if="editingSmtpSetting"
			v-model="editingSmtpSetting"
			:smtp-setting-id="editingSmtpSettingId"
			@saved="refresh"
		/>

		<verify-smtp-setting
			v-if="verifyingSmtpSetting"
			v-model="verifyingSmtpSetting"
			:smtp-setting-id="verifyingSmtpSettingId"
			@saved="refresh"
		/>

		<delete-smtp-setting
			v-if="deletingSmtpSetting"
			v-model="deletingSmtpSetting"
			:smtp-setting-id="deletingSmtpSettingId"
			@saved="refresh"
		/>
	</div>
</template>

<script>
import { getAllSmtpSettings } from '@/services/api'

import CreateSmtpSetting from './dialogs/CreateSmtpSetting'
import EditSmtpSetting from './dialogs/EditSmtpSetting'
import VerifySmtpSetting from './dialogs/VerifySmtpSetting'
import DeleteSmtpSetting from './dialogs/DeleteSmtpSetting'

export default {
	components: {
		CreateSmtpSetting,
		EditSmtpSetting,
		VerifySmtpSetting,
		DeleteSmtpSetting,
	},
	data() {
		return {
			createSmtpSetting: false,

			verifyingSmtpSetting: false,
			verifyingSmtpSettingId: null,

			editingSmtpSetting: false,
			editingSmtpSettingId: null,

			deletingSmtpSetting: false,
			deletingSmtpSettingId: null,

			items: [],
			headers: [
				{
					text: this.$localize('Settings.prop.smtp.fromAddress'),
					value: 'fromAddress',
				},
				{
					text: this.$localize('Settings.prop.smtp.host'),
					value: 'host',
				},
				{
					text: this.$localize('Settings.prop.smtp.port'),
					value: 'port',
				},
				{
					text: this.$localize('Settings.prop.smtp.secure'),
					value: 'secure',
				},
				{
					text: this.$localize('Settings.prop.smtp.isVerified'),
					value: 'isVerified',
				},
				{
					text: '',
					value: 'actions',
					width: 220,
				},
			],
			options: JSON.parse(
				localStorage.getItem('SmtpSettings:options')
			) || { itemsPerPage: -1 },
		}
	},
	created() {
		this.refresh()
	},
	watch: {
		options() {
			localStorage.setItem(
				'SmtpSettings:options',
				JSON.stringify(this.options)
			)
		},
	},

	methods: {
		async refresh() {
			const { result, ok } = await getAllSmtpSettings.call()
			if (ok) {
				this.items = result
			}
		},
		verifySmtpSetting(item) {
			this.verifyingSmtpSettingId = item.smtpSettingId
			this.verifyingSmtpSetting = true
		},
		editSmtpSetting(item) {
			this.editingSmtpSettingId = item.smtpSettingId
			this.editingSmtpSetting = true
		},
		deleteSmtpSetting(item) {
			this.deletingSmtpSettingId = item.smtpSettingId
			this.deletingSmtpSetting = true
		},
	},
}
</script>
