<template>
	<v-form ref="form">
		<v-card>
			<v-card-title>{{
				$localize('Calculation.button.addVariable')
			}}</v-card-title>
			<v-card-text>
				<v-text-field
					:label="$localize('Calculation.variable.name')"
					:hint="$localize('Calculation.variable.name.hint')"
					:persistent-hint="true"
					v-model="field.name"
					:rules="[validation.required]"
					@input="onInput"
				/>
				<v-row>
					<v-col>
						<v-text-field
							:label="$localize('Calculation.variable.key')"
							:hint="$localize('Calculation.variable.key.hint')"
							:persistent-hint="true"
							v-model="field.key"
							:rules="[validation.required]"
							:disabled="!isNew && !field.isNew"
						/>
					</v-col>
					<v-col
						v-if="!isNew && !field.isNew"
						align-self="center"
						cols="auto"
						class="ml-1"
					>
						<v-btn
							small
							outlined
							fab
							@click="renameVariable = true"
						>
							<v-icon>mdi-pencil</v-icon>
						</v-btn>
					</v-col>
				</v-row>
				<v-select
					:label="$localize('Calculation.inputField.type')"
					:hint="$localize('Calculation.inputField.type.hint')"
					:persistent-hint="true"
					:items="allTypes"
					v-model="field.type"
					:rules="[validation.required]"
				/>
			</v-card-text>
			<v-card-actions>
				<v-btn @click="save">{{ $localize('form.button.ok') }}</v-btn>
			</v-card-actions>
		</v-card>
		<rename-variable
			v-model="renameVariable"
			:calculation-id="calculationId"
			:variable-key.sync="field.key"
		/>
	</v-form>
</template>
<script>
import { getAllTypes } from '@/services/code'
import { required } from '@/services/validation'
import { normalizeVariableName } from '@/services/code'
import { isGuid } from '@/services/validation'
import RenameVariable from '../dialogs/RenameVariable'
export default {
	components: { RenameVariable },
	props: {
		value: {
			type: Object,
			default() {
				return {}
			},
		},
		calculationId: { validator: isGuid, required: true },
		fieldCount: Number,
	},
	data() {
		return {
			field: {
				key: this.value.key,
				name: this.value.name,
				type: this.value.type,
				modifier: this.value.modifier,
				isSystemDefined: this.value.isSystemDefined,
				isNew: this.value.isNew,
			},
			isNew: !this.value.key,
			validation: { required },

			renameVariable: false,
			allTypes: [],
		}
	},
	watch: {
		value() {
			this.field = {
				key: this.value.key,
				name: this.value.name,
				type: this.value.type,
				modifier: this.value.modifier,
				isSystemDefined: this.value.isSystemDefined,
				isNew: this.value.isNew,
			}
		},
	},
	created() {
		this.allTypes = getAllTypes()
	},
	methods: {
		onInput() {
			if (this.isNew) {
				this.field.key = this.field.name.replaceAll(' ', '_').toLowerCase()
			}
		},
		save() {
			if (!this.field.key) {
				this.field.key =
					'input_' +
					this.fieldCount +
					'_' +
					normalizeVariableName(this.field.name)
			}
			if (!this.$refs.form.validate()) {
				return
			}
			this.$emit('input', this.field)
			this.$emit('saved')
			this.field = {}
		},
	},
}
</script>
