import { showSnackbar, clearSnackbar } from './snackbars'
import { currentSession } from './session'
import { addGlobalHeader } from '@/services/endpoints/apiEndPoint'

const port = process.env.VUE_APP_API_PORT
const debug = false
let portPart = ':' + window.location.port
if (port) {
    portPart = ':' + port
}

let protocol = 'wss'
if (window.location.protocol === 'http:') {
    protocol = 'ws'
}

const url = `${protocol}://${window.location.hostname}${portPart}/api/stream`

let webSocket = null
let subscribers = {}
let subscribersById = {}
let attempt = 0
let forceClose = false
let nextSubscriptionId = 1

let globalIncomingHandler = null

function retryWait(attempt) {
    return Math.min(attempt * attempt * 500, 30000)
}
async function openWebsocket(reconnect) {
    if (debug) console.log('WebSocket opening...')
    if (forceClose) {
        if (debug) console.log('WebSocket force closed')
        return
    }

    var session = await currentSession()

    if (!session.isLoggedIn || !session.isTenant) {
        if (debug) console.log('Not logged in')
        return 
    }

    if (webSocket !== null && !reconnect) {
        // abort if a websocket is open and we're not reconnecting
        // This allow calls to tryOpenWebsocket even when a websocket is already open
        if (debug) console.log('WebSocket already open')
        return
    }

    attempt++
    webSocket = new WebSocket(url)
    webSocket.onmessage = onMessageReceived
    webSocket.onclose = () => {
        if (debug) console.log('WebSocket closed')
        const wait = retryWait(attempt)
        if (debug) console.log(`Reconnecting attempt ${attempt} in ${wait}`)
        setTimeout(async () => await openWebsocket(true), wait)
    }
    webSocket.onerror = onError
    webSocket.onopen = onOpen
}

export function tryOpenWebsocket() {
    forceClose = false
    openWebsocket(false)
}
export async function closeWebsocket() {
    if (webSocket !== null) {
        forceClose = true
        webSocket.onclose = null
        webSocket.onerror = null
        webSocket.onopen = null
        webSocket.onmessage = null
        webSocket.close()
        webSocket = null
    }
}

function onMessageReceived(webSocketMessage) {
    const payload = JSON.parse(webSocketMessage.data)
    const message = payload.message
    const messageType = payload.type

    if (
        message.info &&
        message.info.position &&
        message.info.position.commitPosition &&
        message.info.position.preparePosition
    ) {
        addGlobalHeader(
            'Iris-Position',
            `${message.info.position.commitPosition}/${message.info.position.preparePosition}`
        )
    }
    if (globalIncomingHandler !== null) {
        globalIncomingHandler(message, messageType)
    }

    const subscriptionsByMessageType = subscribers[messageType]
    const handlers = Object.keys(subscriptionsByMessageType).map(
        (x) => subscriptionsByMessageType[x]
    )
    if (Array.isArray(handlers)) {
        for (var index in handlers) {
            var handler = handlers[index].handler
            var filter = handlers[index].filter

            if (!filter || filter(message)) {
                handler(message, messageType)
            }
        }
    } else {
        console.warn(
            `Received a message of type: ${messageType}, but no subscription was active.`
        )
    }
}

function onOpen() {
    window.ws = { socket: webSocket, subs: subscribers }
    if (attempt > 1) {
        showSnackbar({ text: `We zijn weer online`, color: 'success' })
    } else {
        clearSnackbar()
    }

    attempt = 0
    for (var messageType in subscribers) {
        addSubscriber(messageType)
    }
}
function onError() {
    showSnackbar({ text: `Verbinding verbroken`, color: 'error', timeout: 0 })
}
function addSubscriber(messageType) {
    var subscribeMessage = {
        action: 'subscribe',
        type: messageType,
    }

    webSocket.send(JSON.stringify(subscribeMessage))
}

function removeSubscriber(messageType) {
    if (webSocket && webSocket.readyState === 1) {
        var unsubscribeMessage = {
            action: 'unsubscribe',
            type: messageType,
        }

        webSocket.send(JSON.stringify(unsubscribeMessage))
    }
}

export function subscribe(messageType, handler, filter) {
    let subscriptionId = 'h' + nextSubscriptionId++
    if (typeof subscribers[messageType] !== 'object') {
        subscribers[messageType] = {}
    }

    var subscriptionObject = { handler, filter, messageType }
    subscribers[messageType][subscriptionId] = subscriptionObject
    if (webSocket && webSocket.readyState === 1) {
        addSubscriber(messageType)
    }
    subscribersById[subscriptionId] = subscriptionObject
    return subscriptionId
}

export function unsubscribe(subscriptionId) {
    if (!subscriptionId) return
    const subscriptionObject = subscribersById[subscriptionId]
    if (!subscriptionObject) return

    if (subscriptionObject !== undefined) {
        delete subscribers[subscriptionObject.messageType][subscriptionId]
    }
    delete subscribersById[subscriptionId]
    removeSubscriber(subscriptionObject.messageType)
}

export function getInfo() {
    return { s: subscribers, w: webSocket, f: forceClose }
}

export function onGlobalIncoming(handler) {
    globalIncomingHandler = handler
    return () => (globalIncomingHandler = null)
}
