<template>
	<dialog-form
		id="formEditRelationNote"
		:value="value"
		@input="$emit('input', $event)"
		@commit="save"
		:title="$localize('EditRelationNote.title')"
		:allow-continue="false"
		:loading="loading"
	>
		<v-textarea
			label="Note"
			v-model="note"
			autofocus
			class="inputNote"
			:rules="[validation.required, validation.maxLength(2000)]"
		/>
	</dialog-form>
</template>

<script>
import DialogForm from '@/shared/components/DialogForm'

import sendCommand from '@/services/sendCommand'
import { getRelationNoteById } from '@/services/api'
import { isGuid } from '@/services/validation'
import { required, maxLength } from '@/services/validation'

export default {
	components: { DialogForm },
	props: {
		relationId: {
			required: true,
			validator: isGuid,
		},
		noteId: {
			required: false,
			validator: isGuid,
		},
		value: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			note: '',
			loading: false,
			validation: { required, maxLength },
		}
	},
	async created() {
		this.loading = true
		const { result, ok } = await getRelationNoteById
			.call({ args: { noteId: this.noteId } })

		if (ok) {
			this.note = result.note
		}
		this.loading = false
	},

	methods: {
		async save(callback) {
			const result = await sendCommand('EditRelationNote', {
				relationId: this.relationId,
				noteId: this.noteId,
				newNote: this.note,
			})
			if (result.success) {
				this.$emit('saved')
			}
			callback({ success: result.success })
		},
	},
}
</script>
