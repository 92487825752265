<template>
  <dialog-form
    :value="value"
    @input="$emit('input', $event)"
    :allow-continue="false"
    :title="$localize('SwitchProjectCustomer.title')"
    @commit="save"
    :action-text="$localize('SwitchProjectCustomer.button.save')"
    action-icon="mdi-account-edit"
  >
    <select-relation v-model="switchProjectCustomer.relationId" autofocus />
    <select-contact
      :relation-id="switchProjectCustomer.relationId"
      v-model="switchProjectCustomer.contactId"
    />
  </dialog-form>
</template>

<script>
import DialogForm from "@/shared/components/DialogForm";

import sendCommand from "@/services/sendCommand";
import { isGuid } from "@/services/validation"
import SelectContact from "@/main/components/forms/SelectContact";
import SelectRelation from "@/main/components/forms/SelectRelation";

export default {
  components: { SelectRelation, SelectContact, DialogForm },
  props: {
    projectId: {
      validator: isGuid,
      required: true
    },
    value: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      switchProjectCustomer: {
        projectId: this.projectId
      }
    };
  },

  methods: {
    async save(callback) {
      const result = await sendCommand(
        "SwitchProjectCustomer",
        this.switchProjectCustomer
      );
      if (result.success) {
        this.$emit("saved");
      }
      callback({ success: result.success });
    }
  }
};
</script>
