<template>
	<v-select
		:label="label || $localize('RelationGroup.name')"
		:items="relationGroups"
		v-model="selectedGroupId"
		@input="$emit('input', selectedGroupId)"
		item-text="name"
		item-value="relationGroupId"
		:persistent-hint="true"
		:autofocus="autofocus"
		:hint="hint"
		:filled="filled"
		prepend-icon="mdi-office-building"
		:loading="loading"
		class="selectRelationGroup"
	/>
</template>
<script>
import { getRelationGroups } from '@/services/api'
import { isGuid } from '@/services/validation'

export default {
	props: {
		value: {
			validator: isGuid,
		},
		autofocus: Boolean,
		excludeGroupId: {
			validator: isGuid,
		},
		hint: String,
		filled: Boolean,
		label: String,
	},
	data() {
		return {
			allRelationGroups: [],
			relationGroups: [],
			selectedGroupId: this.value,
			loading: false,
		}
	},
	watch: {
		value(val) {
			this.selectedGroupId = val
		},
		excludeGroupId() {
			this.refresh()
		},
	},
	async created() {
		this.loading = true
        const { result, ok } = await getRelationGroups.call()
		if (ok) {
			this.allRelationGroups = result
		}
		this.refresh()
		this.loading = false
	},
	methods: {
		refresh() {
			this.loading = true
			this.relationGroups = this.allRelationGroups.filter(
				(x) => x.relationGroupId !== this.excludeGroupId
			)
			this.loading = false
		},
	},
}
</script>
