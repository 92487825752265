<template>
	<div class="mt-3">
		<div v-if="prices.length > 1 || enableScales || forceEnableScales">
			<v-row>
				<v-col>{{ $localize('EnterPrice.label.fromAmount') }}</v-col>
				<v-col>{{ $localize('EnterPrice.label.price') }}</v-col>
				<v-col cols="auto" style="width: 64px"></v-col>
			</v-row>
			<v-row v-for="(price, index) in prices" :key="index">
				<v-col>
					<v-text-field
						v-model="price.fromAmount"
						@input="emit"
						type="number"
						hide-details
						outlined
						dense
						:rules="[rules.required]"
					/>
				</v-col>
				<v-col>
					<v-text-field
						v-model="price.price"
						@input="emit"
						type="number"
						hide-details
						outlined
						dense
						:rules="[rules.required]"
					/>
				</v-col>
				<v-col cols="auto">
					<v-btn color="error" fab small @click="removeScale(index)">
						<v-icon>mdi-close</v-icon>
					</v-btn>
				</v-col>
			</v-row>
			<v-row>
				<v-col></v-col>
				<v-col cols="auto">
					<v-btn small fab @click="addScale()">
						<v-icon>mdi-plus</v-icon>
					</v-btn>
				</v-col>
			</v-row>
		</div>
		<div v-else>
			<input-number
				:label="$localize('EnterPrice.label.price')"
				v-model="prices[0].price"
				@input="emit"
				dense
				outlined
			/>
			<v-btn @click="forceEnableScales = true" class="mt-3">{{
				$localize('EnterPrice.button.enablePriceScaling')
			}}</v-btn>
		</div>
	</div>
</template>

<script>
import { isGuid } from '@/services/validation'
import { required } from '@/services/validation'
import InputNumber from '@/main/components/forms/InputNumber'

export default {
	components: { InputNumber },
	props: {
		value: {
			type: Array,
			required: true,
		},
		relationGroupId: {
			validator: isGuid,
			required: true,
		},
	},

	data() {
		return {
			prices: [],
			forceEnableScales: false,
			enableScales: false,
			rules: { required },
		}
	},

	watch: {
		value: {
			immediate: true,
			handler() {
				this.prices = this.value
				if (!this.enableScales)
					this.enableScales = this.value.length > 1
				if (this.prices.length === 0 && !this.forceEnableScales) {
					// Add default scale that covers every range
					this.prices.push({
						relationGroupId: this.relationGroupId,
						fromAmount: 1,
					})
				}
			},
		},
	},

	methods: {
		emit() {
			this.$emit('input', this.prices)
		},
		addScale() {
			this.prices.push({
				relationGroupId: this.relationGroupId,
			})
			this.emit()
		},
		removeScale(index) {
			this.prices.splice(index, 1)
			this.emit()
		},
	},
}
</script>
