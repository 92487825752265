<template>
	<v-dialog :value="value" @input="$emit('input', $event)" max-width="600px">
		<v-card>
			<v-card-title>New tenant</v-card-title>
			<v-form @submit.prevent="save()" class="pa-3">
				<v-text-field label="Naam" v-model="createTenantCommand.name" />
				<v-text-field
					label="Domein"
					v-model="createTenantCommand.domain"
				/>
				<v-select
					v-model="createTenantCommand.language"
					:items="languages"
					label="Taal"
				/>

				<v-btn @click="$emit('input', false)" class="mt-3 mr-3">{{
					$localize('form.button.cancel')
				}}</v-btn>
				<v-btn type="submit" class="success mt-3 mr-3">{{
					$localize('form.button.save')
				}}</v-btn>
			</v-form>
		</v-card>
	</v-dialog>
</template>

<script>
import { getLanguages } from '@/services/api'
import sendCommand from '@/services/sendCommand'

export default {
	props: {
		value: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			createTenantCommand: {},
			languages: [],
		}
	},
	async created() {
		this.languages = await getLanguages.create().load()
	},
	methods: {
		async save() {
			const result = await sendCommand(
				'CreateTenant',
				this.createTenantCommand
			)
			if (result.success) {
				this.createTenantCommand = {}
				this.$emit('input', false)
				this.$emit('saved')
			}
		},
	},
}
</script>
