<template>
	<dialog-form
		id="formAssignRelationManager"
		:value="value"
		@input="$emit('input', $event)"
		@commit="save"
		:title="$localize('AssignRelationManager.title')"
		:allow-continue="false"
		:loading="loading"
	>
		<select-user v-model="managerId" autofocus />
	</dialog-form>
</template>

<script>
import DialogForm from '@/shared/components/DialogForm'
import SelectUser from '@/main/components/forms/SelectUser'

import sendCommand from '@/services/sendCommand'
import { getRelationById } from '@/services/api'
import { isGuid } from '@/services/validation'

export default {
	components: { DialogForm, SelectUser },
	props: {
		relationId: {
			required: true,
			validator: isGuid,
		},
		value: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			loading: false,
			relation: {},
			managerId: null,
		}
	},
	async created() {
		this.loading = true
		const { result, ok } = await getRelationById.call({
			args: { relationId: this.relationId },
		})
		if (ok) {
			this.relation = result
			this.managerId = this.relation.managerId
		}

		this.loading = false
	},
	methods: {
		async save(callback) {
			const result = await sendCommand('AssignRelationManager', {
				relationId: this.relation.relationId,
				managerId: this.managerId,
			})
			if (result.success) {
				this.$emit('saved')
			}
			callback({ success: result.success })
		},
	},
}
</script>
