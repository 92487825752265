<template>
    <div>
        <v-row class="project-header-row" no-gutters>
            <v-col>
                {{ title }}
            </v-col>
        </v-row>
        <v-skeleton-loader :loading="loading" type="list-item">
            <v-card v-if="offers.length > 0" flat class="pl-3 pt-3 pb-3">
                <v-flex>
                    <v-row no-gutters align="center" class="font-weight-bold">
                        <v-col>{{ $localize('Offer.created') }}</v-col>
                        <v-col>{{ $localize('Offer.expires') }}</v-col>
                        <v-col cols="auto" style="width: 185px"></v-col>
                    </v-row>
                    <v-row
                        v-for="offer in offers"
                        :key="offer.offerId"
                        no-gutters
                        align="center"
                    >
                        <v-col>{{ $format.shortDate(offer.offerDate) }}</v-col>
                        <v-col>{{ $format.shortDate(offer.expires) }}</v-col>
                        <v-col cols="auto" style="width: 185px">
                            <v-toolbar
                                flat
                                style="background: none"
                                height="28"
                            >
                                <v-spacer></v-spacer>
                                <v-toolbar-items>
                                    <v-btn
                                        x-small
                                        @click="downloadOffer(offer)"
                                    >
                                        <v-icon color="red" small
                                            >mdi-adobe-acrobat</v-icon
                                        >
                                    </v-btn>
                                    <v-btn
                                        v-if="
                                            $session.roles.includes(
                                                'offers_manage'
                                            )
                                        "
                                        x-small
                                        @click="
                                            sendOffer = true
                                            sendOfferId = offer.offerId
                                        "
                                    >
                                        <v-icon color="success" small
                                            >mdi-email-send</v-icon
                                        >
                                    </v-btn>
                                    <v-btn
                                        v-if="
                                            $session.roles.includes(
                                                'offers_manage'
                                            )
                                        "
                                        x-small
                                        @click="
                                            deleteOffer = true
                                            deleteOfferId = offer.offerId
                                        "
                                    >
                                        <v-icon color="error" small
                                            >mdi-close</v-icon
                                        >
                                    </v-btn>
                                </v-toolbar-items>
                            </v-toolbar>
                        </v-col>
                    </v-row>
                </v-flex>
            </v-card>
            <v-card flat class="text-center">
                <v-btn
                    v-if="$session.roles.includes('offers_manage')"
                    @click="createOffer = true"
                    class="ma-5"
                >
                    <v-icon color="success">mdi-offer</v-icon>
                    <span class="ml-1">{{
                        $localize('ProjectOffers.btn.createOffer')
                    }}</span>
                </v-btn>
            </v-card>
        </v-skeleton-loader>
        <create-offer
            v-if="createOffer"
            v-model="createOffer"
            :project-id="$route.params.projectId"
        />
        <delete-offer
            v-if="deleteOffer"
            v-model="deleteOffer"
            :project-id="$route.params.projectId"
            :offer-id="deleteOfferId"
        />
        <send-offer
            v-if="sendOffer"
            v-model="sendOffer"
            :project-id="$route.params.projectId"
            :offer-id="sendOfferId"
        />
    </div>
</template>

<script>
import { isGuid } from '@/services/validation'

import { getOffer, getProjectOffers } from '@/services/api'
import CreateOffer from '../dialogs/CreateOffer'
import DeleteOffer from '../dialogs/DeleteOffer'
import SendOffer from '../dialogs/SendOffer'
import { subscribe, unsubscribe } from '@/services/websocketHandler'

export default {
    components: { CreateOffer, DeleteOffer, SendOffer },
    props: {
        projectId: {
            validator: isGuid,
        },
        project: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            loading: false,
            createOffer: false,
            deleteOffer: false,
            deleteOfferId: null,
            sendOffer: false,
            sendOfferId: null,

            offers: [],
            subscription1: null,
            subscription2: null,
        }
    },
    computed: {
        title() {
            return (
                this.$localize('ProjectOffers.title') +
                ' ' +
                this.project.projectNumber
            )
        },
    },
    created() {
        this.refresh()

        this.subscription1 = subscribe(
            'OfferCreated',
            this.onOfferCreated,
            (x) => x.projectId === this.projectId
        )
        this.subscription2 = subscribe(
            'OfferDeleted',
            this.onOfferDeleted,
            (x) => x.projectId === this.projectId
        )
    },
    destroyed() {
        unsubscribe(this.subscription1)
        unsubscribe(this.subscription2)
    },
    methods: {
        onOfferCreated(message) {
            this.offers.push({
                projectId: message.projectId,
                offerId: message.offerId,
                userId: message.userId,
                expires: message.expires,
                offerDate: message.offerDate,
                sendHistory: [],
                url: message.physicalPath,
            })
        },
        onOfferDeleted(message) {
            this.offers = this.offers.filter(
                (x) => x.offerId !== message.offerId
            )
        },
        async refresh() {
            this.loading = true
            if (this.$session.roles.includes('offers_view')) {
                const { result, ok } = await getProjectOffers.call({
                    args: { projectId: this.$route.params.projectId },
                })
                if (ok) {
                    this.offers = result
                }
            }
            this.loading = false
        },
        downloadOffer(offer) {
            const url = getOffer.create({
                projectId: this.projectId,
                offerId: offer.offerId,
            }).url
            window.open(url)
        },
    },
}
</script>
<style lang="scss" scoped>
.v-btn:not(.v-btn--round).v-size--x-small {
    min-width: 28px;
    padding: 0;
}
</style>
