<template>
	<dialog-form
		:value="value"
		@input="$emit('input', $event)"
		:title="$localize('CreateProduct.title')"
		@commit="save"
		:commit-message="$localize('CreateProduct.message.saved')"
		@reset="createProduct = { discount: 0 }"
	>
		<v-autocomplete
			v-model="createProduct.savedProductId"
			:items="savedProducts"
			item-value="savedProductId"
			item-text="product"
			:label="$localize('CreateProduct.existingProduct')"
			@input="savedProductSelected"
			clearable
		/>

		<v-combobox
			:items="products"
			:label="$localize('Product.product')"
			v-model="createProduct.product"
			@input.native="setProduct"
			:rules="[validation.maxLength(200)]"
		/>
		<v-text-field
			:label="$localize('Product.description')"
			v-model="createProduct.description"
			:rules="[validation.maxLength(500)]"
		/>
		<input-number
			:label="$localize('Product.discount')"
			v-model="createProduct.discount"
			:rules="[validation.numberRange(5, 2)]"
		/>
		<input-number
			:label="$localize('Product.price')"
			v-model="createProduct.price"
			:rules="[validation.number]"
			persistent-hint
			:hint="$localize('Product.price.hint')"
		/>
		<v-textarea
			:label="$localize('Product.specifications')"
			v-model="createProduct.specifications"
			:rules="[validation.maxLength(2000)]"
			persistent-hint
			:hint="$localize('Product.specifications.hint')"
		/>
		<v-switch
			:label="$localize('Product.offerShowPrice')"
			v-model="createProduct.offerShowPrice"
			hide-details
		/>
		<v-row>
			<v-col>
				<v-switch
					:label="$localize('Product.offerShowParts')"
					v-model="createProduct.offerShowParts"
					hide-details
				/>
			</v-col>
			<v-col>
				<v-switch
					:label="$localize('Product.offerShowPartPrices')"
					v-model="createProduct.offerShowPartPrices"
					:disabled="
						!createProduct.offerShowParts ||
						!createProduct.offerShowPrice
					"
					hide-details
				/>
			</v-col>
		</v-row>
		<select-vat v-model="createProduct.vatPercentage" required />
	</dialog-form>
</template>

<script>
import DialogForm from '@/shared/components/DialogForm'
import SelectVat from '@/main/components/forms/SelectVat'
import InputNumber from '@/main/components/forms/InputNumber'

import sendCommand from '@/services/sendCommand'
import { required, number, maxLength, numberRange } from '@/services/validation'
import { isGuid } from '@/services/validation'
import { getProducts, getSavedProducts } from '@/services/api'

export default {
	components: { DialogForm, SelectVat, InputNumber },
	props: {
		value: {
			type: Boolean,
			default: false,
		},
		projectId: {
			validator: isGuid,
			required: true,
		},
	},

	data() {
		return {
			savedProducts: [],
			products: [],
			createProduct: {
				savedProductId: null,
				discount: 0,
				description: '',
				product: '',
				specifications:
					'Materiaal : \nAantal(len) : \nFormaat : \nAfwerking : ',
				price: null,
				offerShowParts: false,
				offerShowPartPrices: false,
				offerShowPrice: true,
				vatPercentage: null,
			},
			validation: { required, number, maxLength, numberRange },
		}
	},
	watch: {
		'createProduct.offerShowParts'() {
			if (!this.createProduct.offerShowParts) {
				this.createProduct.offerShowPartPrices = false
			}
		},
		'createProduct.offerShowPrice'() {
			if (!this.createProduct.offerShowPrice) {
				this.createProduct.offerShowPartPrices = false
			}
		},
	},
	async created() {
		const { result, ok } = await getSavedProducts.call()
		if (ok) {
			this.savedProducts = result
		}
	},
	methods: {
		async loadProducts() {
			const { result, ok } = await getProducts.call()
			if (ok) {
				this.products = result
			}
		},
		savedProductSelected(selectedProductId) {
			if (!selectedProductId) {
				this.createProduct.description = ''
				this.createProduct.discount = 0
				this.createProduct.product = ''
				this.createProduct.specifications = ''
				this.createProduct.price = null
			} else {
				const product = this.savedProducts.find(
					(x) => x.savedProductId === selectedProductId
				)
				this.createProduct.description = product.description
				this.createProduct.discount = product.discount
				this.createProduct.product = product.product
				this.createProduct.specifications = product.specifications
				this.createProduct.price = product.price
				this.createProduct.offerShowParts = product.offerShowParts
				this.createProduct.offerShowPartPrices =
					product.offerShowPartPrices
				this.createProduct.offerShowPrice = product.offerShowPrice
				if (
					this.createProduct.vatPercentage !== undefined ||
					this.createProduct.vatPercentage !== null
				) {
					this.createProduct.vatPercentage = product.vatPercentage
				}
			}
		},
		setProduct(e) {
			if (!e) {
				this.createProduct.product = ''
			} else if (typeof e === 'object') {
				this.createProduct.product = e.srcElement.value
			}
		},
		async save(callback) {
			this.createProduct.projectId = this.projectId
			const result = await sendCommand(
				'CreateProduct',
				this.createProduct
			)

			if (result.success) {
				this.$emit('saved')
			}
			callback({ success: result.success })
		},
	},
}
</script>
