<template>
	<dialog-form
		id="formCreateContact"
		:value="value"
		@input="$emit('input', $event)"
		@commit="save"
		:commit-message="$localize('CreateContact.message.saved')"
		:title="$localize('CreateContact.title')"
		:allow-continue="allowContinue"
		@reset="createContactCommand = { salutation: 'Dhr. /Mvr.' }"
	>
		<v-text-field
			:label="$localize('Contact.name')"
			v-model="createContactCommand.name"
			:rules="[validation.required, validation.maxLength(100)]"
			prepend-icon="mdi-account"
			class="inputName"
            autofocus
		/>
		<select-salutation v-model="createContactCommand.salutation" />
		<date-select
			v-model="createContactCommand.bornDate"
			:label="$localize('Contact.bornDate')"
			clearable
		/>

		<v-text-field
			:label="$localize('Contact.mobileNumber')"
			v-model="createContactCommand.mobileNumber"
			:rules="[validation.maxLength(50)]"
			prepend-icon="mdi-cellphone"
			class="inputMobileNumber"
		/>
		<v-text-field
			:label="$localize('Contact.alternativeNumber')"
			v-model="createContactCommand.alternativeNumber"
			:rules="[validation.maxLength(50)]"
			prepend-icon="mdi-phone-forward"
			class="inputAlternativeNumber"
		/>

		<input-email v-model="createContactCommand.email" class="inputEmail" />

		<v-text-field
			:label="$localize('Contact.website')"
			v-model="createContactCommand.website"
			:rules="[validation.maxLength(50)]"
			prepend-icon="mdi-web"
			class="inputWebsite"
		/>
		<v-text-field
			:label="$localize('Contact.position')"
			v-model="createContactCommand.position"
			:rules="[validation.maxLength(50)]"
			prepend-icon="mdi-account-question"
			class="inputPosition"
		/>
		<v-textarea
			:label="$localize('Contact.note')"
			v-model="createContactCommand.note"
			:rules="[validation.maxLength(2000)]"
			prepend-icon="mdi-note"
			class="inputNote"
		/>
	</dialog-form>
</template>

<script>
import DialogForm from '@/shared/components/DialogForm'
import SelectSalutation from '@/main/components/forms/SelectSalutation'

import { isGuid } from '@/services/validation'
import sendCommand from '@/services/sendCommand'
import DateSelect from '@/shared/components/DateSelect'
import { required, email, maxLength } from '@/services/validation'

export default {
	components: { DateSelect, DialogForm, SelectSalutation },
	props: {
		relationId: {
			required: true,
			validator: isGuid,
		},
		value: {
			type: Boolean,
			default: false,
		},
		allowContinue: {
			type: Boolean,
			default: true,
		},
		name: {
			type: String,
			default: null,
		},
	},

	data() {
		return {
			createContactCommand: {
                name: this.name,
				salutation: 'Dhr. /Mvr.',
			},
			validation: { required, email, maxLength },
		}
	},

	methods: {
		async save(callback) {
			this.createContactCommand.relationId = this.relationId
			const result = await sendCommand(
				'CreateContact',
				this.createContactCommand
			)
			if (result.success) {
				this.$emit('saved', result)
			}
			callback({ success: result.success })
		},
	},
}
</script>
