import Entry from '@/pages/Entry'

import Default from '@/pages/Default'
import Login from '@/pages/Login'
import ForgotPassword from '@/pages/ForgotPassword'
import RecoverPassword from '@/pages/RecoverPassword'


export default [
    {
        path: '/',
        component: Entry,
        children: [
            {
                name: 'login',
                path: '/login',
                component: Login
            },
            {
                name: 'forgotPassword',
                path: '/forgotPassword',
                component: ForgotPassword
            },
            {
                name: 'recoverPassword',
                path: '/recoverPassword',
                component: RecoverPassword
            },
            {
                name: 'default',
                path: '',
                component: Default
            }
        ]
    }
]