<template>
  <v-dialog :value="value" @input="$emit('input', $event)" max-width="600px">
    <v-card>
      <v-card-title>Delete tenant</v-card-title>
      <v-form id="formDeleteTenant" @submit.prevent="save()" class="pa-3">
        <v-btn @click="$emit('input', false)" class="mt-3 mr-3">{{
          $localize("form.button.cancel")
        }}</v-btn>
        <v-btn type="submit" class="error mt-3 mr-3 btnDelete">{{
          $localize("form.button.delete")
        }}</v-btn>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import sendCommand from "@/services/sendCommand";
import { isGuid } from "@/services/validation";

export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    tenantId: {
      validator: isGuid,
      required: true,
    },
  },
  methods: {
    async save() {
      const result = await sendCommand("DeleteTenant", {
        tenantId: this.tenantId,
      });
      if (result.success) {
        this.$emit("input", false);
        this.$emit("saved");
      }
    },
  },
};
</script>