<template>
	<dialog-form
		:value="value"
		@input="$emit('input', $event)"
		:title="$localize('PasteCode.title')"
		@commit="save"
		:allow-continue="false"
	>
		<v-textarea ref="textarea" v-model="currentCode"></v-textarea>
		<template v-slot:actions-right>
			<v-btn class="mt-3" @click="copyToClipboard">Kopieren</v-btn>
		</template>
	</dialog-form>
</template>
<script>
import DialogForm from '@/shared/components/DialogForm'

export default {
	components: { DialogForm },
	props: {
		value: {
			type: Boolean,
			default: false,
		},
		code: {
			type: String,
		},
	},
	data() {
		return {
			currentCode: this.code,
		}
	},
	methods: {
		async save(callback) {
			this.$emit('update:code', this.currentCode)
			callback({ success: true })
		},
        copyToClipboard(){
            const el = this.$refs.textarea.$refs.input;
            el.select();
            navigator.clipboard.writeText(el.value);
        }
	},
}
</script>
