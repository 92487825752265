<template>
	<dialog-form
		id="dialogCreateProject"
		:value="value"
		@input="$emit('input', $event)"
		max-width="700"
		:fullscreen="$vuetify.breakpoint.xsOnly"
		:title="$localize('CreateProject.title')"
		@commit="save"
		:commit-message="$localize('CreateProject.message.saved')"
		@reset="createProjectCommand = {}"
	>
		<v-text-field
			v-model="createProjectCommand.project"
			:rules="[validation.required, validation.maxLength(100)]"
			:label="$localize('Project.project')"
			prepend-icon="mdi mdi-rotate-3d-variant"
			autofocus
		/>

		<select-relation
			v-model="createProjectCommand.relationId"
			@input="focusRef('selectContact')"
		/>
		<select-contact
			ref="selectContact"
			:relation-id="createProjectCommand.relationId"
			v-model="createProjectCommand.contactId"
			@input="focusRef('deliveryMethod')"
		/>

		<select-delivery-method
			ref="deliveryMethod"
			v-model="createProjectCommand.deliveryMethod"
			:required="false"
		/>

		<select-address
			:label="$localize('Project.deliveryMontageAddress')"
			v-model="createProjectCommand.deliveryAddressId"
			:relation-id="createProjectCommand.relationId"
			:required="false"
		/>
		<select-contact
			:relation-id="createProjectCommand.relationId"
			v-model="createProjectCommand.deliveryContactId"
			:label="$localize('Project.deliveryMontageContact')"
            :required="false"
		/>
		<date-select
			v-model="createProjectCommand.productionDate"
			:clearable="true"
			:label="$localize('Project.productionDate')"
		/>
		<date-select
			v-model="createProjectCommand.deadline"
			:clearable="true"
			:label="$localize('Project.deadline')"
		/>
	</dialog-form>
</template>

<script>
import DialogForm from '@/shared/components/DialogForm'
import sendCommand from '@/services/sendCommand'
import DateSelect from '@/shared/components/DateSelect'
import { required, maxLength } from '@/services/validation'
import { format, startOfToday, addDays } from 'date-fns'
import { focusFirstDeep } from '@/services/domHelper'
import SelectContact from '@/main/components/forms/SelectContact'
import SelectRelation from '@/main/components/forms/SelectRelation'
import SelectAddress from '@/main/components/forms/SelectAddress'
import SelectDeliveryMethod from '@/main/components/forms/SelectDeliveryMethod'
import Vue from 'vue'

export default {
	components: {
		DateSelect,
		SelectContact,
		SelectRelation,
		SelectDeliveryMethod,
		SelectAddress,
		DialogForm,
	},
	props: {
		value: {
			type: Boolean,
			default: false,
		},
	},

	data() {
		return {
			createProjectCommand: {},
			validation: { required, maxLength },
			loading: false,
		}
	},
	async created() {
		this.loading = true
		if (this.$settings.productionLeadTime) {
			this.createProjectCommand.productionDate = format(
				addDays(startOfToday(), this.$settings.productionLeadTime || 0),
				'yyyy-MM-dd'
			)
		}
		this.loading = false
	},
	methods: {
		async save(callback, close) {
			const result = await sendCommand(
				'CreateProject',
				this.createProjectCommand
			)
			if (result.success) {
				this.$emit('saved')
				if (close) {
					Vue.nextTick(() => {
						Vue.nextTick(() => {
							this.$router.push({
								name: 'projectDetails',
								params: { projectId: result.generatedId },
							})
						})
					})
				}
			}

			callback({ success: result.success })
		},
		focusRef(ref) {
			const obj = this.$refs[ref]
			const el = obj.$el ?? obj
			Vue.nextTick(() => {
				focusFirstDeep(el)
			})
		},
	},
}
</script>
