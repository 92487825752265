<template>
  <dialog-form
    :value="value"
    @input="$emit('input', $event)"
    :title="$localize('BulkTrashArticles.title', { count: articleIds.length })"
    @commit="save"
    :commit-message="$localize('BulkTrashArticles.commitMessage')"
    :allow-continue="false"
  >
  </dialog-form>
</template>

<script>
import DialogForm from "@/shared/components/DialogForm";
import sendCommand from "@/services/sendCommand";
export default {
  components: { DialogForm },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    articleIds: {
        type: Array,
        default: () => []
    }
  },
  methods: {
    async save(callback) {
     const result = await sendCommand("BulkTrashArticles", {
        articleIds: this.articleIds
      });
      if (result.success) {
        this.$emit("saved");
      }
      callback({ success: result.success });
    },
  }
};
</script>
