<template>
	<dialog-form
		id="formEnterAddressInformation"
		:value="value"
		@input="$emit('input', $event)"
		@commit="save"
		:title="$localize('EnterAddressInformation.title')"
		:allow-continue="false"
		:loading="loading"
	>
		<v-select
			v-model="enterAddressInformation.type"
			:items="addressTypes"
			:label="$localize('Address.typeName')"
			item-text="name"
			item-value="key"
			:rules="[validation.required]"
			prepend-icon="mdi-map-marker-question"
			class="selectType"
		/>
		<v-text-field
			:label="$localize('Address.location')"
			v-model="enterAddressInformation.location"
			:rules="[validation.required, validation.maxLength(100)]"
			prepend-icon="mdi-home-city"
			class="inputLocation"
		/>
		<v-text-field
			:label="$localize('Address.street')"
			v-model="enterAddressInformation.street"
			:rules="[validation.required, validation.maxLength(100)]"
			prepend-icon="mdi-map"
			class="inputStreet"
		/>
		<v-text-field
			:label="$localize('Address.extraAddressLine')"
			v-model="enterAddressInformation.extraAddressLine"
			:rules="[validation.maxLength(100)]"
			prepend-icon="mdi-map"
			class="inputExtraAddressLine"
		/>
		<v-text-field
			:label="$localize('Address.zipCode')"
			v-model="enterAddressInformation.zipCode"
			:rules="[validation.required, validation.maxLength(20)]"
			prepend-icon="mdi-compass"
			class="inputZipCode"
		/>
		<v-text-field
			:label="$localize('Address.city')"
			v-model="enterAddressInformation.city"
			:rules="[validation.required, validation.maxLength(100)]"
			prepend-icon="mdi-city"
			class="inputCity"
		/>
		<input-email v-model="enterAddressInformation.email" />
		<v-textarea
			:label="$localize('Address.note')"
			v-model="enterAddressInformation.note"
			:rules="[validation.maxLength(500)]"
			prepend-icon="mdi-note"
			class="inputNote"
		/>
	</dialog-form>
</template>

<script>
import DialogForm from '@/shared/components/DialogForm'

import { isGuid } from '@/services/validation'
import { getAddressById, getAddressTypes } from '@/services/api'
import sendCommand from '@/services/sendCommand'
import { required, email, maxLength } from '@/services/validation'

export default {
	components: { DialogForm },
	props: {
		addressId: {
			required: true,
			validator: isGuid,
		},
		value: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			loading: false,
			enterAddressInformation: {},
			addressTypes: [],
			validation: { required, email, maxLength },
		}
	},
	async created() {
		this.loading = true
		const { result, ok } = await getAddressById.call({
			args: { addressId: this.addressId },
		})
		if (ok) {
			const address = result
			this.address = address
			this.enterAddressInformation = {
				addressId: this.addressId,
				type: address.typeKey,
				street: address.street,
				extraAddressLine: address.extraAddressLine,
				zipCode: address.zipCode,
				city: address.city,
				location: address.location,
				email: address.email,
				note: address.note,
			}
		}
        await this.loadAddressTypes()
		this.loading = false
	},
	methods: {
		async loadAddressTypes() {
			const { result, ok } = await getAddressTypes.call()
			if (ok) {
				this.addressTypes = result
			}
		},
		async save(callback) {
			const result = await sendCommand(
				'EnterAddressInformation',
				this.enterAddressInformation
			)
			if (result) {
				this.$emit('saved')
			}
			callback({ success: result.success })
		},
	},
}
</script>
