<template>
	<dialog-form
		:value="value"
		@input="$emit('input', $event)"
		:title="$localize('VerifySmtpSettings.title')"
		:allow-continue="false"
		hide-actions
	>
		<div
			v-if="
				!requestVerificationResult || !requestVerificationResult.success
			"
		>
			<v-alert type="info">{{
				$localize('VerifySmtpSetting.alert.chooseEmail')
			}}</v-alert>
			<v-text-field
				:label="$localize('Settings.prop.smtp.fromAddress')"
				v-model="smtpSetting.fromAddress"
				disabled
			/>
			<v-text-field label="Ontvanger adres" v-model="to"></v-text-field>
			<div class="text-center mb-3">
				<v-btn
					color="success"
					@click="requestSmtpSettingVerification"
					:loading="requestingVerification"
					>{{
						$localize('VerifySmtpSetting.button.sendTestEmail')
					}}</v-btn
				>
			</div>
			<v-alert
				v-if="
					requestVerificationResult &&
					!requestVerificationResult.success
				"
				type="error"
				>{{ requestVerificationResult.message }}</v-alert
			>
		</div>
		<div v-else>
			<v-alert type="info">{{
				$localize('VerifySmtpSetting.alert.verificationEmailSent', {
					to,
				})
			}}</v-alert>
			<v-text-field
				v-model="token"
				label="Verificatie code"
			></v-text-field>
			<div class="text-center mb-3">
				<v-btn color="success" @click="verify" :loading="verifying">{{
					$localize('forms.button.verify')
				}}</v-btn>
			</div>
			<v-alert
				type="warning"
				v-if="verificationResult && !verificationResult.success"
				>{{
					$localize('VerifySmtpSetting.alert.invalidVerificationCode')
				}}</v-alert
			>
			<div class="text-center">
				<a
					v-if="verificationResult && !verificationResult.success"
					@click="requestVerificationResult = null"
					>{{
						$localize(
							'VerifySmtpSetting.link.resendVerificationCode'
						)
					}}</a
				>
			</div>
		</div>
	</dialog-form>
</template>

<script>
import DialogForm from '@/shared/components/DialogForm'
import sendCommand from '@/services/sendCommand'
import { required } from '@/services/validation'
import { isGuid } from '@/services/validation'
import { getSmtpSettingById } from '@/services/api'

export default {
	components: { DialogForm },
	props: {
		value: {
			type: Boolean,
			default: false,
		},
		smtpSettingId: {
			validator: isGuid,
			required: true,
		},
	},
	data() {
		return {
			smtpSetting: {},
			to: '',
			rules: { required },
			testMailSend: false,
			requestingVerification: false,
			requestVerificationResult: null,
			verificationRequested: false,
			verifying: false,
			verificationResult: null,
			token: '',
		}
	},
	async created() {
		const { result, ok } = await getSmtpSettingById.call({
			args: { smtpSettingId: this.smtpSettingId },
		})

		if (ok) {
			this.smtpSetting = result
			this.to = this.smtpSetting.fromAddress
		}
	},
	methods: {
		async requestSmtpSettingVerification() {
			this.requestingVerification = true
			this.requestVerificationResult = await sendCommand(
				'RequestSmtpSettingVerification',
				{
					smtpSettingId: this.smtpSettingId,
					to: this.to,
				}
			)
			this.requestingVerification = false
			this.verificationRequested = true
		},
		async verify() {
			this.verifying = true
			this.verificationResult = await sendCommand('VerifySmtpSetting', {
				smtpSettingId: this.smtpSettingId,
				token: this.token,
			})
			if (this.verificationResult.success) {
				this.$emit('saved')
				this.$emit('input', false)
			}

			this.verifying = false
		},
	},
}
</script>
