<template>
    <v-container fluid class="pa-0">
        <v-row>
            <v-col>
                <v-toolbar flat height="36" class="my-3">
                    <v-toolbar-title>{{
                        $localize('SavedProductsOverview.title')
                        }}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-btn class="primary" @click="createSavedProduct = true">
                            <v-icon class="mr-1">mdi-plus</v-icon>
                            {{
                                $localize(
                            'SavedProductsOverview.button.createSavedProduct'
                            )
                            }}
                        </v-btn>
                    </v-toolbar-items>
                </v-toolbar>
            </v-col>
        </v-row>
        <v-row no-gutters>
            <v-col>
                <v-data-table :items="savedProducts" :headers="headers" @click:row="navSavedProductDetails"
                    :footer-props="{
                        'items-per-page-options': [10, 20, 50, -1],
                    }" :options.sync="options" dense>
                    <template v-slot:[`item.price`]="{ item }">{{
                        $format.currency(price(item))
                        }}</template>
                </v-data-table>
            </v-col>
        </v-row>
        <create-saved-product v-model="createSavedProduct" />
    </v-container>
</template>

<script>
import CreateSavedProduct from './dialogs/CreateSavedProduct.vue'
import { getSavedProducts } from '@/services/api'

export default {
    components: { CreateSavedProduct },
    data() {
        return {
            savedProducts: [],
            headers: [
                { text: this.$localize('Product.product'), value: 'product' },
                {
                    text: this.$localize('Product.description'),
                    value: 'description',
                },
                { text: this.$localize('Product.price'), value: 'price' },
            ],
            options: JSON.parse(
                localStorage.getItem('SmtpSettings:options')
            ) || { itemsPerPage: -1 },

            createSavedProduct: false,
        }
    },
    async created() {
        const { result, ok } = await getSavedProducts.call()
        if (ok) {
            this.savedProducts = result
        }
    },
    watch: {
        options() {
            localStorage.setItem(
                'SmtpSettings:options',
                JSON.stringify(this.options)
            )
        },
    },

    methods: {
        price(product) {
            let price
            if (product.price) {
                price = product.price
            } else {
                price = product.calculatedPrice + product.startingCost
                price = price * (1 - product.discount / 100)
            }
            return price
        },
        navSavedProductDetails(item) {
            this.$router.push({
                name: 'savedProductDetails',
                params: { savedProductId: item.savedProductId },
            })
        },
    },
}
</script>
