export function userNameInitials(userName) {
	if (userName && userName.length > 0) {
		const words = userName.split(' ')
		if (words.length > 1) {
			return (
				words[0].substring(0, 1) +
				words[words.length - 1].substring(0, 1)
			)
		} else {
			return words[0].substring(0, 1)
		}
	}
	return null
}
