<template>
	<v-container fluid class="pa-0">
		<v-row v-if="isDashboard" no-gutters>
			<v-col>
				<v-toolbar flat class="primary">
					<v-toolbar-title class="text-uppercase mr-3">
						<span class="font-weight-light white--text text-h3">{{
							dashboardName
						}}</span>
					</v-toolbar-title>
				</v-toolbar>
			</v-col>
		</v-row>
		<v-row no-gutters>
			<v-col>
				<v-data-table
					:headers="headers"
					:items="projects"
					item-class="text-no-wrap text-truncate"
					:loading="isLoading"
					@click:row="navProject($event)"
					:options.sync="options"
					:server-items-length="totalItemCount"
					:footer-props="{
						'items-per-page-options': [20, 30, 40, 50],
					}"
					@contextmenu:row="openContextMenu"
					dense
				>
					<template v-slot:[`item.created`]="{ item }">{{
						$format.shortDate(item.created)
					}}</template>
					<template v-slot:[`item.progress.created`]="{ item }">{{
						$format.shortDate(item.progress.created)
					}}</template>
					<template v-slot:[`item.productionDate`]="{ item }">
						<span
							v-if="item.productionDate"
							:class="getProjectColorClasses(item)"
							>{{ $format.shortDate(item.productionDate) }}</span
						>
					</template>
					<template v-slot:[`item.deadline`]="{ item }">
						<span
							v-if="item.deadline"
							:class="getProjectColorClasses(item)"
							>{{ $format.shortDate(item.deadline) }}</span
						>
					</template>
					<template v-slot:[`item.relationName`]="{ item }">
						<relation-link :relation-id="item.relationId" :text="item.relationName" />
					</template>
					<template v-slot:[`item.userName`]="{ item }">
						<router-link
							:to="{
								name: 'userDetails',
								params: { userId: item.userId },
							}"
							@click.native.stop
							>{{ item.userName }}</router-link
						>
					</template>
					<template v-slot:footer="{}">
						<v-divider />
						<v-row no-gutters class="mx-3">
							<v-col cols="auto">
								<v-checkbox
									:label="$localize('form.label.autoRefresh')"
									v-model="live"
									@change="setLive()"
									class="pt-0 my-1"
									hide-details
								/>
							</v-col>
							<v-col></v-col>
							<v-col cols="auto">
								<v-menu :close-on-content-click="false">
									<template v-slot:activator="{ on }">
										<v-btn v-on="on" fab x-small>
											<v-icon>mdi-cog</v-icon>
										</v-btn>
									</template>
									<v-list>
										<v-list-item
											v-for="header in allHeaders"
											:key="header.text"
											@click="toggleHeader(header)"
										>
											<v-list-item-action>
												<v-checkbox
													:input-value="
														activeHeaders[
															header.value
														]
													"
												/>
											</v-list-item-action>
											<v-list-item-content>
												<v-list-item-title>{{
													header.text
												}}</v-list-item-title>
											</v-list-item-content>
										</v-list-item>
									</v-list>
								</v-menu>
							</v-col>
						</v-row>
					</template>
				</v-data-table>
			</v-col>
		</v-row>
		<v-menu
			v-model="showContextMenu"
			v-if="contextMenuItem"
			absolute
			:position-x="contextMenuX"
			:position-y="contextMenuY"
			:transition="false"
		>
			<v-list>
				<template v-for="item in contextMenu">
					<v-list-item
						:key="item.value"
						v-if="typeof item.if !== 'function' || item.if()"
						@click="item.action()"
					>
						<v-list-item-icon>
							<v-icon
								:color="item.iconColor"
								v-if="typeof item.icon === 'function'"
								>{{ item.icon() }}</v-icon
							>
							<v-icon :color="item.iconColor" v-else>{{
								item.icon
							}}</v-icon>
						</v-list-item-icon>
						<v-list-item-title>{{ item.text }}</v-list-item-title>
					</v-list-item>
				</template>
			</v-list>
		</v-menu>
		<enter-project-information
			v-if="enterProjectInformation"
			v-model="enterProjectInformation"
			:project-id="contextMenuItem.projectId"
			@saved="refresh(true)"
		/>
		<pdf-exports
			v-if="exportPdfs"
			v-model="exportPdfs"
			:project-id="contextMenuItem.projectId"
		/>
		<change-status-wizard
			v-if="changeStatus"
			v-model="changeStatus"
			:project-id="contextMenuItem.projectId"
			@saved="refresh(true)"
		/>
	</v-container>
</template>

<script>
import PdfExports from './dialogs/PdfExports'
import ChangeStatusWizard from './dialogs/ChangeStatusWizard'
import EnterProjectInformation from './dialogs/EnterProjectInformation'
import RelationLink from '../components/links/RelationLink'

import {
	getProjectByPhase,
	getRelationProjects,
	getProjectsByUser,
	getProjectInDashboard,
	getDashboardById,
} from '@/services/api'
import { isBefore, parseISO } from 'date-fns'
import { isGuid } from '@/services/validation'
import PubSub from 'pubsub-js'

export default {
	components: {
		PdfExports,
		ChangeStatusWizard,
		EnterProjectInformation,
		RelationLink,
	},
	props: {
		phase: String,
		dashboardId: {
			validator: isGuid,
			default: null,
		},
		relationId: {
			validator: isGuid,
			default: null,
		},
		userId: {
			validator: isGuid,
			default: null,
		},
	},

	data() {
		return {
			allHeaders: [
				{
					text: this.$localize('Project.number'),
					value: 'projectNumber',
					width: 90,
				},
				{
					text: this.$localize('Project.addedOn'),
					value: 'created',
					width: 100,
				},
				{
					text: this.$localize('Project.customer'),
					value: 'relationName',
				},
				{
					text: this.$localize('Project.contact'),
					value: 'contactName',
				},
				{
					text: this.$localize('Project.project'),
					value: 'project',
				},
				{
					text: this.$localize('Project.status'),
					value: 'projectStatusName',
				},
				{
					text: this.$localize('Project.handler'),
					value: 'userName',
				},
				{
					text: this.$localize('Project.productionDate'),
					value: 'productionDate',
				},
				{
					text: this.$localize('Project.deadline'),
					value: 'deadline',
				},
				{
					text: this.$localize('Project.invoiceNumber'),
					value: 'fullInvoiceNumber',
				},
				{
					text: this.$localize('Project.projectStatusNote'),
					value: 'projectStatusNote',
					sortable: false,
				},
			],
			activeHeaders: {},
			defaultHeaders: {
				projectNumber: true,
				created: true,
				relationName: true,
				contactName: false,
				project: true,
				projectStatusName: true,
				userName: true,
				productionDate: true,
				deadline: true,
				invoiceDate: false,
				projectStatusNote: false,
			},
			projects: [],
			totalProjectCount: 0,
			search: '',
			options: JSON.parse(
				localStorage.getItem('ProjectOverview:options')
			) || {
				itemsPerPage: 20,
			},
			isLoading: false,

			showContextMenu: false,
			contextMenuItem: null,
			contextMenuX: null,
			contextMenuY: null,
			contextMenu: [
				{
					icon: 'mdi-sync',
					iconColor: 'success',
					text: this.$localize('ProjectDetails.button.changeStatus'),
					action: () => (this.changeStatus = true),
				},
				{
					icon: 'mdi-pencil',
					text: this.$localize('ProjectDetails.button.edit'),
					if: () => this.$session.roles.includes('projects_manage'),
					action: () => (this.enterProjectInformation = true),
				},
				{
					icon: 'mdi-cloud-download',
					iconColor: 'light-blue lighten-2',
					text: this.$localize('ProjectDetails.button.print'),
					action: () => (this.exportPdfs = true),
				},
			],
			enterProjectInformation: false,
			exportPdfs: false,
			changeStatus: false,

			unsubsribe: null,

			live: false,
			liveInterval: null,

			isDashboard: false,
			dashboardName: null,
		}
	},
	computed: {
		totalItemCount() {
			if (this.live) {
				return -1
			}
			return this.totalProjectCount
		},
		currentPage() {
			return parseInt(this.$route.query.page) || 1
		},
		headers() {
			return this.allHeaders
				.filter((x) => this.activeHeaders[x.value] === true)
				.map((x) => {
					return {
						...x,
						class: 'text-truncate',
						cellClass: 'text-truncate max200',
					}
				})
		},
		projectOverviewType() {
			if (this.dashboardId !== null) {
				return 'dashboard'
			} else if (this.relationId !== null) {
				return 'relation'
			} else if (this.userId !== null) {
				return 'user'
			} else {
				return this.phase
			}
		},
	},
	created() {
		this.activeHeaders =
			JSON.parse(
				localStorage.getItem(
					`ProjectOverview:${this.projectOverviewType}:headers`
				)
			) || this.defaultHeaders
		this.options.page = this.currentPage
		this.unsubscribe = PubSub.subscribe('search', (message, data) => {
			if (data.mode === 'page') {
				this.search = data.keyword
			} else {
				this.search = ''
			}
			this.refresh()
		})
		this.live = this.dashboardId !== null
		PubSub.publish('searchstatus')
	},
	destroyed() {
		PubSub.unsubscribe(this.unsubscribe)
		if (this.liveInterval) {
			clearInterval(this.liveInterval)
		}
	},
	watch: {
		$route() {
			this.refresh()
		},
		options(options, oldOptions) {
			// save current settings
			localStorage.setItem(
				'ProjectOverview:options',
				JSON.stringify(this.options)
			)

			// Don't add querystring in relations page
			if (this.options.page != this.currentPage && !this.relationId) {
				// if diffrent page, go to that page
				this.$router.push({
					query: {
						page: this.options.page,
					},
				})
			} else {
				if (
					oldOptions.page !== options.page ||
					oldOptions.itemsPerPage !== options.itemsPerPage ||
					oldOptions.sortBy !== options.sortBy ||
					oldOptions.sortDesc !== options.sortDesc
				) {
					this.refresh()
				}
			}
		},
	},
	methods: {
		setLive() {
			if (this.liveInterval) {
				clearInterval(this.liveInterval)
			}

			if (this.live) {
				this.liveInterval = setInterval(async () => {
					await this.refresh(true)
				}, 5000)
			}
		},
		getProjectColorClasses(project) {
			let today = new Date()
			today.setHours(23, 59, 59, 59)
			if (project.deadline) {
				const deadline = parseISO(project.deadline)
				if (isBefore(deadline, today)) {
					return ['error--text']
				}
			} else if (project.productionDate) {
				const productionDate = parseISO(project.productionDate)
				if (isBefore(productionDate, today)) {
					return ['success--text']
				}
			}
			return []
		},
		async refresh(silent) {
			let cancelLoading
			if (!silent) {
				cancelLoading = setTimeout(() => {
					this.isLoading = true
				}, 200)
			}
			let endpoint
			let args
			if (this.dashboardId !== null) {
				endpoint = getProjectInDashboard
				args = { dashboardId: this.dashboardId }

				const { result, ok } = await getDashboardById.call({
					args: { dashboardId: this.dashboardId },
				})
				if (ok) {
					this.dashboardName = result.name
				}

				this.isDashboard = true
			} else if (this.relationId !== null) {
				endpoint = getRelationProjects
				args = { relationId: this.relationId }
				this.isDashboard = false
			} else if (this.userId !== null) {
				endpoint = getProjectsByUser
				args = { userId: this.userId }
				this.isDashboard = false
			} else {
				endpoint = getProjectByPhase
				args = { phase: this.phase }
				this.isDashboard = false
			}

			const q = {
				...this.options,
				search: this.search,
			}
			const { result, ok } = await endpoint.call({ args, q })
			if (ok) {
				this.projects = result.items
				this.totalProjectCount = result.totalItemCount
			}

			if (cancelLoading) {
				clearTimeout(cancelLoading)
				this.isLoading = false
			}
		},

		navProject(project) {
			if (this.$route.params.phase) {
				// Pre-emptively send active project phase for a smooth transition
				PubSub.publishSync('ActiveProjectPhase', {
					phase: this.$route.params.phase,
				})
			}
			this.$router.push({
				name: 'projectDetails',
				params: {
					projectId: project.projectId,
				},
			})
		},
		openContextMenu(evt, data) {
			evt.preventDefault()
			this.contextMenuX = evt.clientX
			this.contextMenuY = evt.clientY
			this.contextMenuItem = data.item
			this.showContextMenu = true
		},
		toggleHeader(header) {
			this.activeHeaders[header.value] = !this.activeHeaders[header.value]

			localStorage.setItem(
				`ProjectOverview:${this.projectOverviewType}:headers`,
				JSON.stringify(this.activeHeaders)
			)
		},
	},
}
</script>
<style lang="scss">
.max200 {
	max-width: 200px;
}
</style>
