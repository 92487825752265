<template>
	<v-menu
		v-if="value"
		v-model="showSearchMenu"
		:close-on-content-click="false"
		offset-y
	>
		<template v-slot:activator="{}">
			<v-container fluid>
				<v-row no-gutters>
					<v-col class="mr-2" id="searchbox-container">
						<v-text-field
							outlined
							single-line
							hide-details
							:placeholder="
								$localize('SearchBar.searchPlaceholder')
							"
							dense
							rounded
							prepend-inner-icon="mdi-magnify"
							height="48"
							@input="onInput"
							v-model="searchKeyword"
							ref="searchBox"
							autocomplete="off"
							@keydown="onKeyDown"
							clearable
						></v-text-field>
					</v-col>
					<v-col cols="auto">
						<v-btn-toggle v-model="searchMode" rounded mandatory>
							<v-tooltip bottom>
								<template v-slot:activator="{ on }">
									<v-btn
										value="page"
										@click="onInput"
										v-on="on"
									>
										<v-icon
											v-if="$vuetify.breakpoint.xsOnly"
											>mdi-file-outline</v-icon
										>
										<span
											v-if="$vuetify.breakpoint.smAndUp"
											>{{
												$localize(
													'SearchBar.searchMode.page'
												)
											}}</span
										>
									</v-btn>
								</template>
								<span>{{
									$localize(
										'SearchBar.tooltip.searchMode.page'
									)
								}}</span>
							</v-tooltip>

							<v-tooltip bottom>
								<template v-slot:activator="{ on }">
									<v-btn
										value="global"
										@click="
											onInput()
											$refs.searchBox.focus()
										"
										v-on="on"
									>
										<v-icon
											v-if="$vuetify.breakpoint.xsOnly"
											>mdi-earth</v-icon
										>
										<span
											v-if="$vuetify.breakpoint.smAndUp"
											>{{
												$localize(
													'SearchBar.searchMode.global'
												)
											}}</span
										>
									</v-btn>
								</template>
								<span>{{
									$localize(
										'SearchBar.tooltip.searchMode.global'
									)
								}}</span>
							</v-tooltip>
						</v-btn-toggle>
					</v-col>
				</v-row>
			</v-container>
		</template>
		<v-list v-if="searchMode === 'global' && searchKeyword.length > 0">
			<v-list-item-group
				v-model="selectedSearchItem"
				v-if="results.length > 0"
			>
				<v-list-item
					v-for="(item, index) in results"
					:key="index"
					@click="
						$router.push({ path: item.href })
						close()
					"
				>
					<v-list-item-icon>
						<v-icon>{{ itemIcon(item) }}</v-icon>
					</v-list-item-icon>
					<v-list-item-content>{{
						item.description
					}}</v-list-item-content>
				</v-list-item>
			</v-list-item-group>
			<v-skeleton-loader v-else-if="loading" type="list-item">
			</v-skeleton-loader>
			<v-list-item v-else>{{
				$localize('SearchBar.noResults')
			}}</v-list-item>
		</v-list>
	</v-menu>
</template>

<script>
import { globalSearch } from '@/services/api'
import PubSub from 'pubsub-js'
import { getPhaseIcon } from '@/services/icons'

export default {
	props: {
		value: Boolean,
	},
	data() {
		return {
			headers: [
				{ text: '', value: 'type' },
				{ text: '', value: 'description', width: 'auto' },
			],
			results: [],
			loading: false,
			searchMode: '',
			searchKeyword: '',
			showSearchMenu: false,
			selectedSearchItem: null,
			cancelTimeout: null,
			unsubscribe: null,
		}
	},
	watch: {
		keyword: {
			immediate: true,
			handler() {
				this.search()
			},
		},
		value: {
			async handler(val) {
				if (val) {
					await this.$nextTick()
					this.$refs.searchBox.focus()
				} else {
					this.reset()
					this.onInput()
				}
			},
		},
		searchMode() {
			this.onInput()
		},
	},
	created() {
		window.addEventListener('keydown', this.windowKeyDown)
		this.unsubscribe = PubSub.subscribe('searchstatus', () => {
			PubSub.publish('search', {
				keyword: this.searchKeyword,
				mode: this.searchMode,
			})
		})
	},
	methods: {
		close() {
			this.$emit('input', false)
		},
		async search() {
			if (this.searchKeyword) {
				this.loading = true
				this.results = await globalSearch
					.create()
					.load({ keyword: this.searchKeyword })
				this.loading = false
			}
		},
		reset() {
			this.results = []
			this.searchKeyword = ''
			this.selectedSearchItem = 0
			if (this.$refs.searchBox) {
				this.$refs.searchBox.blur()
			}
		},
		onInput() {
			if (this.cancelTimeout) {
				clearTimeout(this.cancelTimeout)
				this.cancelTimeout = null
			}
			this.cancelTimeout = setTimeout(async () => {
				PubSub.publish('search', {
					keyword: this.searchKeyword,
					mode: this.searchMode,
				})
				if (this.searchMode === 'global') {
					this.showSearchMenu = true
					this.selectedSearchItem = 0
					this.search()
				} else if (this.searchMode === 'page') {
					this.results = []
				}
			}, 300)
		},
		onKeyDown(e) {
			if (e.keyCode == 27) {
				this.reset()
				return
			} else if (e.keyCode === 13) {
				// Enter

				if (this.searchMode === 'page') {
					// When pressing enter in 'page' search mode, switch to global mode
					this.showSearchMenu = true
					this.searchMode = 'global'
					this.search()
				} else {
					if (this.results.length > 0) {
						if (this.selectedSearchItem < 0) {
							this.selectedSearchItem = 0
						}
						const item = this.results[this.selectedSearchItem]
						if (this.$router.currentRoute.path !== item.href) {
							this.$router.push({ path: item.href })
						}
						this.close()
						this.reset()
						e.preventDefault()

						return
					}
				}
			} else if (e.keyCode === 40) {
				// Down
				this.selectedSearchItem++
			} else if (e.keyCode === 38) {
				// Up
				this.selectedSearchItem--
			}
			this.selectedSearchItem = Math.max(this.selectedSearchItem, 0)
			this.selectedSearchItem = Math.min(
				this.selectedSearchItem,
				this.results.length - 1
			)
		},

		async windowKeyDown(e) {
			if (e.keyCode === 191 && e.target.tagName === 'BODY') {
				e.preventDefault()
				if (!this.value) {
					this.$emit('input', true)
					await this.$nextTick()
				}
				if (this.$refs.searchBox) {
					this.$refs.searchBox.focus()
				}
			} else if (e.keyCode === 27) {
				if (this.value) {
					this.$emit('input', false)
				}
			}
		},

		itemIcon(item) {
			if (item.type === 'supplier') {
				return 'mdi-truck'
			}
			if (item.type === 'relation') {
				return 'mdi-contacts'
			}
			if (item.type === 'Article') {
				return 'mdi-shape-plus'
			}
			if (item.type === 'Contact') {
				return 'mdi-card-account-phone'
			}
			return getPhaseIcon(item.type)
		},
	},
	destroyed: function () {
		window.removeEventListener('keydown', this.windowKeyDown)
		PubSub.unsubscribe(this.unsubscribe)
	},
}
</script>
<style lang="scss">
#searchbox-container .v-input__prepend-inner,
#searchbox-container .v-input__append-inner {
	margin-top: 12px;
}

#searchbox-container .v-input__slot {
	padding: 0 12px;
}
</style>
