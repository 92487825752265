import { ApiEndPoint } from './apiEndPoint'

export const getAllArticles = new ApiEndPoint('articles')
export const getAllArticlesSlim = new ApiEndPoint('articles/slim')
export const getAllArticlesByCategory = new ApiEndPoint('articles/category/{category}')
export const getAllArticlesUncategorized = new ApiEndPoint('articles/no-category')
export const getArticleById = new ApiEndPoint('articles/{articleId}')

export const getArticleCategories = new ApiEndPoint('articles/categories')
export const getArticleBrands = new ApiEndPoint('articles/brands')

export const downloadArticles = new ApiEndPoint('articles/download/{category}')

export const getAllArticleImports = new ApiEndPoint('articles/imports')
export const getArticleImport = new ApiEndPoint('articles/import/{articleImportId}')
export const getArticleImportData = new ApiEndPoint('articles/import/{articleImportId}/data')
export const getAvailableArticleImportLinks = new ApiEndPoint('articles/import/{articleImportId}/links')
export const getArticleImportHeader = new ApiEndPoint('articles/import/{articleImportId}/header')
