<template>
	<v-container class="pa-0">
		<v-row>
			<v-col>
				<v-toolbar flat class="my-3" height="36">
					<v-toolbar-items>
						<v-btn
							v-if="editable"
							@click="enterUserInformation = true"
							class="mr-3"
						>
							<v-icon>mdi-pencil</v-icon>
						</v-btn>
					</v-toolbar-items>
					<v-toolbar-title>
						{{ user.login }}
					</v-toolbar-title>

					<v-spacer></v-spacer>
					<v-toolbar-items>
						<v-btn 
						v-if="$session.roles.includes('projects_view')"
                            @click="navActionList" class="mr-3">
							<v-icon>mdi-bullhorn</v-icon>
							{{ $localize('UserDetails.actionList') }}
						</v-btn>

						<v-menu v-if="editable">
							<template v-slot:activator="{ on }">
								<v-btn v-on="on" class="primary">
									<v-icon>mdi-dots-vertical</v-icon>
								</v-btn>
							</template>
							<v-list>
								<v-list-item @click="changePassword = true">
									<v-list-item-icon>
										<v-icon>mdi-key</v-icon>
									</v-list-item-icon>
									<v-list-item-title>{{
										$localize(
											'UserDetails.button.changePassword'
										)
									}}</v-list-item-title>
								</v-list-item>
								<v-list-item @click="addVacation = true">
									<v-list-item-icon>
										<v-icon>mdi-airplane</v-icon>
									</v-list-item-icon>
									<v-list-item-title>{{
										$localize(
											'UserDetails.button.addVacation'
										)
									}}</v-list-item-title>
								</v-list-item>
								<template
									v-if="
										$session.roles.includes(
											'settings_users'
										)
									"
								>
									<v-list-item
										v-if="!user.isActivated"
										@click="activateUser()"
									>
										<v-list-item-icon>
											<v-icon>mdi-check</v-icon>
										</v-list-item-icon>
										<v-list-item-title>{{
											$localize(
												'UserDetails.button.activate'
											)
										}}</v-list-item-title>
									</v-list-item>
									<v-list-item
										v-if="user.isActivated"
										@click="deactivateUser()"
									>
										<v-list-item-icon>
											<v-icon>mdi-close</v-icon>
										</v-list-item-icon>
										<v-list-item-title>{{
											$localize(
												'UserDetails.button.deactivate'
											)
										}}</v-list-item-title>
									</v-list-item>
									<v-list-item
										v-if="!user.isTrashed"
										@click="trashUser()"
									>
										<v-list-item-icon>
											<v-icon>mdi-delete</v-icon>
										</v-list-item-icon>
										<v-list-item-title>{{
											$localize('form.button.trash')
										}}</v-list-item-title>
									</v-list-item>
									<v-list-item
										v-if="user.isTrashed"
										@click="untrashUser()"
									>
										<v-list-item-icon>
											<v-icon>mdi-delete-restore</v-icon>
										</v-list-item-icon>
										<v-list-item-title>{{
											$localize('form.button.untrash')
										}}</v-list-item-title>
									</v-list-item>
									<v-list-item @click="welcomeUser = true">
										<v-list-item-icon>
											<v-icon>mdi-email</v-icon>
										</v-list-item-icon>
										<v-list-item-title>{{
											$localize(
												'UserDetails.button.welcome'
											)
										}}</v-list-item-title>
									</v-list-item>
								</template>
							</v-list>
						</v-menu>
					</v-toolbar-items>
				</v-toolbar>
			</v-col>
		</v-row>
		<v-row class="pa-4">
			<v-col class="col-12 col-sm-6">
				<v-row wrap dense>
					<v-col cols="6">{{ $localize('User.login') }}</v-col>
					<v-col cols="6">{{ user.login }}</v-col>
					<v-col cols="6">{{
						$localize('User.recoveryEmail')
					}}</v-col>
					<v-col cols="6">{{ user.recoveryEmail }}</v-col>
					<v-col cols="6">{{ $localize('User.salutation') }}</v-col>
					<v-col cols="6">{{ user.salutation }}</v-col>
					<v-col cols="6">{{ $localize('User.name') }}</v-col>
					<v-col cols="6">{{ user.name }}</v-col>
					<v-col cols="6">{{ $localize('User.bornDate') }}</v-col>
					<v-col cols="6">{{ user.bornDate }}</v-col>
					<v-col cols="6">{{ $localize('User.location') }}</v-col>
					<v-col cols="6">{{ user.location }}</v-col>
					<v-col cols="6">{{
						$localize('User.mobilePhoneNumber')
					}}</v-col>
					<v-col cols="6">{{ user.mobilePhoneNumber }}</v-col>
				</v-row>
			</v-col>
			<v-col
				class="col-12 col-sm-6"
				v-if="user.userId === $session.userId"
			>
				<user-settings />
			</v-col>
		</v-row>
		<v-row>
			<v-col>
				<v-data-table
					:items="user.vacations"
					:headers="vacationHeaders"
				>
					<template v-slot:[`item.start`]="{ item }">{{
						$format.shortDate(item.start)
					}}</template>
					<template v-slot:[`item.end`]="{ item }">{{
						$format.shortDate(item.end)
					}}</template>
					<template v-slot:[`item.action`]="{ item }">
						<v-btn
							v-if="editable"
							small
							depressed
							@click="deleteUserVacation(item.vacationId)"
							class="error"
						>
							<v-icon>mdi-close</v-icon>
						</v-btn>
					</template>
				</v-data-table>
			</v-col>
		</v-row>
		<change-password
			v-if="changePassword"
			v-model="changePassword"
			:user-id="$route.params.userId"
		/>
		<enter-user-information
			v-if="enterUserInformation"
			v-model="enterUserInformation"
			:user-id="$route.params.userId"
			@saved="refresh()"
		/>
		<add-vacation
			v-if="addVacation"
			v-model="addVacation"
			:user-id="$route.params.userId"
			@saved="refresh()"
		/>
		<delete-vacation
			v-if="deleteVacation"
			v-model="deleteVacation"
			:user-id="$route.params.userId"
			:vacation-id="deleteVacationId"
			@saved="refresh()"
		/>
		<welcome-user
			v-if="welcomeUser"
			v-model="welcomeUser"
			:user-id="$route.params.userId"
			@saved="refresh()"
		/>
	</v-container>
</template>

<script>
import { getUserById } from '@/services/api'
import ChangePassword from './dialogs/ChangePassword'
import EnterUserInformation from './dialogs/EnterUserInformation'
import AddVacation from './dialogs/AddVacation'
import DeleteVacation from './dialogs/DeleteVacation'
import WelcomeUser from './dialogs/WelcomeUser'
import sendCommand from '@/services/sendCommand'
import UserSettings from './components/UserSettings'

export default {
	components: {
		ChangePassword,
		EnterUserInformation,
		AddVacation,
		DeleteVacation,
		UserSettings,
		WelcomeUser,
	},
	data() {
		return {
			changePassword: false,
			enterUserInformation: false,
			addVacation: false,
			deleteVacation: false,
			deleteVacationId: null,
			welcomeUser: null,
			user: {
				isTrashed: false,
			},
			vacationHeaders: [
				{ text: this.$localize('Vacation.start'), value: 'start' },
				{ text: this.$localize('Vacation.end'), value: 'end' },
				{ text: this.$localize('Vacation.note'), value: 'note' },
				{ text: '', value: 'action', width: 40, sortable: false },
			],
			session: {},
			isSelf: false,
		}
	},
	computed: {
		editable() {
			return (
				this.user.userId === this.$session.userId ||
				this.$session.roles.includes('settings_users')
			)
		},
	},

	created() {
		this.refresh()
	},
	methods: {
		async refresh() {
			const { result, ok } = await getUserById.call({
				args: { userId: this.$route.params.userId },
			})
			if (ok) {
				this.user = result
				this.isSelf = this.$session.userId === this.user.userId
			}
		},
		async deleteUserVacation(vacationId) {
			this.deleteVacationId = vacationId
			this.deleteVacation = true
		},
		async activateUser() {
			const result = await sendCommand('ActivateUser', {
				userId: this.$route.params.userId,
			})
			if (result.success) {
				this.refresh()
			}
		},
		async deactivateUser() {
			const result = await sendCommand('DeactivateUser', {
				userId: this.$route.params.userId,
			})
			if (result.success) {
				this.refresh()
			}
		},
		async trashUser() {
			const result = await sendCommand('TrashUser', {
				userId: this.$route.params.userId,
			})
			if (result.success) {
				this.refresh()
			}
		},
		async untrashUser() {
			const result = await sendCommand('UntrashUser', {
				userId: this.$route.params.userId,
			})
			if (result.success) {
				this.refresh()
			}
		},
		navActionList() {
			this.$router.push({
				name: 'home',
				params: {
					userId: this.$route.params.userId,
				},
			})
		},
	},
}
</script>

<style scoped>
.v-toolbar__title {
	overflow: visible;
}
</style>
