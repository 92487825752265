<template>
	<dialog-form
		id="formDeleteRelation"
		:value="value"
		@input="$emit('input', $event)"
		@commit="save"
		:title="$localize('DeleteRelation.title')"
		action="delete"
		:loading="loading"
	>
		<p>{{ $localize('DeleteRelation.description') }}</p>
		<p>
			<b>{{ relation.companyName }}</b>
		</p>
		<p>{{ $localize('form.description.actionIsPermanent') }}</p>
	</dialog-form>
</template>

<script>
import DialogForm from '@/shared/components/DialogForm'

import sendCommand from '@/services/sendCommand'
import { getRelationById } from '@/services/api'
import { isGuid } from '@/services/validation'

export default {
	components: { DialogForm },
	props: {
		relationId: {
			required: true,
			validator: isGuid,
		},
		value: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			loading: false,
			relation: {},
		}
	},
	async created() {
		this.loading = true
		const { result, ok } = await getRelationById.call({
			args: { relationId: this.relationId },
		})
		if (ok) {
			this.relation = result
		}
		this.loading = false
	},
	methods: {
		async save(callback) {
			const result = await sendCommand('DeleteRelation', {
				relationId: this.relation.relationId,
			})
			if (result.success) {
				this.$emit('saved')
			}
			callback({ success: result.success })
		},
	},
}
</script>
