<template>
  <dialog-form
    :value="value"
    @input="$emit('input', $event)"
    :title="title"
    :allow-continue="false"
    @commit="save"
    max-width="800"
  >
    <email-editor v-model="emailVal" :autofocus="autofocus" />
  </dialog-form>
</template>

<script>
import DialogForm from "@/shared/components/DialogForm";
import EmailEditor from "../forms/EmailEditor";

export default {
  components: { DialogForm, EmailEditor },
  props: {
    value: Boolean,
    email: Object,
    title: String,
    autofocus: Boolean
  },
  data() {
    return {
      emailVal: this.email
    };
  },
  watch:{
    email(){
      this.emailVal = this.email;
    }
  },
  methods: {
    async save(callback) {
      this.$emit('saved', this.emailVal)
      callback({ success: true });
    }
  }
};
</script>