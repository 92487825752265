<template>
	<dialog-form
		:value="value"
		@input="$emit('input', $event)"
		:title="$localize('CalculatePart.title', { part: calculation.name })"
		:allow-continue="false"
		@commit="save"
		max-width="900"
		:loading="loading"
	>
		<v-row
			v-if="updatingCalculation || !calculation.isLatestPublishedVersion"
		>
			<v-col class="text-center">
				<v-alert type="info">
					<div v-if="!updatingCalculation">
						<p>
							{{
								$localize(
									'CalculatePart.newCalculationVersionAvailable'
								)
							}}
						</p>
						<v-btn @click="updateCalculation">
							{{ $localize('form.button.update') }}
							<v-icon>mdi-update</v-icon>
						</v-btn>
					</div>
					<div v-else>
						{{ $localize('CalculatePart.calculationUpdated') }}
					</div>
				</v-alert>
			</v-col>
		</v-row>
		<v-row v-if="updatingArticles || result.oldArticleUsed">
			<v-col class="text-center">
				<v-alert type="info">
					<div v-if="!updatingArticles">
						<p>
							{{
								$localize(
									'CalculatePart.newArticleVersionsAvailable'
								)
							}}
						</p>
						<v-btn @click="updateArticles">
							{{ $localize('form.button.update') }}
							<v-icon>mdi-update</v-icon>
						</v-btn>
					</div>
					<div v-else>
						{{ $localize('CalculatePart.articlesUpdated') }}
					</div>
				</v-alert>
			</v-col>
		</v-row>
		<v-row>
			<v-col class="col-12 col-sm-6">
				<template v-for="inputField in calculation.input">
					<template v-if="inputField.type === 'number'">
						<number-field
							:key="inputField.key"
							:label="inputField.label"
							:input-field="inputField"
							v-model="input[inputField.key]"
							@input="onInput"
						/>
					</template>
					<template v-if="inputField.type === 'text'">
						<text-field
							:key="inputField.key"
							:label="inputField.label"
							:input-field="inputField"
							v-model="input[inputField.key]"
							@input="onInput"
						/>
					</template>
					<template v-else-if="inputField.type === 'article'">
						<article-field
							:key="inputField.key"
							:label="inputField.label"
							:article-calculation-ids="
								inputField.articleCalculationIds
							"
							:input-field="inputField"
							v-model="input[inputField.key]"
							@input="onInput"
							@update:article="
								setInputArticle(inputField.key, $event)
							"
						/>
					</template>
				</template>
			</v-col>
			<v-col class="col-12 col-sm-6">
				<calculation-output
					:state="output"
					:grid="calculation.outputGrid"
					:hide-buy-price="hideBuyPrice"
				/>
				<template v-for="inputField in calculation.input">
					<div
						:key="input[inputField.key]"
						v-if="
							inputArticles[inputField.key] &&
							(inputArticles[inputField.key].note ||
								inputArticles[inputField.key].url)
						"
					>
						<div>
							<a
								v-if="inputArticles[inputField.key].url"
								:href="inputArticles[inputField.key].url"
								target="_new"
							>
								{{ inputArticles[inputField.key].text }}
							</a>
							<span v-else class="font-weight-bold">
								{{ inputArticles[inputField.key].text }}
							</span>
						</div>
						<div
							v-if="inputArticles[inputField.key].note"
							class="pre"
						>
							{{ inputArticles[inputField.key].note }}
						</div>
					</div>
				</template>
			</v-col>
		</v-row>
		<template v-slot:actions>
			<v-btn
				class="mt-3 mr-3"
				@click="test()"
				:loading="calculating"
				:disabled="!isDirty"
				>{{ $localize('Part.button.calculate') }}</v-btn
			>
		</template>
		<template v-slot:actions-right>
			<v-checkbox
				v-model="hideBuyPrice"
				class="mr-3"
				style="display: inline-block"
				:label="$localize('CalculationOutput.hideBuyPrice')"
				hide-details
			/>
			<v-checkbox
				v-model="autoCalculate"
				style="display: inline-block"
				:label="$localize('CalculatePart.autoCalculate')"
				hide-details
			/>
		</template>
	</dialog-form>
</template>

<script>
import DialogForm from '@/shared/components/DialogForm'
import Vue from 'vue'

import { isGuid } from '@/services/validation'
import { getCalculationById, testCalculation } from '@/services/api'

import NumberField from '@/main/advanced/components/NumberField'
import ArticleField from '@/main/advanced/components/ArticleField'
import TextField from '@/main/advanced/components/TextField'
import CalculationOutput from '@/main/advanced/components/CalculationOutput'

import sendCommand from '@/services/sendCommand'

import { formatISO } from 'date-fns'

const recalculateDelay = 1000

export default {
	components: {
		NumberField,
		ArticleField,
		TextField,
		CalculationOutput,
		DialogForm,
	},
	props: {
		value: {
			type: Boolean,
			default: false,
		},
		part: {
			validator: Object,
			required: true,
		},
		projectId: {
			validator: isGuid,
			required: true,
		},
	},
	data() {
		return {
			loading: false,
			calculating: false,
			updatingCalculation: false,
			updatingArticles: false,
			calculation: {},

			input: {},
			inputArticles: {},
			output: {},
			result: {},

			newVersionDate: null,

			hideBuyPrice: JSON.parse(
				localStorage.getItem('CalculationOutput:hideBuyPrice') || true
			),
			autoCalculate:
				JSON.parse(
					localStorage.getItem('CalculatePart:autoCalculate')
				) || false,
			isDirty: true,
			updateTimeout: null,
		}
	},
	watch: {
		part: {
			immediate: true,
			async handler(part) {
				if (part) {
					this.loadCalculation()
				}
			},
		},
		hideBuyPrice(value) {
			localStorage.setItem(
				'CalculationOutput:hideBuyPrice',
				JSON.stringify(value)
			)
		},
		autoCalculate(value) {
			localStorage.setItem(
				'CalculatePart:autoCalculate',
				JSON.stringify(value)
			)
		},
	},
	methods: {
		onInput() {
			if (this.updateTimeout) clearTimeout(this.updateTimeout)

			this.isDirty = true
			if (!this.autoCalculate) return
			this.updateTimeout = setTimeout(
				async () => await this.test(),
				recalculateDelay
			)
		},
		updateCalculation() {
			this.updatingCalculation = true
			this.loadCalculation()
			this.onInput()
		},
		updateArticles() {
			this.updatingArticles = true
			this.newVersionDate = formatISO(new Date())
			this.onInput()
		},
		async loadCalculation() {
			this.loading = true
			const version = this.updatingCalculation
				? -2
				: this.part.calculationVersion
			const { result, ok } = await getCalculationById.call({
				args: {
					calculationId: this.part.calculationId,
					version: version,
				},
			})
			if (ok) {
				this.calculation = result
			}
			this.input = this.part.input || {}
			this.output = this.part.displayInfo
			this.loading = false
		},
		async test() {
			this.calculating = true
			const { result, ok } = await testCalculation.call({
				args: {
					calculationId: this.calculation.calculationId,
					version: this.part.calculationVersion,
				},
				payload: {
					...this.input,
					$$project: this.projectId,
					$$product: this.part.productId,
					$$part: this.part.partId,
					$$versionDate: this.newVersionDate || this.part.versionDate,
				},
			})

			if (ok) {
				this.output = result.state
				this.result = result
				this.calculating = false
				this.isDirty = false
			}
		},
		async save(callback) {
			const result = await sendCommand('CalculatePart', {
				projectId: this.projectId,
				productId: this.part.productId,
				partId: this.part.partId,
				input: this.input,
				updateCalculationVersion: this.updatingCalculation,
				versionDate:
					this.newVersionDate ||
					this.part.versionDate ||
					formatISO(new Date()),
			})

			if (result.success) {
				this.$emit('saved')
			}

			callback({ success: result.success })
		},
		setInputArticle(key, article) {
			Vue.set(this.inputArticles, key, article)
		},
	},
}
</script>
<style scoped lang="css">
.v-alert {
	margin-bottom: 0;
}
</style>
