<template>
    <v-skeleton-loader :loading="loading" type="list-item-three-line">
        <div>
            <v-row class="project-header-row" no-gutters>
                <v-col>{{ $localize('ProjectTimeline.title') }}</v-col>
            </v-row>
            <v-row dense class="font-weight-bold pt-3">
                <v-col style="width: 26px" cols="auto"> </v-col>
                <v-col style="width: 150px" cols="auto">{{
                    $localize('ProjectTimeline.date')
                }}</v-col>
                <v-col style="width: 150px" cols="auto">{{
                    $localize('ProjectTimeline.user')
                }}</v-col>
                <v-col cols="3">{{ $localize('ProjectTimeline.event') }}</v-col>
                <v-col>{{ $localize('ProjectTimeline.change') }}</v-col>
            </v-row>
            <v-row v-for="item in timelineData" :key="item.eventNumber" dense>
                <v-col style="width: 26px" cols="auto">
                    <v-icon small :class="iconClass[item.title]">{{
                        icons[item.title]
                    }}</v-icon>
                </v-col>
                <v-col style="width: 150px" cols="auto">
                    {{ $format.shortDateTime(item.created) }}
                </v-col>
                <v-col style="width: 150px" cols="auto" class="text-truncate">
                    {{ item.createdByName }}
                </v-col>
                <v-col class="text-truncate" cols="3">
                    {{ $localize(item.title) }}
                </v-col>
                <v-col class="text-truncate">
                    <component
                        v-if="components[item.title]"
                        :is="components[item.title]"
                        :item="item"
                    />
                </v-col>
            </v-row>
        </div>
    </v-skeleton-loader>
</template>

<script>
import InfoChanged from './timelineItems/InfoChanged'
import StatusChanged from './timelineItems/StatusChanged'
import ProjectCreated from './timelineItems/ProjectCreated'
import { isGuid } from '@/services/validation'
import { getProjectTimeline } from '@/services/api'
import { subscribe, unsubscribe } from '@/services/websocketHandler'

export default {
    props: {
        projectId: {
            validator: isGuid,
            required: true,
        },
    },
    data() {
        return {
            loading: false,
            events: [
                'ProjectStatusChanged',
                'OfferCreated',
                'OfferDeleted',
                'InvoiceNumberAssigned',
                'InvoiceEmailSent',
                'OfferEmailSent',
                'OrderConfirmationEmailSent',
                'StatusUpdateEmailSent',
                'OfferDeleted',
            ],
            timeline: [],
            components: {
                'ProjectEvent.infoChanged': InfoChanged,
                'ProjectEvent.statusChanged': StatusChanged,
                'ProjectEvent.created': ProjectCreated,
            },
            icons: {
                'ProjectEvent.statusChanged': 'mdi-sync',
                'ProjectEvent.offerCreated': 'mdi-adobe-acrobat',
                'ProjectEvent.created': 'mdi-plus-circle-outline',
                'ProjectEvent.offerSent': 'mdi-email-send',
                'ProjectEvent.orderConfirmationSent': 'mdi-email-check',
                'ProjectEvent.invoiceCreated': 'mdi-cash-register',
                'ProjectEvent.invoiceSent': 'mdi-email-send',
                'ProjectEvent.statusUpdateSent': 'mdi-email-sync',
            },
            iconClass: {
                'ProjectEvent.statusChanged': '',
                'ProjectEvent.offerCreated': 'red--text',
                'ProjectEvent.created': 'success--text',
                'ProjectEvent.offerSent': 'success--text',
                'ProjectEvent.orderConfirmationSent': 'success--text',
                'ProjectEvent.invoiceCreated': '',
                'ProjectEvent.invoiceSent': 'success--text',
                'ProjectEvent.statusUpdateSent': 'success--text',
            },
            subscriptions: [],
        }
    },
    computed: {
        timelineData() {
            if (!this.timeline || this.timeline.length === 0) {
                return []
            }
            const t = []
            let phase 

            for (
                let eventIndex = this.timeline.length - 1;
                eventIndex >= 0;
                eventIndex--
            ) {
                const item = this.timeline[eventIndex]
                if (item.data.phase) {
                    phase = item.data.phase
                } else if (item.data.newPhase) {
                    phase = item.data.newPhase
                }
                item.phase = phase

                t.push(item)
            }
            return t.reverse()
        },
    },
    created() {
        this.refresh()
        for (let index in this.events) {
            let eventName = this.events[index]
            const subscription = subscribe(
                eventName,
                this.refresh,
                (x) => x.projectId === this.projectId
            )
            this.subscriptions.push(subscription)
        }
    },
    destroyed() {
        for (let index in this.subscriptions) {
            let subscription = this.events[index]
            unsubscribe(subscription)
        }
    },
    methods: {
        async refresh() {
            this.loading = true
            const { result, ok } = await getProjectTimeline.call({
                args: { projectId: this.projectId },
            })
            if (ok) {
                this.timeline = result
            }
            this.loading = false
        },
    },
}
</script>
<style lang="scss" scoped>
.v-subheader {
    height: auto;
    padding: 0;
    text-align: right;
}

.row.row--dense {
    padding-left: 12px !important;
}
</style>
