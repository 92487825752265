<template>
	<dialog-form
		:value="value"
		@input="$emit('input', $event)"
		:title="$localize('RenameVariable.title')"
		:allow-continue="false"
		@commit="save"
	>
		<v-text-field
			:label="$localize('RenameVariable.key')"
			v-model="renameVariable.newKey"
			prepend-icon="mdi-text"
			autofocus
			:rules="[validation.required, validation.maxLength(50)]"
		/>
	</dialog-form>
</template>

<script>
import DialogForm from '@/shared/components/DialogForm'
import sendCommand from '@/services/sendCommand'
import { isGuid } from '@/services/validation'
import { required, maxLength } from '@/services/validation'
export default {
	components: { DialogForm },
	props: {
		value: {
			type: Boolean,
			default: false,
		},
		calculationId: { validator: isGuid, required: true },
		variableKey: {
			type: String,
		},
	},

	data() {
		return {
			renameVariable: {
				newKey: this.variableKey,
			},
			validation: { required, maxLength },
		}
	},

	watch: {
		key(val) {
			this.renameVariable.key = val
		},
	},

	methods: {
		async save(callback) {
			this.renameVariable.oldKey = this.variableKey
			this.renameVariable.calculationId = this.calculationId
			const result = await sendCommand(
				'RenameVariable',
				this.renameVariable
			)

			if (result.success) {
				this.$emit('saved')
				this.$emit('update:variableKey', this.renameVariable.newKey)
			}

			callback({ success: result.success })
		},
	},
}
</script>
