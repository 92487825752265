var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',[_c('v-form',{ref:"form"},[_c('v-card-text',[_c('v-subheader',[_vm._v(_vm._s(_vm.$localize('CalculationOutputEditor.title')))]),_c('v-row',[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-text-field',{staticStyle:{"width":"150px"},attrs:{"outlined":"","dense":"","type":"number","label":"Aantal rijen","hide-details":""},model:{value:(_vm.rCount),callback:function ($$v) {_vm.rCount=$$v},expression:"rCount"}})],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-text-field',{staticStyle:{"width":"150px"},attrs:{"outlined":"","dense":"","type":"number","label":"Aantal kolommen","hide-details":""},model:{value:(_vm.cCount),callback:function ($$v) {_vm.cCount=$$v},expression:"cCount"}})],1),_c('v-col',[_c('v-btn',{on:{"click":_vm.createCells}},[_vm._v("Maak cellen")]),_c('v-btn',{on:{"click":function($event){_vm.pastingCode = true}}},[_vm._v("Plakken")])],1)],1),_vm._l((_vm.value.variables),function(variable){return _c('v-chip',{key:variable.key,staticClass:"mr-1",on:{"click":function($event){return _vm.insertVariable($event, variable.key)}}},[_vm._v(_vm._s(variable.name))])}),_c('draggable',{staticClass:"list-group",attrs:{"list":_vm.grid,"handle":".grip","ghost-class":"ghost"},on:{"start":function($event){_vm.dragging = true},"end":function($event){_vm.dragging = false
					_vm.isDirty = true}}},_vm._l((_vm.grid),function(row,rowIndex){return _c('v-row',{key:'row-' + rowIndex,attrs:{"align":"center","justify":"center"}},[_c('v-col',{staticClass:"grip",attrs:{"cols":"auto"}},[_c('v-icon',[_vm._v("mdi-drag")])],1),_vm._l((row),function(cell,columnIndex){return _c('v-col',{key:'col-' + columnIndex},[_c('v-text-field',{staticClass:"text-code",attrs:{"outlined":"","dense":"","hide-details":""},on:{"input":_vm.onInput,"blur":function($event){_vm.selectedCell = {
									column: columnIndex,
									row: rowIndex,
								}}},model:{value:(_vm.grid[rowIndex][columnIndex]),callback:function ($$v) {_vm.$set(_vm.grid[rowIndex], columnIndex, $$v)},expression:"grid[rowIndex][columnIndex]"}})],1)})],2)}),1),_c('v-checkbox',{attrs:{"label":_vm.$localize(
						'CalculationCodeEditor.field.displayCodeStructure'
					)},model:{value:(_vm.displayCode),callback:function ($$v) {_vm.displayCode=$$v},expression:"displayCode"}}),(_vm.displayCode)?_c('pre',{staticStyle:{"white-space":"pre"}},[_vm._v(_vm._s(JSON.stringify(_vm.grid)))]):_vm._e()],2),_c('v-card-actions',[_c('v-btn',{attrs:{"disabled":!_vm.isDirty},on:{"click":function($event){return _vm.reset()}}},[_vm._v(_vm._s(_vm.$localize('form.button.cancel')))]),_c('v-btn',{attrs:{"disabled":!_vm.isDirty},on:{"click":function($event){return _vm.saveOutputFields(false)}}},[_vm._v(_vm._s(_vm.$localize('form.button.save')))]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.saveOutputFields(true)}}},[_vm._v(_vm._s(_vm.$localize('form.button.saveAndContinue')))])],1)],1),_c('v-lazy',{attrs:{"value":_vm.pastingCode}},[_c('paste-code',{attrs:{"code":JSON.stringify(_vm.grid)},on:{"update:code":_vm.loadJson},model:{value:(_vm.pastingCode),callback:function ($$v) {_vm.pastingCode=$$v},expression:"pastingCode"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }