<template>
	<div class="mx-3">
		<v-toolbar flat dense>
			<v-toolbar-title>{{ trigger.name }}</v-toolbar-title>
			<v-spacer></v-spacer>
			<v-toolbar-items></v-toolbar-items>
		</v-toolbar>
		<v-stepper v-model="step">
			<v-stepper-header>
				<v-stepper-step
					key="step-1"
					:step="1"
					:complete="step > 1"
					:editable="true"
					>{{
						$localize('TriggerDetails.title.stepEvents')
					}}</v-stepper-step
				>
				<v-divider />
				<v-stepper-step
					key="step-2"
					:step="2"
					:complete="step > 2"
					:editable="true"
					>{{
						$localize('TriggerDetails.title.stepAction')
					}}</v-stepper-step
				>
				<v-divider />
				<v-stepper-step
					key="step-3"
					:step="3"
					:complete="step > 3"
					:editable="true"
					>{{
						$localize('TriggerDetails.title.stepSettings')
					}}</v-stepper-step
				>
			</v-stepper-header>
			<v-stepper-items>
				<v-stepper-content key="step-1" :step="1">
					<trigger-events-editor
						v-if="loaded"
						v-model="trigger"
						@saved="refresh()"
						@continue="step = 2"
					/>
				</v-stepper-content>
				<v-stepper-content key="step-2" :step="2">
					<trigger-actions-editor
						v-if="loaded"
						v-model="trigger"
						@saved="refresh()"
						@continue="step = 3"
					/>
				</v-stepper-content>
				<v-stepper-content key="step-3" :step="3">
					<v-card>
						<v-card-text>
							<v-row>
								<v-col>
									<v-text-field
										:label="$localize('Trigger.name')"
										v-model="trigger.name"
										:rules="[
											validation.required,
											validation.maxLength(50),
										]"
									/>
								</v-col>
								<v-col cols="auto">
									<v-btn @click="rename()">{{
										$localize('form.button.save')
									}}</v-btn>
								</v-col>
							</v-row>
						</v-card-text>
						<v-card-actions>
							<v-btn
								:disabled="!trigger.isEnabled"
								@click="disable()"
								>{{ $localize('form.button.disable') }}</v-btn
							>
							<v-btn
								:disabled="trigger.isEnabled"
								@click="enable()"
								>{{ $localize('form.button.enable') }}</v-btn
							>
						</v-card-actions>
					</v-card>
				</v-stepper-content>
			</v-stepper-items>
		</v-stepper>
	</div>
</template>

<script>
import { getTriggerById } from '@/services/api'
import { reload } from '@/services/code'

import TriggerEventsEditor from './TriggerEventsEditor'
import TriggerActionsEditor from './TriggerActionsEditor'
import sendCommand from '@/services/sendCommand'
import { required, maxLength } from '@/services/validation'
export default {
	components: { TriggerEventsEditor, TriggerActionsEditor },
	data() {
		return {
			trigger: {},
			step: 1,
			loaded: false,
			validation: { required, maxLength },
		}
	},
	async created() {
		await reload()
		await this.refresh()
		this.computeStep()
		this.loaded = true
	},
	methods: {
		async refresh() {
			const { result, ok } = await getTriggerById.call({
				args: { triggerId: this.$route.params.triggerId },
			})

			if (ok) {
				this.trigger = result
			}
		},
		async enable() {
			await sendCommand('EnableTrigger', {
				triggerId: this.$route.params.triggerId,
			})
			await this.refresh()
		},
		async disable() {
			await sendCommand('DisableTrigger', {
				triggerId: this.$route.params.triggerId,
			})
			await this.refresh()
		},
		async rename() {
			await sendCommand('RenameTrigger', {
				triggerId: this.$route.params.triggerId,
				newName: this.trigger.name,
			})
			await this.refresh()
		},
		computeStep() {
			if (this.trigger.eventTypes.length === 0) {
				this.step = 1
			} else if (this.trigger.actions === null) {
				this.step = 2
			} else {
				this.step = 3
			}
		},
	},
}
</script>
