import { ApiEndPoint } from './apiEndPoint'

// relations
export const getRelations = new ApiEndPoint('relations')
export const getRelationsWithContactsSimple = new ApiEndPoint('relations/withcontacts')
export const getRelationsPaged = new ApiEndPoint('relations/page')
export const getSuppliers = new ApiEndPoint('relations/suppliers')
export const getCustomers = new ApiEndPoint('relations/customers')

export const getRelationById = new ApiEndPoint('relations/{relationId}')
export const getRelationNoteById = new ApiEndPoint('relations/notes/{noteId}')

// contacts
export const getRelationContacts = new ApiEndPoint('relations/{relationId}/contacts')
export const getRelationEmails = new ApiEndPoint('relations/{relationId}/emails')
export const getContactById = new ApiEndPoint('relations/contacts/{contactId}')

// addresses
export const getRelationAddresses = new ApiEndPoint('relations/{relationId}/addresses')
export const getAddressById = new ApiEndPoint('relations/addresses/{addressId}')
export const getAddressTypes = new ApiEndPoint('relations/addresses/types')

// projects
export const getRelationProjects = new ApiEndPoint('relations/{relationId}/projects')

// notes
export const getRelationNotes = new ApiEndPoint('relations/{relationId}/notes')

// groups
export const getRelationGroups = new ApiEndPoint('relations/groups')

// pdf's
export const getInvoiceOverview = new ApiEndPoint('relations/{relationId}/invoice-overview')

// imports
export const getRelationImports = new ApiEndPoint('relations/imports')
export const getRelationImport = new ApiEndPoint('relations/import/{relationImportId}')
export const getRelationImportLinks = new ApiEndPoint('relations/import/{relationImportId}/links')
export const getRelationImportData = new ApiEndPoint('relations/import/{relationImportId}/data')
export const getRelationImportHeader = new ApiEndPoint('relations/import/{relationImportId}/header')
