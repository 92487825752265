<template>
	<div class="mx-3">
		<v-toolbar flat dense>
			<v-toolbar-title>{{ calculation.name }}</v-toolbar-title>
			<v-spacer></v-spacer>
			<v-toolbar-items></v-toolbar-items>
		</v-toolbar>

		<v-stepper v-model="step">
			<v-stepper-header>
				<v-stepper-step
					key="step-1"
					:step="1"
					:complete="step > 1"
					:editable="true"
					>{{
						$localize('CalculationDetails.title.stepFormInput')
					}}</v-stepper-step
				>
				<v-divider />
				<v-stepper-step
					key="step-2"
					:step="2"
					:complete="step > 2"
					:editable="true"
					>{{
						$localize('CalculationDetails.title.stepCalculation')
					}}</v-stepper-step
				>
				<v-divider />
				<v-stepper-step
					key="step-3"
					:step="3"
					:complete="step > 3"
					:editable="true"
					>{{
						$localize('Calculation.title.stepOutput')
					}}</v-stepper-step
				>
				<v-divider />
				<v-stepper-step
					key="step-4"
					:step="4"
					:complete="step > 4"
					:editable="true"
					>{{
						$localize('Calculation.title.stepTesting')
					}}</v-stepper-step
				>
				<v-divider />
				<v-stepper-step
					key="step-5"
					:step="5"
					:complete="step > 5"
					:editable="true"
					>{{
						$localize('Calculation.title.stepOptions')
					}}</v-stepper-step
				>
			</v-stepper-header>

			<v-stepper-items>
				<v-stepper-content key="step-1" :step="1">
					<calculation-input-editor
						v-if="loaded"
						v-model="calculation"
						@saved="refresh()"
						@continue="step = 2"
					/>
				</v-stepper-content>
				<v-stepper-content key="step-2" :step="2">
					<calculation-code-editor
						v-if="loaded"
						v-model="calculation"
						@saved="refresh()"
						@continue="step = 3"
					/>
				</v-stepper-content>
				<v-stepper-content key="step-3" :step="3">
					<calculation-grid-output-editor
						v-model="calculation"
						@saved="refresh()"
						@continue="step = 4"
					/>
				</v-stepper-content>
				<v-stepper-content key="step-4" :step="4">
					<calculation-tester
						:calculation-id="$route.params.calculationId"
						@continue="step = 5"
					/>
				</v-stepper-content>
				<v-stepper-content key="step-6" :step="5">
					<v-card>
						<v-card-text>
							<v-row>
								<v-col>
									<v-text-field
										:label="$localize('Calculation.name')"
										v-model="calculation.name"
										:rules="[
											validation.required,
											validation.maxLength(50),
										]"
									/>
									<v-text-field
										:label="
											$localize('Calculation.description')
										"
										v-model="calculation.description"
									/>
								</v-col>
								<v-col cols="auto">
									<v-btn @click="save()">{{
										$localize('form.button.save')
									}}</v-btn>
								</v-col>
							</v-row>
						</v-card-text>
						<v-card-actions>
							<v-btn
								:disabled="calculation.isTrashed"
								@click="trash()"
								>{{ $localize('form.button.trash') }}</v-btn
							>
							<v-btn
								:disabled="!calculation.isTrashed"
								@click="untrash()"
								>{{ $localize('form.button.untrash') }}</v-btn
							>

							<v-btn color="primary" @click="publish()">{{
								$localize('form.button.publish')
							}}</v-btn>
							<v-btn color="primary" @click="publishAsNew()">{{
								$localize('form.button.publishAsNew')
							}}</v-btn>
						</v-card-actions>
					</v-card>
				</v-stepper-content>
			</v-stepper-items>
		</v-stepper>
	</div>
</template>

<script>
import { getCalculationById } from '@/services/api'
import { reload } from '@/services/code'
import CalculationInputEditor from './CalculationInputEditor'
import CalculationTester from './CalculationTester'
import CalculationGridOutputEditor from './CalculationGridOutputEditor'
import CalculationCodeEditor from './CalculationCodeEditor'
import sendCommand from '@/services/sendCommand'
import { required, maxLength } from '@/services/validation'
export default {
	components: {
		CalculationInputEditor,
		CalculationCodeEditor,
		CalculationGridOutputEditor,
		CalculationTester,
	},
	data() {
		return {
			step: 1,
			steps: ['Form Input', 'Calculation', 'Output'],
			renameCalculation: false,
			calculation: {},
			loaded: false,
			validation: { required, maxLength },
		}
	},

	async created() {
		await this.refresh()
		this.computeStep()
	},

	methods: {
		async refresh() {
			await reload()
			const { result, ok } = await getCalculationById.call({
				args: {
					calculationId: this.$route.params.calculationId,
					version: -1,
				},
			})

			if (ok) {
				this.calculation = result
			}
			this.loaded = true
		},
		async save() {
			await sendCommand('RenameCalculation', {
				calculationId: this.$route.params.calculationId,
				newName: this.calculation.name,
				newDescription: this.calculation.description,
			})
		},
		async publish() {
			const result = await sendCommand('PublishCalculation', {
				calculationId: this.$route.params.calculationId,
			})
			if (result.success) {
				this.calculation.hasPublishedVersion = true
			}
		},
		async publishAsNew() {
			const result = await sendCommand('PublishCalculationAsNewVersion', {
				calculationId: this.$route.params.calculationId,
			})
			if (result.success) {
				this.calculation.hasPublishedVersion = true
			}
		},
		async trash() {
			const result = await sendCommand('TrashCalculation', {
				calculationId: this.$route.params.calculationId,
			})
			if (result.success) {
				this.calculation.isTrashed = true
			}
		},
		async untrash() {
			const result = await sendCommand('UntrashCalculation', {
				calculationId: this.$route.params.calculationId,
			})
			if (result.success) {
				this.calculation.isTrashed = false
			}
		},

		computeStep() {
			if (this.calculation.input.length === 0) {
				this.step = 1
			} else if (
				this.calculation.actions === null ||
				this.calculation.actions === '[]'
			) {
				this.step = 2
			} else if (this.calculation.outputGrid.length === 0) {
				this.step = 3
			} else {
				this.step = 4
			}
		},
	},
}
</script>
