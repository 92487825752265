<template>
    <v-list>
        <template v-for="item in availableLinks">
            <template v-if="item.children">
                <v-tooltip right :key="item.name" :disabled="!mini">
                    <template v-slot:activator="{ on }">
                        <v-list-item
                            @click="item.isOpen = !item.isOpen"
                            v-on="on"
                        >
                            <v-list-item-icon>
                                <v-icon>{{ item.icon }}</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>
                                {{ $localize(item.name) }}
                                <v-icon
                                    v-show="!item.isOpen"
                                    class="list-item-menu-icon"
                                    >mdi-menu-down</v-icon
                                >
                                <v-icon
                                    v-show="item.isOpen"
                                    class="list-item-menu-icon"
                                    >mdi-menu-up</v-icon
                                >
                            </v-list-item-title>
                        </v-list-item>
                    </template>
                    <span>{{ $localize(item.name) }}</span>
                </v-tooltip>

                <v-expand-transition :key="item.name + 'transition'">
                    <div v-show="item.isOpen">
                        <v-divider />

                        <v-tooltip
                            right
                            v-for="childItem in item.children"
                            :key="childItem.name + childItem.text"
                            :disabled="!mini"
                        >
                            <template v-slot:activator="{ on }">
                                <v-list-item :to="childItem.to" v-on="on">
                                    <v-list-item-icon>
                                        <v-icon>{{ childItem.icon }}</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-title>{{
                                        childItem.text
                                    }}</v-list-item-title>
                                </v-list-item>
                            </template>
                            <span>{{ childItem.text }}</span>
                        </v-tooltip>

                        <v-divider />
                    </div>
                </v-expand-transition>
            </template>
            <template v-else>
                <v-tooltip right :key="item.name" :disabled="!mini">
                    <template v-slot:activator="{ on }">
                        <v-list-item :to="item.to" v-on="on">
                            <v-list-item-icon>
                                <v-icon>{{ item.icon }}</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>{{
                                $localize(item.name)
                            }}</v-list-item-title>
                        </v-list-item>
                    </template>
                    <span>{{ $localize(item.name) }}</span>
                </v-tooltip>
            </template>
        </template>

        <v-tooltip right :disabled="!mini">
            <template v-slot:activator="{ on }">
                <v-list-item
                    v-if="$session.isAdministrator && $session.impersonated"
                    v-on="on"
                    @click="navBackToAdmin()"
                >
                    <v-list-item-icon>
                        <v-icon>mdi-logout</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Back to Admin</v-list-item-title>
                </v-list-item>
            </template>
            <span>Back to Admin</span>
        </v-tooltip>
        <v-tooltip right :disabled="!mini">
            <template v-slot:activator="{ on }">
                <v-list-item @click="logout()" v-on="on" class="btnLogout">
                    <v-list-item-icon>
                        <v-icon>mdi-logout</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>{{
                        $localize('LogoutButton.text')
                    }}</v-list-item-title>
                </v-list-item>
            </template>
            <span>{{ $localize('LogoutButton.text') }}</span>
        </v-tooltip>
        <v-tooltip right :disabled="!mini">
            <template v-slot:activator="{ on }">
                <v-list-item
                    :href="helpUrl"
                    target="_new"
                    v-on="on"
                    class="btnLogout"
                >
                    <v-list-item-icon>
                        <v-icon>mdi-help-circle</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>{{
                        $localize('HelpButton.text')
                    }}</v-list-item-title>
                </v-list-item>
            </template>
            <span>{{ $localize('HelpButton.text') }}</span>
        </v-tooltip>
    </v-list>
</template>

<script>
import sendCommand from '@/services/sendCommand'
import { currentSession, clearSession } from '@/services/session'
import { closeWebsocket } from '@/services/websocketHandler'

export default {
    props: {
        mini: Boolean,
    },
    data() {
        return {
            links: [],
        }
    },
    computed: {
        availableLinks() {
            return this.links.filter((x) => {
                if (x.if && !x.if()) return false

                if (x.to && x.to.name === 'advanced') {
                    return this.$session.isAdministrator
                }
                return true
            })
        },
        helpUrl() {
            const helpCategory = this.$route.meta.helpCategory
            const helpItem = this.$route.meta.helpItem

            let url = 'https://torecs.nl/support'

            if (helpCategory) {
                url += '/' + helpCategory
            }
            if (helpItem) {
                url += '/' + helpItem
            }
            return url
        },
    },
    async created() {
        this.links = [
            {
                icon: 'mdi-home',
                name: 'NavigationDrawer.link.actionList',
                if: () =>
                    this.$vuetify.breakpoint.smAndDown &&
                    this.$session.roles.includes('projects_view'),
                to: { name: 'home' },
            },
            {
                icon: 'mdi-briefcase',
                name: 'NavigationDrawer.link.projects',
                if: () =>
                    this.$vuetify.breakpoint.smAndDown &&
                    this.$session.roles.includes('projects_view'),
                children: [
                    {
                        icon: 'mdi-file-document-outline',
                        text: this.$localize('Project.phaseRequests'),
                        to: {
                            name: 'projectOverview',
                            params: { phase: 'request' },
                        },
                    },
                    {
                        icon: 'mdi-hammer-wrench',
                        text: this.$localize('Project.phaseOffers'),
                        to: {
                            name: 'projectOverview',
                            params: { phase: 'offer' },
                        },
                    },
                    {
                        icon: 'mdi-handshake',
                        text: this.$localize('Project.phaseOrders'),
                        to: {
                            name: 'projectOverview',
                            params: { phase: 'order' },
                        },
                    },
                    {
                        icon: 'mdi-cash-register',
                        text: this.$localize('Project.phaseInvoices'),
                        to: {
                            name: 'projectOverview',
                            params: { phase: 'invoice' },
                        },
                    },
                    {
                        icon: 'mdi-archive',
                        text: this.$localize('Project.archive'),
                        to: {
                            name: 'projectOverview',
                            params: { phase: 'archive' },
                        },
                    },
                ],
                isOpen: false,
            },
            {
                icon: 'mdi-calendar-month',
                name: 'NavigationDrawer.link.calendar',
                if: () =>
                    this.$vuetify.breakpoint.smAndDown &&
                    this.$session.roles.includes('calendar_view'),
                to: { name: 'calendar' },
            },
            {
                icon: 'mdi-contacts',
                name: 'NavigationDrawer.link.relations',
                if: () => this.$session.roles.includes('relations_view'),
                to: { name: 'relationOverview', params: { type: 'customers' } },
            },
            {
                icon: 'mdi-shape-plus',
                name: 'NavigationDrawer.link.articles',
                if: () => this.$session.roles.includes('articles_view'),
                to: { name: 'articleOverview' },
            },
            {
                icon: 'mdi-cube-unfolded',
                name: 'NavigationDrawer.link.products',
                if: () => this.$session.roles.includes('products_manage'),
                to: { name: 'savedProductsOverview' },
            },
            {
                icon: 'mdi-cog',
                name: 'NavigationDrawer.link.settings',
                if: () =>
                    this.$session.roles.some((r) => r.startsWith('settings_')),
                to: { name: 'settingsOverview' },
            },
            {
                icon: 'mdi-code-braces',
                name: 'NavigationDrawer.link.advanced',
                to: { name: 'advanced' },
            },
        ]
    },
    methods: {
        async logout() {
            const result = await sendCommand('Logout', {})
            if (result.success) {
                closeWebsocket()
                clearSession()
                this.$router.push({ name: 'login' })
            }
        },
        async navBackToAdmin() {
            const result = await sendCommand('LoginToImpersonator', {})
            if (result.success) {
                closeWebsocket()
                this.session = await currentSession(true)
                this.$router.push({ name: 'adminHome' })
            }
        },
    },
}
</script>
<style scoped>
.list-item-menu-icon {
    position: absolute;
    right: 12px;
}
</style>
