<template>
	<dialog-form
		:value="value"
		@input="$emit('input', $event)"
		:title="$localize('Label.deleteLabel')"
		:allow-continue="false"
		@commit="save"
		action="delete"
		max-width="800"
	>
        {{ $localize('DeleteLabel.description') }}
	</dialog-form>
</template>

<script>
import DialogForm from '@/shared/components/DialogForm'
import sendCommand from '@/services/sendCommand'

export default {
	components: { DialogForm },
	props: {
		value: Boolean,
        labelId: String
	},
	methods: {
		async save(callback) {
			const result = await sendCommand('DeleteLabel', {
                labelId: this.labelId,
			})
            this.$store.dispatch('loadLabels', { force: true })
			if (result.success) {
				this.$emit('saved')
			}
			callback({ success: true })
		},
	},
}
</script>
