<template>
  <div :key="refreshKey">
    {{ scopeName }}
    <v-menu>
      <template v-slot:activator="{ on }">
        <v-btn v-on="on" small>{{ $localize('form.button.add') }}</v-btn>
      </template>
      <v-list>
        <v-list-item
          v-for="(statementDefinition) in statementDefinitions"
          :key="statementDefinition.id"
          @click="createStatement(statementDefinition.id)"
        >
          <v-list-item-title>{{ statementDefinition.name }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <draggable
      :list="statements"
      @start="dragging = true"
      @end="dragging = false; dragEnd()"
      class="list-group"
      ghost-class="ghost"
    >
      <div v-for="(statement, index) in statements" :key="index" class="ml-6">
        <code-statement
          v-model="statements[index]"
          :variables="variables"
          :code-context-id="codeContextId"
          @delete="deleteStatement(index)"
          @copy="copyStatement(index)"
          @input="refresh()"
        ></code-statement>
      </div>
    </draggable>
  </div>
</template>

<script>
import draggable from "vuedraggable";

import { createStatement, getStatements } from "@/services/code";
import { isGuid } from "@/services/validation"

export default {
  components: { draggable, CodeStatement: () => import("./CodeStatement.vue") },
  props: {
    codeContextId: {
      validator: isGuid,
      required: true
    },

    value: {
      type: Array,
      required: true,
      validator(value) {
        var notObject = value.filter(x => {
          if (
            x.id === undefined ||
            x.arguments === undefined ||
            x.scopes === undefined
          ) {
            console.error(
              'Invalid prop: type check failed for prop "value". Expected item in array to have to following properties defined: id, arguments, scopes'
            );
            console.log(x);
            return x;
          }
        });

        return notObject.length === 0;
      }
    },
    variables: {
      type: Array,
      required: true
    },
    scope: String
  },

  data() {
    return {
      dagging: false,
      refreshKey: 0,
      statements: this.value,
      statementDefinitions: []
    };
  },
  computed: {
    scopeName(){
      if(this.scope){
        return this.scope;
      }
      return this.$localize('CodeStatements.label.actions');
    }
  },
  watch: {
    value(val) {
      this.statements = val;
    }
  },
  async created() {
    this.statementDefinitions = await getStatements();
  },
  methods: {
    createStatement(id) {
      const statement = createStatement(id);
      this.statements.push(statement);

      this.$emit("input", this.statements);
    },
    deleteStatement(index) {
      this.statements.splice(index, 1);
      this.refreshKey++;
      this.$emit("input", this.statements);
    },
    copyStatement(index) {
      var statementToCopy = this.statements[index];
      var copiedStatement = JSON.parse(JSON.stringify(statementToCopy));
      this.statements.push(copiedStatement);
      this.refreshKey++;
      this.$emit("input", this.statements);
    },
    dragEnd(){
      this.refreshKey++;
      this.$emit("input", this.statements);
    },
    refresh() {
      this.$emit("input", this.statements);
    }
  }
};
</script>
