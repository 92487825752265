<template>
    <v-menu
        v-if="selectedElement"
        :value="value"
        @input="$emit('input', $event)"
        :close-on-content-click="false"
        :activator="selectedElement"
        offset-x
        max-width="600px"
    >
        <v-card color="lighten-4" min-width="400px" flat>
            <v-toolbar color="primary" dark>
                <v-icon>mdi-calendar-month</v-icon>
                <v-toolbar-title class="ml-1">{{
                    selectedEvent.name
                }}</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn
                    class="close-button"
                    fab
                    text
                    small
                    @click="$emit('input', false)"
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card-text>
                <div style="white-space: pre">{{ selectedEvent.details }}</div>
                <div v-if="selectedEvent.data">
                    <v-chip
                        v-for="user in $store.getters.getUsersByIds(
                            selectedEvent.data.users
                        )"
                        class="white--text whiteOutline"
                        :key="user.userId"
                        small
                        :color="user.color"
                    >
                        <v-icon small>mdi-account-tie</v-icon>
                        {{ user.name }}
                    </v-chip>
                    <v-chip
                        v-for="label in $store.getters.getLabelsByIds(
                            selectedEvent.data.labels
                        )"
                        class="white--text whiteOutline"
                        small
                        :key="label.labelId"
                        :color="label.color"
                    >
                        <v-icon small>mdi-label</v-icon>
                        {{ label.name }}
                    </v-chip>
                </div>
                <template v-for="fileId in selectedEvent.data.files">
                    <calendar-card-item-file :key="fileId" :file-id="fileId" />
                </template>
            </v-card-text>

            <v-card-actions v-if="$session.roles.includes('planning_manage')">
                <v-btn @click="moveToPreviousWeek(selectedEvent)">
                    {{ $localize('Calendar.button.moveToPreviousWeek') }}
                </v-btn>
                <v-btn
                    @click="
                        editAppointmentId = selectedEvent.data.appointmentId
                        editAppointment = true
                    "
                    ><v-icon>mdi-pencil</v-icon></v-btn
                >
                <v-btn
                    @click="
                        deleteAppointmentId = selectedEvent.data.appointmentId
                        deleteAppointment = true
                    "
                >
                    <v-icon>mdi-delete</v-icon>
                </v-btn>
                <v-btn @click="moveToNextWeek(selectedEvent)">
                    {{ $localize('Calendar.button.moveToNextWeek') }}
                </v-btn>
            </v-card-actions>
        </v-card>
        <v-card v-if="selectedEvent.data.project">
            <v-toolbar>
                <v-icon>{{ getEventIcon(selectedEvent) }}</v-icon>
                <v-toolbar-title class="ml-1">
                    {{ selectedEvent.data.project.project }}</v-toolbar-title
                >
                <v-spacer></v-spacer>
            </v-toolbar>
            <v-card-text>
                <v-row>
                    <v-col cols="6" v-if="selectedEvent.data.contact">
                        <b>Contactpersoon</b><br />
                        {{ selectedEvent.data.contact.name }}
                        <br />
                        <a :href="'tel:' + selectedEvent.data.contact.mobileNumber">{{ selectedEvent.data.contact.mobileNumber }}</a>
                        <br />
                        <a :href="'tel:' + selectedEvent.data.contact.alternativeNumber">{{ selectedEvent.data.contact.alternativeNumber }}</a>
                        <br />
                        <a :href="'mailto:' + selectedEvent.data.contact.email">{{ selectedEvent.data.contact.email }}</a>
                    </v-col>
                    <v-col cols="6" v-if="selectedEvent.data.address">
                        <b>Montage/aflever adres</b><br />
                        {{ selectedEvent.data.address.zipCode }}
                        {{ selectedEvent.data.address.city }}
                        <br />
                        {{ selectedEvent.data.address.street }}
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-btn
                    v-if="$session.roles.includes('projects_manage')"
                    @click="enterProjectInformation = true"
                >
                    <v-icon>mdi-pencil</v-icon>
                    <span class="ml-1">
                        {{ this.$localize('ProjectDetails.button.edit') }}
                    </span>
                </v-btn>
                <v-btn @click="exportPdfs = true">
                    <v-icon color="light-blue lighten-2"
                        >mdi-cloud-download</v-icon
                    >
                    <span class="ml-1">
                        {{ this.$localize('ProjectDetails.button.print') }}
                    </span>
                </v-btn>
                <v-btn
                    v-if="$session.roles.includes('projects_manage')"
                    @click="changeStatus = true"
                >
                    <v-icon color="success">mdi-sync</v-icon>
                    <span class="ml-1">
                        {{
                            this.$localize('ProjectDetails.button.changeStatus')
                        }}
                    </span>
                </v-btn>
                <v-btn @click="navProject">
                    <v-icon>mdi-open-in-app</v-icon>
                    <span class="ml-1">
                        {{ this.$localize('ProjectDetails.button.open') }}
                    </span>
                </v-btn>
            </v-card-actions>
        </v-card>
        <edit-appointment
            v-if="editAppointment"
            v-model="editAppointment"
            :appointment-id="editAppointmentId"
            @saved="
                $emit('input', false)
                $emit('refresh')
            "
        />
        <delete-appointment
            v-if="deleteAppointment"
            v-model="deleteAppointment"
            :appointment-id="deleteAppointmentId"
            @saved="
                $emit('input', false)
                $emit('refresh')
            "
        />
        <enter-project-information
            v-if="enterProjectInformation"
            v-model="enterProjectInformation"
            :project-id="selectedEvent.data.project.projectId"
            @saved="
                $emit('input', false)
                $emit('refresh')
            "
        />
        <pdf-exports
            v-if="exportPdfs"
            v-model="exportPdfs"
            :project-id="selectedEvent.data.project.projectId"
        />
        <change-status-wizard
            v-if="changeStatus"
            v-model="changeStatus"
            :project-id="selectedEvent.data.project.projectId"
            @saved="
                $emit('input', false)
                $emit('refresh')
            "
        />
    </v-menu>
</template>

<script>
import EditAppointment from '../dialogs/EditAppointment'
import DeleteAppointment from '../dialogs/DeleteAppointment'
import PdfExports from '@/main/projects/dialogs/PdfExports'
import ChangeStatusWizard from '@/main/projects/dialogs/ChangeStatusWizard'
import EnterProjectInformation from '@/main/projects/dialogs/EnterProjectInformation'

import CalendarCardItemFile from './CalendarCardItemFile'

import PubSub from 'pubsub-js'
import sendCommand from '@/services/sendCommand'

import { addWeeks } from 'date-fns'
import { getEventIcon } from '../calendarFunctions'
export default {
    components: {
        EditAppointment,
        DeleteAppointment,
        PdfExports,
        ChangeStatusWizard,
        EnterProjectInformation,
        CalendarCardItemFile,
    },
    props: {
        value: Boolean,
        selectedElement: {},
        selectedEvent: {},
    },
    data() {
        return {
            editAppointment: false,
            editAppointmentId: null,

            deleteAppointment: false,
            deleteAppointmentId: null,
            enterProjectInformation: false,
            exportPdfs: false,
            changeStatus: false,
        }
    },
    created() {
        this.$store.dispatch('loadUsers')
    },
    computed: {
        projectUser() {
            if (this.selectedEvent.data.project) {
                return this.$store.getters.getUserById(
                    this.selectedEvent.data.project.userId
                )
            }
            return null
        },
    },

    methods: {
        getEventIcon,

        async moveToPreviousWeek(event) {
            await sendCommand('MoveAppointmentTopreviousWeek', {
                appointmentId: event.data.appointmentId,
            })
            this.focus = addWeeks(this.focus, -1)
            this.$emit('input', false)
            this.$emit('refresh')
        },
        async moveToNextWeek(event) {
            await sendCommand('MoveAppointmentToNextWeek', {
                appointmentId: event.data.appointmentId,
            })
            this.focus = addWeeks(this.focus, 1)
            this.$emit('input', false)
            this.$emit('refresh')
        },
        navProject() {
            // Pre-emptively send active project phase for a smooth transition
            PubSub.publishSync('ActiveProjectPhase', {
                phase: this.selectedEvent.data.project.phase,
            })
            this.$router.push({
                name: 'projectDetails',
                params: {
                    projectId: this.selectedEvent.data.project.projectId,
                },
            })
        },
    },
}
</script>

<style lang="scss" scoped>
.v-card__actions {
    flex-wrap: wrap;
}
.v-chip {
    margin: 0 8px 8px 0;
}
</style>
