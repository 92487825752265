import { localize } from '@/plugins/Mountain'

const numberPattern = /^-?\d+[.,]?\d*$/
const emailPattern = /\S+@\S+$/
const guidPattern = /[a-f0-9]{8}(?:-[a-f0-9]{4}){3}-[a-f0-9]{12}/i
function hasValue(value) {
    if (value === '' || value === undefined || value === null) {
        return false
    }
    return true
}
export const required = (value) => {
    if (Array.isArray(value)) {
        return !!value.length || localize('Validation.required')
    }
    return (
        (value !== undefined && value !== null && value !== '') ||
        localize('Validation.required')
    )
}

export const number = (value) => {
    if (Array.isArray(value)) {
        return localize('Validation.numbersOnly')
    }

    if (Number.isNaN(value)) {
        return localize('Validation.numbersOnly')
    }
    const type = typeof value
    if (type === 'object' && value !== null) {
        return localize('Validation.numbersOnly')
    }
    if (hasValue(value)) {
        if (type === 'number') {
            return true
        } else if (type === 'string') {
            if (numberPattern.test(value)) {
                return true
            }
        }
    } else {
        return true
    }
    return localize('Validation.numbersOnly')
}
export const numberRange = (precision, scale) => (value) => {
    if (!hasValue(value)) {
        return true
    }
    if (!number(value)) {
        return true
    }

    value = toNumber(value)

    const max =
        Math.pow(10, precision - scale) - 1 + (1 - 1 / Math.pow(10, scale))
    const min = -max

    if (value > max || value < min) {
        return localize('Validation.numberPrecision', {
            precision: precision - scale,
            scale,
        })
    }
    return true
}

export const integer = (value) => {
    if (!hasValue(value)) {
        return true
    }
    if (parseInt(value) == value) {
        return true
    }

    return localize('Validation.integerOnly')
}
export const integerRange = (min, max) => (value) => {
    if (
        parseInt(value) == value &&
        parseInt(value) >= min &&
        parseInt(value) <= max
    ) {
        return true
    }
    return localize('Validation.numberRangeBetween', { min, max })
}

export const email = (value) => {
    if (!hasValue(value)) {
        return true
    }
    if (!emailPattern.test(value)) {
        return localize('Validation.emailOnly')
    }
    return true
}

export const maxLength = (max) => (value) => {
    if (!hasValue(value)) {
        return true
    }

    if (value.length > max) {
        return localize('Validation.maxLength', { max })
    }
    return true
}

export const minLength = (min) => (value) => {
    if (!hasValue(value)) {
        return true
    }

    if (value.length < min) {
        return localize('Validation.minLength', { min })
    }
    return true
}

export const length = (len) => (value) => {
    const error = minLength(len)(value)
    if (error === true) {
        return maxLength(len)(value)
    }
    return error
}

export const isGuid = (value) => {
    if (!hasValue(value)) {
        return true
    }
    if (!guidPattern.test(value)) {
        return localize('Validation.guid')
    }
    return true
}
export const toNumber = (input) => {
    if (typeof input === 'string') {
        const parsedText = input.replace(',', '.').trim()

        const number = parseFloat(parsedText)
        if (isNaN(number)) return null

        return number
    }
    if (typeof input === 'number' && !isNaN(input)) {
        return input
    }
    return null
}
