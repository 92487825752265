<template>
    <dialog-form
        :value="value"
        @input="$emit('input', $event)"
        @commit="save"
        :title="$localize('ChangeLicense.title')"
        :allow-continue="false"
    >
        <v-select
            v-model="changeLicense.maxProjects"
            :items="tiers"
            :item-text="itemText"
            item-value="maxProjects"
            @input="maxProjectsChanged"
            label="Licentie"
        >
            <template v-slot:item="{ item }">
                {{ itemText(item) }}
                <span v-if="item.disabled"> &nbsp;(huidige) </span>
            </template>
        </v-select>
        <v-alert
            v-if="changeLicense.maxProjects !== null && isDowngrade"
            type="info"
        >
            De gekozen licentie is kleiner dan uw huidige licentie, deze gaat
            begin volgende maand pas in.
        </v-alert>
        <p>Licentie starten</p>
        <v-btn-toggle v-model="changeLicense.start" mandatory>
            <v-btn :disabled="isDowngrade" value="now">
                <span class="hidden-sm-and-down">Per direct</span>
            </v-btn>
            <v-btn value="firstOfNextMonth">
                <span class="hidden-sm-and-down">Begin volgende maand</span>
            </v-btn>
        </v-btn-toggle>
        <v-alert
            v-if="
                changeLicense.maxProjects !== null &&
                !isDowngrade &&
                changeLicense.start === 'now' && 
                changeLicense.maxProjects !== currentLicense.maxProjects
            "
            class="mt-3"
            type="info"
        >
            De gekozen licentie zal per direct ingaan. Extra kosten van
            {{ $format.currency(extraCosts) }} worden gerekend op uw volgende
            factuur. Daarna betaald u het bovenstaande bedrag per maand.
        </v-alert>
    </dialog-form>
</template>

<script>
import DialogForm from '@/shared/components/DialogForm'
import sendCommand from '@/services/sendCommand'
import { getDaysInMonth } from 'date-fns'

export default {
    components: { DialogForm },
    props: {
        value: {
            type: Boolean,
            default: false,
        },
        currentLicense: {
            type: Object,
            required: false,
        },
    },
    data() {
        return {
            tiers: [],
            changeLicense: {
                maxProjects: this.currentLicense?.maxProjects || 100,
                start: 'now',
            },
        }
    },
    computed: {
        isDowngrade() {
            if (this.currentLicense !== null) {
                return (
                    this.currentLicense.maxProjects >
                    this.changeLicense.maxProjects
                )
            }
            return false
        },
        extraCosts() {
            const daysInMonth = getDaysInMonth(new Date())
            let daysLeftInMonth = daysInMonth - new Date().getDate()

            let oldCost = this.currentLicense.amount
            let newCost = (this.changeLicense.maxProjects / 100) * 20 + 10
            let costIncrease = newCost - oldCost
            let costIncreasePerDay = costIncrease / daysInMonth
            return costIncreasePerDay * daysLeftInMonth
        },
    },
    created() {
        for (let i = 1; i < 10; i++) {
            const amount = 10 + i * 20
            this.tiers.push({
                amount: amount,
                maxProjects: i * 100,
                disabled: this.currentLicense?.maxProjects === i * 100 && this.currentLicense?.amount === amount
            })
        }
    },
    methods: {
        async save(callback) {
            const result = await sendCommand(
                'ChangeLicense',
                this.changeLicense
            )

            if (result.success) {
                this.$emit('saved')
            }

            callback({ success: result.success })
        },
        itemText(item) {
            return (
                item.maxProjects +
                ' Projecten per maand (' +
                this.$format.currency(item.amount) +
                ')'
            )
        },
        maxProjectsChanged() {
            if (this.isDowngrade) {
                this.changeLicense.start = 'firstOfNextMonth'
            }
        },
    },
}
</script>
