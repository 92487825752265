import { getLabels } from '@/services/api'

export const labels = {
	state: {
		items: [],
		loaded: false,
	},
	getters: {
		getLabelById: (state) => (labelId) => {
			if (!labelId) return null
			const user = state.items.filter((l) => l.labelId === labelId)
			if (user.length > 0) {
				return user[0]
			}

			return null
		},
		getLabelsByIds: (state) => (labelIds) => {
			if (!labelIds) return []
			const filteredLabels = labelIds
				.map((labelId) => {
					const label = state.items.filter(
						(l) => l.labelId === labelId
					)
					if (label.length > 0) {
						return label[0]
					}
					return null
				})
				.filter((x) => x != null)
			return filteredLabels
		},
	},
	mutations: {
		setLabels(state, labels) {
			state.items = labels
			state.loaded = true
		},
	},
	actions: {
		async loadLabels(context, args) {
			if (context.state.loaded && (!args || args.force !== true)) return
			const { result, ok } = await getLabels.call()
			if (ok) {
				context.commit('setLabels', result)
			}
		},
	},
}
