<template>
	<v-skeleton-loader :loading="loading" type="list-item">
		<v-list>
			<v-list-item
				v-for="calculation in calculations"
				:key="calculation.calculationId"
				@click="$emit('calculate', calculation.calculationId)"
			>
				<v-list-item-title>{{ calculation.name }}</v-list-item-title>
			</v-list-item>
		</v-list>
	</v-skeleton-loader>
</template>

<script>
import { getAllPublishedCalculations } from '@/services/api'

export default {
	data() {
		return {
			calculations: [],
			loading: false,
		}
	},
	async created() {
		this.loading = true
		const { result, ok } = await getAllPublishedCalculations.call()
		if (ok) {
			this.calculations = result
		}
		this.loading = false
	},
}
</script>
