<template>
	<dialog-form
		:value="value"
		@input="$emit('input', $event)"
		:title="$localize('PdfExports.title')"
		:allow-continue="false"
		@commit="save"
		:loading="loading"
		:action-text="$localize('forms.buttons.download')"
		action-icon="mdi-cloud-download"
	>
		<select-address
			v-model="selectedAddressId"
			:relation-id="project.relationId"
			select-first
			select-first-of-type="$company"
		/>
		<select-contact
			v-model="selectedContactId"
			:relation-id="project.relationId"
		/>

		<v-switch
			v-if="$session.roles.includes('projects_calculate')"
			v-model="selectedFileTypes"
			value="order-confirmation"
			:label="$localize('ProjectDetails.button.orderConfirmation')"
		></v-switch>
		<v-switch
			v-if="$session.roles.includes('projects_calculate')"
			v-model="selectedFileTypes"
			value="cash-receipt"
			:label="$localize('ProjectDetails.button.cashReceipt')"
		></v-switch>
		<v-switch
			v-model="selectedFileTypes"
			value="production-order"
			:label="$localize('ProjectDetails.button.productionOrder')"
		></v-switch>
		<v-switch
			v-model="selectedFileTypes"
			value="packing-slip"
			:label="$localize('ProjectDetails.button.packingSlip')"
		></v-switch>
		<v-switch
			v-model="selectedFileTypes"
			value="anonymous-packing-slip"
			:label="$localize('ProjectDetails.button.anonmymousPackingSlip')"
		></v-switch>
		<v-switch
			v-model="selectedFileTypes"
			value="work-order"
			:label="$localize('ProjectDetails.button.workOrder')"
		></v-switch>
	</dialog-form>
</template>

<script>
import DialogForm from '@/shared/components/DialogForm'
import SelectAddress from '@/main/components/forms/SelectAddress'
import SelectContact from '@/main/components/forms/SelectContact'
import { isGuid } from '@/services/validation'
import { getProjectById, projectPdfDownload } from '@/services/api'

export default {
	components: { DialogForm, SelectAddress, SelectContact },
	props: {
		value: {
			type: Boolean,
			default: false,
		},
		projectId: {
			validator: isGuid,
			required: true,
		},
	},
	data() {
		return {
			loading: false,
			project: {},
			selectedAddressId: null,
			selectedContactId: null,
			selectedFileTypes: [],
		}
	},

	async created() {
		this.loading = true
		const { result, ok } = await getProjectById.call({
			args: { projectId: this.projectId },
		})
		if (ok) {
			this.project = result
			this.selectedContactId = this.project.contactId
		}
		this.loading = false
	},
	methods: {
		save(callback) {
			if (this.selectedFileTypes.length === 0) {
				callback({ success: false })
				return
			}
			var url = projectPdfDownload.create({
				projectId: this.projectId,
			}).url
			url += `?addressId=${this.selectedAddressId}`
			url += `&contactId=${this.selectedContactId}`
			url += `&fileTypes=${encodeURI(this.selectedFileTypes)}`
			window.open(url)
			callback({ success: true })
		},
	},
}
</script>
