<template>
  <dialog-form
    :value="value"
    @input="$emit('input', $event)"
    :title="$localize('CreateCalculation.title')"
    :allow-continue="false"
    @commit="save"
  >
    <v-text-field
      :label="$localize('Calculation.name')"
      v-model="createCalculation.name"
      :rules="[validation.required, validation.maxLength(50)]"
    />
  </dialog-form>
</template>

<script>
import sendCommand from "@/services/sendCommand";
import { required, maxLength } from "@/services/validation";
import DialogForm from "@/shared/components/DialogForm";
import Vue from "vue";

export default {
  components: { DialogForm },
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      createCalculation: {},
      validation: { required, maxLength }
    };
  },

  methods: {
    async save(callback) {
      const result = await sendCommand(
        "CreateCalculation",
        this.createCalculation
      );

      if (result.success) {
        this.$emit("saved");
        Vue.nextTick(() => {
          this.$router.push({
            name: "calculationDetails",
            params: { calculationId: result.generatedId }
          });
        });
      }

      callback({ success: result.success });
    }
  }
};
</script>