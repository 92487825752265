<template>
	<div v-if="grid && grid.length > 0">
		<v-container class="calculation-output" no-gutters>
			<v-row dense v-for="(row, rowIndex) in grid" :key="rowIndex">
				<v-col v-for="(cell, columnIndex) in row" :key="columnIndex">
					<div
						v-if="
							columnIndex !== 1 || !hideBuyPrice || rowIndex === 0
						"
						v-html="outputTemplate(cell)"
					></div>
				</v-col>
			</v-row>
		</v-container>
	</div>
</template>

<script>
import { encode } from 'html-entities'
const variableReplace = /\[\[(\w+):?(\w+)?\]\]/gi
const mdBold = /\*\*([^*]*)\*\*/g
const mdItalic = /\*([^*]*)\*/g

export default {
	props: {
		state: Object,
		grid: Array,
		test: Boolean,
        hideBuyPrice: Boolean
	},
	methods: {
		outputTemplate(template) {
			if (!template) return ''

			let value = this.replaceTemplate(template)

			value = encode(value, { level: 'xml' })

			value = this.basicMarkDown(value)

			return value
		},
		replaceTemplate(template) {
			const replaced = template.replace(
				variableReplace,
				(match, p1, p2) => {
					let value = this.state[p1]
					if (!value) {
						if (this.test) {
							return p1
						}
						return ''
					}
					const formatter = p2

					if (formatter) {
						value = this.formatValue(value, formatter)
					}

					return value
				}
			)
			return replaced
		},
		formatValue(value, formatter) {
			let formattedValue
			switch (formatter) {
				case 'currency':
					formattedValue = this.$format.currency(value)
					break
				default:
					return value
			}
			return formattedValue
		},
		basicMarkDown(value) {
			value = value.replace(mdBold, (match, p1) => {
				return '<strong>' + p1 + '</strong>'
			})
			value = value.replace(mdItalic, (match, p1) => {
				return '<i>' + p1 + '</i>'
			})
			return value
		},
	},
}
</script>
<style scoped lang="scss">
.row:first-child {
	background-color: #0277bd;
	color: white;
}
.row:last-child {
	border-bottom: 1px solid #0277bd;
}
.col:first-child {
	text-align: right;
	border-left: 1px solid #0277bd;
}
.col:last-child {
	border-right: 1px solid #0277bd;
}
</style>
