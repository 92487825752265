<template>
	<v-container class="pa-0">
		<v-row>
			<v-col>
				<v-toolbar flat height="36" class="my-3">
					<v-toolbar-items>
						<v-btn
							v-if="$session.roles.includes('articles_manage')"
							@click="enterArticleInformation = true"
							class="mr-3"
						>
							<v-icon>mdi-pencil</v-icon>
						</v-btn>
					</v-toolbar-items>
					<v-toolbar-title
						>{{ article.brand }} {{ article.name }}</v-toolbar-title
					>
					<v-spacer></v-spacer>
					<v-toolbar-items>
						<v-menu
							v-if="$session.roles.includes('articles_manage')"
						>
							<template v-slot:activator="{ on }">
								<v-btn v-on="on" class="primary">
									<v-icon>mdi-dots-vertical</v-icon>
								</v-btn>
							</template>
							<v-list>
								<v-list-item
									v-if="!article.isTrashed"
									@click="trash()"
								>
									<v-list-item-icon>
										<v-icon>mdi-delete</v-icon>
									</v-list-item-icon>
									<v-list-item-title>{{
										$localize('Article.cmd.TrashArticle')
									}}</v-list-item-title>
								</v-list-item>

								<v-list-item
									v-if="article.isTrashed"
									@click="untrash()"
								>
									<v-list-item-icon>
										<v-icon>mdi-delete-restore</v-icon>
									</v-list-item-icon>
									<v-list-item-title>{{
										$localize('Article.cmd.UntrashArticle')
									}}</v-list-item-title>
								</v-list-item>

								<v-list-item @click="copy()">
									<v-list-item-icon>
										<v-icon>mdi-content-copy</v-icon>
									</v-list-item-icon>
									<v-list-item-title>{{
										$localize('Article.cmd.CopyArticle')
									}}</v-list-item-title>
								</v-list-item>

								<v-list-item @click="deleteArticle = true">
									<v-list-item-icon>
										<v-icon>mdi-close</v-icon>
									</v-list-item-icon>
									<v-list-item-title>{{
										$localize('Article.cmd.DeleteArticle')
									}}</v-list-item-title>
								</v-list-item>
							</v-list>
						</v-menu>
					</v-toolbar-items>
				</v-toolbar>
			</v-col>
		</v-row>
		<v-row class="px-4">
			<v-col>
				<v-row>
					<v-col class="col-12 col-md-6">
						<v-row wrap dense>
							<v-col cols="12" class="grey--text">{{
								$localize('Article.subtitle.info')
							}}</v-col>
							<v-col cols="6">{{
								$localize('Article.name')
							}}</v-col>
							<v-col cols="6">{{ article.name }}</v-col>
							<v-col cols="6">{{
								$localize('Article.supplier')
							}}</v-col>
							<v-col cols="6">
								<relation-link
									:relation-id="article.supplierId"
                                    :text="article.supplierName"
								/>
							</v-col>
							<v-col cols="6">{{
								$localize('Article.brand')
							}}</v-col>
							<v-col cols="6">{{ article.brand }}</v-col>
							<v-col cols="6">{{
								$localize('Article.url')
							}}</v-col>
							<v-col cols="6">
								<a
									v-if="article.url"
									:href="article.url"
									target="_new"
								>
									{{ article.url }}
								</a>
							</v-col>
							<v-col cols="6">{{
								$localize('Article.category')
							}}</v-col>
							<v-col cols="6">{{ article.category }}</v-col>
							<v-col cols="12" class="grey--text">{{
								$localize('Article.subtitle.attributes')
							}}</v-col>
						</v-row>
						<v-row
							v-for="attributeType in requiredAttributeTypes"
							:key="attributeType.alias"
							dense
						>
							<v-col cols="6"
								>{{ attributeType.displayName }}

								<v-btn
									v-if="
										$session.roles.includes(
											'articles_manage'
										)
									"
									icon
									x-small
									@click="editAttribute(attributeType.alias)"
								>
									<v-icon>mdi-pencil</v-icon>
								</v-btn>
							</v-col>
							<v-col cols="6">
								{{ article.attributes[attributeType.alias] }}
							</v-col>
						</v-row>
					</v-col>
					<v-col v-if="article.note" class="col-12 col-md-6">
						<v-card class="pa-3 mb-3 pre">{{
							article.note
						}}</v-card>
					</v-col>
					<v-col class="col-12">
						<v-row dense>
							<v-col cols="12" class="grey--text">{{
								$localize('Article.subtitle.prices')
							}}</v-col>
						</v-row>
						<v-row
							v-for="priceType in requiredPriceTypes"
							:key="priceType.alias"
							dense
						>
							<v-col cols="3"
								>{{ priceType.displayName }}
								<v-btn
									v-if="
										$session.roles.includes(
											'articles_manage'
										)
									"
									icon
									x-small
									@click="editPrice(priceType.alias)"
								>
									<v-icon>mdi-pencil</v-icon>
								</v-btn>
							</v-col>
							<v-col cols="9">
								<article-price-table
									:prices="article.prices[priceType.alias]"
								/>
							</v-col>
						</v-row>
						<v-row dense>
							<v-col cols="3" class="grey--text">
								{{ $localize('Article.subtitle.calculations') }}
								<v-btn
									v-if="
										$session.roles.includes(
											'articles_manage'
										)
									"
									icon
									x-small
									@click="changeCalculations = true"
								>
									<v-icon>mdi-pencil</v-icon>
								</v-btn>
							</v-col>
							<v-col cols="9">
								<div
									v-for="calculation in calculations"
									:key="calculation.key"
								>
									{{ calculation.name }}
								</div>
							</v-col>
						</v-row>
					</v-col>
				</v-row>
			</v-col>
		</v-row>

		<enter-article-information
			v-if="enterArticleInformation"
			v-model="enterArticleInformation"
			:articleId="$route.params.articleId"
			:key="'info-' + $route.params.articleId"
			@saved="refresh()"
		/>
		<delete-article
			v-if="deleteArticle"
			v-model="deleteArticle"
			:articleId="$route.params.articleId"
			@saved="navArticleOverview"
		/>
		<enter-article-price
			v-if="enterPrice"
			v-model="enterPrice"
			:article-id="$route.params.articleId"
			:price-alias="enterPriceAlias"
			@saved="refresh"
		/>
		<change-article-calculations
			v-if="changeCalculations"
			v-model="changeCalculations"
			:article-id="$route.params.articleId"
			@saved="refresh()"
		/>
		<enter-article-attribute
			v-if="enterAttribute"
			v-model="enterAttribute"
			:article-id="$route.params.articleId"
			:attribute-alias="enterAttributeAlias"
			@saved="refresh()"
		/>
	</v-container>
</template>

<script>
import {
	getArticleById,
	getPriceTypes,
	getAttributeTypes,
	getCalculationTypes,
} from '@/services/api'
import EnterArticleInformation from './dialogs/EnterArticleInformation'
import DeleteArticle from './dialogs/DeleteArticle'
import EnterArticlePrice from './dialogs/EnterArticlePrice'
import EnterArticleAttribute from './dialogs/EnterArticleAttribute'
import ChangeArticleCalculations from './dialogs/ChangeArticleCalculations'
import ArticlePriceTable from './components/ArticlePriceTable'

import RelationLink from '../components/links/RelationLink'

import sendCommand from '@/services/sendCommand'
export default {
	components: {
		EnterArticleInformation,
		DeleteArticle,
		EnterArticlePrice,
		EnterArticleAttribute,
		ChangeArticleCalculations,
		ArticlePriceTable,
		RelationLink,
	},
	data() {
		return {
			enterArticleInformation: false,
			deleteArticle: false,
			changeCalculations: false,
			article: {},
			priceTypes: [],
			calculationTypes: [],

			attributeTypes: [],
			calculations: [],
			on: null,

			enterPriceAlias: null,
			enterPrice: false,

			enterAttributeAlias: null,
			enterAttribute: false,
		}
	},
	computed: {
		requiredAttributeTypes() {
			return this.attributeTypes.filter((x) => this.isRequired(x.alias))
		},
		requiredPriceTypes() {
			return this.priceTypes.filter((x) => this.isRequired(x.alias))
		},
	},
	created() {
		this.refresh()
	},
	methods: {
		isRequired(alias) {
			for (
				let index = 0;
				index < this.article.calculations.length;
				index++
			) {
				const calculationId = this.article.calculations[index]
				var calculation = this.calculationTypes.filter(
					(x) => x.calculationFieldId === calculationId
				)[0]
				if (calculation) {
					if (
						calculation.prices.filter((x) => x.alias === alias)
							.length > 0
					) {
						return true
					}
					if (
						calculation.attributes.filter((x) => x.alias === alias)
							.length > 0
					) {
						return true
					}
				}
			}
			return false
		},
		async refresh() {
			const { result, ok } = await getArticleById.call({
				args: { articleId: this.$route.params.articleId },
			})

			if (ok) {
				this.article = result
				await this.loadPriceTypes()
				await this.loadAttributeTypes()
				await this.loadCalculationTypes()
			}
		},

		async loadPriceTypes() {
			const { result, ok } = await getPriceTypes.call()
			if (ok) {
				this.priceTypes = result.filter((x) => x.isEnabled)
			}
		},
		async loadAttributeTypes() {
			const { result, ok } = await getAttributeTypes.call()
			if (ok) {
				this.attributeTypes = result.filter((x) => x.isEnabled)
			}
		},
		async loadCalculationTypes() {
			const { result, ok } = await getCalculationTypes.call()
			if (ok) {
                this.calculationTypes = result
				this.calculations = result
					.map((ct) => ({
						key: ct.calculationFieldId,
						name: ct.name,
						enabled:
							this.article.calculations.indexOf(
								ct.calculationFieldId
							) !== -1,
					}))
					.filter((x) => x.enabled)
			}
		},
		async trash() {
			const result = await sendCommand('TrashArticle', {
				articleId: this.$route.params.articleId,
			})
			if (result.success) {
				this.refresh()
			}
		},
		async untrash() {
			const result = await sendCommand('UntrashArticle', {
				articleId: this.$route.params.articleId,
			})
			if (result.success) {
				this.refresh()
			}
		},
		async copy() {
			const result = await sendCommand('CopyArticle', {
				articleToCopy: this.$route.params.articleId,
			})
			if (result.success) {
				this.$router.push({
					name: 'articleDetails',
					params: { articleId: result.generatedId },
				})
			}
		},
		editPrice(priceAlias) {
			this.enterPriceAlias = priceAlias
			this.enterPrice = true
		},
		editAttribute(attributeAlias) {
			this.enterAttributeAlias = attributeAlias
			this.enterAttribute = true
		},
		navBack() {
			if (this.article.isTrashed) {
				this.$router.push({ name: 'articleOverviewTrash' })
			} else {
				this.$router.push({ name: 'articleOverview' })
			}
		},
		async navArticleOverview() {
			await this.$nextTick()
			this.$router.push({ name: 'articleOverview' })
		},
	},
}
</script>
<style scoped>
.v-toolbar__title {
	overflow: visible;
}

.inline-checkbox {
	display: inline-block;
	margin: 0;
}
</style>
