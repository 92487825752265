<template>
  <v-app id="irisApp">
    <snackbar />
    <dev-tool v-if="$session.isAdministrator" v-show="showDevTool" />
    <app-bar v-if="!focus" />

    <v-main style="padding-bottom: 100px">
      <router-view :key="routingKey()" />

      <div class="focus-switcher-wrapper-outer">
        <div class="focus-switcher-wrapper-inner">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn
                fab
                small
                class="ml-2 mb-2"
                @click="focus = !focus"
                v-on="on"
              >
                <v-icon v-if="!focus">mdi-fullscreen</v-icon>
                <v-icon v-else>mdi-fullscreen-exit</v-icon>
              </v-btn>
            </template>
            <span>{{ $localize("Home.button.focusMode") }}</span>
          </v-tooltip>
        </div>
      </div>
    </v-main>
    <v-scale-transition origin="center center">
      <v-btn
        v-show="scrollY > 0"
        fab
        x-large
        bottom
        right
        fixed
        color="primary"
        @click="toTop"
      >
        <v-icon>mdi mdi-chevron-up</v-icon>
      </v-btn>
    </v-scale-transition>
  </v-app>
</template>
<script>
import Snackbar from "./Snackbar";
import AppBar from "./components/AppBar";
import DevTool from "./DevTool";

export default {
  components: { Snackbar, AppBar, DevTool },
  data() {
    return {
      scrollY: 0,
      focus: false,
      showDevTool: false
    };
  },
  async created() {
    window.addEventListener("scroll", this.onScroll);
    window.addEventListener("keydown", this.onKeyDown)
    this.onScroll();

    if (this.$settings.theme === "dark") {
      this.$vuetify.theme.dark = true;
    } else {
      this.$vuetify.theme.dark = false;
    }

    // Initialize languages
    const locale = this.$settings.language.split("-")[0];

    this.$vuetify.lang.current = locale; // vuetify
    window.__localeId__ = locale; // date-fns
  },
  destroyed() {
    window.removeEventListener("scroll", this.onScroll);
    window.removeEventListener("keydown", this.onKeyDown)
  },
  methods: {
    onScroll() {
      this.scrollY = window.scrollY;
    },
    onKeyDown(event){
      if(event.ctrlKey && event.shiftKey && event.keyCode === 57){
        this.showDevTool = !this.showDevTool;
      }
    },
    routingKey() {
      if (this.$router.currentRoute.name === 'articleOverview'){
          return this.$router.currentRoute.name; 
      }
      if (this.$router.currentRoute.matched.length >= 2 &&
          this.$router.currentRoute.matched[1].name === 'relationDetails') {
          return this.$router.currentRoute.matched[1].name; 
      }
      let key = this.$router.currentRoute.path;
      //console.log(this.$router.currentRoute)
      //console.log(key)
      return key;
      /*
      const matchedRoutes = this.$router.currentRoute.matched;
      if (matchedRoutes.length > 2) {
        key = matchedRoutes[1].name;
      } else {
        key = this.$router.currentRoute.path;
      }

      //key += window.location.search;

      return key;
      */
    },
    toTop() {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    },
  },
};
</script>
<style lang="scss">
.focus-switcher-wrapper-outer {
  position: absolute;
  left: 0;
}
.focus-switcher-wrapper-inner {
  position: fixed;
  bottom: 0;
}
</style>
