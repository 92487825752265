<template>
    <v-row no-gutters>
        <v-col>
            <v-autocomplete
                :value="selectedAddressId"
                @input="onAddressSelected($event)"
                :label="label || $localize('Address')"
                :items="addresses"
                :item-text="(x) => x.location + ' (' + x.typeName + ')'"
                item-value="addressId"
                :clearable="true"
                :disabled="!relationId"
                :rules="rules"
                prepend-icon="mdi-map-marker"
                :autofocus="autofocus"
                :loading="loading"
                @update:search-input="onSearchInput"
            />
        </v-col>
        <v-col
            v-if="$session.roles.includes('relations_manage')"
            align-self="center"
            cols="auto"
            class="ml-1"
        >
            <v-btn
                small
                outlined
                fab
                color="success"
                :disabled="!relationId"
                @click="createAddress = true"
            >
                <v-icon>mdi-plus</v-icon>
            </v-btn>
        </v-col>
        <v-lazy>
            <create-address
                v-if="relationId && createAddress"
                v-model="createAddress"
                :relation-id="relationId"
                :allow-continue="false"
                @saved="onAddressSaved($event)"
                :name="lastSearchInput"
            />
        </v-lazy>
    </v-row>
</template>

<script>
import { isGuid } from '@/services/validation'
import { required } from '@/services/validation'
import { getRelationAddresses } from '@/services/api'
import CreateAddress from '@/main/relations/dialogs/CreateAddress'

export default {
    components: { CreateAddress },
    props: {
        value: {
            validator: isGuid,
        },
        relationId: {
            validator: isGuid,
        },
        autofocus: Boolean,
        defaultType: String,
        selectFirst: Boolean,
        selectFirstOfType: String,
        label: String,
        required: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            loaded: false,
            selectedAddressId: this.value,
            createAddress: false,
            addresses: [],
            loading: false,
            lastSearchInput: null,
        }
    },
    computed: {
        rules() {
            if (this.required) {
                return [required]
            }
            return []
        },
    },
    watch: {
        value() {
            this.selectedAddressId = this.value
        },
        relationId: {
            immediate: true,
            handler() {
                this.refresh()
            },
        },
    },
    methods: {
        async refresh() {
            if (this.relationId) {
                this.loading = true
                const { result, ok } = await getRelationAddresses.call({
                    args: { relationId: this.relationId },
                })
                if (ok) {
                    this.addresses = result.filter((x) => !x.isTrashed)
                }
                if (!this.selectedAddressId) {
                    if (this.defaultType) {
                        var defaultAddresses = this.addresses.filter(
                            (x) => x.typeKey === this.defaultType
                        )
                        if (defaultAddresses.length > 0) {
                            this.onAddressSelected(
                                defaultAddresses[0].addressId
                            )
                        }
                    } else if (this.selectFirst) {
                        if (this.addresses.length > 0) {
                            var ofType = this.addresses.filter(
                                (x) => x.typeKey === this.selectFirstOfType
                            )
                            if (ofType.length > 0) {
                                this.onAddressSelected(ofType[0].addressId)
                            } else {
                                this.onAddressSelected(
                                    this.addresses[0].addressId
                                )
                            }
                        }
                    }
                }

                this.loading = false
                this.loaded = true
            }
        },
        async onAddressSaved(response) {
            this.selectedAddressId = response.generatedId
            await this.refresh()
            this.$emit('input', this.selectedAddressId)
        },
        onAddressSelected(addressId) {
            this.selectedAddressId = addressId
            this.$emit('input', this.selectedAddressId)
        },
        onSearchInput(searchInput) {
            if (searchInput !== null) {
                this.lastSearchInput = searchInput
            }
        },
    },
}
</script>
