<template>
	<v-row no-gutters>
		<v-col>
			<v-combobox
				v-model="selectedItems"
				:items="items"
				@input="emitInput"
				multiple
				prepend-icon="mdi-label"
				hide-selected
				label="Labels"
				:loading="loading"
                :menu-props="{ bottom: true }"
			>
				<template v-slot:selection="{ item, parent }">
					<v-chip class="white--text" :color="item.color">
						<span class="pr-2">
							{{ item.text }}
						</span>
						<v-icon small @click="parent.selectItem(item)"
							>$delete</v-icon
						>
					</v-chip>
				</template>
				<template v-slot:item="{ item }">
					<v-chip class="white--text" :color="item.color">{{
						item.text
					}}</v-chip>
					<v-spacer />
					<v-list-item-action @click.stop>
						<v-btn
							icon
							@click.stop.prevent="
								deleteLabelId = item.labelId
								deleteLabel = true
							"
						>
							<v-icon>mdi-delete</v-icon>
						</v-btn>
					</v-list-item-action>
					<v-list-item-action @click.stop>
						<v-btn
							icon
							@click.stop.prevent="
								editLabelObj = $store.getters.getLabelById(
									item.labelId
								)
								editLabel = true
							"
						>
							<v-icon>mdi-pencil</v-icon>
						</v-btn>
					</v-list-item-action>
				</template>
			</v-combobox>
		</v-col>
		<v-col align-self="center" cols="auto" class="ml-1">
			<v-btn
				small
				outlined
				fab
				color="success createRelation"
				@click="createLabel = true"
			>
				<v-icon>mdi-plus</v-icon>
			</v-btn>
		</v-col>

		<create-label v-model="createLabel" @saved="refreshLabels(true)" />
		<delete-label
			v-model="deleteLabel"
			:label-id="deleteLabelId"
			@saved="refreshLabels(true)"
		/>
		<edit-label
			v-if="editLabelObj !== null"
			v-model="editLabel"
			:label="editLabelObj"
			@saved="refreshLabels(true)"
		/>
	</v-row>
</template>

<script>
import CreateLabel from '@/main/calendar/dialogs/CreateLabel'
import EditLabel from '@/main/calendar/dialogs/EditLabel'
import DeleteLabel from '@/main/calendar/dialogs/DeleteLabel'
export default {
	components: { CreateLabel, EditLabel, DeleteLabel },
	props: {
		value: Array,
	},
	data() {
		return {
			selectedItems: [],
			items: [],
			loading: false,

			createLabel: false,
			editLabel: false,
			editLabelObj: null,

			deleteLabel: false,
			deleteLabelId: null,
		}
	},
	watch: {
		value: {
			immediate: true,
			handler(value) {
				if (value) {
					this.selectedItems = this.$store.getters
						.getLabelsByIds(value)
						.map(this.mapLabel)
						.filter((x) => x !== null)
				} else {
					this.selectedItems = []
				}
			},
		},
	},
	async created() {
		await this.refreshLabels()
	},
	methods: {
		async refreshLabels(force) {
			this.loading = true
            await this.$store.dispatch('loadLabels',{force})
			this.loading = false

			this.items = this.$store.state.labels.items
				.map(this.mapLabel)
				.filter((x) => x !== null)
			this.selectedItems = this.$store.getters
				.getLabelsByIds(this.value)
				.map(this.mapLabel)
				.filter((x) => x !== null)
		},
		mapLabel(label) {
			if (!label) return null
			return {
				labelId: label.labelId,
				color: label.color,
				text: label.name,
			}
		},
		emitInput() {
			this.$emit(
				'input',
				this.selectedItems.map((x) => x.labelId)
			)
		},
	},
}
</script>

<style></style>
