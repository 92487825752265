<template>
	<v-row no-gutters>
		<v-col>
			<v-autocomplete
				:value="selectedContactId"
				@input="onContactSelected($event)"
				:label="label ?? $localize('SelectContact.field.contact')"
				:items="contacts"
				item-text="name"
				item-value="contactId"
				:clearable="clearable"
				:disabled="!relationId"
				:rules="rules"
				:loading="loading"
				prepend-icon="mdi-account"
				:autofocus="autofocus"
				@update:search-input="onSearchInput"
			/>
		</v-col>
		<v-col
			v-if="$session.roles.includes('relations_manage')"
			align-self="center"
			cols="auto"
			class="ml-1"
		>
			<v-btn
				small
				outlined
				fab
				color="success"
				@click="createContact = true"
				:disabled="!relationId"
			>
				<v-icon>mdi-plus</v-icon>
			</v-btn>
		</v-col>

		<create-contact
			v-if="relationId && createContact"
			v-model="createContact"
			:allow-continue="false"
			:relationId="relationId"
			@saved="onContactSaved($event)"
			:name="lastSearchInput"
		/>
	</v-row>
</template>

<script>
import { isGuid } from '@/services/validation'
import { getRelationContacts } from '@/services/api'
import { required } from '@/services/validation'
import CreateContact from '@/main/relations/dialogs/CreateContact'

export default {
	components: { CreateContact },
	props: {
		value: {
			validator: isGuid,
		},
		relationId: {
			validator: isGuid,
		},
		autofocus: Boolean,
		clearable: {
			type: Boolean,
			default: true,
		},
		label: String,
		required: {
			type: Boolean,
			default: true,
		},
	},

	data() {
		return {
			contacts: [],
			createContact: false,
			selectedContactId: this.value,
			rules: this.required ? [required] : [],
			loading: false,
			loaded: false,
			lastSearchInput: null,
		}
	},

	watch: {
		relationId: {
			immediate: true,
			handler() {
				if (!this.relationId) return
				this.refresh()
			},
		},
		value: {
			immediate: true,
			handler() {
				this.selectedContactId = this.value
			},
		},
	},
	methods: {
		async refresh() {
			this.loading = true
			const { result, ok } = await getRelationContacts.call({
				args: { relationId: this.relationId },
			})
			if (ok) {
				this.contacts = result
			}
			this.loading = false
		},
		async onContactSaved(response) {
			this.selectedContactId = response.generatedId
			await this.refresh()
			this.$emit('input', this.selectedContactId)
		},
		onContactSelected(contactId) {
			this.selectedContactId = contactId
			this.$emit('input', contactId)
		},
		onSearchInput(searchInput) {
			if (searchInput !== null) {
				this.lastSearchInput = searchInput
			}
		},
	},
}
</script>
