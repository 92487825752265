<template>
  <v-app>
    <div class="login-bg">
      <v-container fill-height>
        <router-view />
      </v-container>
    </div>
  </v-app>
</template>

<script>

export default {
};
</script>
<style lang="scss" scoped>
.login-bg {
  background: rgba(106, 171, 212, 1);
  background: radial-gradient(
    circle 400px at center,
    rgb(123, 194, 238) 0%,
    rgba(62, 138, 188, 1) 100%
  );
  height: 100%;
  overflow: auto;
}
</style>