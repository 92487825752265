<template>
	<v-container fluid class="pa-0">
		<v-row>
			<v-col>
				<v-toolbar flat height="36" class="my-3">
					<v-toolbar-items>
						<v-btn
                            v-if="$session.roles.includes('relations_manage')"
							v-on="on"
							@click="enterRelationInformation = true"
							class="mr-3 btnEnterRelationInformation"
						>
							<v-icon>mdi-pencil</v-icon>
						</v-btn>
					</v-toolbar-items>

					<v-toolbar-title>{{
						relation.companyName
					}}</v-toolbar-title>
					<v-spacer></v-spacer>
					<v-toolbar-items>
						<v-btn
							depressed
							@click="downloadInvoiceOverview()"
							class="btnDownloadInvoiceOverview"
						>
							<v-icon color="red">mdi-adobe-acrobat</v-icon>
							<span
								v-if="$vuetify.breakpoint.smAndUp"
								class="ml-1"
							>
								{{
									$localize(
										'RelationsDetails.button.outstandingInvoices'
									)
								}}
							</span>
						</v-btn>

						<v-menu v-if="$session.roles.includes('relations_manage')">
							<template v-slot:activator="{ on }">
								<v-btn
									v-on="on"
									class="primary btnRelationMenu"
								>
									<v-icon>mdi-dots-vertical</v-icon>
								</v-btn>
							</template>
							<v-list>
								<v-list-item
									@click="assignRelationManager = true"
									class="btnAssignRelationManager"
								>
									<v-list-item-icon>
										<v-icon>mdi-account-tie</v-icon>
									</v-list-item-icon>
									<v-list-item-title>{{
										$localize(
											'RelationDetails.button.assignManager'
										)
									}}</v-list-item-title>
								</v-list-item>

								<v-list-item
									@click="createRelationNote = true"
									class="btnCreateRelationNote"
								>
									<v-list-item-icon>
										<v-icon>mdi-plus</v-icon>
									</v-list-item-icon>
									<v-list-item-title>{{
										$localize(
											'RelationDetails.button.createNote'
										)
									}}</v-list-item-title>
								</v-list-item>

								<v-list-item
									v-if="!relation.isTrashed"
									@click="trash()"
									class="btnTrashRelation"
								>
									<v-list-item-icon>
										<v-icon>mdi-delete</v-icon>
									</v-list-item-icon>
									<v-list-item-title>{{
										$localize('form.button.trash')
									}}</v-list-item-title>
								</v-list-item>

								<v-list-item
									v-if="relation.isTrashed"
									@click="untrash()"
									class="btnUntrashRelation"
								>
									<v-list-item-icon>
										<v-icon>mdi-delete-restore</v-icon>
									</v-list-item-icon>
									<v-list-item-title>{{
										$localize('form.button.untrash')
									}}</v-list-item-title>
								</v-list-item>

								<v-list-item
									@click="deleteRelation = true"
									class="btnDeleteRelation"
								>
									<v-list-item-icon>
										<v-icon>mdi-close</v-icon>
									</v-list-item-icon>
									<v-list-item-title>{{
										$localize('form.button.delete')
									}}</v-list-item-title>
								</v-list-item>
							</v-list>
						</v-menu>
					</v-toolbar-items>
				</v-toolbar>
			</v-col>
		</v-row>
		<v-row>
			<v-col class="col-12 col-md-6">
				<v-row dense class="px-4">
					<v-col cols="6">{{
						$localize('Relation.companyName')
					}}</v-col>
					<v-col cols="6">{{ relation.companyName }}</v-col>
					<v-col cols="6">{{ $localize('Relation.phone') }}</v-col>
					<v-col cols="6">{{ relation.phoneNumber }}</v-col>
					<v-col cols="6">{{ $localize('Relation.email') }}</v-col>
					<v-col cols="6">{{ relation.email }}</v-col>
					<v-col cols="6">{{
						$localize('Relation.invoiceEmail')
					}}</v-col>
					<v-col cols="6">{{ relation.invoiceEmail }}</v-col>

					<v-col cols="6">{{ $localize('Relation.website') }}</v-col>
					<v-col cols="6">{{ relation.website }}</v-col>
					<v-col cols="6">{{ $localize('Relation.fax') }}</v-col>
					<v-col cols="6">{{ relation.faxNumber }}</v-col>
					<v-col cols="6">{{ $localize('Relation.tax') }}</v-col>
					<v-col cols="6">{{ relation.taxNumber }}</v-col>
					<v-col cols="6">{{ $localize('Relation.manager') }}</v-col>
					<v-col cols="6">
						<router-link
							v-if="relation.managerId"
							:to="{
								name: 'userDetails',
								params: { userId: relation.managerId },
							}"
							>{{ relation.managerName }}</router-link
						>
					</v-col>
					<v-col cols="6">{{ $localize('Relation.group') }}</v-col>
					<v-col cols="6">
						<router-link
							:to="{
								name: 'settingsOverview',
								params: { category: 'relationGroups' },
							}"
							>{{ relation.groupName }}</router-link
						>
					</v-col>
					<v-col cols="6">{{
						$localize('Relation.externalId')
					}}</v-col>
					<v-col cols="6">{{ relation.externalId }}</v-col>
				</v-row>
			</v-col>
		</v-row>
		<v-row>
			<v-col>
				<v-tabs v-model="tab" center-active>
					<v-tab
						key="projects"
						:to="{
							name: 'relationProjectOverview',
							params: { relationId: $route.params.relationId },
						}"
						>{{ $localize('Relation.label.projects') }}</v-tab
					>
					<v-tab
						key="contacts"
						:to="{
							name: 'contactOverview',
							params: { relationId: $route.params.relationId },
						}"
						>{{ $localize('Relation.label.contacts') }}</v-tab
					>
					<v-tab
						key="addresses"
						:to="{
							name: 'addressOverview',
							params: { relationId: $route.params.relationId },
						}"
						>{{ $localize('Relation.label.addresses') }}</v-tab
					>
				</v-tabs>
				<router-view :key="$route.path + key" />
			</v-col>
		</v-row>
		<v-navigation-drawer right app width="400" clipped>
			<project-notes :relation-id="$route.params.relationId" />
		</v-navigation-drawer>
		<enter-relation-information
			v-if="enterRelationInformation"
			v-model="enterRelationInformation"
			:relation-id="$route.params.relationId"
			@saved="refresh()"
		/>
		<delete-relation
			v-if="deleteRelation"
			v-model="deleteRelation"
			:relation-id="$route.params.relationId"
			@saved="navRelationOverview"
		/>
		<assign-relation-manager
			v-if="assignRelationManager"
			v-model="assignRelationManager"
			:relation-id="$route.params.relationId"
			@saved="refresh()"
		/>
		<create-relation-note
			v-if="createRelationNote"
			v-model="createRelationNote"
			:relation-id="$route.params.relationId"
			@saved="refresh()"
		/>
	</v-container>
</template>

<script>
import { getRelationById, getInvoiceOverview } from '@/services/api'
import sendCommand from '@/services/sendCommand'

import EnterRelationInformation from './dialogs/EnterRelationInformation'
import DeleteRelation from './dialogs/DeleteRelation'
import AssignRelationManager from './dialogs/AssignRelationManager'
import CreateRelationNote from './dialogs/CreateRelationNote'
import ProjectNotes from '../projects/components/ProjectNotes'

export default {
	components: {
		EnterRelationInformation,
		DeleteRelation,
		AssignRelationManager,
		CreateRelationNote,
		ProjectNotes,
	},

	data() {
		return {
			relation: {
				isTrashed: false,
			},
			enterRelationInformation: false,
			deleteRelation: false,
			assignRelationManager: false,
			createRelationNote: false,

			selectContactId: null,

			selectAddressId: null,

			tab: 'contacts',
			on: null,
			key: 0,
		}
	},
	created() {
		this.refresh()
	},

	methods: {
		async refresh() {
			const { result, ok } = await getRelationById.call({
				args: { relationId: this.$route.params.relationId },
			})
			if (ok) {
				this.relation = result
			}
		},
		async trash() {
			const result = await sendCommand('TrashRelation', {
				relationId: this.relation.relationId,
			})
			if (result.success) {
				await this.refresh()
			}
		},
		async untrash() {
			const result = await sendCommand('UntrashRelation', {
				relationId: this.relation.relationId,
			})
			if (result.success) {
				await this.refresh()
			}
		},
		navBack() {
			if (this.relation.isTrashed) {
				this.$router.push({ name: 'relationOverviewTrash' })
			} else {
				this.$router.push({ name: 'relationOverview' })
			}
		},
		async navRelationOverview() {
			await this.$nextTick()
			this.$router.push({ name: 'relationOverview' })
		},
		downloadInvoiceOverview() {
			const url = getInvoiceOverview.create({
				relationId: this.$route.params.relationId,
			}).url
			window.open(url)
		},
	},
}
</script>

<style scoped>
.v-toolbar__title {
	overflow: visible;
}
</style>
