<template>
	<dialog-form
		:value="value"
		@input="$emit('input', $event)"
		:title="
			$localize('BulkLinkSupplier.title', { count: articleIds.length })
		"
		@commit="save"
		:commit-message="$localize('BulkLinkSupplier.commitMessage')"
		:allow-continue="false"
	>
		<select-relation
			v-model="selectedSupplierId"
			:customers="false"
			:suppliers="true"
			autofocus
            :rules="[validation.required]"
		/>
	</dialog-form>
</template>

<script>
import DialogForm from '@/shared/components/DialogForm'
import sendCommand from '@/services/sendCommand'
import { required } from '@/services/validation'
import SelectRelation from '@/main/components/forms/SelectRelation'
export default {
	components: { DialogForm, SelectRelation },
	props: {
		value: {
			type: Boolean,
			default: false,
		},
		articleIds: {
			type: Array,
			default: () => [],
		},
	},
	data() {
		return {
			selectedSupplierId: null,
            validation: {required}
		}
	},
	methods: {
		async save(callback) {
			const result = await sendCommand('BulkLinkSupplier', {
				articleIds: this.articleIds,
				supplierId: this.selectedSupplierId,
			})
			if (result.success) {
				this.$emit('saved')
			}
			callback({ success: result.success })
		},
	},
}
</script>
