<template>
    <v-dialog :value="value" @input="$emit('input', $event)" max-width="600px">
        <v-card>
            <v-card-title>Add License</v-card-title>
            <v-form @submit.prevent="save()" class="pa-3" ref="form">
                <v-select
                    v-model="addLicenseCommand.license"
                    :items="licenses"
                    label="Licentie"
                    :rules="[validation.required]"
                />
                <date-select
                    label="start"
                    v-model="addLicenseCommand.start"
                    :rules="[validation.required]"
                />
                <date-select
                    label="end"
                    v-model="addLicenseCommand.end"
                    clearable
                />
                <v-text-field
                    label="Max Projecten"
                    v-model="addLicenseCommand.maxProjects"
                />
                <v-text-field
                    label="Bedrag per maand"
                    v-model="addLicenseCommand.amount"
                    :rules="[validation.required]"
                />

                <v-btn @click="$emit('input', false)" class="mt-3 mr-3">{{
                    $localize('form.button.cancel')
                }}</v-btn>
                <v-btn type="submit" class="success mt-3 mr-3">{{
                    $localize('form.button.save')
                }}</v-btn>
            </v-form>
        </v-card>
    </v-dialog>
</template>

<script>
import sendCommand from '@/services/sendCommand'
import { isGuid, required } from '@/services/validation'
import DateSelect from '@/shared/components/DateSelect'

export default {
    components: { DateSelect },
    props: {
        value: {
            type: Boolean,
            default: false,
        },
        tenantId: {
            validator: isGuid,
            required: true,
        },
    },
    data() {
        return {
            addLicenseCommand: {},
            licenses: ['free', 'standard'],
            validation: { required },
        }
    },
    methods: {
        async save() {
            if (!this.$refs.form || !this.$refs.form.validate()) {
                return
            }
            this.addLicenseCommand.tenantId = this.tenantId
            const result = await sendCommand(
                'AddLicense',
                this.addLicenseCommand
            )
            if (result.success) {
                this.addLicenseCommand = {}
                this.$emit('input', false)
                this.$emit('saved')
            }
        },
    },
}
</script>
