<template>
	<div>
		<v-toolbar flat height="36" class="my-3">
			<v-spacer></v-spacer>
			<v-toolbar-items>
				<v-btn
					v-if="$session.roles.includes('relations_manage')"
					@click="createAddress = true"
					class="primary"
				>
					<v-icon class="mr-1">mdi-plus</v-icon>
					{{ $localize('RelationDetails.button.createAddress') }}
				</v-btn>
			</v-toolbar-items>
		</v-toolbar>
		<v-data-table
			:items="addresses"
			:headers="headers"
			@click:row="addressId = $event.addressId"
			:loading="loading"
			:footer-props="{ 'items-per-page-options': [10, 20, 50, -1] }"
			:options.sync="options"
			dense
		>
			<template v-slot:footer="{}">
				<v-divider />
				<v-row no-gutters class="mx-3">
					<v-col cols="auto">
						<v-checkbox
							:label="$localize('form.label.trash')"
							v-model="trash"
							@change="filter"
							class="pt-0 my-1 btnTrash"
							hide-details
						/>
					</v-col>
				</v-row>
			</template>
		</v-data-table>
		<address-details
			v-if="addressId"
			:address-id="addressId"
			:key="addressId"
			@saved="refresh()"
			@closed="
				refresh()
				addressId = null
			"
		/>
		<create-address
			v-if="createAddress"
			v-model="createAddress"
			:relation-id="$route.params.relationId"
			@saved="refresh()"
		/>
	</div>
</template>

<script>
import { getRelationAddresses } from '@/services/api'
import AddressDetails from './AddressDetails'
import CreateAddress from './dialogs/CreateAddress'

export default {
	components: { AddressDetails, CreateAddress },
	data() {
		return {
			headers: [],
			allAddresses: [],
			addresses: [],
			addressId: null,
			createAddress: false,
			trash: false,
			loading: false,
			options: JSON.parse(
				localStorage.getItem('AddressOverview:options')
			) || {
				itemsPerPage: -1,
			},
		}
	},
	created() {
		this.refresh()
		this.headers = [
			{ text: this.$localize('Address.location'), value: 'location' },
			{ text: this.$localize('Address.typeName'), value: 'typeName' },
			{ text: this.$localize('Address.street'), value: 'street' },
			{
				text: this.$localize('Address.extraAddressLine'),
				value: 'extraAddressLine',
			},
			{ text: this.$localize('Address.zipCode'), value: 'zipCode' },
			{ text: this.$localize('Address.city'), value: 'city' },
			{ text: this.$localize('Address.email'), value: 'email' },
		]
	},
	watch: {
		options() {
			localStorage.setItem(
				'AddressOverview:options',
				JSON.stringify(this.options)
			)
		},
	},
	methods: {
		async refresh() {
			this.loading = true
			const { result, ok } = await getRelationAddresses.call({
				args: { relationId: this.$route.params.relationId },
			})
			if (ok) {
				this.allAddresses = result
				this.filter()
			}
			this.loading = false
		},
		filter() {
			this.addresses = this.allAddresses.filter(
				(x) => x.isTrashed === this.trash
			)
		},
	},
}
</script>
