var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',[_c('v-card-title',[_vm._v(_vm._s(_vm.$localize('RelationImportLink.title'))+": "+_vm._s(_vm.relationImport.originalFileName))]),(_vm.relationImport)?_c('v-card-text',[_c('v-form',{ref:"linkingForm"},[_c('v-row',{staticClass:"linkingOptions"},[_c('v-col',[_c('v-select',{attrs:{"items":[1, 2, 3, 4, 5],"label":"Aantal contact personen","outlined":"","hide-details":"","dense":""},on:{"change":_vm.loadLinks},model:{value:(_vm.contactCount),callback:function ($$v) {_vm.contactCount=$$v},expression:"contactCount"}})],1),_c('v-col',[_c('v-select',{attrs:{"items":[1, 2, 3, 4, 5],"label":"Aantal adressen","outlined":"","hide-details":"","dense":""},on:{"change":_vm.loadLinks},model:{value:(_vm.addressCount),callback:function ($$v) {_vm.addressCount=$$v},expression:"addressCount"}})],1)],1),_c('div',{staticClass:"import-wrapper"},[_c('table',{staticClass:"import-table"},[_c('thead',[_c('tr',_vm._l((_vm.columnCount),function(index){return _c('th',{key:index - 1},[_c('v-autocomplete',{attrs:{"solo":"","items":_vm.links,"item-value":"id","item-text":"text","rules":[
										_vm.validation.nonDuplicate,
										_vm.validation.required,
									],"clearable":""},on:{"change":function($event){return _vm.saveLinks()}},model:{value:(
										_vm.relationImport.links[index - 1]
									),callback:function ($$v) {_vm.$set(_vm.relationImport.links, index - 1, $$v)},expression:"\n\t\t\t\t\t\t\t\t\t\trelationImport.links[index - 1]\n\t\t\t\t\t\t\t\t\t"}})],1)}),0),(_vm.relationImport.hasHeader)?_c('tr',{staticClass:"preview-header"},_vm._l((_vm.data[0]),function(column,columnIndex){return _c('th',{key:columnIndex,class:{
									excluded:
										_vm.relationImport.links[
											columnIndex
										] === '-1',
								}},[_vm._v(" "+_vm._s(column)+" ")])}),0):_vm._e()]),_c('tbody',_vm._l((_vm.data),function(row,index){return _c('tr',{key:index},[(
									index > 0 || !_vm.relationImport.hasHeader
								)?_vm._l((row),function(column,columnIndex){return _c('td',{key:index + '-' + columnIndex,class:{
										excluded:
											_vm.relationImport.links[
												columnIndex
											] === '-1',
									}},[_c('span',{attrs:{"title":row[column]}},[_vm._v(_vm._s(column))])])}):_vm._e()],2)}),0)])])],1)],1):_vm._e(),_c('v-card-actions',[_c('v-btn',{on:{"click":function($event){return _vm.$emit('back')}}},[_vm._v(_vm._s(_vm.$localize('form.button.back')))]),_c('v-btn',{staticClass:"success",on:{"click":_vm.confirm}},[_vm._v(_vm._s(_vm.$localize('form.button.confirm')))])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }