<template>
	<v-container fluid class="pa-0">
		<v-row>
			<v-col>
				<v-toolbar flat height="36" class="my-3">
					<v-toolbar-title>{{
						$localize('ArticleOverview.title')
					}}</v-toolbar-title>

					<v-spacer></v-spacer>
					<v-toolbar-items
						v-if="$session.roles.includes('articles_manage')"
					>
						<v-btn
							@click="navImport()"
							class="mr-3 btnArticleImport"
						>
							<v-icon class="mr-1">mdi-database-import</v-icon>
							{{ $localize('ArticleOverview.import') }}
						</v-btn>
						<v-btn
							@click="downloadCsv()"
							class="mr-3 btnDownloadCsv"
						>
							<v-icon class="mr-1">mdi-database-export</v-icon>
							{{ $localize('ArticleOverview.export') }}
						</v-btn>
						<v-btn
							class="primary btnCreateArticle"
							@click="createArticle = true"
						>
							<v-icon class="mr-1">mdi-plus</v-icon>
							{{ $localize('CreateArticle.title') }}
						</v-btn>
					</v-toolbar-items>
				</v-toolbar>
			</v-col>
		</v-row>
		<v-row v-if="$vuetify.breakpoint.smAndDown">
			<v-col>
				<v-select
					:items="categories"
					item-text="text"
					item-value="value"
					class="mx-4 selectCategory"
					:value="category"
					outlined
					rounded
					hide-details
					@change="navCategory($event)"
				/>
			</v-col>
		</v-row>

		<v-row
			no-gutters
			align="stretch"
			justify="space-between"
			class="flex-nowrap"
		>
			<v-col cols="auto" v-if="$vuetify.breakpoint.mdAndUp">
				<v-tabs vertical>
					<v-tab
						v-for="category in categories"
						:key="category.value"
						:to="{
							name: 'articleOverview',
							params: {
								category: category.value,
								trash: $route.params.trash,
							},
						}"
					>
						<span v-if="category === '$$all'">{{
							$localize('ArticleOverview.label.allArticles')
						}}</span>
						<span v-else-if="category">{{ category.text }}</span>
						<span v-else>{{
							$localize('ArticleOverview.label.noCategory')
						}}</span>
					</v-tab>
				</v-tabs>
			</v-col>
			<v-col>
				<router-view :key="updateKey" @refresh="refresh" />
			</v-col>
		</v-row>

		<create-article
			v-if="createArticle"
			v-model="createArticle"
			@saved="
				updateKey++
				refresh()
			"
		/>
	</v-container>
</template>

<script>
import CreateArticle from './dialogs/CreateArticle'
import { getArticleCategories, downloadArticles } from '@/services/api'

export default {
	components: { CreateArticle },
	data() {
		return {
			categories: [],
			createArticle: false,
			tabValue: 0,
			updateKey: 0,
		}
	},
	computed: {
		trash() {
			return this.$route.params.trash === 'trash'
		},
		category() {
			return this.$route.params.category || '$$all'
		},
	},
	watch: {
		'$route.params.trash'() {
			this.refresh()
		},
	},
	created() {
		this.refresh()
	},
	methods: {
		async refresh() {
			const { result, ok } = await getArticleCategories.call({
				q: { trash: this.trash },
			})

			if (ok) {
				const categories = result
				this.categories = categories.map((category) => {
					return {
						text:
							category ||
							this.$localize('ArticleOverview.label.noCategory'),
						value: category || '$$none',
					}
				})
				this.categories.splice(0, 0, {
					text: this.$localize('ArticleOverview.label.allArticles'),
					value: '$$all',
				})
				if (
					categories.indexOf(this.category) === -1 &&
					this.category !== '$$all' &&
					this.category !== '$$none'
				) {
					this.navCategory('$$all')
				}
			}
		},

		navCategory(category) {
			this.$router.push({
				name: 'articleOverview',
				params: {
					category: category,
					trash: this.$route.params.trash,
				},
			})
		},

		navImport() {
			this.$router.push({
				name: 'articleImport',
			})
		},
		downloadCsv() {
			const downloadUrl = downloadArticles.create({
				category: this.category,
			}).url
			window.open(downloadUrl)
		},
	},
}
</script>
