<template>
  <v-dialog :value="value" @input="$emit('input', $event)" max-width="600px" :loading="loading">
    <v-card>
      <v-card-title>User Details</v-card-title>
      <v-form @submit.prevent="save(true)" class="pa-3">
        <v-text-field label="Naam" v-model="enterUserInformationCommand.name" />

        <v-select
          :label="$localize('User.salutation')"
          :items="['Dhr. /Mvr.', 'Mvr.', 'Dhr.']"
          v-model="enterUserInformationCommand.salutation"
        ></v-select>

        <v-menu
          ref="bornDatePicker"
          v-model="bornDatePicker"
          :return-value.sync="enterUserInformationCommand.bornDate"
          :close-on-content-click="false"
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              v-on="on"
              v-model="enterUserInformationCommand.bornDate"
              :label="$localize('User.bornDate')"
              readonly
            ></v-text-field>
          </template>

          <v-date-picker
            v-model="enterUserInformationCommand.bornDate"
            @input="$refs.bornDatePicker.save(enterUserInformationCommand.bornDate)"
          ></v-date-picker>
        </v-menu>

        <v-text-field label="Filiaal" v-model="enterUserInformationCommand.location" />
        <v-text-field
          :label="$localize('User.mobilePhoneNumber')"
          v-model="enterUserInformationCommand.mobilePhoneNumber"
        />

        <v-btn
          @click="$emit('input', false)"
          class="mt-3 mr-3"
        >{{ $localize('form.button.cancel') }}</v-btn>
        <v-btn type="submit" class="success mt-3 mr-3">{{ $localize('form.button.save') }}</v-btn>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { isGuid } from "@/services/validation"

import { getTenantUserById } from "@/services/api";
import sendCommand from "@/services/sendCommand";
export default {
  props: {
    userId: {
      validator: isGuid,
      required: true
    },
    value: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      enterUserInformationCommand: {},
      on: null,
      bornDatePicker: false,
      loading: false
    };
  },
  async created() {
    this.loading = true;
    const user = await getTenantUserById
      .create({ userId: this.$route.params.userId })
      .load();

    this.enterUserInformationCommand = {
      userId: this.$route.params.userId,
      name: user.name,
      salutation: user.salutation,
      bornDate: user.bornDate ? user.bornDate.split("T")[0] : null,
      location: user.location,
      mobilePhoneNumber: user.mobilePhoneNumber
    };
    this.loading = false;
  },
  methods: {
    async save() {
      const result = await sendCommand(
        "EnterUserInformation",
        this.enterUserInformationCommand
      );
      if (result.success) {
        this.$emit("input", false);
        this.$emit("saved");
      }
    }
  }
};
</script>