<template>
    <v-navigation-drawer
        :value="value"
        @input="$emit('input', $event)"
        right
        app
        clipped
        width="400"
    >
        <v-form ref="form" @submit.prevent="save(true)" class="pa-3">
            <v-card-title>{{
                $localize('CreateAppointment.title')
            }}</v-card-title>
            <v-card-text>
                <v-text-field
                    :label="$localize('Appointment.title')"
                    v-model="createAppointment.title"
                    :rules="[validation.required, validation.maxLength(100)]"
                />
                <v-textarea
                    :label="$localize('Appointment.description')"
                    v-model="createAppointment.description"
                    :rules="[validation.maxLength(4000)]"
                />

                <select-user
                    :label="$localize('Appointment.users')"
                    v-model="createAppointment.users"
                    :multiple="true"
                    :required="false"
                />
                <select-labels v-model="createAppointment.labels" />
                <v-switch
                    :label="$localize('Appointment.isEntireDay')"
                    v-model="createAppointment.isEntireDay"
                    @change="onIsEntireDayInput"
                />
                <date-time-select
                    :label="$localize('Appointment.startdate')"
                    v-model="createAppointment.startAt"
                    :default-minutes="0"
                    @input="onStartDateInput"
                    :enable-time="!createAppointment.isEntireDay"
                />
                <date-time-select
                    :label="$localize('Appointment.enddate')"
                    v-model="createAppointment.endAt"
                    :default-minutes="0"
                    @input="onEndDateInput"
                    :enable-time="!createAppointment.isEntireDay"
                />
                <v-file-input
                    :label="$localize('Appointment.files')"
                    v-model="files"
                    accept="image/png, image/jpeg, application/pdf"
                    multiple
                    chips
                >
                    <template v-slot:selection="{ index, text }">
                        <v-chip>
                            <span class="pr-2">
                                {{ text }}
                            </span>
                            <v-icon
                                small
                                @click.stop.prevent="removeFile(index)"
                                >$delete</v-icon
                            >
                        </v-chip>
                    </template>
                </v-file-input>
            </v-card-text>
            <v-card-actions>
                <v-btn @click="close()" class="mt-3">{{
                    $localize('form.button.cancel')
                }}</v-btn>
                <v-btn type="submit" class="success mt-3">{{
                    $localize('form.button.save')
                }}</v-btn>
                <v-btn @click="save(false)" class="success mt-3">{{
                    $localize('form.button.saveAndContinue')
                }}</v-btn>
            </v-card-actions>
        </v-form>
    </v-navigation-drawer>
</template>

<script>
import sendCommand from '@/services/sendCommand'
import SelectUser from '@/main/components/forms/SelectUser'
import SelectLabels from '@/main/components/forms/SelectLabels'
import { required, maxLength } from '@/services/validation'
import DateTimeSelect from '@/shared/components/DateTimeSelect'
import { isGuid } from '@/services/validation'
import { getProjectById } from '@/services/api'
import {
    formatISO,
    parseISO,
    differenceInDays,
    addDays,
    format,
} from 'date-fns'
const shortDateTimeFormat = 'yyyy-MM-dd HH:mm'
export default {
    components: { DateTimeSelect, SelectUser, SelectLabels },
    props: {
        value: {
            type: Boolean,
            default: false,
        },
        startAt: {
            type: Date,
            default: null,
            required: false,
        },
        endAt: {
            type: Date,
            default: null,
            required: false,
        },
        isEntireDay: {
            type: Boolean,
            default: false,
            required: false,
        },
        projectId: {
            validator: isGuid,
            required: false,
        },
    },

    data() {
        return {
            createAppointment: {
                startAt: this.startAt
                    ? format(this.startAt, shortDateTimeFormat)
                    : '',
                endAt: this.endAt
                    ? format(this.endAt, shortDateTimeFormat)
                    : '',
                title: '',
                users: [],
                labels: [],
                projectId: this.projectId,
                isEntireDay: false,
                attachments: [],
            },
            validation: { required, maxLength },
            project: null,
            previousStartAt: this.startAt,
            files: [],
        }
    },
    watch: {
        startAt(val) {
            this.createAppointment.startAt = format(val, shortDateTimeFormat)
        },
        endAt(val) {
            this.createAppointment.endAt = format(val, shortDateTimeFormat)
        },
    },
    async created() {
        if (this.projectId) {
            const { result, ok } = await getProjectById.call({
                args: { projectId: this.projectId },
            })

            if (ok) {
                this.project = result
                this.createAppointment.title =
                    this.project.projectNumber + ' - ' + this.project.project
                this.createAppointment.users = [this.project.userId]
            }
        }
    },
    methods: {
        dateInput(value) {
            if (!this.end.date) {
                this.end.date = value
            }
        },
        onIsEntireDayInput() {
            if (!this.createAppointment.isEntireDay) {
                console.log(1)
            }
            this.$emit('update:isEntireDay', this.createAppointment.isEntireDay)
        },
        onStartDateInput(startAt) {
            const startAtDate = parseISO(startAt)
            let daysShifted = differenceInDays(
                startAtDate,
                this.previousStartAt
            )

            const endAtDate = addDays(this.endAt, daysShifted)

            this.$emit('update:startAt', startAtDate)
            this.$emit('update:endAt', endAtDate)

            this.previousStartAt = startAtDate
        },
        onEndDateInput(endAt) {
            const endAtDate = parseISO(endAt)

            this.$emit('update:endAt', endAtDate)
        },
        removeFile(index) {
            if (this.files.length > index) {
                this.files.splice(index, 1)
            }
        },
        async save(close) {
            if (!this.$refs.form.validate()) {
                return
            }
            this.createAppointment.startAt = formatISO(
                parseISO(this.createAppointment.startAt)
            )
            this.createAppointment.endAt = formatISO(
                parseISO(this.createAppointment.endAt)
            )

            const formData = new FormData()
            for (const index in this.files) {
                const attachment = this.files[index]
                formData.append(attachment.name, attachment)
            }
            formData.append('command', JSON.stringify(this.createAppointment))

            const result = await sendCommand('CreateAppointment', formData, {
                'Content-Type': 'multipart/form-data',
            })

            if (result.success) {
                this.$emit('saved')
            } else {
                return
            }

            if (close) {
                this.close()
            } else {
                this.title = ''
                this.$refs.form.resetValidation()
            }
        },
        close() {
            this.$emit('input', false)
            this.$emit('update:startAt', null)
            this.$emit('update:endAt', null)
            this.$emit('update:isEntireDay', null)

            if (this.$route.query.projectId) {
                this.$router.push({ name: 'calendar' })
            }

            this.$refs.form.resetValidation()
        },
    },
}
</script>
