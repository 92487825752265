var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{
        'v-event-draggable': _vm.event.data.isTimedEvent,
        'event-multiline': _vm.event.data.isTimedEvent,
        'event-singleline': !_vm.event.data.isTimedEvent,
        'pl-1': true,
    }},[_c('span',{staticClass:"event-title"},[(_vm.eventIcon)?_c('v-icon',{staticClass:"white--text",attrs:{"small":""}},[_vm._v(_vm._s(_vm.eventIcon))]):_vm._e(),_vm._v(" "+_vm._s(_vm.event.name)+" ")],1),_vm._l((_vm.$store.getters.getUsersByIds(_vm.event.data.users)),function(user){return _c('span',{key:user.userId},[_c('v-icon',{attrs:{"small":"","color":_vm.colorMode === 'user' && _vm.event.data.users.length > 1
                    ? user.color
                    : '#fff'}},[_vm._v("mdi-account-tie")]),_vm._v(" "+_vm._s(user.name)+" ")],1)}),_vm._l((_vm.$store.getters.getLabelsByIds(_vm.event.data.labels)),function(label){return _c('span',{key:label.labelId,staticClass:"white--text",attrs:{"small":""}},[_c('v-icon',{attrs:{"small":"","color":_vm.colorMode === 'label' && _vm.event.data.labels.length > 1
                    ? label.color
                    : '#fff'}},[_vm._v("mdi-label")]),_vm._v(" "+_vm._s(label.name)+" ")],1)})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }