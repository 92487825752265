export function focusFirstDeep(element) {
	if (element.focus !== undefined) {
		element.focus()
		if (element.tagName === 'input') {
			return true
		}
		if (document.activeElement === element) {
			return true
		}
	}
	for (const child of element.children) {
		if (focusFirstDeep(child)) {
			return true
		}
	}

	return false
}
