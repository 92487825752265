<template>
	<v-card>
		<v-form ref="form">
			<v-card-text>
				<v-subheader>{{
					$localize('CalculationOutputEditor.title')
				}}</v-subheader>
				<v-row>
					<v-col cols="auto">
						<v-text-field
							v-model="rCount"
							outlined
							dense
							type="number"
							style="width: 150px"
							label="Aantal rijen"
							hide-details
						/>
					</v-col>
					<v-col cols="auto">
						<v-text-field
							v-model="cCount"
							outlined
							dense
							type="number"
							style="width: 150px"
							label="Aantal kolommen"
							hide-details
						/>
					</v-col>
					<v-col>
						<v-btn @click="createCells">Maak cellen</v-btn>
						<v-btn @click="pastingCode = true">Plakken</v-btn>
					</v-col>
				</v-row>
				<v-chip
					v-for="variable in value.variables"
					:key="variable.key"
					class="mr-1"
					@click="insertVariable($event, variable.key)"
					>{{ variable.name }}</v-chip
				>
				<draggable
					:list="grid"
					handle=".grip"
					@start="dragging = true"
					@end="
						dragging = false
						isDirty = true
					"
					class="list-group"
					ghost-class="ghost"
				>
					<v-row
						v-for="(row, rowIndex) in grid"
						:key="'row-' + rowIndex"
						align="center"
						justify="center"
					>
						<v-col cols="auto" class="grip">
							<v-icon>mdi-drag</v-icon>
						</v-col>
						<v-col
							v-for="(cell, columnIndex) in row"
							:key="'col-' + columnIndex"
						>
							<v-text-field
								v-model="grid[rowIndex][columnIndex]"
								outlined
								dense
								hide-details
								class="text-code"
								@input="onInput"
								@blur="
									selectedCell = {
										column: columnIndex,
										row: rowIndex,
									}
								"
							/>
						</v-col>
					</v-row>
				</draggable>
				<v-checkbox
					:label="
						$localize(
							'CalculationCodeEditor.field.displayCodeStructure'
						)
					"
					v-model="displayCode"
				/>
				<pre v-if="displayCode" style="white-space: pre">{{
					JSON.stringify(grid)
				}}</pre>
			</v-card-text>
			<v-card-actions>
				<v-btn :disabled="!isDirty" @click="reset()">{{
					$localize('form.button.cancel')
				}}</v-btn>
				<v-btn :disabled="!isDirty" @click="saveOutputFields(false)">{{
					$localize('form.button.save')
				}}</v-btn>
				<v-btn color="primary" @click="saveOutputFields(true)">{{
					$localize('form.button.saveAndContinue')
				}}</v-btn>
			</v-card-actions>
		</v-form>

		<v-lazy :value="pastingCode">
			<paste-code
				v-model="pastingCode"
				:code="JSON.stringify(grid)"
				@update:code="loadJson"
			/>
		</v-lazy>
	</v-card>
</template>

<script>
import Vue from 'vue'
import draggable from 'vuedraggable'
import { required } from '@/services/validation'
import sendCommand from '@/services/sendCommand'
import PasteCode from './dialogs/PasteCode'
export default {
	components: { draggable, PasteCode },
	props: {
		value: {
			type: Object,
		},
	},
	data() {
		return {
			dragging: false,

			rCount: 0,
			cCount: 0,
			grid: [],
			selectedCell: { column: 0, row: 0 },

			isDirty: false,
			validation: { required },
			displayCode: false,

			pastingCode: false,
		}
	},
	computed: {
		columnCount() {
			return parseInt(this.cCount)
		},
		rowCount() {
			return parseInt(this.rCount)
		},
	},
	watch: {
		value: {
			immediate: true,
			handler() {
				this.reset()
			},
		},
	},

	methods: {
		loadJson(json) {
			this.grid = JSON.parse(json)
		},
		reset() {
			if (this.value.outputGrid) {
				this.grid = JSON.parse(JSON.stringify(this.value.outputGrid))
			} else {
				this.grid = []
			}
			this.rCount = this.grid.length
			if (this.rCount > 0) {
				this.cCount = this.grid[0].length
			} else {
				this.cCount = 0
			}
		},
		createCells() {
			let array = []
			for (let rowIndex = 0; rowIndex < this.rowCount; rowIndex++) {
				array[rowIndex] = []
				for (
					let columnIndex = 0;
					columnIndex < this.columnCount;
					columnIndex++
				) {
					if (
						this.grid[rowIndex] &&
						this.grid[rowIndex][columnIndex]
					) {
						// Copy value from previous dataset
						array[rowIndex].push(this.grid[rowIndex][columnIndex])
					} else {
						array[rowIndex].push('')
					}
				}
			}
			this.grid = array
			this.isDirty = true
		},
		insertVariable(event, variableKey) {
			const syntax = '[[' + variableKey + ':currency]]'
			var value =
				this.grid[this.selectedCell.row][this.selectedCell.column]

			Vue.set(
				this.grid[this.selectedCell.row],
				this.selectedCell.column,
				(value || '') + syntax
			)
			this.isDirty = true
		},
		onInput() {
			this.isDirty = true
		},
		async saveOutputFields(nextStep) {
			if (!this.$refs.form.validate()) {
				return
			}

			if (this.isDirty) {
				const result = await sendCommand(
					'ChangeCalculationOutputGrid',
					{
						calculationId: this.value.calculationId,
						grid: this.grid,
					}
				)
				if (!result.success) {
					return
				}

				this.isDirty = false
				const calculation = this.value
				calculation.outputGrid = this.grid
				this.$emit('input', calculation)
			}
			this.$emit('saved')
			if (nextStep) {
				this.$emit('continue')
			}
		},
	},
}
</script>

<style lang="scss" scoped>
.grip {
	cursor: grab;
}
.grip:active {
	cursor: grabbing;
}
</style>
