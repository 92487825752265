<template>
    <v-row>
        <v-col :cols="enableTime ? 6 : 12">
            <date-select
                :label="label"
                v-model="dateValue"
                @input="input"
                prepend-icon="mdi-calendar-clock"
                :autofocus="autofocus"
            />
        </v-col>
        <v-col v-if="enableTime" cols="3">
            <v-text-field
                v-model="hours"
                :label="$localize('calendar.hour')"
                type="number"
                :rules="[validation.required, validation.integerRange(0, 23)]"
                @input="input"
            />
        </v-col>
        <v-col v-if="enableTime" cols="3">
            <v-text-field
                v-model="minutes"
                :label="$localize('calendar.minute')"
                type="number"
                :rules="[validation.required, validation.integerRange(0, 59)]"
                @input="input"
            />
        </v-col>
    </v-row>
</template>

<script>
import { format, parseISO, getHours, getMinutes, isValid } from 'date-fns'
import { required, integerRange } from '@/services/validation'
import DateSelect from './DateSelect'

export default {
    components: { DateSelect },
    props: {
        label: String,
        value: {},
        autofocus: Boolean,
        defaultMinutes: Number,
        enableTime: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            dateValue: null,
            hours: '',
            minutes: this.defaultMinutes,

            validation: { required, integerRange },
        }
    },
    watch: {
        value: {
            immediate: true,
            handler(val) {
                this.value = val
                this.refresh()
            },
        },
    },
    methods: {
        refresh() {
            const dateTime = parseISO(this.value)

            if (isValid(dateTime)) {
                this.dateValue = format(dateTime, 'yyyy-MM-dd')
            } else {
                this.dateValue = null
            }

            this.hours = getHours(dateTime) + ''
            this.minutes = getMinutes(dateTime) + ''

            if (!this.minutes || isNaN(this.minutes)) {
                this.minutes = this.defaultMinutes
            }
        },
        input() {
            if (this.enableTime) {
                const hours = (this.hours + '').padStart(2, '0')
                const minutes = (this.minutes + '').padStart(2, '0')
                const date = `${this.dateValue}T${hours}:${minutes}:00`
                if (isValid(parseISO(date))) {
                    this.$emit('input', date)
                }
            } else {
                if (isValid(parseISO(this.dateValue))) {
                    this.$emit('input', this.dateValue)
                }
                else{
                    console.log('invalid date');
                }
            }
        },
    },
}
</script>
