<template>
	<v-toolbar-items>
		<v-menu v-if="collapsed">
			<template v-slot:activator="{ on }">
				<v-btn
					v-on="on"
					:small="size === 'small'"
					:x-small="size === 'x-small'"
					class="primary"
				>
					<v-icon small>mdi-dots-vertical</v-icon>
				</v-btn>
			</template>
			<v-list>
				<template v-for="(item, index) in items">
					<v-list-item
						:key="index"
						v-if="typeof item.if !== 'function' || item.if()"
						small
						depressed
						:disabled="isDisabled(item)"
						:class="getClasses(item)"
						@click="item.action(value)"
					>
						<v-list-item-icon v-if="item.icon">
							<v-icon
								v-text="item.icon"
								:color="item.iconColor"
							></v-icon>
						</v-list-item-icon>
						<v-list-item-content
							v-text="item.text"
						></v-list-item-content>
					</v-list-item>
				</template>
			</v-list>
		</v-menu>

		<template v-else v-for="(item, index) in items">
			<v-btn
				:key="index"
				v-if="typeof item.if !== 'function' || item.if()"
				:small="size === 'small'"
				:x-small="size === 'x-small'"
				:disabled="isDisabled(item)"
				:class="getClasses(item)"
				@click="item.action(value)"
			>
				<v-icon
					v-if="item.icon"
					v-text="item.icon"
					:color="item.iconColor"
					small
				/>
				<template v-else v-text="item.text" />
			</v-btn>
		</template>
	</v-toolbar-items>
</template>

<script>
export default {
	props: {
		/*
          item properties:
           - text : String (required)
           - icon : String (required)
           - iconColor : String
           - action : Function (required)
           - color : String
           - textColor : String
           - disabled : Boolean | Function (default = false)
           - if : Function (return false to hide)
         */
		items: Array,
		value: Object,

		size: {
			type: String, // small x-small
			default: 'x-small',
		},
	},
	computed: {
		collapsed() {
			return this.$vuetify.breakpoint.smAndDown
		},
	},
	methods: {
		isDisabled(item) {
			if (item.disabled === true) {
				return true
			}
			if (typeof item.disabled === 'function') {
				return item.disabled(this.value)
			}
			return false
		},
		getClasses(item) {
			let classes = []
			if (item.textColor) {
				classes.push(item.textColor + '--text')
			}
			if (item.color) {
				classes.push(item.color)
			}
			return classes
		},
	},
}
</script>
<style lang="scss" scoped>
.v-btn:not(.v-btn--round).v-size--x-small {
	min-width: 28px;
	padding: 0;
}
</style>
