<template>
    <div>
        <v-icon v-if="item.phase">
            {{ getPhaseIcon(item.phase) }}
        </v-icon>
        {{ item.data.newStatus }}
        <v-hover v-if="item.data.note" v-slot="{ hover }">
            <div :class="{ 'text-truncate': !hover, 'text-wrap': hover }">
                {{ item.data.note }}
            </div>
        </v-hover>
    </div>
    <!--
  <v-card>
    <v-card-title v-if="item.data.oldPhase !== item.data.newPhase || item.data.oldStatus !== item.data.newStatus">
      <v-row no-gutters>
        <template v-if="item.data.oldPhase !== item.data.newPhase">
          <v-col cols="auto" class="striketrough">{{ $localize(item.data.oldPhase) }}</v-col>
          <v-col cols="auto">{{$localize( item.data.newPhase) }}</v-col>
        </template>
        <template v-if="item.data.oldStatus !== item.data.newStatus">
          <v-col cols="auto" class="striketrough">{{ statusNameById(item.data.oldStatus) }}</v-col>
          <v-col cols="auto">{{statusNameById(item.data.newStatus) }}</v-col>
        </template>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-row v-if="item.data.note" no-gutters>
        <v-col><strong>{{ $localize('StatusChanged.note') }}: </strong>{{item.data.note}}</v-col>
      </v-row>
      <v-row no-gutters>
        <v-col>
          <strong>{{ item.createdByName }}</strong>&nbsp;
        </v-col>
        <v-col>{{ $format.shortDateTime(item.created) }}</v-col>
      </v-row>
    </v-card-text>
  </v-card>

  -->
</template>

<script>
import { getPhaseIcon } from '../../../../services/icons'
export default {
    props: {
        item: Object,
    },
    methods: { getPhaseIcon },
}
</script>

<style scoped lang="scss">
.container {
    padding: 0;
}
.v-card__title {
    .col {
        margin-right: 4px;
    }
}
.strikethrough {
    text-decoration: line-through;
    color: #bdbdbd;
}
</style>
