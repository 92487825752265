<template>
  <dialog-form
    :value="value"
    @input="$emit('input', $event)"
    :title="$localize('ChangePassword.title')"
    @commit="save"
    @reset="changeUserPasswordCommand = {}"
    :allow-continue="false"
  >
    <v-text-field
      prepend-icon="mdi-lock-outline"
      :label="$localize('ChangePassword.field.currentPassword')"
      type="password"
      v-model="changeUserPasswordCommand.currentPassword"
      :rules="[validation.required]"
      autofocus
    />
    <v-text-field
      prepend-icon="mdi-lock-outline"
      :label="$localize('ChangePassword.field.newPassword')"
      type="password"
      v-model="changeUserPasswordCommand.newPassword"
      :rules="[validation.required]"
    />
    <v-text-field
      prepend-icon="mdi-lock-check-outline"
      :label="$localize('ChangePassword.field.newPasswordRepeat')"
      type="password"
      v-model="changeUserPasswordCommand.newPasswordRepeat"
      :rules="[validation.required]"
    />
  </dialog-form>
</template>

<script>
import DialogForm from "@/shared/components/DialogForm";
import sendCommand from "@/services/sendCommand";
import { isGuid } from "@/services/validation"
import { required } from "@/services/validation";

export default {
  components: { DialogForm },
  props: {
    userId: {
      required: true,
      validator: isGuid
    },
    value: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      changeUserPasswordCommand: {},
      validation: { required }
    };
  },

  methods: {
    async save(callback) {
      this.changeUserPasswordCommand.userId = this.userId;
      const result = await sendCommand(
        "ChangeUserPassword",
        this.changeUserPasswordCommand
      );
      if (result.success) {
        this.$emit("saved");
      } 
      callback({success: result.success})
    }
  }
};
</script>
