<template>
	<div>
		<v-toolbar flat height="36" class="my-3">
			<v-toolbar-title>{{
				$localize('RelationGroupsOverview.title')
			}}</v-toolbar-title>

			<v-spacer></v-spacer>
			<v-toolbar-items>
				<v-btn
					class="primary btnCreateRelationGroup"
					@click="addRelationGroup = true"
				>
					<v-icon class="mr-1">mdi-plus</v-icon>
					{{
						$localize('RelationGroupsOverview.button.create')
					}}</v-btn
				>
			</v-toolbar-items>
		</v-toolbar>
		<v-data-table
			:items="groups"
			:headers="headers"
			:footer-props="{ 'items-per-page-options': [10, 20, 50, -1] }"
			:options.sync="options"
			dense
		>
			<template v-slot:[`item.action`]="{ item }">
				<table-toolbar>
					<v-btn
						small
						depressed
						class="mr-2 btnRenameRelationGroup"
						@click="
							renameRelationGroupObj = item
							renameRelationGroup = true
						"
					>
						<v-icon dense>mdi-pencil</v-icon>
					</v-btn>

					<v-btn
						small
						depressed
						@click="
							removeRelationGroupObj = item
							removeRelationGroup = true
						"
						class="btnDeleteRelationGroup"
					>
						<v-icon dense>mdi-close</v-icon>
					</v-btn>
				</table-toolbar>
			</template>
		</v-data-table>
		<add-relation-group v-model="addRelationGroup" @saved="refresh()" />
		<rename-relation-group
			v-if="renameRelationGroup"
			v-model="renameRelationGroup"
			:group-id="renameRelationGroupObj.relationGroupId"
			:old-name="renameRelationGroupObj.name"
			@saved="refresh()"
		/>
		<remove-relation-group
			v-if="removeRelationGroup"
			v-model="removeRelationGroup"
			:group-id="removeRelationGroupObj.relationGroupId"
			@saved="refresh()"
		/>
	</div>
</template>

<script>
import AddRelationGroup from '@/main/relations/dialogs/AddRelationGroup'
import RenameRelationGroup from '@/main/relations/dialogs/RenameRelationGroup'
import RemoveRelationGroup from '@/main/relations/dialogs/RemoveRelationGroup'
import { getRelationGroups } from '@/services/api'

export default {
	components: { AddRelationGroup, RenameRelationGroup, RemoveRelationGroup },
	data() {
		return {
			addRelationGroup: false,
			renameRelationGroup: false,
			renameRelationGroupObj: {},
			removeRelationGroup: false,
			removeRelationGroupObj: {},

			groups: [],
			headers: [
				{ text: this.$localize('RelationGroup.name'), value: 'name' },
				{
					value: 'action',
					sortable: false,
					width: 150,
				},
			],
			options: JSON.parse(
				localStorage.getItem('RelationGroupOverview:options')
			) || { itemsPerPage: -1 },
		}
	},
	watch: {
		options() {
			localStorage.setItem(
				'RelationGroupOverview:options',
				JSON.stringify(this.options)
			)
		},
	},
	created() {
		this.refresh()
	},
	methods: {
		async refresh() {
			const { result, ok } = await getRelationGroups.call()
			if (ok) {
				this.groups = result
			}
		},
	},
}
</script>
