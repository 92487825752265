<template>
	<dialog-form
		:value="value"
		@input="$emit('input', $event)"
		:title="$localize('Label.createLabel')"
		:allow-continue="false"
		@commit="save"
		max-width="800"
	>
		<v-text-field
			v-model="name"
			:label="$localize('Label.name')"
			:rules="[validation.required, validation.maxLength(50)]"
			prepend-icon="mdi-label"
			autofocus
		/>

		<input-color v-model="color" />
	</dialog-form>
</template>

<script>
import DialogForm from '@/shared/components/DialogForm'
import sendCommand from '@/services/sendCommand'
import InputColor from '@/main/components/forms/InputColor'
import { required, maxLength } from '@/services/validation'

export default {
	components: { DialogForm, InputColor },
	props: {
		value: Boolean,
	},
	data() {
		return {
			emailVal: this.email,
			validation: { required, maxLength },

			name: '',
			color: '',
		}
	},
	methods: {
		async save(callback) {
			const result = await sendCommand('CreateLabel', {
				name: this.name,
				color: this.color,
			})
			if (result.success) {
                this.$store.dispatch('loadLabels', { force: true })
				this.$emit('saved')
			}

			callback({ success: result.success })
		},
	},
}
</script>
