<template>
  <v-container>
    <p>There is nothing to see here</p> <v-btn link class="primary--text" @click="$router.back()">Go Back</v-btn>
  </v-container>
</template>

<script>
export default {
}
</script>
