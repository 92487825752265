<template>
	<div>
		{{ $localize('CodeVariables.label.variables') }}
		<v-menu v-model="creating" :close-on-content-click="false" offset-y>
			<template v-slot:activator="{ on }">
				<v-btn small v-on="on" @click="createNew()">{{
					$localize('form.button.add')
				}}</v-btn>
			</template>
			<edit-variable
				v-if="creating"
				v-model="newVariable"
				:calculation-id="codeContextId"
				:field-count="localVariableCount"
				@saved="addVariable()"
			/>
		</v-menu>
		<div class="variables-grid">
			<draggable
				:list="variables"
				handle=".grip"
				@start="dragging = true"
				@end="
					dragging = false
					commitCustomVariables()
				"
				class="list-group"
				ghost-class="ghost"
			>
				<template v-for="(variable, index) in variables">
					<v-row
						dense
						:key="variable.key"
						v-if="variable.modifier === 'local'"
					>
						<v-col cols="auto" class="grip">
							<v-icon>mdi-drag</v-icon>
						</v-col>
						<v-col cols="auto" class="blue--text">{{
							variable.modifier
						}}</v-col>
						<v-col cols="auto" class="teal--text">{{
							variable.type
						}}</v-col>
						<v-col cols="auto" class="black--text">{{
							variable.key
						}}</v-col>
						<v-col cols="auto" class="black--text">{{
							variable.name
						}}</v-col>
						<v-col>
							<span v-if="!variable.isSystemDefined">
								<v-menu
									v-model="editing[index]"
									:close-on-content-click="false"
									offset-y
								>
									<template v-slot:activator="{ on }">
										<v-btn small v-on="on">{{
											$localize('form.button.edit')
										}}</v-btn>
									</template>
									<edit-variable
										v-if="variables[index]"
										v-model="variables[index]"
										:calculation-id="codeContextId"
										:field-count="localVariableCount"
										@saved="
											editing[index] = false
											commitCustomVariables()
										"
									/>
								</v-menu>
								<v-btn
									small
									@click="deleteVariable(variable)"
									>{{
										$localize('form.button.delete')
									}}</v-btn
								>
							</span>
						</v-col>
					</v-row>
				</template>
			</draggable>
		</div>
	</div>
</template>

<script>
import { getAllTypes } from '@/services/code'
import { required } from '@/services/validation'
import { isGuid } from '@/services/validation'
import EditVariable from '@/main/advanced/components/EditVariable'
import draggable from 'vuedraggable'

export default {
	components: { EditVariable, draggable },
	props: {
		codeContextId: {
			validator: isGuid,
			required: true,
		},
		value: {
			type: Array,
			required: true,
			validator(value) {
				var notObject = value.filter((x) => {
					if (
						x.name === undefined ||
						x.type === undefined ||
						x.modifier === undefined ||
						x.isSystemDefined === undefined
					) {
						console.error(
							'Invalid prop: type check failed for prop "value". Expected item in array to have to following properties defined: name, type, modifier, isSystemDefined'
						)
						console.log(x)
						return x
					}
				})

				return notObject.length === 0
			},
		},
		allowInOut: {
			type: Boolean,
			default: true,
		},
	},

	data() {
		return {
			dragging: false,
			variables: this.value,
			creating: false,
			variableModifiers: [
				{
					text: this.$localize('CodeVariables.modifier.input'),
					value: 'input',
				},
				{
					text: this.$localize('CodeVariables.modifier.output'),
					value: 'output',
				},
				{
					text: this.$localize('CodeVariables.modifier.local'),
					value: 'local',
				},
			],
			newVariable: {
				type: null,
				name: null,
				modifier: 'local',
				isSystemDefined: false,
				isNew: true,
			},
			allTypes: [],
			validation: {
				required,
			},
			editing: [],
		}
	},
	computed: {
		localVariableCount() {
			return this.localVariables.length
		},
		localVariables() {
			return this.variables.filter((x) => x.modifier === 'local')
		},
	},
	watch: {
		value(val) {
			this.variables = val
		},
	},
	async created() {
		this.allTypes = getAllTypes()
	},

	methods: {
		createNew() {
			if (this.$refs.form) {
				this.$refs.form.resetValidation()
			}
			this.newVariable = {
				modifier: 'local',
				isSystemDefined: false,
				isNew: true,
			}
			this.creating = true
		},
		async deleteVariable(variable) {
			const index = this.variables.indexOf(variable)
			if (index >= 0) {
				this.variables.splice(index, 1)

				await this.commitCustomVariables()
			}
		},
		addVariable() {
			this.variables.push(this.newVariable)
			this.commitCustomVariables()
			this.creating = false
		},
		uniqueName(value) {
			const exists =
				this.variables.filter((x) => x.name === value).length > 0
			return (
				!exists ||
				this.$localize('CodeVariables.message.nameMustBeUnique')
			)
		},

		async commitCustomVariables() {
			this.$emit('input', this.variables)
		},
	},
}
</script>

<style scoped lang="scss">
.variables-grid {
	display: table;

	.row {
		display: table-row;
	}
	.col {
		display: table-cell;
		white-space: nowrap;
	}
}
</style>
