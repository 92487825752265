<template>
  <dialog-form
    :value="value"
    @input="$emit('input', $event)"
    action="delete"
    :title="$localize('DeleteAttribute.title')"
    @commit="save"
  >
    <p>{{$localize('DeleteAttribute.description')}}</p>
    <p>
      <b>{{alias}}</b>
    </p>
    <p>{{$localize('form.description.actionIsPermanent')}}</p>
  </dialog-form>
</template>

<script>
import DialogForm from "@/shared/components/DialogForm";

import sendCommand from "@/services/sendCommand";

export default {
  components: { DialogForm },

  props: {
    value: {
      type: Boolean,
      default: false
    },
    alias: {
      type: String
    }
  },

  methods: {
    async save(callback) {
      const result = await sendCommand("DeleteAttribute", {
        alias: this.alias
      });

      if (result.success) {
        this.$emit("saved");
      }

      callback({ success: result.success });
    }
  }
};
</script>