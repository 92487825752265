<template>
	<v-card>
		<v-card-title
			>Opties: {{ articleImport.originalFileName }}</v-card-title
		>
		<v-card-text>
			<v-radio-group
				v-model="hasHeader"
				label="Bevat onderstaande eerste regel van uw bestand de namen van de kolommen?"
			>
				<v-radio :value="true" :label="$localize('form.label.yes')" />
				<v-radio :value="false" :label="$localize('form.label.no')" />
			</v-radio-group>
			<table class="import-table">
				<thead>
					<tr class="preview-header">
						<th v-for="(column, index) in header" :key="index">
							{{ column }}
						</th>
					</tr>
				</thead>
			</table>
		</v-card-text>
		<v-card-actions>
			<v-btn @click="$emit('back')">{{
				$localize('form.button.back')
			}}</v-btn>
			<v-btn color="primary" @click="upload()">{{
				$localize('form.button.next')
			}}</v-btn>
		</v-card-actions>
	</v-card>
</template>

<script>
import sendCommand from '@/services/sendCommand'
import { getArticleImport, getArticleImportHeader } from '@/services/api'
import { isGuid } from '@/services/validation'
export default {
	props: {
		articleImportId: {
			validator: isGuid,
			required: true,
		},
	},
	data() {
		return {
			articleImport: {},
			header: [],
			hasHeader: true,
		}
	},
	async created() {
		const { result, ok } = await getArticleImport.call({
			args: { articleImportId: this.articleImportId },
		})
		if (ok) {
			this.articleImport = result
			this.hasHeader = this.articleImport.hasHeader
			await this.loadHeader()
		}
	},

	methods: {
		async loadHeader() {
			const { result, ok } = await getArticleImportHeader.call({
				args: { articleImportId: this.articleImportId },
			})
			if (ok) {
				this.header = result
			}
		},
		async upload() {
			const cmd = {
				articleImportId: this.articleImportId,
				hasHeader: this.hasHeader,
			}
			const result = await sendCommand('SetArticleImportOptions', cmd)
			if (result.success) {
				this.$emit('continue')
			}
		},
	},
}
</script>
