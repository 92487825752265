<template>
  <div>
    <v-toolbar flat dense>
      <v-toolbar-title>Admins</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <v-btn text class="primary" @click="createAdministrator = true">New admin</v-btn>
      </v-toolbar-items>
    </v-toolbar>
    <v-data-table :items="users" :headers="headers" @click:row="navUser($event)">
      <template v-slot:[`item.isActivated`]="{ item }">
        <span v-show="item.isActivated">
          <v-icon class="success--text">mdi-check</v-icon>
        </span>
        <span v-show="!item.isActivated">
          <v-icon class="error--text">mdi-close</v-icon>
        </span>
      </template>
    </v-data-table>
    <create-administrator v-model="createAdministrator" @saved="refresh()" />
  </div>
</template>

<script>
import { getAdministrators } from "@/services/api";
import CreateAdministrator from '../components/CreateAdministrator'

export default {
  components: {CreateAdministrator},
  data: function() {
    return {
      headers: [
        { text: "Login", value: "login" },
        { text: "Email", value: "email" },
        { text: "Activated", value: "isActivated" }
      ],
      users: [],
      createAdministrator: false
    };
  },
  created() {
    this.refresh()
  },
  methods: {
    async refresh(){
      this.users = await getAdministrators.create().load();
    },
    navUser(user) {
      this.$router.push({
        name: "admin.userDetails",
        params: { userId: user.userId }
      });
    }
  }
};
</script>