<template>
  <dialog-form
    :value="value"
    @input="$emit('input', $event)"
    action="delete"
    :title="$localize('DeleteAppointment.title')"
    @commit="save"
  >
    <p>{{ $localize('DeleteAppointment.description') }}</p>
  </dialog-form>
</template>

<script>
import DialogForm from "@/shared/components/DialogForm";

import sendCommand from "@/services/sendCommand";
import { isGuid } from "@/services/validation"

export default {
  components: { DialogForm },
  props: {
    appointmentId: {
      validator: isGuid
    },
    value: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      article: {},
      loading: false
    };
  },

  methods: {
    async save(callback) {
      const result = await sendCommand("DeleteAppointment", {
        appointmentId: this.appointmentId
      });

      if (result.success) {
        this.$emit("saved");
      }
      callback({success: result.success})
    }
  }
};
</script>
