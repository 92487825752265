var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-skeleton-loader',{attrs:{"loading":_vm.loading,"type":"list-item"}},[_c('v-list',[_vm._l((_vm.dashboards),function(dashboard){return _c('v-list-item',{key:dashboard.calculationId,attrs:{"to":{
				name: 'dashboard',
				params: {
					dashboardId: dashboard.dashboardId,
				},
			}}},[_c('v-list-item-title',[_vm._v(_vm._s(dashboard.name))])],1)}),(_vm.dashboards.length === 0)?[(_vm.$session.roles.includes('settings_general'))?_c('v-list-item',{attrs:{"to":{
					name: 'settingsOverview',
					params: { category: 'dashboards' },
				}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi mdi-plus")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Dashboard maken")])],1)],1):_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Er zijn geen dashboards")])],1)],1)]:_vm._e()],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }