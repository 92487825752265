<template>
  <dialog-form
    :value="value"
    @input="$emit('input', $event)"
    :title="$localize('DeleteRelationImport.title')"
    action="delete"
    @commit="save"
  >
    <p>{{$localize('DeleteRelationImport.description')}}</p>
    <p>{{$localize('form.description.actionIsPermanent')}}</p>
  </dialog-form>
</template>

<script>
import DialogForm from "@/shared/components/DialogForm";
import sendCommand from "@/services/sendCommand";
import { isGuid } from "@/services/validation"

export default {
  components: { DialogForm },
  props: {
    relationImportId: {
      required: false,
      validator: isGuid
    },
    value: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      relation: {},
    };
  },

  methods: {
    async save(callback) {
      const result = await sendCommand("DeleteRelationImport", {
        relationImportId: this.relationImportId
      });

      if (result.success) {
        this.$emit("saved");
      }
      callback({success: result.success})
    }
  }
};
</script>
