<template>
	<dialog-form
		:value="value"
		@input="$emit('input', $event)"
		:title="$localize('ChangeDashboardColumns.title')"
		@commit="save"
		:allow-continue="false"
		:loading="loading"
	>
		<v-list>
			<v-subheader>Kolommen</v-subheader>
			<draggable
				v-model="columns"
				@start="dragging = true"
				@end="dragging = false"
			>
				<v-list-item v-for="column in columns" :key="column">
					<v-list-item-icon class="grip">
						<v-icon>mdi-drag-vertical</v-icon>
					</v-list-item-icon>
					<v-list-item-content>
						<v-list-item-title>{{
							$localize('Dashboard.column.' + column)
						}}</v-list-item-title>
					</v-list-item-content>
					<v-list-item-action>
						<v-tooltip bottom>
							<template v-slot:activator="{ on }">
								<v-btn
									v-on="on"
									icon
									small
									@click="removeColumn(column)"
								>
									<v-icon>mdi-delete</v-icon>
								</v-btn>
							</template>
							<span>{{
								$localize(
									'ChangeDashboardColumns.tooltip.removeColumn'
								)
							}}</span>
						</v-tooltip>
					</v-list-item-action>
				</v-list-item>
			</draggable>
			<v-divider></v-divider>
			<v-subheader>Beschikbare kolommen</v-subheader>
			<v-list-item v-for="column in availableColumns" :key="column">
				<v-list-item-content>
					<v-list-item-title>{{
						$localize('Dashboard.column.' + column)
					}}</v-list-item-title>
				</v-list-item-content>
				<v-list-item-action>
					<v-tooltip bottom>
						<template v-slot:activator="{ on }">
							<v-btn
								v-on="on"
								icon
								small
								@click="addColumn(column)"
							>
								<v-icon>mdi-plus</v-icon>
							</v-btn>
						</template>
						<span>{{
							$localize(
								'ChangeDashboardColumns.tooltip.addColumn'
							)
						}}</span>
					</v-tooltip>
				</v-list-item-action>
			</v-list-item>
		</v-list>
	</dialog-form>
</template>

<script>
import DialogForm from '@/shared/components/DialogForm'
import sendCommand from '@/services/sendCommand'
import { getDashboardById } from '@/services/api'
import { isGuid } from '@/services/validation'
import draggable from 'vuedraggable'

export default {
	components: { DialogForm, draggable },
	props: {
		value: Boolean,
		dashboardId: {
			validator: isGuid,
			required: true,
		},
	},
	data() {
		return {
			dagging: false,
			dashboard: {},
			columns: [],
			allColumns: [
				'projectNumber',
				'created',
				'createdByName',
				'relationName',
				'project',
				'projectStatusName',
				'userName',
				'productionDate',
				'deadline',
				'projectStatusNote',
				'invoiceNumber',
				'invoiceDate',
			],
		}
	},
	computed: {
		availableColumns() {
			return this.allColumns.filter((x) => this.columns.indexOf(x) === -1)
		},
	},
	async created() {
		this.loading = true
		const { result, ok } = await getDashboardById.call({
			args: { dashboardId: this.dashboardId },
		})
		if (ok) {
			this.dashboard = result
			this.columns = this.dashboard.columns || []
		}
		this.loading = false
	},
	methods: {
		removeColumn(column) {
			const columnIndex = this.columns.indexOf(column)
			this.columns.splice(columnIndex, 1)
		},
		addColumn(column) {
			this.columns.push(column)
		},
		async save(callback) {
			const result = await sendCommand('ChangeDashboardColumns', {
				dashboardId: this.dashboardId,
				columns: this.columns,
			})

			if (result.success) {
				this.$emit('saved')
			}
			callback({ success: result.success })
		},
	},
}
</script>
