<template>
	<div>
		<v-toolbar flat height="36" class="my-3">
			<v-spacer></v-spacer>
			<v-toolbar-items>
				<v-btn
					v-if="$session.roles.includes('relations_manage')"
					@click="createContact = true"
					class="primary"
				>
					<v-icon class="mr-1">mdi-plus</v-icon>
					{{ $localize('RelationDetails.button.createContact') }}
				</v-btn>
			</v-toolbar-items>
		</v-toolbar>
		<v-data-table
			:headers="headers"
			:items="contacts"
			@click:row="contactId = $event.contactId"
			:loading="loading"
			:footer-props="{ 'items-per-page-options': [10, 20, 50, -1] }"
			:options.sync="options"
			dense
		>
			<template v-slot:footer="{}">
				<v-divider />
				<v-row no-gutters class="mx-3">
					<v-col cols="auto">
						<v-checkbox
							:label="$localize('form.label.trash')"
							v-model="trash"
							@change="filter"
							class="pt-0 my-1 btnTrash"
							hide-details
						/>
					</v-col>
				</v-row>
			</template>
		</v-data-table>

		<contact-details
			v-if="contactId"
			:contact-id="contactId"
			:key="contactId"
			@saved="refresh()"
			@closed="
				refresh()
				contactId = null
			"
		/>
		<create-contact
			v-if="createContact"
			v-model="createContact"
			:relation-id="$route.params.relationId"
			@saved="refresh()"
		/>
	</div>
</template>

<script>
import { getRelationContacts } from '@/services/api'
import ContactDetails from './ContactDetails'
import CreateContact from './dialogs/CreateContact'

export default {
	components: { ContactDetails, CreateContact },
	data() {
		return {
			headers: [],
			allContacts: [],
			contacts: [],
			trash: false,
			contactId: null,
			createContact: false,
			loading: false,
			options: JSON.parse(
				localStorage.getItem('ContactOverview:options')
			) || {
				itemsPerPage: -1,
			},
		}
	},
	created() {
		this.refresh()
	},
	watch: {
		options() {
			localStorage.setItem(
				'ContactOverview:options',
				JSON.stringify(this.options)
			)
		},
	},
	methods: {
		async refresh() {
			this.loading = true
			this.headers = [
				{ text: this.$localize('Contact.name'), value: 'name' },
				{ text: this.$localize('Contact.email'), value: 'email' },
				{
					text: this.$localize('Contact.mobileNumber'),
					value: 'mobileNumber',
				},
				{
					text: this.$localize('Contact.alternativeNumber'),
					value: 'alternativeNumber',
				},
				{ text: this.$localize('Contact.position'), value: 'position' },
			]

			const { result, ok } = await getRelationContacts.call({
				args: { relationId: this.$route.params.relationId },
			})
			if (ok) {
				this.allContacts = result
				this.filter()
			}
			this.loading = false
		},
		filter() {
			this.contacts = this.allContacts.filter(
				(x) => x.isTrashed === this.trash
			)
		},
	},
}
</script>
