<template>
	<dialog-form
		:value="value"
		@input="$emit('input', $event)"
		:title="$localize('DeleteVat.title')"
		@commit="save"
		:allow-continue="false"
		action="delete"
	>
		{{ $localize('DeleteVat.message') }}
	</dialog-form>
</template>

<script>
import DialogForm from '@/shared/components/DialogForm'

import sendCommand from '@/services/sendCommand'
import { isGuid } from '@/services/validation'

export default {
	components: { DialogForm },
	props: {
		value: Boolean,
		vatId: {
			validator: isGuid,
			required: true,
		},
	},

	methods: {
		async save(callback) {
			const result = await sendCommand('DeleteVat', { vatId: this.vatId })

			if (result.success) {
				this.$emit('saved')
			}
			callback({ success: result.success })
		},
	},
}
</script>
