<template>
  <dialog-form
    :value="value"
    @input="$emit('input', $event)"
    :title="$localize('DeleteProjectStatus.title')"
    @commit="save"
    action="delete"
  >
    <p>{{ $localize('DeleteProjectStatus.description') }}</p>
  </dialog-form>
</template>

<script>
import DialogForm from "@/shared/components/DialogForm";

import sendCommand from "@/services/sendCommand";
import { isGuid } from "@/services/validation"
export default {
  components: { DialogForm },

  props: {
    value: {
      type: Boolean,
      default: false
    },
    projectStatusId: {
      required: false,
      validator: isGuid
    }
  },

  methods: {
    async save(callback) {
      const result = await sendCommand("DeleteProjectStatus", {
        projectStatusId: this.projectStatusId
      });

      if (result.success) {
        this.$emit("saved");
      }
      callback({ success: result.success });
    }
  }
};
</script>
