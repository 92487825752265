<template>
    <div>
        <v-toolbar flat dense>
            <v-toolbar-title>Licenses</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
                <v-btn depressed @click="addingLicense = true"
                    >Add License</v-btn
                >
            </v-toolbar-items>
        </v-toolbar>
        <v-data-table :items="licenses" :headers="headers">
            <template v-slot:[`item.licenseId`]="{ item }">
                <div class="id-col">
                    <v-icon x-small @click="copyId(item.licenseId)"
                        >mdi-content-copy</v-icon
                    >
                    {{ item.licenseId }}
                </div>
            </template>
            <template v-slot:[`item.start`]="{ item }">
                {{ $format.shortDate(item.start) }}
            </template>
            <template v-slot:[`item.end`]="{ item }">
                <template v-if="item.end">
                    {{ $format.shortDate(item.end) }}
                </template>
                <span v-else class="grey--text">
                    {{ $format.shortDate(item.virtualEnd) }}
                </span>
            </template>
        </v-data-table>

        <add-license
            v-model="addingLicense"
            :tenant-id="tenantId"
            @saved="refresh"
        />
    </div>
</template>

<script>
import { isGuid } from '@/services/validation'
import { parseISO, formatISO, addDays } from 'date-fns'

import { getTenantLicenses } from '@/services/api'
import AddLicense from '../dialogs/AddLicense'

export default {
    components: { AddLicense },
    props: {
        tenantId: {
            validator: isGuid,
            required: true,
        },
    },
    data: function () {
        return {
            addingLicense: false,
            headers: [
                { text: 'Id', value: 'licenseId', width: 50 },
                { text: 'Licenstie', value: 'license' },
                { text: 'Projecten/m', value: 'maxProjects' },
                { text: 'Bedrag/m', value: 'amount' },
                { text: 'Start', value: 'start' },
                { text: 'End', value: 'end' },
            ],
            licenses: [],
        }
    },
    watch: {
        tenantId: {
            immediate: true,
            handler: function () {
                this.refresh()
            },
        },
    },
    methods: {
        async refresh() {
            if (this.tenantId) {
                this.licenses = await getTenantLicenses
                    .create({ tenantId: this.$props.tenantId })
                    .load()
                let previousLicense = null
                for (let index in this.licenses) {
                    const license = this.licenses[index]

                    if (previousLicense !== null) {
                        previousLicense.virtualEnd = formatISO(
                            addDays(parseISO(license.start), -1)
                        )
                    }

                    previousLicense = license
                }
            } else {
                this.licenses = []
            }
        },
        copyId(licenseId) {
            navigator.clipboard.writeText(licenseId)
        },
    },
}
</script>
<style>
.id-col {
    width: 50px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
</style>
