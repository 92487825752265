<template>
    <v-dialog
        :value="value"
        @input="$emit('input', $event)"
        :max-width="1000"
        non-linear
        :loading="loading"
        :persistent="step === 2"
    >
        <v-card v-if="project">
            <v-card-title>{{
                $localize('ChangeStatusWizard.title')
            }}</v-card-title>

            <v-card-text>
                <change-status-step
                    :project-id="projectId"
                    :project="project"
                    @close="close"
                    @saved="onSaved"
                    @target="target = $event"
                />
            </v-card-text>
        </v-card>
        <send-status-update
            v-model="sendStatusUpdate"
            :project-id="projectId"
            @saved="onStatusSend"
            @input="close"
        />
    </v-dialog>
</template>

<script>
import { getProjectById } from '@/services/api'
import { isGuid } from '@/services/validation'
import backButton from '@/mixins/backButton'

import ChangeStatusStep from '../components/steps/ChangeStatusStep'
import SendStatusUpdate from './SendStatusUpdate'

export default {
    mixins: [
        backButton(
            (x) => x.value,
            (x) => x.$emit('input', false)
        ),
    ],
    components: {
        ChangeStatusStep,
        SendStatusUpdate,
    },
    props: {
        value: {
            type: Boolean,
            default: false,
        },
        projectId: {
            validator: isGuid,
            required: true,
        },
    },

    data() {
        return {
            key: 0,
            step: 1,
            project: null,
            loading: false,
            target: {},
            maxSteps: 3,
            sendStatusUpdate: false,
        }
    },
    created() {
        this.refresh()
    },
    methods: {
        enableStep(step) {
            switch (step) {
                case 1:
                    return true
                case 2:
                    if (this.target) return false
                    if (!this.target) return false
                    switch (this.target.phase) {
                        case 'request':
                            return false
                        case 'offer':
                            return false
                        case 'order':
                            if (!this.project.productionDate) {
                                return true
                            }
                            return false
                        case 'invoice':
                            if (!this.project.invoice.addressId) {
                                return true
                            }
                            return false
                        case 'archive':
                            return false
                    }
                    return false
                case 3:
                    return this.sendStatusUpdate
            }
            return true
        },
        async refresh() {
            this.loading = true
            const { result, ok } = await getProjectById.call({
                args: { projectId: this.projectId },
            })
            if (ok) {
                this.project = result
            }
            this.loading = false
        },
        onSaved(sendStatusUpdate) {
            console.log(sendStatusUpdate)
            this.sendStatusUpdate = sendStatusUpdate
            if (!sendStatusUpdate) {
                this.close()
            }
        },
        onStatusSend() {
            this.close()
        },
        close() {
            this.$emit('input', false)
        },
    },
}
</script>
<style lang="scss" scoped>
.v-stepper__content {
    padding: 0;
    transition: none;
}
</style>
