import { ApiEndPoint } from './apiEndPoint'

export const getPriceTypes = new ApiEndPoint('calculation/price-types')
export const getAttributeTypes = new ApiEndPoint('calculation/attribute-types')
export const getAttributes = new ApiEndPoint('calculation/attributes')
export const getCalculationTypes = new ApiEndPoint('calculation/calculation-types')
export const getCalculationType = new ApiEndPoint('calculation/calculation-types/{articleCalculationId}')

export const getCodeEnvironment = new ApiEndPoint('code/environment')
//export const getTriggers = new ApiEndPoint('code/triggers')

//export const getCodeContextById = new ApiEndPoint('code/context/{codeContextId}')

export const getAllCalculations = new ApiEndPoint('calculation')
export const getAllPublishedCalculations = new ApiEndPoint('calculation/published')
export const getCalculationById = new ApiEndPoint('calculation/{calculationId}/{version}')

export const testCalculation = new ApiEndPoint('calculation/{calculationId}/{version}/test', null, 'POST')


export const getAllTriggers = new ApiEndPoint('triggers')
export const getTriggerById = new ApiEndPoint('triggers/{triggerId}');

export const getEventTypes = new ApiEndPoint('code/events');


export const getAllSmtpSettings = new ApiEndPoint('system/smtp')
export const getSmtpSettingById = new ApiEndPoint('system/smtp/{smtpSettingId}')

export const getAllVat = new ApiEndPoint('system/vat')

export const getAllIntegrations = new ApiEndPoint('system/integrations')
export const getIntegration = new ApiEndPoint('system/integration/{provider}')

export const getSystemFile = new ApiEndPoint('system/file')
export const getFile = new ApiEndPoint('system/file/{fileId}')

