<template>
	<dialog-form
		id="formEnterRelationInformation"
		:value="value"
		@input="$emit('input', $event)"
		@commit="save"
		:title="$localize('EnterRelationInformation.title')"
		:allow-continue="false"
		:loading="loading"
	>
		<v-text-field
			:label="$localize('Relation.companyName')"
			v-model="enterRelationInformation.companyName"
			:rules="[validation.required, validation.maxLength(50)]"
			prepend-icon="mdi-account"
			class="inputCompanyName"
		/>
		<v-text-field
			:label="$localize('Relation.phone')"
			v-model="enterRelationInformation.phoneNumber"
			:rules="[validation.maxLength(50)]"
			prepend-icon="mdi-phone"
			class="inputPhone"
		/>
		<input-email
			v-model="enterRelationInformation.email"
			class="inputEmail"
		/>
		<input-email
			v-model="enterRelationInformation.invoiceEmail"
			:label="$localize('Relation.invoiceEmail')"
			class="inputInvoiceEmail"
		/>

		<v-text-field
			:label="$localize('Relation.website')"
			v-model="enterRelationInformation.website"
			:rules="[validation.maxLength(50)]"
			prepend-icon="mdi-web"
			class="inputWebsite"
		/>

		<v-text-field
			:label="$localize('Relation.fax')"
			v-model="enterRelationInformation.faxNumber"
			:rules="[validation.maxLength(50)]"
			prepend-icon="mdi-fax"
			class="inputFax"
		/>
		<v-text-field
			:label="$localize('Relation.tax')"
			:rules="[validation.maxLength(50)]"
			v-model="enterRelationInformation.taxNumber"
			prepend-icon="mdi-bank"
			class="inputTax"
		/>
		<select-relation-group
			:label="$localize('Relation.group')"
			v-model="enterRelationInformation.groupId"
		/>

		<v-layout row class="mx-3">
			<v-flex xs12 sm6>
				<v-switch
					:label="$localize('Relation.isCustomer')"
					v-model="enterRelationInformation.isCustomer"
					:rules="[validation.customerOrSupplier]"
					class="switchIsCustomer"
				/>
			</v-flex>
			<v-flex xs12 sm6>
				<v-switch
					:label="$localize('Relation.isSupplier')"
					v-model="enterRelationInformation.isSupplier"
					:rules="[validation.customerOrSupplier]"
					class="switchIsSupplier"
				/>
			</v-flex>
		</v-layout>
	</dialog-form>
</template>

<script>
import DialogForm from '@/shared/components/DialogForm'
import SelectRelationGroup from '@/main/components/forms/SelectRelationGroup'

import sendCommand from '@/services/sendCommand'
import { getRelationById } from '@/services/api'
import { isGuid } from '@/services/validation'
import { required, email, maxLength } from '@/services/validation'
export default {
	components: { DialogForm, SelectRelationGroup },
	props: {
		relationId: {
			required: true,
			validator: isGuid,
		},
		value: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			loading: false,
			enterRelationInformation: {},
			validation: {
				required,
				email,
				customerOrSupplier: () => {
					if (
						!this.enterRelationInformation.isCustomer &&
						!this.enterRelationInformation.isSupplier
					) {
						return 'Relation must be a customer or a supplier'
					}
					return true
				},
				maxLength,
			},
		}
	},
	async created() {
		this.loading = true
		const { result, ok } = await getRelationById.call({
			args: { relationId: this.$route.params.relationId },
		})
		if (ok) {
			const relation = result
			this.relation = relation
			this.enterRelationInformation = {
				relationId: this.relationId,
				companyName: relation.companyName,
				website: relation.website,
				email: relation.email,
				invoiceEmail: relation.invoiceEmail,
				phoneNumber: relation.phoneNumber,
				faxNumber: relation.faxNumber,
				taxNumber: relation.taxNumber,
				isCustomer: relation.isCustomer,
				isSupplier: relation.isSupplier,
				groupId: relation.groupId,
			}
		}

		this.loading = false
	},
	methods: {
		async save(callback) {
			const result = await sendCommand(
				'EnterRelationInformation',
				this.enterRelationInformation
			)
			if (result.success) {
				this.$emit('saved')
			}
			callback({ success: result.success })
		},
	},
}
</script>
