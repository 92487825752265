<template>
    <v-container>
        <v-toolbar flat dense>
            <v-btn icon @click="$router.push({ name: 'adminHome' })">
                <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
            <v-toolbar-title>Tenants</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
                <v-text-field
                    v-model="filter"
                    id="searchTenants"
                    outlined
                    class="mr-3"
                    dense
                    hide-details
                ></v-text-field>
                <v-btn @click="createTenant = true">New tenant</v-btn>
            </v-toolbar-items>
        </v-toolbar>
        <v-data-table
            :items="tenants"
            :headers="headers"
            @click:row="navTenant($event)"
            :search="filter"
        >
            <template v-slot:[`item.tenantId`]="{ item }">
                <div class="id-col">
                    <v-icon x-small @click="copyId(item.tenantId)"
                        >mdi-content-copy</v-icon
                    >
                    {{ item.tenantId }}
                </div>
            </template>
        </v-data-table>

        <create-tenant v-model="createTenant" @saved="refresh()" />
    </v-container>
</template>

<script>
import { getTenants } from '@/services/api'
import CreateTenant from '../components/tenants/CreateTenant.vue'

export default {
    components: { CreateTenant },
    data() {
        return {
            headers: [
                { text: 'Id', value: 'tenantId', width: 50 },
                { text: 'Naam', value: 'name' },
                { text: 'Domain', value: 'domain' },
            ],
            tenants: [],
            createTenant: false,
            createTenantEnvironment: false,
            filter: '',
        }
    },
    created: function () {
        this.refresh()
    },
    methods: {
        async refresh() {
            this.tenants = await getTenants.create().load()
        },
        navTenant(tenant) {
            this.$router.push({
                name: 'tenantDetails',
                params: { tenantId: tenant.tenantId },
            })
        },
    },
}
</script>
