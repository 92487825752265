<template>
  <div class="pa-3">
    <v-select v-model="filter.levels" :items="levels" label="Level" multiple />
    <v-combobox
      v-model="propertyFilter.property"
      label="Property"
      :items="properties"
    />

    <v-select v-model="propertyFilter.operator" :items="operators" />
    <v-combobox v-model="propertyFilter.value" :items="propertyValues[propertyFilter.property]" :disabled="!propertyFilter.property"  />
    <v-btn>Add</v-btn>

    <v-btn @click="$emit('input', filter)">Apply</v-btn>
  </div>
</template>

<script>
import{getLogProperties, getLogPropertiesAndValues} from '@/services/api'
export default {
  props: {
    value: Object
  },
  data() {
    return {
      levels: ['Critical', 'Error', 'Warning', 'Information', 'Debug', 'Trace'],
      properties: [],
      propertyValues: [],
      operators: ['>', '>=', '==', '!=', '<=', '<', 'like', 'not like'],
      filter: this.value || {},

      propertyFilter: {}
    };
  },
  async created(){
    this.properties = await getLogProperties.create().load();
    this.propertyValues = await getLogPropertiesAndValues.create().load();
  }
};
</script>
