<template>
  <dialog-form
    :value="value"
    @input="$emit('input', $event)"
    :title="$localize('AssignInvoiceNumber.title')"
    @commit="save"
    :allow-continue="false"
    :action-text="$localize('AssignInvoiceNumberStep.button.assignInvoiceNumber')"
  >
    {{$localize('AssignInvoiceNumber.description')}}
  </dialog-form>
</template>

<script>
import DialogForm from "@/shared/components/DialogForm";
import sendCommand from "@/services/sendCommand";
import { isGuid } from "@/services/validation"

export default {
  components: { DialogForm },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    projectId: {
      validator: isGuid,
      required: true
    }
  },
  methods: {
    async save(callback) {
      const result = await sendCommand("AssignInvoiceNumber", {
        projectId: this.projectId
      });

      if (result.success) {
        this.$emit("saved");
      }
      callback({success: result.success});
    }
  }
};
</script>
