<template>
	<v-card>
		<v-card-text>
			<code-editor
				v-model="codeContext"
				:code-context-id="calculation.calculationId"
				:allow-in-out-variables="false"
				@input="updateCodeContext()"
			/>
			<v-checkbox
				:label="
					$localize(
						'CalculationCodeEditor.field.displayCodeStructure'
					)
				"
				v-model="displayCode"
			/>
			<pre v-if="displayCode" style="white-space: pre">{{
				JSON.stringify(codeContext.statements, null, 2)
			}}</pre>
		</v-card-text>
		<v-card-actions>
			<v-btn :disabled="!isDirty" @click="resetCodeContext()">{{
				$localize('form.button.cancel')
			}}</v-btn>
			<v-btn :disabled="!isDirty" @click="saveCodeContext(false)">{{
				$localize('form.button.save')
			}}</v-btn>
			<v-btn color="primary" @click="saveCodeContext(true)">{{
				$localize('form.button.saveAndContinue')
			}}</v-btn>
		</v-card-actions>
	</v-card>
</template>

<script>
import sendCommand from '@/services/sendCommand'
import CodeEditor from '@/shared/code/CodeEditor'
export default {
	components: { CodeEditor },
	props: {
		value: Object,
	},

	data() {
		return {
			calculation: {},
			codeContext: {},
			isDirty: false,
			displayCode: false,
		}
	},

	watch: {
		value: {
			immediate: true,
			handler(val) {
				this.calculation = val
				this.resetCodeContext()
			},
		},
	},

	methods: {
		updateCodeContext() {
			this.isDirty = true
		},
		resetCodeContext() {
			this.calculation = this.value

			this.codeContext = {
				contextType: 'calculation',
				events: [],
				statements: JSON.parse(this.calculation.actions) || [],
				variables: [...this.calculation.variables],
			}
			this.isDirty = false
		},
		async saveCodeContext(nextStep) {
			if (this.isDirty) {
				this.isDirty = false
				const result = await sendCommand('ChangeCalculationCode', {
					calculationId: this.$route.params.calculationId,
					jsonCode: JSON.stringify(this.codeContext.statements),
				})

				const result2 = await sendCommand(
					'ChangeCalculationCodeVariables',
					{
						calculationId: this.$route.params.calculationId,
						variables: this.codeContext.variables.filter(
							(x) => !x.isSystemDefined
						),
					}
				)

				if (!result.success || !result2.success) {
					return
				}
			}
			this.$emit('saved')
			if (nextStep) {
				this.$emit('continue')
			}
		},
	},
}
</script>
