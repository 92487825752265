<template>
  <div>
      <table>
          <tr v-for="message in commands" :key="message.messageId">
              <td>
                  <router-link :to="{ name: 'message', params: { messageId: message.messageId }}">
                    {{ message.messageId }}
                    </router-link>
                  </td>
              <td>{{ message.type }}</td>
              <td>{{ message.created }}</td>
          </tr>
      </table>
  </div>
</template>

<script>
import { getCommands } from "@/services/api";
export default {
  data() {
    return {
        commands: []
    };
  },
  created: async function() {
    this.commands = await getCommands.create().load();
  }
};
</script>

