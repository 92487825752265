<template>
  <div>
    <template v-for="(setting, index) in editors">
      <div :key="index">
        <div v-if="setting.file">
          <v-file-input />
        </div>
        <div v-else-if="typeof(setting.items) === 'function'">
          <v-select
            @input="saveSetting(setting.prop, $event)"
            filled
            v-model="settings[setting.prop]"
            :items="setting.items()"
            :label="$localize('Settings.prop.' + setting.prop)"
            :hint="$localize('Settings.prop.' + setting.prop + '.hint')"
            persistent-hint
            :multiple="setting.multiple"
            :item-value="setting.itemValue"
            :item-text="setting.itemText"
          />
        </div>
        <div v-else>
          <v-text-field
            :value="settings[setting.prop]"
            @change="saveSetting(setting.prop, $event)"
            filled
            :label="$localize('Settings.prop.' + setting.prop)"
            :hint="$localize('Settings.prop.' + setting.prop + '.hint')"
            persistent-hint
            :type="setting.type || 'text'"
          />
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import sendCommand from '@/services/sendCommand';
import { currentSession } from "@/services/session";

export default {
  data() {
    return {
      settings: {},
      editors: [
        { prop: "theme",  items: () => this.themes },
      ],
      themes: [ 
         { value: "default", text: this.$localize('Theme.standard') },
         { value: "dark", text: this.$localize('Theme.dark') }
      ],
      session: {}
    };
  },
  created() {
    this.refresh();
  },
  methods: {
    async refresh() {
      this.session = await currentSession();
      this.settings = this.session.userSettings;
    },
    async saveSetting(key, value){
      const result = await sendCommand("SaveUserSetting", {
        key, 
        value
      })
      if(!result.success) return;
      
      if(key === 'theme'){
        if(value === 'dark'){
          this.$vuetify.theme.dark = true;
        }
        else{
          this.$vuetify.theme.dark = false;
        }
      }
    }
  }
};
</script>
