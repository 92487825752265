<template>
  <v-select
    :label="$localize('SelectSalutation.label')"
    :hint="$localize('SelectSalutation.hint')"
    :items="items"
    :value="value"
    @input="$emit('input', $event)"
    prepend-icon="mdi-gender-male-female"
    :autofocus="autofocus"
    class="selectSalutation"
    clearable
  />
</template>

<script>
export default {
  props: {
    value: String,
    autofocus: Boolean
  },
  data() {
    return {
      items: [
        this.$localize("SelectSalutation.value.MaleFemale"),
        this.$localize("SelectSalutation.value.Famale"),
        this.$localize("SelectSalutation.value.Male")
      ]
    };
  }
};
</script>