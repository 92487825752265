<template>
	<v-skeleton-loader :loading="loading" type="list-item">
		<v-list>
			<v-list-item
				v-for="dashboard in dashboards"
				:key="dashboard.calculationId"
				:to="{
					name: 'dashboard',
					params: {
						dashboardId: dashboard.dashboardId,
					},
				}"
			>
				<v-list-item-title>{{ dashboard.name }}</v-list-item-title>
			</v-list-item>
			<template v-if="dashboards.length === 0">
				<v-list-item
					v-if="$session.roles.includes('settings_general')"
					:to="{
						name: 'settingsOverview',
						params: { category: 'dashboards' },
					}"
				>
					<v-list-item-icon>
						<v-icon>mdi mdi-plus</v-icon>
					</v-list-item-icon>
					<v-list-item-content>
						<v-list-item-title>Dashboard maken</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
				<v-list-item
					v-else
				>
					<v-list-item-content>
						<v-list-item-title>Er zijn geen dashboards</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
			</template>
		</v-list>
	</v-skeleton-loader>
</template>

<script>
import { getDashboards } from '@/services/api'
import PubSub from 'pubsub-js'
export default {
	data() {
		return {
			dashboards: [],
			unsubscribe: null,
			loading: false,
		}
	},
	async created() {
		await this.refresh()
		this.unsubscribe = PubSub.subscribe('RefreshDashboardMenu', () => {
			this.refresh()
		})
	},
	destroyed() {
		PubSub.unsubscribe(this.unsubscribe)
	},

	methods: {
		async refresh() {
			this.loading = true
			const { result, ok } = await getDashboards.call()
			if (ok) {
				this.dashboards = result
			}
			this.loading = false
		},
	},
}
</script>
