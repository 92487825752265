<template>
    <router-view />
</template>
<script>
export default {
    async created() {
        this.$vuetify.lang.current = 'nl'
    },
}
</script>
