<template>
	<div>
		<v-toolbar flat height="36" class="my-3">
			<v-spacer></v-spacer>
			<v-toolbar-items>
				<v-btn class="primary" @click="createAttribute = true">
					{{ $localize('AttributesOverview.button.createAttribute') }}
					<v-icon>mdi-plus</v-icon>
				</v-btn>
			</v-toolbar-items>
		</v-toolbar>

		<v-sheet>
			<v-data-table
				:items="attributes"
				:headers="headers"
				dense
				disable-pagination
				hide-default-footer
			>
				<template v-slot:[`item.isEnabled`]="{ item }">
					<span v-show="item.isEnabled">
						<v-icon class="success--text">mdi-check</v-icon>
					</span>
					<span v-show="!item.isEnabled">
						<v-icon class="error--text">mdi-close</v-icon>
					</span>
				</template>
				<template v-slot:[`item.isPrice`]="{ item }">
					<span v-if="item.isPrice">{{
						$localize('Attribute.type.price')
					}}</span>
					<span v-else>{{
						$localize('Attribute.type.attribute')
					}}</span>
				</template>
				<template v-slot:[`item.alias`]="{ item }">
					<v-btn
						small
						depressed
						v-if="item.isEnabled"
						@click="disable(item)"
						class="mr-2"
						>{{ $localize('form.button.disable') }}</v-btn
					>
					<v-btn
						small
						depressed
						v-if="!item.isEnabled"
						@click="enable(item)"
						class="mr-2"
						>{{ $localize('form.button.enable') }}</v-btn
					>
					<v-btn small depressed @click="rename(item)" class="mr-2">
						<v-icon dense>mdi-pencil</v-icon>
					</v-btn>
					<v-btn small depressed @click="delAttribute(item)">
						<v-icon>mdi-close</v-icon>
					</v-btn>
				</template>
			</v-data-table>
		</v-sheet>

		<create-attribute
			v-if="createAttribute"
			v-model="createAttribute"
			@saved="refresh()"
		/>
		<rename-attribute
			v-if="renameAttribute"
			v-model="renameAttribute"
			:alias="renameAttributeAlias"
			:displayName="renameAttributeDisplayName"
			@saved="refresh()"
		/>
		<delete-attribute
			v-if="deleteAttribute"
			v-model="deleteAttribute"
			:alias="deleteAttributeAlias"
			@saved="refresh()"
		/>
	</div>
</template>

<script>
import CreateAttribute from './dialogs/CreateAttribute'
import RenameAttribute from './dialogs/RenameAttribute'
import DeleteAttribute from './dialogs/DeleteAttribute'

import { getAttributes } from '@/services/api'
import sendCommand from '@/services/sendCommand'

export default {
	components: { CreateAttribute, RenameAttribute, DeleteAttribute },
	data() {
		return {
			loading: false,
			createAttribute: false,
			deleteAttribute: false,
			deleteAttributeAlias: null,

			renameAttribute: false,
			renameAttributeAlias: null,
			renameAttributeDisplayName: null,
			headers: [
				{
					text: this.$localize('Attribute.displayName'),
					value: 'displayName',
				},
				{ text: this.$localize('Attribute.type'), value: 'isPrice' },
				{
					text: this.$localize('Attribute.status'),
					value: 'isEnabled',
				},
				{ text: '', value: 'alias', width: 300 },
			],
			attributes: [],
		}
	},

	created() {
		this.refresh()
	},

	methods: {
		async refresh() {
			this.loading = true
			const { result, ok } = await getAttributes.call()
			if (ok) {
				this.attributes = result
			}

			this.loading = false
		},

		rename(attribute) {
			this.renameAttributeAlias = attribute.alias
			this.renameAttributeDisplayName = attribute.displayName
			this.renameAttribute = true
		},
		async enable(attribute) {
			const result = await sendCommand('EnableAttribute', {
				alias: attribute.alias,
			})
			if (result.success) {
				attribute.isEnabled = true
			}
		},
		async disable(attribute) {
			const result = await sendCommand('DisableAttribute', {
				alias: attribute.alias,
			})
			if (result.success) {
				attribute.isEnabled = false
			}
		},
		delAttribute(attribute) {
			this.deleteAttributeAlias = attribute.alias
			this.deleteAttribute = true
		},
	},
}
</script>
