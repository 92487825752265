<template>
	<dialog-form
		:value="value"
		@input="$emit('input', $event)"
		:allow-continue="false"
		:title="$localize('EnterArticleInformation.title')"
		@commit="save"
		:loading="loading"
	>
		<v-text-field
			v-model="enterArticleInformationCommand.name"
			:rules="[validation.required, validation.maxLength(200)]"
			:label="$localize('Article.name')"
			prepend-icon="mdi-cube"
		/>

		<select-relation
			v-model="enterArticleInformationCommand.supplierId"
			:customers="false"
			:suppliers="true"
			:label="$localize('Article.supplier')"
			:rules="{}"
		/>

		<v-combobox
			v-model="enterArticleInformationCommand.brand"
			:items="brands"
			:label="$localize('Article.brand')"
			prepend-icon="mdi-format-list-bulleted"
			@input.native="setBrand"
			:rules="[validation.maxLength(200)]"
		/>
		<v-text-field
			v-model="enterArticleInformationCommand.url"
			:label="$localize('Article.url')"
			prepend-icon="mdi-link"
			:rules="[validation.maxLength(200)]"
		/>
		<v-combobox
			v-model="enterArticleInformationCommand.category"
			:items="categories"
			:label="$localize('Article.category')"
			prepend-icon="mdi-group"
			@input.native="setCategory"
			:rules="[validation.maxLength(200)]"
		/>
		<v-textarea
			v-model="enterArticleInformationCommand.note"
			:label="$localize('Article.note')"
			prepend-icon="mdi-note"
			:rules="[validation.maxLength(500)]"
		/>
		<v-switch
			v-model="enterArticleInformationCommand.isDefault"
			:label="$localize('Article.isDefault')"
			:hint="$localize('Article.isDefault.hint')"
		/>
	</dialog-form>
</template>

<script>
import DialogForm from '@/shared/components/DialogForm'

import sendCommand from '@/services/sendCommand'
import { isGuid } from '@/services/validation'
import {
	getArticleBrands,
	getArticleCategories,
	getArticleById,
} from '@/services/api'
import { required, maxLength } from '@/services/validation'
import SelectRelation from '@/main/components/forms/SelectRelation'

export default {
	components: { SelectRelation, DialogForm },
	props: {
		articleId: {
			required: true,
			validator: isGuid,
		},
		value: {
			type: Boolean,
			default: false,
		},
	},

	data() {
		return {
			loading: false,
			enterArticleInformationCommand: {},
			brands: [],
			categories: [],
			validation: { required, maxLength },
		}
	},
	async created() {
		this.loading = true

		const { result, ok } = await getArticleById.call({
			args: { articleId: this.articleId },
		})
		if (ok) {
            const article = result
			this.enterArticleInformationCommand = {
				articleId: this.articleId,
				name: article.name,
				brand: article.brand,
				url: article.url,
				category: article.category,
				note: article.note,
				supplierId: article.supplierId,
				isDefault: article.isDefault,
			}
		}
		await this.loadBrands()
		await this.loadCategories()

		this.loading = false
	},
	methods: {
		async loadBrands() {
			const { result, ok } = await getArticleBrands.call()
			if (ok) {
				this.brands = result
			}
		},
		async loadCategories() {
			const { result, ok } = await getArticleCategories.call()
			if (ok) {
				this.categories = result.filter((category) => category !== null)
			}
		},
		setCategory(e) {
			if (!e) {
				this.enterArticleInformationCommand.category = ''
			} else if (typeof e === 'object') {
				this.enterArticleInformationCommand.category =
					e.srcElement.value
			}
		},
		setBrand(e) {
			if (!e) {
				this.enterArticleInformationCommand.brand = ''
			} else if (typeof e === 'object') {
				this.enterArticleInformationCommand.brand = e.srcElement.value
			}
		},
		async save(callback) {
			const result = await sendCommand(
				'EnterArticleInformation',
				this.enterArticleInformationCommand
			)
			if (result.success) {
				this.$emit('saved')
			}
			callback({ success: result.success })
		},
	},
}
</script>
