<template>
	<v-autocomplete
		:value="selectedUserIds"
		@input="onUserSelected($event)"
		:label="
			label ??
			(multiple
				? $localize('SelectUser.field.users')
				: $localize('SelectUser.field.user'))
		"
		:items="users"
		item-text="name"
		item-value="userId"
		:clearable="true"
		:rules="rules()"
		:multiple="multiple"
		:outlined="!form"
		:flat="!form"
		:solo="!form"
		prepend-icon="mdi-account-tie"
		:autofocus="autofocus"
		:loading="loading"
		:hide-details="hideDetails"
		class="selectUser"
	/>
</template>

<script>
import { isGuid } from '@/services/validation'
import { required } from '@/services/validation'
import { getUsers } from '@/services/api'

export default {
	props: {
		value: {
			validator(value) {
				if (!value) return true

				if (Array.isArray(value)) {
					return value.every((e) => isGuid(e))
				}

				return isGuid(value)
			},
		},
		multiple: {
			type: Boolean,
			default: false,
		},
		required: {
			type: Boolean,
			default: true,
		},
		form: {
			type: Boolean,
			default: true,
		},
		autofocus: Boolean,
		hideDetails: [Boolean, String],
		label: String,
	},
	data() {
		return {
			selectedUserIds: this.value,
			validation: { required },
			rules() {
				if (this.required) {
					return [required]
				}
				return []
			},
			users: [],
			loading: false,
		}
	},
	watch: {
		value(val) {
			this.selectedUserIds = val
		},
	},
	async created() {
		this.loading = true
		const { result, ok } = await getUsers.call({ q: { trash: false } })
		if (ok) {
			this.users = result
		}
		this.loading = false
	},
	methods: {
		onUserSelected(userIds) {
			this.selectedUserIds = userIds
			this.$emit('input', userIds)
		},
	},
}
</script>
