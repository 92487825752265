<template>
  <dialog-form
    id="formCreateRelationNote"
    :value="value"
    @input="$emit('input', $event)"
    @commit="save"
    :title="$localize('CreateRelationNote.title')"
    :allow-continue="false"
    @reset="note = ''"
  >
    <v-textarea
      v-model="note"
      :label="$localize('Relation.note')"
      :rules="[validation.required, validation.maxLength(2000)]"
      autofocus
      class="inputNote"
    />
  </dialog-form>
</template>

<script>
import DialogForm from "@/shared/components/DialogForm";

import sendCommand from "@/services/sendCommand";
import { isGuid } from "@/services/validation"
import { required, maxLength } from "@/services/validation";

export default {
  components: { DialogForm },
  props: {
    relationId: {
      required: true,
      validator: isGuid,
    },
    value: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      note: "",
      validation: { required, maxLength },
    };
  },
  methods: {
    async save(callback) {
      const result = await sendCommand("CreateRelationNote", {
        relationId: this.relationId,
        note: this.note,
      });
      if (result.success) {
        this.$emit("saved");
      }
      callback({ success: result.success });
    },
  },
};
</script>