<template>
	<v-navigation-drawer
		:value="value"
		@input="$emit('input', $event)"
		right
		app
		width="350"
		clipped
	>
		<v-skeleton-loader :loading="!project">
			<v-toolbar flat class="my-3" height="36">
				<v-toolbar-title>
					{{ $localize('ProjectInformation.customerDetails') }}
				</v-toolbar-title>
				<v-spacer />
				<v-toolbar-items>
					<v-btn @click="showProjectNotes = !showProjectNotes">
						<v-icon v-show="!showProjectNotes"
							>mdi-chevron-down</v-icon
						>
						<v-icon v-show="showProjectNotes"
							>mdi-chevron-up</v-icon
						>
					</v-btn>
				</v-toolbar-items>
			</v-toolbar>
			<v-divider />
			<v-expand-transition>
				<project-notes
					v-if="project.relationId"
					v-show="showProjectNotes"
					:relationId="project.relationId"
				/>
			</v-expand-transition>

			<v-row
				no-gutters
				align="center"
				class="font-weight-bold project-header-row"
			>
				<v-col>{{ $localize('Project.customerInformation') }}</v-col>
			</v-row>

			<v-row wrap dense class="py-2">
				<v-col cols="5">{{ $localize('Project.number') }}</v-col>
				<v-col cols="7">
					{{ project.projectNumber }}
				</v-col>
				<v-col cols="5">{{ $localize('Project.project') }}</v-col>
				<v-col cols="7">
					{{ project.project }}
				</v-col>
				<v-col cols="5">{{ $localize('Project.customer') }}</v-col>
				<v-col cols="7">
					<relation-link :relation-id="project.relationId" :text="project.relationName" />
				</v-col>
				<v-col cols="5">{{ $localize('Project.contact') }}</v-col>
				<v-col cols="7">{{ project.contactName }}</v-col>
				<v-col cols="5">{{ $localize('Project.addedOn') }}</v-col>
				<v-col cols="7">{{ $format.shortDate(project.created) }}</v-col>
				<v-col cols="5">{{ $localize('Project.addedBy') }}</v-col>
				<v-col cols="7">
					<router-link
						v-if="project.userId"
						:to="{
							name: 'userDetails',
							params: { userId: project.createdBy },
						}"
						>{{ project.createdByName }}</router-link
					>
				</v-col>
				<v-col cols="5" class="mt-3">{{
					$localize('Project.handler')
				}}</v-col>
				<v-col cols="7" class="mt-3">
					<router-link
						v-if="project.userId"
						:to="{
							name: 'userDetails',
							params: { userId: project.userId },
						}"
						>{{ project.userName }}</router-link
					>
				</v-col>

				<v-col cols="5">{{
					$localize('Project.deliveryMethod')
				}}</v-col>
				<v-col cols="7">
					<template v-if="project.deliveryMethod">{{
						$localize('DeliveryMethod.' + project.deliveryMethod)
					}}</template>
				</v-col>
				<v-col cols="5">{{
					$localize('Project.deliveryMontageAddress')
				}}</v-col>
				<v-col cols="7">{{ project.deliveryAddressText }}</v-col>
				<v-col cols="5">{{
					$localize('Project.deliveryMontageContact')
				}}</v-col>
				<v-col cols="7">{{ project.deliveryContactName }}</v-col>

				<v-col cols="5" class="mt-3">{{
					$localize('Project.status')
				}}</v-col>
				<v-col cols="7" class="mt-3">{{
					project.projectStatusName
				}}</v-col>
				<v-col cols="5">{{
					$localize('Project.productionDate')
				}}</v-col>
				<v-col cols="7">{{
					$format.shortDate(project.productionDate)
				}}</v-col>
				<v-col cols="5">{{ $localize('Project.deadline') }}</v-col>
				<v-col cols="7">{{
					$format.shortDate(project.deadline)
				}}</v-col>
			</v-row>
			<template v-if="project.productionInfo">
				<v-row class="project-header-row" no-gutters dense>
					<v-col>
						{{ $localize('ProjectNotes.title.productionInfo') }}
					</v-col>
				</v-row>
				<v-row dense>
					<v-col class="pre my-2">{{ project.productionInfo }}</v-col>
				</v-row>
			</template>

			<template v-if="project.workInfo">
				<v-row class="project-header-row" no-gutters dense>
					<v-col>
						{{ $localize('ProjectNotes.title.workInfo') }}
					</v-col>
				</v-row>
				<v-row dense>
					<v-col class="pre my-2">{{ project.workInfo }}</v-col>
				</v-row>
			</template>
			<v-row class="project-header-row" no-gutters dense>
				<v-col>
					{{ $localize('ProjectInformation.attachments') }}
				</v-col>
			</v-row>
			<v-row dense>
				<v-col
					v-for="file in files"
					:key="file.fileId"
					cols="6"
					class="align-self-center text-center preview-file"
				>
					<v-img
						v-if="file.contentType.startsWith('image')"
						:src="getFile.create({ fileId: file.fileId }).url"
						@click="onFileClick(file.fileId)"
						content-class="center center"
					/>
					<v-icon v-else @click="onFileClick(file.fileId)"
						>mdi-file-outline</v-icon
					>
					<div class="caption text-truncate">
						{{ file.originalFileName }}
					</div>
				</v-col>
			</v-row>
			<v-row
				v-if="
					files.length < 10 &&
					$session.roles.includes('projects_manage')
				"
				dense
			>
				<v-col>
					<v-file-input
						prepend-icon="mdi-camera"
						class="justify-center"
						hide-input
						hide-details
						@change="onFileUpload"
					/>
				</v-col>
			</v-row>
		</v-skeleton-loader>
		<project-file
			v-if="showFile"
			v-model="showFile"
			:project-id="projectId"
			:file-id="selectedFileId"
		/>
	</v-navigation-drawer>
</template>

<script>
import Vue from 'vue'
import ProjectNotes from './ProjectNotes'
import ProjectFile from '../dialogs/ProjectFile'
import RelationLink from '../../components/links/RelationLink'
import { isGuid } from '@/services/validation'
import backButton from '@/mixins/backButton'
import sendCommand from '@/services/sendCommand'
import { getFile } from '@/services/api'

export default {
	mixins: [
		backButton(
			// Only close the component using back button on sm or smaller screens
			(x) => x.value && x.$vuetify.breakpoint.smAndDown,
			(x) => x.$emit('input', false)
		),
	],
	components: { ProjectNotes, ProjectFile, RelationLink },
	props: {
		value: Boolean,
		projectId: {
			validator: isGuid,
		},
		project: Object,
	},
	data() {
		return {
			showProjectNotes: false,
			getFile: getFile,
			showFile: false,
			selectedFileId: null,
			fileMetadata: {},
		}
	},
	watch: {
		async 'project.files'(newFiles) {
			for (const index in newFiles) {
				const fileId = newFiles[index]
				if (this.fileMetadata[fileId] === undefined) {
					const { result, ok } = await getFile.call({
						args: { fileId: fileId },
						q: { meta: true },
					})
					if (ok) {
						Vue.set(this.fileMetadata, fileId, result)
					}
				}
			}
			for (const fileId in this.fileMetadata) {
				if (newFiles.indexOf(fileId) === -1) {
					Vue.delete(this.fileMetadata, fileId)
				}
			}
		},
	},
	computed: {
		files() {
			return Object.values(this.fileMetadata)
		},
	},
	created() {
		if (this.showByDefault && this.$vuetify.breakpoint.mdAndUp) {
			this.$emit('input', true)
		}
	},
	methods: {
		async onFileUpload(value) {
			const formData = new FormData()
			if (value) {
				formData.append(value.name, value)
			}
			formData.append(
				'command',
				JSON.stringify({ projectId: this.projectId })
			)

			const result = await sendCommand('UploadProjectFile', formData, {
				'Content-Type': 'multipart/form-data',
			})
			if (!result.success) return
		},
		onFileClick(fileId) {
			this.selectedFileId = fileId
			this.showFile = true
		},
	},
}
</script>
<style lang="scss" scoped>
.v-divider {
	&.theme--light {
		border-color: #0277bd;
	}

	border-width: 1px;
}

.row {
	padding-left: 12px;

	&.row--dense {
		margin-top: 0;

		.col {
			padding: 2px;
		}
	}
}

.preview-file > * {
	cursor: pointer;
}
</style>
