<template>
	<dialog-form
		:value="value"
		@input="$emit('input', $event)"
		:title="$localize('CalculatePart.title', { part: calculation.name })"
		:allow-continue="false"
		@commit="save"
		max-width="900"
		:loading="loading"
		:action-text="$localize('TestCalculation.createOrder')"
	>
		<v-row>
			<v-col class="col-12 col-sm-6">
				<template v-for="inputField in calculation.input">
					<template v-if="inputField.type === 'number'">
						<number-field
							:key="inputField.key"
							:label="inputField.label"
							:input-field="inputField"
							v-model="input[inputField.key]"
							@input="onInput"
						/>
					</template>
					<template v-if="inputField.type === 'text'">
						<text-field
							:key="inputField.key"
							:label="inputField.label"
							:input-field="inputField"
							v-model="input[inputField.key]"
							@input="onInput"
						/>
					</template>
					<template v-else-if="inputField.type === 'article'">
						<article-field
							:key="inputField.key"
							:label="inputField.label"
							:article-calculation-ids="
								inputField.articleCalculationIds
							"
							:input-field="inputField"
							v-model="input[inputField.key]"
							@input="onInput"
						/>
					</template>
				</template>
			</v-col>
			<v-col class="col-12 col-sm-6">
				<calculation-output
					:state="output"
					:grid="calculation.outputGrid"
					:hide-buy-price="hideBuyPrice"
				/>
				<div
					class="text-center grey--text"
					v-if="result.startingCosts > 0"
				>
					{{ $localize('TestCalculation.initialCosts') }}
				</div>
				<div
					class="text-h5 text-center"
					v-if="result.startingCosts > 0"
				>
					{{ $format.currency(result.startingCosts) }}
				</div>
				<div class="text-center grey--text" v-if="totalCosts">
					{{ $localize('TestCalculation.total') }}
				</div>
				<div class="text-h4 text-center" v-if="totalCosts">
					{{ $format.currency(totalCosts) }}
				</div>
			</v-col>
		</v-row>
		<template v-slot:actions>
			<v-btn
				@click="test()"
				:loading="calculating"
				:disabled="!isDirty"
				class="mr-3 mt-3"
				>{{ $localize('Part.button.calculate') }}</v-btn
			>
			<!--<v-btn class="success mr-3 mt-3" @click="createOrder">
        {{ $localize('TestCalculation.createOrder') }}
        <v-icon>mdi-plus</v-icon>
      </v-btn>-->
		</template>
		<template v-slot:actions-right>
			<v-checkbox
				v-model="hideBuyPrice"
				class="mr-3"
				style="display: inline-block"
				:label="$localize('CalculationOutput.hideBuyPrice')"
				hide-details
			/>
			<v-checkbox
				v-model="autoCalculate"
				style="display: inline-block"
				:label="$localize('CalculatePart.autoCalculate')"
				hide-details
			/>
		</template>

		<create-order
			v-model="creatingOrder"
			:calculation-id="calculationId"
			:calculation-input="input"
			@saved="$emit('input', false)"
		/>
	</dialog-form>
</template>

<script>
import DialogForm from '@/shared/components/DialogForm'

import { isGuid } from '@/services/validation'
import { getCalculationById, testCalculation } from '@/services/api'

import NumberField from '@/main/advanced/components/NumberField'
import ArticleField from '@/main/advanced/components/ArticleField'
import TextField from '@/main/advanced/components/TextField'
import CalculationOutput from '@/main/advanced/components/CalculationOutput'
import CreateOrder from './CreateOrder'
const recalculateDelay = 1000

export default {
	components: {
		NumberField,
		ArticleField,
		TextField,
		CalculationOutput,
		DialogForm,
		CreateOrder,
	},
	props: {
		value: {
			type: Boolean,
			default: false,
		},
		calculationId: {
			validator: isGuid,
			required: true,
		},
	},
	data() {
		return {
			loading: false,
			calculating: false,
			updatingCalculation: false,
			updatingArticles: false,
			creatingOrder: false,
			calculation: {},

			input: {},
			output: {},
			result: {},

			newVersionDate: null,

			hideBuyPrice: JSON.parse(
				localStorage.getItem('CalculationOutput:hideBuyPrice') || true
			),
			autoCalculate:
				JSON.parse(
					localStorage.getItem('CalculatePart:autoCalculate')
				) || false,
			isDirty: true,
			updateTimeout: null,
		}
	},
	computed: {
		totalCosts() {
			return (
				parseFloat(this.output.system_price) + this.result.startingCosts
			)
		},
	},
	watch: {
		hideBuyPrice(value) {
			localStorage.setItem(
				'CalculationOutput:hideBuyPrice',
				JSON.stringify(value)
			)
		},
		autoCalculate(value) {
			localStorage.setItem(
				'CalculatePart:autoCalculate',
				JSON.stringify(value)
			)
		},
	},
	created() {
		this.loadCalculation()
	},
	methods: {
		onInput() {
			if (this.updateTimeout) clearTimeout(this.updateTimeout)

			this.isDirty = true
			if (!this.autoCalculate) return

			this.updateTimeout = setTimeout(
				async () => await this.test(),
				recalculateDelay
			)
		},
		async loadCalculation() {
			this.loading = true
			const { result, ok } = await getCalculationById.call({
				args: {
					calculationId: this.calculationId,
					version: -2,
				},
			})

			if (ok) {
				this.calculation = result
			}
			this.input = {}
			this.output = {}
			this.loading = false
		},
		async test() {
			this.calculating = true
			const { result, ok } = await testCalculation.call({
				args: {
					calculationId: this.calculation.calculationId,
					version: -2,
				},
				payload: this.input,
			})

			if (ok) {
				this.output = result.state
				this.result = result
			}
			this.calculating = false
			this.isDirty = false
		},
		async save(callback) {
			this.creatingOrder = true
			callback({ success: false })
		},
	},
}
</script>
<style scoped lang="css">
.v-alert {
	margin-bottom: 0;
}
</style>
