<template>
    <div>
        <v-toolbar flat height="36" class="my-3">
            <v-toolbar-items>
                <v-btn
                    v-if="$session.roles.includes('projects_manage')"
                    @click="changeStatus = true"
                    class="mr-3"
                >
                    <v-icon color="success">mdi-sync</v-icon>
                    <span v-show="$vuetify.breakpoint.mdAndUp" class="ml-1">{{
                        $localize('ProjectDetails.button.changeStatus')
                    }}</span>
                </v-btn>

                <v-btn
                    v-if="$session.roles.includes('projects_calculate')"
                    @click="sendOrderConfirmation = true"
                    class="mr-3"
                >
                    <v-icon color="success">mdi-email-check</v-icon>
                    <span v-show="$vuetify.breakpoint.mdAndUp" class="ml-1">{{
                        $localize('ProjectDetails.button.sendOrderConfirmation')
                    }}</span>
                </v-btn>

                <v-btn
                    v-if="$session.roles.includes('projects_manage')"
                    @click="sendStatusUpdate = true"
                    class="mr-3"
                >
                    <v-icon color="success">mdi-email-sync</v-icon>
                    <span v-show="$vuetify.breakpoint.mdAndUp" class="ml-1">{{
                        $localize('ProjectDetails.button.sendStatusUpdate')
                    }}</span>
                </v-btn>

                <v-btn
                    v-if="$session.roles.includes('planning_manage')"
                    @click="createAppointmentForOrder"
                    class="mr-3"
                >
                    <v-icon color="success">mdi-calendar-plus</v-icon>
                    <span v-show="$vuetify.breakpoint.mdAndUp" class="ml-1">{{
                        $localize('ProjectDetails.button.createAppointment')
                    }}</span>
                </v-btn>

                <v-btn @click="exportPdfs = true" class="mr-3">
                    <v-icon color="light-blue lighten-2"
                        >mdi-cloud-download</v-icon
                    >
                    <span v-show="$vuetify.breakpoint.mdAndUp" class="ml-1">{{
                        $localize('ProjectDetails.buttons.download')
                    }}</span>
                </v-btn>
            </v-toolbar-items>
            <v-spacer></v-spacer>
        </v-toolbar>
        <pdf-exports
            v-if="exportPdfs"
            v-model="exportPdfs"
            :project-id="$route.params.projectId"
        />

        <change-status-wizard
            v-if="changeStatus"
            v-model="changeStatus"
            :project-id="$route.params.projectId"
        />
        <send-order-confirmation
            v-if="sendOrderConfirmation"
            v-model="sendOrderConfirmation"
            :project-id="$route.params.projectId"
        />
        <send-status-update
            v-if="sendStatusUpdate"
            v-model="sendStatusUpdate"
            :project-id="$route.params.projectId"
        />
    </div>
</template>

<script>
import PdfExports from '../dialogs/PdfExports'
import ChangeStatusWizard from '../dialogs/ChangeStatusWizard'
import SendOrderConfirmation from '../dialogs/SendOrderConfirmation'
import SendStatusUpdate from '../dialogs/SendStatusUpdate'

import { isGuid } from '@/services/validation'

export default {
    components: {
        ChangeStatusWizard,
        SendOrderConfirmation,
        SendStatusUpdate,
        PdfExports,
    },
    props: {
        projectId: {
            validator: isGuid,
            required: true,
        },
        project: Object,
    },

    data() {
        return {
            exportPdfs: false,
            changeStatus: false,
            sendOrderConfirmation: false,
            sendStatusUpdate: false,
            createAppointment: false,
        }
    },
    methods: {
        createAppointmentForOrder() {
            this.$router.push({
                name: 'calendar',
                query: { projectId: this.project.projectId },
            })
        },
    },
}
</script>
