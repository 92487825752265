<template>
	<dialog-form
		:value="value"
		@input="$emit('input', $event)"
		:title="$localize('EnterProjectInformation.title')"
		:allow-continue="false"
		@commit="save"
		:loading="loading"
	>
		<v-text-field
			:label="$localize('Project.project')"
			v-model="enterProjectInformation.project"
			prepend-icon="mdi-rotate-3d-variant"
			:rules="[validation.required, validation.maxLength(100)]"
		/>

		<select-contact
			:relationId="project.relationId"
			v-model="enterProjectInformation.contactId"
		/>
		<select-user
			v-model="enterProjectInformation.userId"
			:label="$localize('Project.handler')"
		/>
		<select-delivery-method
			v-model="enterProjectInformation.deliveryMethod"
			:required="false"
		/>
		<select-address
			:label="$localize('Project.deliveryMontageAddress')"
			v-model="enterProjectInformation.deliveryAddressId"
			:relation-id="project.relationId"
			:required="isDeliveryAddressRequired"
		/>
		<select-contact
			:relation-id="project.relationId"
			v-model="enterProjectInformation.deliveryContactId"
			:label="$localize('Project.deliveryMontageContact')"
			:required="false"
		/>
		<date-select
			:label="$localize('Project.productionDate')"
			v-model="enterProjectInformation.productionDate"
			:clearable="true"
		/>
		<date-select
			:label="$localize('Project.deadline')"
			v-model="enterProjectInformation.deadline"
			:clearable="true"
		/>
		<v-textarea
			v-model="enterProjectInformation.productionInfo"
			:label="$localize('ProjectNotes.title.productionInfo')"
			prepend-icon="mdi-text-subject"
		/>
		<v-textarea
			v-model="enterProjectInformation.workInfo"
			:label="$localize('ProjectNotes.title.workInfo')"
			prepend-icon="mdi-text-subject"
		/>
		<select-address
			:relation-id="project.relationId"
			v-model="enterProjectInformation.invoiceAddressId"
			:label="$localize('Project.invoiceAddress')"
			:clearable="true"
			:required="false"
		/>
	</dialog-form>
</template>

<script>
import DialogForm from '@/shared/components/DialogForm'

import sendCommand from '@/services/sendCommand'
import { isGuid } from '@/services/validation'
import { getProjectById } from '@/services/api'
import SelectContact from '@/main/components/forms/SelectContact'
import SelectUser from '@/main/components/forms/SelectUser'
import { required, maxLength } from '@/services/validation'
import SelectDeliveryMethod from '@/main/components/forms/SelectDeliveryMethod'
import DateSelect from '@/shared/components/DateSelect'
import SelectAddress from '@/main/components/forms/SelectAddress'

export default {
	components: {
		SelectContact,
		SelectDeliveryMethod,
		SelectUser,
		DateSelect,
		DialogForm,
		SelectAddress,
	},
	props: {
		value: {
			type: Boolean,
			default: false,
		},
		projectId: {
			validator: isGuid,
			required: true,
		},
	},
	computed: {
		isDeliveryAddressRequired() {
			return false
		},
	},
	data() {
		return {
			loading: false,
			project: {},
			enterProjectInformation: {},
			validation: { required, maxLength },
		}
	},
	async created() {
		this.loading = true
		const { result, ok } = await getProjectById.call({
			args: { projectId: this.projectId },
		})
		if (ok) {
			this.project = result
			this.enterProjectInformation = {
				projectId: this.project.projectId,
				contactId: this.project.contactId,
				project: this.project.project,
				productionDate: this.project.productionDate,
				deadline: this.project.deadline,
				deliveryMethod: this.project.deliveryMethod,
				deliveryAddressId: this.project.deliveryAddressId,
				deliveryContactId: this.project.deliveryContactId,
				userId: this.project.userId,
				productionInfo: this.project.productionInfo,
				workInfo: this.project.workInfo,
				invoiceAddressId: this.project.invoice.addressId,
			}
		}

		this.loading = false
	},
	methods: {
		async save(callback) {
			const result = await sendCommand(
				'EnterProjectInformation',
				this.enterProjectInformation
			)
			if (result.success) {
				this.$emit('saved')
			}
			callback({ success: result.success })
		},
	},
}
</script>
