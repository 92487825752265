<template>
	<dialog-form
		:value="value"
		@input="$emit('input', $event)"
		:title="
			$localize('BulkDeleteRelations.title', {
				count: relationIds.length,
			})
		"
		@commit="save"
		:commit-message="$localize('BulkDeleteRelations.commitMessage')"
		:allow-continue="false"
		action="delete"
	>
	</dialog-form>
</template>

<script>
import DialogForm from '@/shared/components/DialogForm'
import sendCommand from '@/services/sendCommand'
export default {
	components: { DialogForm },
	props: {
		value: {
			type: Boolean,
			default: false,
		},
		relationIds: {
			type: Array,
			default: () => [],
		},
	},
	methods: {
		async save(callback) {
			const result = await sendCommand('BulkDeleteRelations', {
				relationIds: this.relationIds,
			})
			if (result.success) {
				this.$emit('saved')
			}
			callback({ success: result.success })
		},
	},
}
</script>
