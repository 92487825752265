<template>
    <dialog-form :value="value" @input="$emit('input', $event)" :title="$localize('EnterProductInformation.title')"
        :allow-continue="false" @commit="save">
        <v-text-field :label="$localize('Product.product')" v-model="editSavedProduct.product" autofocus
            :rules="[validation.maxLength(200)]" />
        <v-text-field :label="$localize('Product.description')" v-model="editSavedProduct.description"
            :rules="[validation.maxLength(500)]" />
        <input-number :label="$localize('Product.discount')" v-model="editSavedProduct.discount"
            :rules="[validation.number, validation.numberRange(5, 2)]" />
        <input-number :label="$localize('Product.price')" v-model="editSavedProduct.price" />
        <v-textarea :label="$localize('Product.specifications')" v-model="editSavedProduct.specifications"
            :rules="[validation.maxLength(2000)]" />
        <v-switch :label="$localize('Product.offerShowPrice')" v-model="editSavedProduct.offerShowPrice" hide-details />
        <v-row>
            <v-col>
                <v-switch :label="$localize('Product.offerShowParts')" v-model="editSavedProduct.offerShowParts"
                    hide-details />
            </v-col>
            <v-col>
                <v-switch :label="$localize('Product.offerShowPartPrices')"
                    v-model="editSavedProduct.offerShowPartPrices" :disabled="!editSavedProduct.offerShowParts ||
                        !editSavedProduct.offerShowPrice
                        " hide-details />
            </v-col>
        </v-row>
        <select-vat v-model="editSavedProduct.vatPercentage" clearable />
    </dialog-form>
</template>

<script>
import DialogForm from '@/shared/components/DialogForm'
import InputNumber from '@/main/components/forms/InputNumber'
import SelectVat from '@/main/components/forms/SelectVat'

import sendCommand from '@/services/sendCommand'
import { required, number, numberRange, maxLength } from '@/services/validation'
import { isGuid } from '@/services/validation'
import { getSavedProductById } from '@/services/api'

export default {
    components: { DialogForm, InputNumber, SelectVat },
    props: {
        value: {
            type: Boolean,
            default: false,
        },
        savedProductId: {
            validator: isGuid,
        },
    },

    data() {
        return {
            editSavedProduct: {
                offerShowParts: false,
                offerShowPartPrices: false,
                offerShowPrice: true,
            },
            validation: { required, number, numberRange, maxLength },
        }
    },
    watch: {
        'editSavedProduct.offerShowParts'() {
            if (!this.editSavedProduct.offerShowParts) {
                this.editSavedProduct.offerShowPartPrices = false
            }
        },
        'editSavedProduct.offerShowPrice'() {
            if (!this.editSavedProduct.offerShowPrice) {
                this.editSavedProduct.offerShowPartPrices = false
            }
        },
        savedProductId: {
            immediate: true,
            async handler() {
                if (this.savedProductId) {
                    const { result, ok } = await getSavedProductById.call({
                        args: { productId: this.savedProductId },
                    })

                    if (ok) {
                        const product = result
                        this.editSavedProduct = {
                            savedProductId: product.savedProductId,
                            product: product.product,
                            description: product.description,
                            discount: product.discount,
                            price: product.price,
                            specifications: product.specifications,
                            offerShowParts: product.offerShowParts,
                            offerShowPartPrices: product.offerShowPartPrices,
                            offerShowPrice: product.offerShowPrice,
                            vatPercentage: product.vatPercentage,
                        }
                    }
                }
            },
        },
    },
    methods: {
        async save(callback) {
            const result = await sendCommand(
                'EditSavedProduct',
                this.editSavedProduct
            )
            if (result.success) {
                this.$emit('saved')
            }
            callback({ success: result.success })
        },
    },
}
</script>
