export function getPhaseIcon(phase) {
    phase = phase.toLowerCase()
	if (phase === 'request') {
		return 'mdi-file-document-outline'
	}
	if (phase === 'offer') {
		return 'mdi-hammer-wrench'
	}
	if (phase === 'order') {
		return 'mdi-handshake'
	}
	if (phase === 'invoice') {
		return 'mdi-cash-register'
	}
	if (phase === 'archive') {
		return 'mdi-archive'
	}
	return null
}
