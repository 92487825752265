// inspired by: https://jessarcher.com/blog/closing-modals-with-the-back-button-in-a-vue-spa/
// inspired by: https://gist.github.com/tamirvs/d1a584f3fc9c494cf75d3ca76c54fb1b

let current = null; // { previous, guard, unregisterGuard }
export default function (isOpen, close) {
  return {
    created() {

      const guard = (to, from, next) => {
        if (isOpen(this)) {
          close(this);
          next(false);
          return;
        }
        next();
      }

      const destroyed = () => {
        current.unregisterGuard()
        current = current.previous;
        if (current !== null) {
          // reregister previous guard
          current.unregisterGuard = this.$router.beforeEach(current.guard);
        }
      }

      if (current !== null) {
        // unregister previous guard
        current.unregisterGuard();
      }

      current = {
        previous: current,
        guard: guard,
        unregisterGuard: null
      };


      current.unregisterGuard = this.$router.beforeEach(current.guard);

      this.$once('hook:destroyed', destroyed);
    }
  }
}
