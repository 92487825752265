<template>
	<dialog-form
		:value="value"
		@input="$emit('input', $event)"
		:title="
			$localize('BulkSelectArticleCalculations.title', {
				count: articleIds.length,
			})
		"
		@commit="save"
		:commit-message="
			$localize('BulkSelectArticleCalculations.commitMessage')
		"
		:allow-continue="false"
		max-width="1000"
	>
		<v-skeleton-loader type="card" :loading="loading">
			<v-simple-table dense>
				<template v-slot:default>
					<thead>
						<tr>
							<th>{{ $localize('Calculation.name') }}</th>
							<th colspan="4">
								{{
									$localize(
										'BulkChangeArticleCalculation.articleCalculations'
									)
								}}
							</th>
						</tr>
					</thead>
					<tbody>
						<tr
							v-for="calculation in calculations"
							:key="calculation.calculationId"
						>
							<td>{{ calculation.name }}</td>
							<template v-for="input in calculation.input">
								<td
									v-if="input.articleCalculationIds"
									:key="input.key"
								>
									<v-checkbox
										v-for="(
											articleCalculationId, index
										) in input.articleCalculationIds"
										:key="`${input.key}-${index}-${articleCalculationId}`"
										v-model="enabledCalculations"
										:value="articleCalculationId"
										indeterminate
										@update:indeterminate="
											IntederminateEvent(
												articleCalculationId
											)
										"
										:label="
											getCalculationFieldName(
												articleCalculationId
											)
										"
										hide-details
										dense
										class="ma-0"
									/>
								</td>
							</template>
						</tr>
					</tbody>
				</template>
			</v-simple-table>
		</v-skeleton-loader>
	</dialog-form>
</template>

<script>
import DialogForm from '@/shared/components/DialogForm'
import sendCommand from '@/services/sendCommand'
import {
	getCalculationTypes,
	getAllPublishedCalculations,
} from '@/services/api'
export default {
	components: { DialogForm },
	props: {
		value: {
			type: Boolean,
			default: false,
		},
		articleIds: {
			type: Array,
			default: () => [],
		},
	},
	data() {
		return {
			loading: false,
			calculations: [],
			calculationFields: [],
			enabledCalculations: [],
			determinedCalculationFieldIds: [],
		}
	},
	async created() {
		this.loading = true
		const { result, ok } = await getAllPublishedCalculations.call()
		if (ok) {
			this.calculations = result
			this.calculations = this.calculations.filter((calculation) =>
				calculation.input.find((input) => input.articleCalculationIds)
			)
		}
        await this.loadCalculationTypes();
		this.enabledCalculations = []
		this.loading = false
	},
	methods: {
		async loadCalculationTypes() {
			const { result, ok } = await getCalculationTypes.call()
			if (ok) {
				this.calculationFields = result
			}
		},
		async save(callback) {
			const disabledCalculations =
				this.determinedCalculationFieldIds.filter(
					(x) => this.enabledCalculations.indexOf(x) === -1
				)

			const result = await sendCommand('BulkChangeArticleCalculations', {
				articleIds: this.articleIds,
				enabledCalculations: this.enabledCalculations,
				disabledCalculations: disabledCalculations,
			})
			if (result.success) {
				this.$emit('saved')
			}
			callback({ success: result.success })
		},
		getCalculationFieldName(calculationFieldId) {
			const calculationField = this.calculationFields.find(
				(x) => x.calculationFieldId === calculationFieldId
			)
			if (calculationField) {
				return calculationField.name
			}
			return ''
		},
		IntederminateEvent(articleCalculationId) {
			this.determinedCalculationFieldIds.push(articleCalculationId)
		},
	},
}
</script>
