<template>
  <v-layout align-center justify-center>
    <v-flex style="max-width:400px;">
      <h1 class="white--text">{{ $localize('Login.subtitle.forgotPassword') }}</h1>
      <v-form
        v-if="!message"
        ref="requestPasswordRecoveryForm"
        @submit.prevent="requestPasswordRecovery"
      >
        <v-card class="pa-3">
          <v-card-subtitle
            v-if="$route.query.errorMessage"
            class="elevation-2 mb-3"
          >{{$route.query.errorMessage}}</v-card-subtitle>
          <v-card-text>
            <v-text-field
              :label="$localize('Login.field.login')"
              v-model="requestPasswordRecoveryCommand.login"
              :rules="[validation.required]"
              outlined
              hide-details
              autofocus
            />
          </v-card-text>
          <v-card-actions>
            <v-btn type="submit" class="success" :loading="processing">{{ $localize('Login.button.forgotPassword') }}</v-btn>
          </v-card-actions>
          <v-card-text>
            <v-divider />
          </v-card-text>
          <v-card-actions>
            <a @click="$router.push({name: 'login'})">{{ $localize('Login.link.backToLogin') }}</a>
          </v-card-actions>
        </v-card>
      </v-form>
      <v-card v-else class="pa-3">
        <v-card-text class="elevation-2">{{message}}</v-card-text>
        <v-card-actions></v-card-actions>
        <v-card-actions>
          <v-row>
            <v-col>
              <a @click="$router.push({name: 'login'})">{{ $localize('Login.link.backToLogin') }}</a>
            </v-col>
            <v-col>
              <a @click="message = null">{{ $localize('Login.link.resendRecoveryLink') }}</a>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import sendCommand from "@/services/sendCommand";
import { required } from "@/services/validation";
export default {
  data() {
    return {
      requestPasswordRecoveryCommand: {},
      message: null,
      validation: { required },
      processing: false
    };
  },
  methods: {
    async requestPasswordRecovery() {
      if (!this.$refs.requestPasswordRecoveryForm.validate()) return;

      this.processing = true;
      const response = await sendCommand(
        "RequestPasswordRecovery",
        this.requestPasswordRecoveryCommand
      );
      if (response.success) {
        this.message = "We hebben een herstellink verstuurd.";
      }
      this.processing = false;
    }
  }
};
</script>
<style lang="scss" scoped>
.v-card__actions {
  justify-content: center;
}
</style>
