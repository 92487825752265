<template>
	<dialog-form
		:value="value"
		@input="$emit('input', $event)"
		:title="$localize('CreateVat.title')"
		@commit="save"
		:allow-continue="false"
	>
		<input-number
			:label="$localize('Vat.amount')"
			v-model="createVat.vatPercentage"
			:rules="[validation.required, validation.numberRange(4,2)]"
		/>
		<date-select
			:label="$localize('Vat.startAt')"
			v-model="createVat.startAt"
			clearable
		/>
		<v-text-field
			:label="$localize('Vat.invoiceText')"
			v-model="createVat.invoiceText"
			clearable
			:rules="[validation.maxLength(50)]"
		/>
		<v-switch
			:label="$localize('Vat.isDefault')"
			v-model="createVat.isDefault"
		/>
	</dialog-form>
</template>

<script>
import DialogForm from '@/shared/components/DialogForm'
import InputNumber from '@/main/components/forms/InputNumber'
import DateSelect from '@/shared/components/DateSelect'
import sendCommand from '@/services/sendCommand'
import { required, maxLength, numberRange } from '@/services/validation'

export default {
	components: { DialogForm, DateSelect, InputNumber },
	props: {
		value: Boolean,
	},
	data() {
		return {
			createVat: {},
			validation: { required, maxLength, numberRange },
		}
	},

	methods: {
		async save(callback) {
			const result = await sendCommand('CreateVat', this.createVat)

			if (result.success) {
				this.$emit('saved')
			}
			callback({ success: result.success })
		},
	},
}
</script>
