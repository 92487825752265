import { ApiEndPoint } from './apiEndPoint'

// tenants/users
export const getTenants = new ApiEndPoint('admin/tenant')
export const getTenantById = new ApiEndPoint('admin/tenant/{tenantId}')
export const getTenantUsers = new ApiEndPoint('admin/tenant/{tenantId}/users')
export const getTenantUserById = new ApiEndPoint('admin/user/{userId}')
export const getAdministrators = new ApiEndPoint('admin/admins')

export const getTenantLicenses = new ApiEndPoint('admin/tenant/{tenantId}/licenses')
export const getTenantLicenseById = new ApiEndPoint('admin/tenant/{tenantId}/licenses/{licenseId}')
export const getTenantInvoices = new ApiEndPoint('admin/tenant/{tenantId}/invoices')
export const getTenantInvoiceById = new ApiEndPoint('admin/tenant/{tenantId}/invoices/{invoiceId}')

// ops
export const getMessageLog = new ApiEndPoint('admin/ops/logs')
export const getStats = new ApiEndPoint('stats')


export const getCommands = new ApiEndPoint('admin/ops/commands')
export const getExceptions = new ApiEndPoint('admin/ops/exceptions')
export const getRequestLog = new ApiEndPoint('admin/ops/requests')
export const getMessageTree = new ApiEndPoint('admin/ops/message/{messageId}')
export const getLogProperties = new ApiEndPoint('admin/ops/properties')
export const getLogPropertiesAndValues = new ApiEndPoint('admin/ops/properties-values')
