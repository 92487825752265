<template>
	<v-form ref="form">
		<v-card>
			<v-card-title>{{
				$localize('Calculation.button.addInput')
			}}</v-card-title>
			<v-card-text>
				<v-row>
					<v-col>
						<v-text-field
							:label="$localize('Calculation.inputField.key')"
							:hint="$localize('Calculation.inputField.key.hint')"
							:persistent-hint="true"
							v-model="field.key"
							:rules="[validation.required]"
							:disabled="!isNew && !field.isNew"
						/>
					</v-col>
					<v-col v-if="!isNew && !field.isNew" align-self="center" cols="auto" class="ml-1">
						<v-btn
							small
							outlined
							fab
							@click="renameVariable = true"
						>
							<v-icon>mdi-pencil</v-icon>
						</v-btn>
					</v-col>
				</v-row>
				<v-text-field
					:label="$localize('Calculation.inputField.name')"
					:hint="$localize('Calculation.inputField.name.hint')"
					:persistent-hint="true"
					v-model="field.label"
					:rules="[validation.required]"
				/>
				<v-select
					:label="$localize('Calculation.inputField.type')"
					:hint="$localize('Calculation.inputField.type.hint')"
					:persistent-hint="true"
					:items="formFieldTypes"
					v-model="field.type"
					:rules="[validation.required]"
				/>
				<select-article-calculation
					v-model="field.articleCalculationIds"
					v-if="field.type === 'article'"
				/>
				<v-text-field
					:label="$localize('Calculation.inputField.suffix')"
					:hint="$localize('Calculation.inputField.suffix.hint')"
					:persistent-hint="true"
					v-model="field.suffix"
				/>
				<v-text-field
					:label="$localize('Calculation.inputField.hint')"
					:hint="$localize('Calculation.inputField.hint.hint')"
					:persistent-hint="true"
					v-model="field.hint"
				/>
				<v-checkbox
					v-model="field.isRequired"
					:label="$localize('Calculation.inputField.isRequired')"
				/>
			</v-card-text>
			<v-card-actions>
				<v-btn @click="save">{{ $localize('form.button.ok') }}</v-btn>
			</v-card-actions>
		</v-card>
		<rename-variable
			v-model="renameVariable"
			:calculation-id="calculationId"
			:variable-key.sync="field.key"
		/>
	</v-form>
</template>
<script>
import { required } from '@/services/validation'
import { normalizeVariableName } from '@/services/code'
import { isGuid } from '@/services/validation'
import SelectArticleCalculation from '@/main/components/forms/SelectArticleCalculation'
import RenameVariable from '../dialogs/RenameVariable'
export default {
	components: { SelectArticleCalculation, RenameVariable },
	props: {
		value: {
			type: Object,
			default() {
				return {}
			},
		},
		calculationId: { validator: isGuid, required: true },
		fieldCount: Number,
	},
	data() {
		return {
			formFieldTypes: ['text', 'number', 'article'],
			field: {
				key: this.value.key,
				type: this.value.type,
				label: this.value.label,
				suffix: this.value.suffix,
				hint: this.value.hint,
				articleCalculationIds: this.value.articleCalculationIds,
				isRequired: this.value.isRequired,
                isNew: this.value.isNew
			},
			isNew: !this.value.key,
			validation: { required },

			renameVariable: false,
		}
	},
	watch: {
		value() {
			this.field = {
				key: this.value.key,
				type: this.value.type,
				label: this.value.label,
				suffix: this.value.suffix,
				hint: this.value.hint,
				articleCalculationIds: this.value.articleCalculationIds,
				isRequired: this.value.isRequired,
                isNew: this.value.isNew
			}
		},
	},
	methods: {
		save() {
			if (!this.field.key) {
				this.field.key =
					'input_' +
					this.fieldCount +
					'_' +
					normalizeVariableName(this.field.label)
			}
			if (!this.$refs.form.validate()) {
				return
			}
			this.$emit('input', this.field)
			this.$emit('saved')
			this.field = {}
		},
	},
}
</script>
