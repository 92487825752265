<template>
    <v-data-table
        :items="users"
        :headers="headers"
        @click:row="navUser($event)"
    >
        <template v-slot:[`item.userId`]="{ item }">
            <div class="id-col">
                <v-icon x-small @click="copyId(item.userId)"
                    >mdi-content-copy</v-icon
                >
                {{ item.userId }}
            </div>
        </template>
    </v-data-table>
</template>

<script>
import { isGuid } from '@/services/validation'

import { getTenantUsers } from '@/services/api'

export default {
    props: {
        tenantId: {
            validator: isGuid,
            required: true,
        },
    },
    data: function () {
        return {
            headers: [
                { text: 'Id', value: 'userId', width:50 },
                { text: 'Name', value: 'login' },
                { text: 'Email', value: 'email' },
            ],
            users: [],
        }
    },
    watch: {
        tenantId: {
            immediate: true,
            handler: async function () {
                if (this.tenantId) {
                    this.users = await getTenantUsers
                        .create({ tenantId: this.$props.tenantId })
                        .load()
                } else {
                    this.users = []
                }
            },
        },
    },
    methods: {
        navUser(user) {
            this.$router.push({
                name: 'admin.userDetails',
                params: { userId: user.userId },
            })
        },
    },
}
</script>
