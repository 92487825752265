<template>
	<v-container fluid class="pa-0">
		<v-row no-gutters>
			<v-col>
				<v-toolbar flat class="primary">
					<v-toolbar-title class="text-uppercase mr-3">
						<span class="font-weight-light white--text text-h3">{{
							dashboardName
						}}</span>
					</v-toolbar-title>
				</v-toolbar>
			</v-col>
		</v-row>
		<v-row no-gutters>
			<v-col>
				<v-data-table
					:headers="headers"
					:items="projects"
					:loading="loading"
					@click:row="navProject($event)"
					:options.sync="options"
					:server-items-length="totalItemCount"
					:footer-props="{
						'items-per-page-options': [20, 30, 40, 50],
					}"
					@contextmenu:row="openContextMenu"
					dense
				>
					<template v-slot:[`item.projectNumber`]="{ item }">
						<v-icon>{{ getProjectIcon(item) }}</v-icon>
						{{ item.projectNumber }}</template
					>
					<template v-slot:[`item.created`]="{ item }">{{
						$format.shortDate(item.created)
					}}</template>
					<template v-slot:[`item.progress.created`]="{ item }">{{
						$format.shortDate(item.progress.created)
					}}</template>
					<template v-slot:[`item.createdByName`]="{ item }">
						<router-link
							:to="{
								name: 'userDetails',
								params: { userId: item.createdBy },
							}"
							@click.native.stop
							>{{ item.createdByName }}</router-link
						>
					</template>
					<template v-slot:[`item.productionDate`]="{ item }">
						<span
							v-if="item.productionDate"
							:class="getProjectColorClasses(item)"
							>{{ $format.shortDate(item.productionDate) }}</span
						>
					</template>
					<template v-slot:[`item.deadline`]="{ item }">
						<span
							v-if="item.deadline"
							:class="getProjectColorClasses(item)"
							>{{ $format.shortDate(item.deadline) }}</span
						>
					</template>
					<template v-slot:[`item.relationName`]="{ item }">
						<router-link
							:to="{
								name: 'relationProjectOverview',
								params: { relationId: item.relationId },
							}"
							@click.native.stop
							>{{ item.relationName }}</router-link
						>
					</template>
					<template v-slot:[`item.userName`]="{ item }">
						<router-link
							:to="{
								name: 'userDetails',
								params: { userId: item.userId },
							}"
							@click.native.stop
							>{{ item.userName }}</router-link
						>
					</template>
					<template v-slot:[`item.projectStatusNote`]="{ item }">
						<div class="note">
							{{ item.projectStatusNote }}
						</div>
					</template>
					<template v-slot:[`item.invoiceDate`]="{ item }">
						<span v-if="item.invoiceDate">{{
							$format.shortDate(item.invoiceDate)
						}}</span>
					</template>
					<template v-slot:[`item.invoiceNumber`]="{ item }">
						<template v-if="item.invoiceNumber">
							{{
								$format.year(item.invoiceDate) +
								'.' +
								item.invoiceNumber
							}}
						</template>
					</template>
					<template v-slot:footer="{}">
						<v-divider />
						<v-row no-gutters class="mx-3">
							<v-col cols="auto">
								<v-checkbox
									:label="$localize('form.label.autoRefresh')"
									v-model="live"
									@change="setLive()"
									class="pt-0 my-1"
									hide-details
								/>
							</v-col>
						</v-row>
					</template>
				</v-data-table>
			</v-col>
		</v-row>
		<v-menu
			v-model="showContextMenu"
			v-if="contextMenuItem"
			absolute
			:position-x="contextMenuX"
			:position-y="contextMenuY"
			:transition="false"
		>
			<v-list>
				<v-list-item
					v-for="item in contextMenu"
					:key="item.text"
					:disabled="!item.action"
					v-show="item.visible()"
					@click="item.action()"
				>
					<v-list-item-icon>
						<v-icon
							:color="item.iconColor"
							v-if="typeof item.icon === 'function'"
							>{{ item.icon() }}</v-icon
						>
						<v-icon :color="item.iconColor" v-else>{{
							item.icon
						}}</v-icon>
					</v-list-item-icon>
					<v-list-item-title>{{ item.text }}</v-list-item-title>
				</v-list-item>
			</v-list>
		</v-menu>
		<enter-project-information
			v-if="enterProjectInformation"
			v-model="enterProjectInformation"
			:project-id="contextMenuItem.projectId"
		/>
		<pdf-exports
			v-if="exportPdfs"
			v-model="exportPdfs"
			:project-id="contextMenuItem.projectId"
		/>
		<change-status-wizard
			v-if="changeStatus"
			v-model="changeStatus"
			:project-id="contextMenuItem.projectId"
		/>
	</v-container>
</template>

<script>
import PdfExports from './dialogs/PdfExports'
import ChangeStatusWizard from './dialogs/ChangeStatusWizard'
import EnterProjectInformation from './dialogs/EnterProjectInformation'

import { getProjectInDashboard, getDashboardById } from '@/services/api'
import { isBefore, parseISO } from 'date-fns'
import { isGuid } from '@/services/validation'
import { getPhaseIcon } from '@/services/icons'
import PubSub from 'pubsub-js'

export default {
	components: {
		PdfExports,
		ChangeStatusWizard,
		EnterProjectInformation,
	},
	props: {
		phase: String,
		dashboardId: {
			validator: isGuid,
			required: true,
		},
	},

	data() {
		return {
			availableHeaders: [
				{
					text: this.$localize('Dashboard.column.projectNumber'),
					value: 'projectNumber',
					width: 90,
				},
				{
					text: this.$localize('Dashboard.column.created'),
					value: 'created',
					width: 100,
				},
				{
					text: this.$localize('Dashboard.column.createdByName'),
					value: 'createdByName',
				},
				{
					text: this.$localize('Dashboard.column.relationName'),
					value: 'relationName',
				},
				{
					text: this.$localize('Dashboard.column.project'),
					value: 'project',
				},
				{
					text: this.$localize('Dashboard.column.projectStatusName'),
					value: 'projectStatusName',
				},
				{
					text: this.$localize('Dashboard.column.userName'),
					value: 'userName',
				},
				{
					text: this.$localize('Dashboard.column.productionDate'),
					value: 'productionDate',
				},
				{
					text: this.$localize('Dashboard.column.deadline'),
					value: 'deadline',
				},
				{
					text: this.$localize('Dashboard.column.projectStatusNote'),
					value: 'projectStatusNote',
					width: 300,
					sortable: false,
				},
				{
					text: this.$localize('Dashboard.column.invoiceDate'),
					value: 'invoiceDate',
				},
				{
					text: this.$localize('Dashboard.column.invoiceNumber'),
					value: 'invoiceNumber',
					sortable: false,
				},
			],
			projects: [],
			totalProjectCount: 0,
			search: '',
			options: JSON.parse(
				localStorage.getItem('ProjectDashboard:options')
			) || {
				itemsPerPage: 20,
			},
			loading: false,

			showContextMenu: false,
			contextMenuItem: null,
			contextMenuX: null,
			contextMenuY: null,
			contextMenu: [
				{
					icon: 'mdi-sync',
					iconColor: 'success',
					text: this.$localize('ProjectDetails.button.changeStatus'),
					visible: () => true,
					action: () => (this.changeStatus = true),
				},
				{
					icon: 'mdi-pencil',
					text: this.$localize('ProjectDetails.button.edit'),
					visible: () => true,
					action: () => (this.enterProjectInformation = true),
				},
				{
					icon: 'mdi-cloud-download',
					iconColor: 'light-blue lighten-2',
					text: this.$localize('ProjectDetails.button.print'),
					visible: () => true,
					action: () => (this.exportPdfs = true),
				},
			],
			enterProjectInformation: false,
			exportPdfs: false,
			toOffer: false,
			toOrder: false,
			toInvoice: false,
			changeStatus: false,

			unsubsribe: null,

			live: true,
			liveInterval: null,

			dashboardName: null,
			dashboardColumns: [],
		}
	},
	computed: {
		totalItemCount() {
			if (this.live) {
				return -1
			}
			return this.totalProjectCount
		},
		currentPage() {
			return this.$route.query.page || 1
		},
		headers() {
			return this.dashboardColumns
				.map((column) => {
					// find header
					var headers = this.availableHeaders.filter(
						(x) => x.value === column
					)
					if (headers.length === 1) {
						return headers[0]
					}
					console.warning('unknown column: ' + column)
					return null
				})
				.map((x) => {
					return {
						...x,
						class: 'text-truncate',
						cellClass: 'text-truncate',
					}
				})
		},
	},
	created() {
		this.options.page = this.currentPage
		this.unsubscribe = PubSub.subscribe('search', (message, data) => {
			if (data.mode === 'page') {
				this.search = data.keyword
			} else {
				this.search = ''
			}
			this.refresh()
		})
	},
	destroyed() {
		PubSub.unsubscribe(this.unsubscribe)
		if (this.liveInterval) {
			clearInterval(this.liveInterval)
		}
	},
	watch: {
		$route() {
			this.refresh()
		},
		options() {
			// save current settings
			localStorage.setItem(
				'ProjectDashboard:options',
				JSON.stringify(this.options)
			)

			// Don't add querystring in relations page
			if (this.options.page != this.currentPage && !this.relationId) {
				// if diffrent page, go to that page
				this.$router.push({
					query: {
						page: this.options.page,
					},
				})
			} else {
				this.refresh()
			}
		},
	},
	methods: {
		setLive() {
			if (this.liveInterval) {
				clearInterval(this.liveInterval)
			}

			if (this.live) {
				this.liveInterval = setInterval(async () => {
					await this.refresh(true)
				}, 5000)
			}
		},
		getProjectColorClasses(project) {
			let today = new Date()
			today.setHours(23, 59, 59, 59)
			if (project.deadline) {
				const deadline = parseISO(project.deadline)
				if (isBefore(deadline, today)) {
					return ['error--text']
				}
			} else if (project.productionDate) {
				const productionDate = parseISO(project.productionDate)
				if (isBefore(productionDate, today)) {
					return ['success--text']
				}
			}
			return []
		},
		getProjectIcon(project) {
			return getPhaseIcon(project.phase)
		},
		async refresh(silent) {
			if (!silent) this.loading = true
			const q = {
				...this.options,
				search: this.search,
			}
            await this.loadDashboard()
			const { result, ok } = await getProjectInDashboard.call({
				args: { dashboardId: this.dashboardId },
				q,
			})
			if (ok) {
				this.projects = result.items
				this.totalProjectCount = result.totalItemCount
			}

			if (!silent) this.loading = false
		},
		async loadDashboard() {
			const { result, ok } = await getDashboardById.call({
				args: { dashboardId: this.dashboardId },
			})

			if (ok) {
				this.dashboardColumns = result.columns || []
				this.dashboardName = result.name
			}
		},

		navProject(project) {
			if (this.$route.params.phase) {
				// Pre-emptively send active project phase for a smooth transition
				PubSub.publishSync('ActiveProjectPhase', {
					phase: this.$route.params.phase,
				})
			}
			this.$router.push({
				name: 'projectDetails',
				params: {
					projectId: project.projectId,
				},
			})
		},
		openContextMenu(evt, data) {
			evt.preventDefault()
			this.contextMenuX = evt.clientX
			this.contextMenuY = evt.clientY
			this.contextMenuItem = data.item
			this.showContextMenu = true
		},
	},
}
</script>
<style lang="scss" scoped>
.note {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	max-width: 300px;
}
</style>
