<template>
	<v-container>
		<v-row no-gutters align="center" class="font-weight-bold">
			<v-col>{{ $localize('Product.product') }}</v-col>
			<v-col>{{ $localize('Product.description') }}</v-col>
			<v-col
				cols="auto"
				align="right"
				class="pr-3"
				style="width: 100px"
				>{{ $localize('Product.price') }}</v-col
			>
			<v-col cols="auto" :style="'width:' + buttonBarWidth"></v-col>
		</v-row>
		<v-row no-gutters align="stretch" class="mt-3">
			<v-col class="font-weight-bold">{{ product.product }}</v-col>
			<v-col>{{ product.description }}</v-col>
			<v-col
				align="right"
				cols="auto"
				style="width: 100px"
				:class="{ error: !!product.price }"
				class="grey lighten-2 pr-3"
				>{{ $format.currency(price) }}</v-col
			>
			<v-col cols="auto" :style="'width:' + buttonBarWidth">
				<v-toolbar flat style="background: none" height="36">
					<v-spacer></v-spacer>
					<v-toolbar-items>
						<v-menu>
							<template v-slot:activator="{ on }">
								<v-btn v-on="on" x-small depressed>
									<v-icon small>mdi-plus</v-icon>
								</v-btn>
							</template>
							<v-list>
								<v-list-item
									v-for="calculation in calculations"
									:key="calculation.calculationId"
									@click="addPart(calculation)"
								>
									<v-list-item-title>{{
										calculation.name
									}}</v-list-item-title>
								</v-list-item>
							</v-list>
						</v-menu>
					</v-toolbar-items>
					<collapsable-buttons :items="productButtons" />
				</v-toolbar>
			</v-col>
		</v-row>
		<v-row
			v-if="product.startingCost > 0"
			:key="product.productId + '-startingcost'"
			no-gutters
			align="stretch"
		>
			<v-col>{{ this.$localize('ProductsDetails.initialCosts') }}</v-col>
			<v-col></v-col>
			<v-col
				align="right"
				cols="auto"
				style="width: 100px"
				class="grey lighten-2 pr-3"
				>{{ $format.currency(product.startingCost) }}</v-col
			>
			<v-col cols="auto" :style="'width:' + buttonBarWidth"></v-col>
		</v-row>
		<v-row
			v-for="part in product.parts"
			:key="part.savedPartId"
			align="stretch"
			no-gutters
		>
			<v-col>{{ part.specifications }}</v-col>
			<v-col>{{ part.description }}</v-col>
			<v-col
				cols="auto"
				align="right"
				style="width: 100px"
				class="grey lighten-3 pr-3"
				>{{ $format.currency(part.price) }}</v-col
			>
			<v-col cols="auto" :style="'width:' + buttonBarWidth">
				<v-toolbar flat style="background: none" height="36">
					<v-spacer></v-spacer>
					<collapsable-buttons :items="partButtons" :value="part" />
				</v-toolbar>
			</v-col>
		</v-row>

		<delete-product
			v-if="deletingProduct"
			v-model="deletingProduct"
			:saved-product-id="$route.params.savedProductId"
			@saved="navSavedProductsOverview"
		/>

		<edit-product
			v-if="editProduct"
			v-model="editProduct"
			:saved-product-id="$route.params.savedProductId"
			@saved="refresh()"
		/>

		<delete-product-part
			v-if="deletingPart"
			v-model="deletingPart"
			:saved-product-id="$route.params.savedProductId"
			:saved-part-id="deletePartId"
			@saved="refresh()"
		/>
		<calculate-product-part
			v-if="calculatingPart"
			v-model="calculatingPart"
			:part="calculatePartObj"
			:saved-product-id="$route.params.savedProductId"
			@saved="refresh()"
		/>
	</v-container>
</template>

<script>
import DeleteProductPart from './dialogs/DeleteProductPart'
import CalculateProductPart from './dialogs/CalculateProductPart'
import DeleteProduct from './dialogs/DeleteProduct'
import EditProduct from './dialogs/EditProduct'

import {
	getSavedProductById,
	getAllPublishedCalculations,
} from '@/services/api'
import CollapsableButtons from '@/shared/components/CollapsableButtons'
import sendCommand from '@/services/sendCommand'

export default {
	components: {
		CollapsableButtons,
		DeleteProductPart,
		CalculateProductPart,
		DeleteProduct,
		EditProduct,
	},
	data() {
		return {
			product: {
				parts: [],
			},
			calculations: [],

			editProduct: false,
			deletingProduct: false,

			deletingPart: false,
			deletePartId: null,

			calculatingPart: false,
			calculatePartObj: null,

			productButtons: [
				{
					icon: 'mdi-pencil',
					text: this.$localize('ProductDetails.product.button.edit'),
					action: () => this.enterProductInformation(),
				},
				{
					icon: 'mdi-arrow-up',
					text: this.$localize(
						'ProductDetails.product.button.moveUp'
					),
					disabled: true,
				},
				{
					icon: 'mdi-arrow-down',
					text: this.$localize(
						'ProductDetails.product.button.moveDown'
					),
					disabled: true,
				},
				{
					icon: 'mdi-close',
					text: this.$localize(
						'ProductDetails.product.button.delete'
					),
					action: () => this.deleteProduct(),
					textColor: 'error',
				},
			],
			partButtons: [
				{
					icon: 'mdi-pencil',
					text: this.$localize('ProductDetails.part.button.edit'),
					action: (part) => this.calculatePart(part),
				},
				{
					icon: 'mdi-content-copy',
					text: this.$localize('ProductDetails.part.button.copy'),
					action: (part) => this.duplicatePart(part),
				},
				{
					icon: 'mdi-arrow-up',
					text: this.$localize('ProductDetails.part.button.moveUp'),
					action: (part) => this.movePartUp(part),
					disabled: (part) => part.partNumber === 1,
				},
				{
					icon: 'mdi-arrow-down',
					text: this.$localize('ProductDetails.part.button.moveDown'),
					action: (part) => this.movePartDown(part),
					disabled: (part) =>
						part.partNumber === this.product.parts.length,
				},
				{
					icon: 'mdi-close',
					text: this.$localize('ProductDetails.part.button.delete'),
					action: (part) => this.deletePart(part),
					textColor: 'error',
				},
			],
		}
	},
	computed: {
		buttonBarWidth() {
			if (this.$vuetify.breakpoint.mdAndUp) {
				return '290px'
			}
			return '150px'
		},
		price() {
			let price
			if (this.product.price) {
				price = this.product.price
			} else {
				price = this.product.calculatedPrice + this.product.startingCost
				price = price * (1 - this.product.discount / 100)
			}
			return price
		},
	},
	async created() {
		await this.refresh()

		const { result, ok } = await getAllPublishedCalculations.call()
		if (ok) {
			this.calculations = result
		}
	},
	methods: {
		async refresh() {
			const { result, ok } = await getSavedProductById.call({
				args: { productId: this.$route.params.savedProductId },
			})
			if (ok) {
				this.product = result
			}
		},
		deleteProduct() {
			this.deletingProduct = true
		},
		enterProductInformation() {
			this.editProduct = true
		},
		async addPart(calculation) {
			const result = await sendCommand('AddPartToSavedProduct', {
				savedProductId: this.$route.params.savedProductId,
				calculationId: calculation.calculationId,
			})
			if (result.success) {
				this.refresh()
			}
		},
		calculatePart(part) {
			this.calculatePartObj = part
			this.calculatingPart = true
		},
		async duplicatePart(part) {
			const result = await sendCommand('DuplicateSavedPart', {
				savedPartIdToDuplicate: part.savedPartId,
				savedProductId: this.$route.params.savedProductId,
			})
			if (result.success) {
				this.refresh()
			}
		},
		async movePartUp(part) {
			const result = await sendCommand('MoveSavedPartUp', {
				savedPartId: part.savedPartId,
				savedProductId: this.$route.params.savedProductId,
			})
			if (result.success) {
				this.refresh()
			}
		},
		async movePartDown(part) {
			const result = await sendCommand('MoveSavedPartDown', {
				savedPartId: part.savedPartId,
				savedProductId: this.$route.params.savedProductId,
			})
			if (result.success) {
				this.refresh()
			}
		},
		async deletePart(part) {
			this.deletingPart = true
			this.deletePartId = part.savedPartId
		},
		async navSavedProductsOverview() {
			await this.$nextTick()
			this.$router.push({ name: 'savedProductsOverview' })
		},
	},
}
</script>
<style lang="scss" scoped>
.v-toolbar__content {
	padding-left: 0;
}

.col {
	display: flex;
	flex-direction: column;
	justify-content: center;

	span {
		display: inline-block;
		vertical-align: middle;
	}
}
</style>
