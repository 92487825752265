<template>
  <v-dialog :value="value" @input="$emit('input', $event)" max-width="600px">
    <v-card>
      <v-card-title>Create User</v-card-title>
      <v-form @submit.prevent="save()" class="pa-3">
        <v-text-field label="FullName" v-model="createTenantUserCommand.fullName" :rules="[validation.maxLength(100)]"/>
        <input-email label="Email" v-model="createTenantUserCommand.email" />

        <v-btn @click="$emit('input', false)" class="mt-3 mr-3">{{ $localize('form.button.cancel') }}</v-btn>
        <v-btn type="submit" class="success mt-3 mr-3">{{ $localize('form.button.save') }}</v-btn>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { isGuid } from "@/services/validation"
import sendCommand from "@/services/sendCommand";
import {maxLength} from "@/services/validation";
export default {
  props: {
    tenantId: {
      validator: isGuid,
      required: true
    },
    value: {
      type: Boolean,
      default: false
    }
  },

  data: function() {
    return {
      createTenantUserCommand: {},
      validation: {maxLength}
    };
  },

  methods: {
    save: async function() {
      this.createTenantUserCommand.tenantId = this.$props.tenantId;
      const result = await sendCommand(
        "AdminCreateTenantUser",
        this.createTenantUserCommand
      );
      if (result.success) {
        this.createTenantUserCommand = {};
        this.$emit("input", false);
        this.$emit("saved");
      }
    }
  }
};
</script>
