<template>
	<v-text-field
		:label="label"
		:value="input"
		@input="emit"
		clearable
		v-bind="$attrs"
		:rules="rules"
		:suffix="suffix"
	/>
</template>
<script>
import { required, number, toNumber } from '@/services/validation'
export default {
	props: {
		label: String,
		value: {
			type: [String, Number],
			default: null,
		},
		required: Boolean,
		suffix: String,
	},
	data() {
		return {
			input: this.toText(this.value),
		}
	},
	computed: {
		rules() {
			if (this.$attrs.rules !== undefined) {
				return this.$attrs.rules
			}
			if (this.required) {
				return [required, number]
			} else {
				return [number]
			}
		},
	},
	watch: {
		value(newValue) {
			if (this.input === null) {
				this.input = this.toText(newValue)
			}
		},
	},
	methods: {
		toText(number) {
			if (
				typeof number === 'number' ||
				(typeof number === 'string' && number !== '')
			) {
				return number.toString().replace('.', ',')
			}
			return null
		},
		emit(input) {
			const value = toNumber(input)
			this.$emit('input', value)
		},
	},
}
</script>
