<template>
	<dialog-form
		:value="value"
		@input="$emit('input', $event)"
		:title="$localize('EnterArticleAttribute.title')"
		@commit="save"
		:allow-continue="false"
	>
		<input-number
			v-model="attributeValue"
			:label="attributeType.displayName"
		/>
	</dialog-form>
</template>
<script>
import InputNumber from '@/main/components/forms/InputNumber'
import DialogForm from '@/shared/components/DialogForm'
import { isGuid } from '@/services/validation'
import sendCommand from '@/services/sendCommand'
import { getArticleById, getAttributeTypes } from '@/services/api'

export default {
	components: { DialogForm, InputNumber },
	props: {
		value: {
			type: Boolean,
			default: false,
		},
		articleId: {
			validator: isGuid,
		},
		attributeAlias: {
			type: String,
		},
	},
	data() {
		return {
			attributeValue: '',
			attributeType: {},
		}
	},
	async created() {
		await this.loadAttributeTypes()
		const { result, ok } = await getArticleById.call({
			args: { articleId: this.articleId },
		})
		if (ok) {
			this.article = result
            this.attributeValue = this.article.attributes[this.attributeAlias]
		}

	},

	methods: {
		async loadAttributeTypes() {
			const { result, ok } = await getAttributeTypes.call()
			if (ok) {
				const attributeTypes = result
				this.attributeType = attributeTypes.filter(
					(x) => x.alias === this.attributeAlias
				)[0]
			}
		},
		addScale() {
			this.prices.push({})
		},
		removeScale(index) {
			this.prices.splice(index, 1)
		},
		async save(callback) {
			const result = await sendCommand('EnterArticleAttribute', {
				articleId: this.articleId,
				attributeAlias: this.attributeAlias,
				value: this.attributeValue,
			})
			if (result.success) {
				this.$emit('saved')
			}
			callback({ success: result.success })
		},
	},
}
</script>
