<template>
	<dialog-form
		:value="value"
		@input="$emit('input', $event)"
		:title="$localize('SendOffer.title')"
		@commit="save"
		:allow-continue="false"
		:loading="loading"
		loading-skeleton="list-item-avatar-two-line@2, list-item-two-line, image"
		:action-text="$localize('SendOffer.button.sendOffer')"
		action-icon="mdi-email-send"
	>
		<send-email-form
			v-model="data.email"
			:relation-id="data.relationId"
			:contact-id="data.contactId"
			@nav="nav"
		/>
	</dialog-form>
</template>

<script>
import DialogForm from '@/shared/components/DialogForm'

import sendCommand from '@/services/sendCommand'
import { isGuid } from '@/services/validation'
import { prepareOfferEmail } from '@/services/api'
import SendEmailForm from '../components/steps/SendEmailForm'
import Vue from 'vue'

export default {
	components: { DialogForm, SendEmailForm },
	props: {
		value: {
			type: Boolean,
			default: false,
		},
		projectId: {
			validator: isGuid,
			required: true,
		},
		offerId: {
			validator: isGuid,
			required: false,
		},
	},

	data() {
		return {
			loading: false,
			data: {},
		}
	},
	async created() {
		this.loading = true
		const { result, ok } = await prepareOfferEmail.call({
			args: { projectId: this.projectId },
		})
		if (ok) {
			this.data = result
		}
		this.loading = false
	},
	methods: {
		async save(callback) {
			const result = await sendCommand('SendOffer', {
				projectId: this.projectId,
				offerId: this.offerId,
				email: this.data.email,
			})

			if (result.success) {
				this.$emit('saved')
			}

			callback({ success: result.success })
		},
		nav(to) {
			this.$emit('input', false)
			Vue.nextTick(() => {
				this.$router.push(to)
			})
		},
	},
}
</script>
