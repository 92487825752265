<template>
    <div>
        <v-icon>
            {{ getPhaseIcon(item.data.phase) }}
        </v-icon>
        {{ item.data.status }}
    </div>
</template>

<script>
import { getPhaseIcon } from '../../../../services/icons'
export default {
    props: {
        item: Object,
    },
    methods: {
        getPhaseIcon,
    },
}
</script>
