<template>
	<dialog-form
		id="formDeleteContact"
		:value="value"
		@input="$emit('input', $event)"
		@commit="save"
		:title="$localize('DeleteContact.title')"
		action="delete"
		:loading="loading"
	>
		<p>{{ $localize('DeleteContact.description') }}</p>
		<p>
			<b>{{ contact.name }}</b>
		</p>
		<p>{{ $localize('form.description.actionIsPermanent') }}</p>
	</dialog-form>
</template>

<script>
import DialogForm from '@/shared/components/DialogForm'

import sendCommand from '@/services/sendCommand'
import { getContactById } from '@/services/api'
import { isGuid } from '@/services/validation'

export default {
	components: { DialogForm },
	props: {
		contactId: {
			required: true,
			validator: isGuid,
		},
		value: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			loading: false,
			contact: {},
		}
	},
	async created() {
		this.loading = true
		const { result, ok } = await getContactById.call({
			args: { contactId: this.contactId },
		})
		if (ok) {
			this.contact = result
		}
		this.loading = false
	},
	methods: {
		async save(callback) {
			const result = await sendCommand('DeleteContact', {
				contactId: this.contact.contactId,
			})
			if (result.success) {
				this.$emit('saved')
			}
			this.$emit('input', false)
			callback({ succes: result.success })
		},
	},
}
</script>
