<template>
    <div>
        <v-toolbar flat dense>
            <v-spacer></v-spacer>
            <v-toolbar-items>
                <v-btn @click="startPrepareInvoicesJob">Start prepare invoices job</v-btn>
            </v-toolbar-items>
        </v-toolbar>
    </div>
</template>

<script>
import sendCommand from '@/services/sendCommand'

export default {
    data() {
        return {}
    },

    methods: {
        async startPrepareInvoicesJob() {
            await sendCommand('StartPrepareInvoicesJob', { })
        },
    },
}
</script>
