<template>
	<div>
		<v-text-field
			:rules="rules"
			:label="label"
			:value="val"
			@input="$emit('input', $event)"
            :suffix="inputField.suffix"
            :hint="inputField.hint"
		/>
	</div>
</template>

<script>
import { required } from '@/services/validation'
export default {
	props: {
		label: String,
		value: [String],
		inputField: Object,
	},
	computed: {
		rules() {
			if (this.inputField.isRequired) {
				return [required]
			}
			return []
		},
	},
	data() {
		return {
			val: this.value,
		}
	},
	watch: {
		value(val) {
			this.val = val
		},
	},
}
</script>
