<template>
	<dialog-form
		:value="value"
		@input="$emit('input', $event)"
		:title="$localize('EditProjectStatus.title')"
		:allow-continue="false"
		@commit="save"
		:loading="loading"
	>
		<v-text-field
			v-model="editProjectStatusCommand.newName"
			:label="$localize('Project.name')"
			:rules="[validation.required, validation.maxLength(50)]"
			autofocus
		/>
		<v-row>
			<v-col>
				<v-switch
					:label="$localize('Project.phaseRequests')"
					v-model="editProjectStatusCommand.request"
				/>
			</v-col>
			<v-col>
				<v-switch
					:label="$localize('Project.phaseOffers')"
					v-model="editProjectStatusCommand.offer"
				/>
			</v-col>
			<v-col>
				<v-switch
					:label="$localize('Project.phaseOrders')"
					v-model="editProjectStatusCommand.order"
				/>
			</v-col>
			<v-col>
				<v-switch
					:label="$localize('Project.phaseInvoices')"
					v-model="editProjectStatusCommand.invoice"
				/>
			</v-col>
			<v-col>
				<v-switch
					:label="$localize('Project.phaseArchive')"
					v-model="editProjectStatusCommand.archive"
				/>
			</v-col>
		</v-row>
		<v-switch
			v-model="editProjectStatusCommand.showInActionList"
			:label="$localize('ProjectStatus.showInActionList')"
		/>
		<v-text-field
			v-model="editProjectStatusCommand.actionListRecallDays"
			:label="$localize('ProjectStatus.recallDays')"
			:rules="[validation.integer]"
		/>
		<v-switch
			v-model="editProjectStatusCommand.sendEmailNotification"
			:label="$localize('ProjectStatus.sendEmailNotification')"
		/>
	</dialog-form>
</template>

<script>
import DialogForm from '@/shared/components/DialogForm'

import sendCommand from '@/services/sendCommand'
import { isGuid } from '@/services/validation'
import { getProjectStatusById } from '@/services/api'
import { required, maxLength, integer } from '@/services/validation'

export default {
	components: { DialogForm },

	props: {
		value: {
			type: Boolean,
			default: false,
		},
		projectStatusId: {
			required: true,
			validator: isGuid,
		},
	},
	data() {
		return {
			editProjectStatusCommand: {
				newName: '',
			},
			validation: { required, maxLength, integer },
			loading: false,
		}
	},

	async created() {
		this.loading = true
		const { result, ok } = await getProjectStatusById.call({
			args: { projectStatusId: this.projectStatusId },
		})

		if (ok) {
			this.editProjectStatusCommand = {
				projectStatusId: this.projectStatusId,
				newName: result.name,
				request: result.request,
				offer: result.offer,
				order: result.order,
				invoice: result.invoice,
				archive: result.archive,
				showInActionList: result.showInActionList,
				actionListRecallDays: result.actionListRecallDays,
				sendEmailNotification: result.sendEmailNotification,
			}
		}
		this.loading = false
	},
	methods: {
		async save(callback) {
			const result = await sendCommand(
				'EditProjectStatus',
				this.editProjectStatusCommand
			)

			if (result.success) {
				this.$emit('saved')
			}
			callback({ success: result.success })
		},
	},
}
</script>
