<template>
  <project-overview :user-id="user" />
</template>
<script>
import ProjectOverview from "./ProjectOverview";
import { isGuid } from "@/services/validation";
export default {
  components: { ProjectOverview },
  props: {
    userId: {
      validator: isGuid,
      required: false,
    },
  },
  computed: {
    user() {
      if (this.userId) {
        return this.userId;
      }
      return this.$session.userId;
    },
  },
};
</script>