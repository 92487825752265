
import { getCodeEnvironment } from '@/services/api'



var statementDefinitions = [];
var functions = [];
//var events = [];
var preValues = {};

export async function reload() {
  const data = await getCodeEnvironment.create().load();
  statementDefinitions = data.statements;
  functions = data.functions;
  preValues = data.preValues;
}
export function getPreValues(type){
  if(preValues[type]){
    return preValues[type];
  }
  return [];
}

export function getAllTypes() {
  var types = [];
  for (var index in functions) {
    var action = functions[index];
    if (action.return.substring(0, 1) !== '%' && action.return !== 'void' && types.indexOf(action.return) === -1) {
      types.push(action.return);
    }
  }
  return types;
}

export function getStatements(){
  return statementDefinitions;
}

export function getFunctionByReturnType(type) {
  let actions = [];
  for (let index = 0; index < functions.length; index++) {
    const func = functions[index];
    if ((type === 'any' && func.return !== 'void') || func.return === type) {
      actions.push(func)
    }
  }
  return actions;
}

export function getFunctionDefinitionById(id) {

  var s = functions.filter(x => x.id === id);
  if (s.length > 0) {
    return s[0];
  }
  console.error('Definition not found "' + id + '"');
  return null;
}
export function getStatementDefinitionById(id) {

  var s = statementDefinitions.filter(x => x.id === id);
  if (s.length > 0) {
    return s[0];
  }
  console.error('Definition not found "' + id + '"');
  return null;
}

export function createStatement(id) {
  var statement = {
    id: id,
    scopes: []
  };

  const def = getStatementDefinitionById(id);
  statement.arguments = def.parameters.map(() => null);

  for (let index = 0; index < def.scopes.length; index++) {
    statement.scopes.push([]);
  }
  return statement;
}

export function createValue(type, idOrValue, dataType) {
  if (type !== 'function' && type !== 'constant' && type != 'variable' && type != 'preValue') {
    console.error('Value type must be one of: function, constant or variable, got: ' + type);
  }
  var value = {
    type: type,
    dataType: dataType
  }
  if (type === 'function') {
    value.id = idOrValue;
    value.function = idOrValue;
    const definition = getFunctionDefinitionById(idOrValue);
    value.arguments = definition.parameters.map(() => null);
  }
  else if (type === 'constant') {
    value.value = idOrValue;
  }
  else if (type === 'variable') {
    value.variableKey = idOrValue;
  }
  else if(type === 'preValue'){
    value.id = idOrValue;
  }
  return value;
}

export function CodeColor(statement, value) {

  switch (statement) {
    case 'variable-modifier':
      return '#0101FD';
    case 'variable':
      return '#007d9a';
    case 'code-logic':
      return "black";
    case 'function':
      break;
    default:
      switch (value) {
        case 'bool':
          return "#a31515"
        case 'text':
          return "#a31515"
        case 'number':
          return "#a31515"
        case 'datetime':
        case 'date':
          return "#a31515"
        default:
          return "black";
      }
  }
}

export function normalizeVariableName(name){
  let normalized = name.replace(/[^\w_]/g, "");
  normalized = normalized.toLowerCase();
  return normalized;
  
}