<template>
	<dialog-form
		:value="value"
		@input="$emit('input', $event)"
		:title="$localize('EnterProductInformation.title')"
		:allow-continue="false"
		@commit="save"
		:loading="loading"
	>
		<v-combobox
			:items="products"
			:label="$localize('Product.product')"
			v-model="enterProductInformation.product"
			@input.native="setProduct"
			:rules="[validation.maxLength(200)]"
		/>
		<v-text-field
			:label="$localize('Product.description')"
			v-model="enterProductInformation.description"
			:rules="[validation.maxLength(500)]"
		/>
		<input-number
			:label="$localize('Product.discount')"
			v-model="enterProductInformation.discount"
			:rules="[validation.number, validation.numberRange(5, 2)]"
		/>
		<input-number
			:label="$localize('Product.price')"
			v-model="enterProductInformation.price"
			persistent-hint
			:hint="$localize('Product.price.hint')"
		/>
		<v-textarea
			:label="$localize('Product.specifications')"
			v-model="enterProductInformation.specifications"
			:rules="[validation.maxLength(2000)]"
			persistent-hint
			:hint="$localize('Product.specifications.hint')"
		/>
		<v-switch
			:label="$localize('Product.offerShowPrice')"
			v-model="enterProductInformation.offerShowPrice"
			hide-details
		/>
		<v-row>
			<v-col>
				<v-switch
					:label="$localize('Product.offerShowParts')"
					v-model="enterProductInformation.offerShowParts"
					hide-details
				/>
			</v-col>
			<v-col>
				<v-switch
					:label="$localize('Product.offerShowPartPrices')"
					v-model="enterProductInformation.offerShowPartPrices"
					:disabled="
						!enterProductInformation.offerShowParts ||
						!enterProductInformation.offerShowPrice
					"
					hide-details
				/>
			</v-col>
		</v-row>
		<select-vat v-model="enterProductInformation.vatPercentage" required />
	</dialog-form>
</template>

<script>
import DialogForm from '@/shared/components/DialogForm'
import InputNumber from '@/main/components/forms/InputNumber'
import SelectVat from '@/main/components/forms/SelectVat'

import sendCommand from '@/services/sendCommand'
import { required, number, numberRange, maxLength } from '@/services/validation'
import { isGuid } from '@/services/validation'
import { getProductById, getProducts } from '@/services/api'

export default {
	components: { DialogForm, InputNumber, SelectVat },
	props: {
		value: {
			type: Boolean,
			default: false,
		},
		productId: {
			validator: isGuid,
			required: true,
		},
	},

	data() {
		return {
			products: [],
			enterProductInformation: {},
			validation: { required, number, numberRange, maxLength },
			loading: false,
		}
	},
	watch: {
		'enterProductInformation.offerShowParts'() {
			if (!this.enterProductInformation.offerShowParts) {
				this.enterProductInformation.offerShowPartPrices = false
			}
		},
		'enterProductInformation.offerShowPrice'() {
			if (!this.enterProductInformation.offerShowPrice) {
				this.enterProductInformation.offerShowPartPrices = false
			}
		},
	},
	async created() {
		this.loading = true
		await this.loadProducts()
		const { result, ok } = await getProductById.call({
			args: { productId: this.productId },
		})
		if (ok) {
			const product = result
			this.enterProductInformation = {
				projectId: product.projectId,
				productId: product.productId,
				product: product.product,
				description: product.description,
				discount: product.discount,
				price: product.price,
				vatPercentage: product.vatPercentage,
				specifications: product.specifications,

				offerShowPrice: product.offerShowPrice,
				offerShowParts: product.offerShowParts,
				offerShowPartPrices: product.offerShowPartPrices,
			}
		}
		this.loading = false
	},
	methods: {
		async loadProducts() {
			const { result, ok } = await getProducts.call()
			if (ok) {
				this.products = result
			}
		},
		setProduct(e) {
			if (!e) {
				this.enterProductInformation.product = ''
			} else if (typeof e === 'object') {
				this.enterProductInformation.product = e.srcElement.value
			}
		},
		async save(callback) {
			this.enterProductInformation.discount =
				this.enterProductInformation.discount || 0

			const result = await sendCommand(
				'EnterProductInformation',
				this.enterProductInformation
			)
			if (result.success) {
				this.$emit('saved')
			}
			callback({ success: result.success })
		},
	},
}
</script>
