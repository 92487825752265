import { ApiEndPoint } from './apiEndPoint'

export const getProducts = new ApiEndPoint('projects/products')

export const getProjectStatuses = new ApiEndPoint('projects/statuses')
export const getEnabledProjectStatuses = new ApiEndPoint('projects/statuses/enabled')
export const getEnabledProjectStatusesByPhase = new ApiEndPoint('projects/statuses/enabled/{phase}')
export const getProjectStatusById = new ApiEndPoint('projects/statuses/{projectStatusId}')


export const getProjectsByUser = new ApiEndPoint("users/{userId}/projects")

export const getProjectByPhase = new ApiEndPoint("projects/{phase}")
export const getProjectInDashboard = new ApiEndPoint('projects/dashboard/{dashboardId}')

export const getProjectById = new ApiEndPoint("projects/{projectId}")
export const getProjectProducts = new ApiEndPoint("projects/{projectId}/products")
export const getProjectOffers = new ApiEndPoint("projects/{projectId}/offers")
export const getProjectTimeline = new ApiEndPoint("projects/{projectId}/timeline")
export const getProductById = new ApiEndPoint("projects/product/{productId}")

export const getOffer = new ApiEndPoint("projects/{projectId}/offer/{offerId}")
export const getExampleOffer = new ApiEndPoint("projects/{projectId}/example-offer")
export const getInvoice = new ApiEndPoint("projects/{projectId}/invoice")
export const getExampleInvoice = new ApiEndPoint("projects/{projectId}/example-invoice")

export const projectPdfDownload = new ApiEndPoint("projects/{projectId}/download")


export const getDashboards = new ApiEndPoint("dashboards")
export const getDashboardById = new ApiEndPoint("dashboards/{dashboardId}")


// Emails
export const prepareOfferEmail = new ApiEndPoint('mail/offer/{projectId}')
export const prepareInvoiceEmail = new ApiEndPoint('mail/invoice/{projectId}')
export const prepareOrderConfirmationEmail = new ApiEndPoint('mail/order-confirmation/{projectId}')
export const prepareStatusUpdateEmail = new ApiEndPoint('mail/status-update/{projectId}')
