<template>
	<v-row no-gutters>
		<v-col>
			<v-select
				:label="$localize('ArticleCalculation.prices')"
				:items="priceTypes"
				v-model="selectedPrices"
				@input="$emit('input', selectedPrices)"
				item-text="displayName"
				item-value="alias"
				multiple
				:hint="$localize('ArticleCalculation.prices.hint')"
				:persistent-hint="true"
				prepend-icon="mdi-currency-eur"
				:autofocus="autofocus"
				:loading="loading"
			/>
		</v-col>
		<v-col align-self="center" cols="auto" class="ml-1">
			<v-btn
				small
				outlined
				fab
				color="success"
				@click="createAttribute = true"
			>
				<v-icon>mdi-plus</v-icon>
			</v-btn>
		</v-col>

		<create-attribute
			v-if="createAttribute"
			v-model="createAttribute"
			:attribute-type="true"
			:allow-continue="false"
			@saved="onSaved($event)"
		/>
	</v-row>
</template>

<script>
import CreateAttribute from '@/main/advanced/dialogs/CreateAttribute'

import { getPriceTypes } from '@/services/api'
export default {
	components: { CreateAttribute },
	props: {
		value: {
			type: Array,
			required: true,
		},
		autofocus: Boolean,
	},
	data() {
		return {
			createAttribute: false,
			priceTypes: [],
			selectedPrices: this.value,
			loading: false,
		}
	},
	watch: {
		value(val) {
			this.selectedPrices = val
		},
	},
	created() {
		this.refresh()
	},
	methods: {
		async refresh() {
			this.loading = true
			const { result, ok } = await getPriceTypes.call()
			if (ok) {
				this.priceTypes = result
			}
			this.loading = false
		},
		async onSaved(alias) {
			await this.refresh()
			this.selectedPrices.push(alias)
		},
	},
}
</script>
