<template>
  <div>
    <v-text-field
      class="email-field"
      filled
      :label="label"
      :hint="hint"
      :persistent-hint="true"
      :value="inputPreview"
      :readonly="true"
      @click="editingEmail = true"
      prepend-icon="mdi-email"
    />

    <enter-email v-model="editingEmail" :email="emailData" @saved="$emit('input', JSON.stringify($event))" :title="label" :autofocus="autofocus" />
  </div>
</template>

<script>
import EnterEmail from "../dialogs/EnterEmail";
export default {
  components: { EnterEmail },
  props: {
    label: String,
    hint: String,
    value: String,
    autofocus: Boolean
  },
  data() {
    return {
      editingEmail: false
    };
  },
  computed: {
    emailData(){
      if(this.value){
        return JSON.parse(this.value);
      }
      return {}
    },
    inputPreview() {
      if (this.emailData) {
        if (this.emailData.subject) {
          return this.emailData.subject.slice(0, 50);
        }
      }

      return "Klik hier om een email aan te maken";
    }
  },
};
</script>