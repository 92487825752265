<template>
    <div>
        <v-row class="project-header-row" no-gutters>
            <v-col>
                {{ invoiceTitle }}
            </v-col>
        </v-row>
        <v-card v-if="invoice && invoice.path" flat class="pl-3 pt-3 pb-3">
            <v-flex>
                <v-row no-gutters align="center" class="font-weight-bold">
                    <v-col cols="4">{{ $localize('Invoice.addedOn') }}</v-col>
                    <v-col cols="4">{{ $localize('Invoice.sentOn') }}</v-col>
                    <v-col cols="4"></v-col>
                </v-row>
                <v-row no-gutters align="center">
                    <v-col cols="4">{{
                        $format.shortDate(invoice.date)
                    }}</v-col>
                    <v-col cols="4">{{
                        $format.shortDate(invoice.sent)
                    }}</v-col>
                    <v-col cols="4">
                        <v-toolbar
                            flat
                            dense
                            tile
                            class
                            style="background: none"
                            height="28"
                        >
                            <v-spacer></v-spacer>
                            <v-toolbar-items>
                                <v-btn
                                    v-if="
                                        $session.roles.includes(
                                            'invoices_manage'
                                        )
                                    "
                                    x-small
                                    @click="sendInvoice = true"
                                >
                                    <v-icon color="success" small
                                        >mdi-email-send</v-icon
                                    >
                                </v-btn>

                                <v-btn
                                    v-if="
                                        $session.roles.includes('invoices_view')
                                    "
                                    x-small
                                    @click="downloadInvoice"
                                >
                                    <v-icon color="red" small
                                        >mdi-adobe-acrobat</v-icon
                                    >
                                </v-btn>
                            </v-toolbar-items>
                        </v-toolbar>
                    </v-col>
                </v-row>
            </v-flex>
            <div class="text-center">
                <v-btn
                    v-if="$session.roles.includes('invoices_manage')"
                    @click="createInvoice = true"
                    class="ma-5"
                >
                    <v-icon color="success">mdi-plus</v-icon>
                    <span class="ml-1">{{
                        $localize('ProjectInvoice.btn.recreateInvoice')
                    }}</span>
                </v-btn>
            </div>
        </v-card>
        <v-card v-else-if="invoice.number" class="text-center" flat>
            <v-btn
                v-if="$session.roles.includes('invoices_manage')"
                @click="createInvoice = true"
                class="ma-5"
            >
                <v-icon color="success">mdi-plus</v-icon>
                <span class="ml-1">{{
                    $localize('ProjectInvoice.btn.createInvoice')
                }}</span>
            </v-btn>
        </v-card>
        <v-card v-else class="text-center" flat>
            <v-btn
                v-if="
                    !invoice.number &&
                    $session.roles.includes('invoices_manage')
                "
                @click="assignInvoiceNumber = true"
                class="ma-5"
            >
                <v-icon>mdi-counter</v-icon>
                <span class="ml-1">
                    {{ $localize('ProjectInvoice.btn.assignInvoiceNumber') }}
                </span>
            </v-btn>
        </v-card>
        <assign-invoice-number
            v-if="assignInvoiceNumber"
            v-model="assignInvoiceNumber"
            :project-id="$route.params.projectId"
        />
        <create-invoice
            v-if="createInvoice"
            v-model="createInvoice"
            :project-id="$route.params.projectId"
        />
        <send-invoice
            v-if="sendInvoice"
            v-model="sendInvoice"
            :project-id="projectId"
        />
    </div>
</template>

<script>
import { isGuid } from '@/services/validation'

import { getExampleInvoice, getInvoice } from '@/services/api'
import AssignInvoiceNumber from '../dialogs/AssignInvoiceNumber'
import CreateInvoice from '../dialogs/CreateInvoice'
import SendInvoice from '../dialogs/SendInvoice'

export default {
    components: {
        AssignInvoiceNumber,
        CreateInvoice,
        SendInvoice,
    },
    props: {
        projectId: {
            validator: isGuid,
        },
        invoice: Object,
    },
    data() {
        return {
            assignInvoiceNumber: false,
            createInvoice: false,
            sendInvoice: false,
        }
    },
    computed: {
        invoiceTitle() {
            if (!this.invoice || !this.invoice.date) {
                return this.$localize('ProjectInvoice.title')
            }
            var id =
                this.$localize('ProjectInvoice.title') +
                ' ' +
                this.$format.year(this.invoice.date) +
                '.' +
                this.invoice.number
            return id
        },
    },
    methods: {
        downloadExampleInvoice() {
            const url = getExampleInvoice.create({
                projectId: this.projectId,
            }).url
            window.open(url)
        },
        downloadInvoice() {
            const url = getInvoice.create({ projectId: this.projectId }).url
            window.open(url)
        },
    },
}
</script>
<style lang="scss" scoped>
.v-btn:not(.v-btn--round).v-size--x-small {
    min-width: 28px;
    padding: 0;
}
</style>
