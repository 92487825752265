<template>
  <div v-if="message">
    <message :message="message.message" />

    <div v-for="child in message.children" :key="child.message.messageId">
      <message :message="child.message" />
    </div>
  </div>
</template>

<script>
import { getMessageTree } from "@/services/api";
import Message from "../components/ops/Message";

export default {
  components: { Message },
  data() {
    return {
      message: null
    };
  },
  async created() {
    const messageId = this.$route.params.messageId;
    this.message = await getMessageTree.create({ messageId: messageId }).load();
  }
};
</script>

