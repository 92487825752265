<template>
  <dialog-form
    :value="value"
    @input="$emit('input', $event)"
    :title="$localize('CreateTrigger.title')"
    @commit="save"
    :allow-continue="false"
  >
    <v-text-field
      :label="$localize('Trigger.name')"
      v-model="name"
      :rules="[rules.required, rules.maxLength]"
    />
  </dialog-form>
</template>

<script>
import DialogForm from "@/shared/components/DialogForm";
import sendCommand from "@/services/sendCommand";
import { required, maxLength } from "@/services/validation";

export default {
  components: { DialogForm },

  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      name: null,
      rules: { required, maxLength },
    };
  },
  methods: {
    async save(callback) {
      const result = await sendCommand("CreateTrigger", { name: this.name });

      if (result.success) {
        this.$emit("saved");
      }
      callback({ success: result.success });
    },
  },
};
</script>
