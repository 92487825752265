<template>
	<div>
		<v-row
			no-gutters
			align="center"
			class="font-weight-bold project-header-row"
		>
			<v-col>{{ $localize('Product.product') }}</v-col>
			<v-col v-if="$vuetify.breakpoint.smAndUp">{{
				$localize('Product.description')
			}}</v-col>
			<v-col
				v-if="$session.roles.includes('projects_calculate')"
				cols="auto"
				align="right"
				class="pr-3"
				style="width: 100px"
				>{{ $localize('Product.price') }}</v-col
			>
			<v-col cols="auto" :style="'width:' + buttonBarWidth"></v-col>
		</v-row>
		<v-skeleton-loader :loading="loading" type="list-item-three-line">
			<div>
				<template v-for="(product, index) in products">
					<product-details
						v-model="products[index]"
						:key="product.productId"
						:button-bar-width="buttonBarWidth"
						:button-bar-height="buttonBarHeight"
						:calculations="calculations"
						:is-first="index === 0"
						:is-last="index === products.length - 1"
					/>
					<v-row
						v-if="product.startingCost > 0"
						:key="product.productId + '-startingcost'"
						no-gutters
						align="stretch"
					>
						<v-col>{{
							$localize('ProductsDetails.initialCosts')
						}}</v-col>
						<v-col v-if="$vuetify.breakpoint.smAndUp"></v-col>
						<v-col
							align="right"
							cols="auto"
							style="width: 100px"
							class="grey lighten-2 pr-3"
							>{{ $format.currency(product.startingCost) }}</v-col
						>
						<v-col
							cols="auto"
							:style="'width:' + buttonBarWidth"
						></v-col>
					</v-row>
					<template v-for="(part, index) in product.parts">
						<part-details
							v-model="product.parts[index]"
							:key="part.partId"
							:button-bar-width="buttonBarWidth"
							:button-bar-height="buttonBarHeight"
							:is-first="index === 0"
							:is-last="index === product.parts.length - 1"
						/>
					</template>
				</template>
			</div>
		</v-skeleton-loader>
		<v-row
			v-if="$session.roles.includes('projects_calculate')"
			no-gutters
			align="center"
			class="my-3"
		>
			<v-col class="font-weight-bold">{{
				$localize('ProductsDetails.totalCosts')
			}}</v-col>
			<v-col v-if="$vuetify.breakpoint.smAndUp"></v-col>
			<v-col
				cols="auto"
				align="right"
				class="pr-3 font-weight-bold"
				style="width: 100px"
				>{{ $format.currency(totalPrice) }}</v-col
			>
			<v-col cols="auto" :style="'width:' + buttonBarWidth"></v-col>
		</v-row>
	</div>
</template>

<script>
import ProductDetails from './ProductDetails'
import PartDetails from './PartDetails'

import { getProjectProducts, getAllPublishedCalculations } from '@/services/api'
import { isGuid } from '@/services/validation'
import { subscribe, unsubscribe } from '@/services/websocketHandler'
import Vue from 'vue'

export default {
	components: {
		ProductDetails,
		PartDetails,
	},
	props: {
		projectId: {
			validator: isGuid,
		},
	},
	data() {
		return {
			loading: false,
			products: [],
			calculations: [],
			subscription1: null,
			subscription2: null,
			subscription3: null,
			subscription4: null,
			subscription5: null,
			subscription6: null,
			subscription7: null,
			subscription8: null,
		}
	},
	computed: {
		buttonBarWidth() {
			if (this.$vuetify.breakpoint.mdAndUp) {
				return '265px'
			}
			return '110px'
		},

		buttonBarHeight() {
			return '28px'
		},

		totalPrice() {
			let total = 0
			for (var index in this.products) {
				const product = this.products[index]
				let price
				if (product.price) {
					price = product.price
				} else {
					price = product.calculatedPrice + product.startingCost
					price = price * (1 - product.discount / 100)
				}
				total += price
			}
			return total
		},
	},
	async created() {
		this.loading = true
		const { result, ok } = await getProjectProducts.call({
			args: { projectId: this.$route.params.projectId },
		})
		if (ok) {
			this.products = result
		}
		await this.loadCalculations()
		this.loading = false
		this.subscription1 = subscribe(
			'ProductCreated',
			this.onMessage,
			(x) => x.projectId === this.projectId
		)
		this.subscription2 = subscribe(
			'ProductInformationEntered',
			this.onMessage,
			(x) => x.projectId === this.projectId
		)
		this.subscription3 = subscribe(
			'ProductDeleted',
			this.onMessage,
			(x) => x.projectId === this.projectId
		)
		this.subscription4 = subscribe(
			'ProductsSorted',
			this.onMessage,
			(x) => x.projectId === this.projectId
		)
		this.subscription5 = subscribe(
			'PartsSorted',
			this.onMessage,
			(x) => x.projectId === this.projectId
		)
		this.subscription6 = subscribe(
			'PartCreated',
			this.onMessage,
			(x) => x.projectId === this.projectId
		)
		this.subscription7 = subscribe(
			'PartCalculated',
			this.onMessage,
			(x) => x.projectId === this.projectId
		)
		this.subscription8 = subscribe(
			'PartDeleted',
			this.onMessage,
			(x) => x.projectId === this.projectId
		)
	},
	destroyed() {
		unsubscribe(this.subscription1)
		unsubscribe(this.subscription2)
		unsubscribe(this.subscription3)
		unsubscribe(this.subscription4)
		unsubscribe(this.subscription5)
		unsubscribe(this.subscription6)
		unsubscribe(this.subscription7)
		unsubscribe(this.subscription8)
	},
	methods: {
		async loadCalculations() {
			const { result, ok } = await getAllPublishedCalculations.call()
			if (ok) {
				this.calculations = result
			}
		},
		onMessage(message, messageType) {
			switch (messageType) {
				case 'ProductCreated':
					this.products.push({
						projectId: message.projectId,
						productId: message.productId,
						price: null,
						discount: 0,
						calculatedPrice: 0,
						startingCost: 0,
						parts: [],
					})
					break
				case 'ProductInformationEntered':
					{
						const product = this.products.find(
							(x) => x.productId === message.productId
						)
						Vue.set(product, 'description', message.description)
						Vue.set(product, 'product', message.product)
						Vue.set(product, 'price', message.price)
						Vue.set(product, 'discount', message.discount)
					}
					break
				case 'ProductDeleted':
					this.products = this.products.filter(
						(x) => x.productId !== message.productId
					)
					break
				case 'ProductsSorted':
					{
						this.products = message.sortedProducts.map(
							(productId) =>
								this.products.find(
									(product) => product.productId === productId
								)
						)
					}
					break
				case 'PartsSorted':
					{
						const product = this.products.find(
							(x) => x.productId === message.productId
						)

						var sortedParts = message.sortedParts.map((partId) =>
							product.parts.find((part) => part.partId === partId)
						)
						Vue.set(product, 'parts', sortedParts)
					}
					break
				case 'PartCreated':
					{
						const product = this.products.find(
							(x) => x.productId === message.productId
						)
						product.parts.push({
							projectId: message.projectId,
							productId: message.productId,
							partId: message.partId,
							calculationId: message.calculationId,
							price: 0,
							displayInfo: {},
							input: {},
							calculationVersion: message.calculationVersion,
						})
					}
					break
				case 'PartCalculated':
					{
						const product = this.products.find(
							(x) => x.productId === message.productId
						)
						const part = product.parts.find(
							(x) => x.partId === message.partId
						)
						Vue.set(part, 'price', message.price)
						Vue.set(part, 'description', message.description)
						Vue.set(part, 'specifications', message.specifications)
						Vue.set(part, 'input', message.input)
						Vue.set(part, 'displayInfo', message.displayInfo)
						Vue.set(
							part,
							'calculationVersion',
							message.calculationVersion
						)
						Vue.set(part, 'versionDate', message.versionDate)
					}
					break
				case 'PartDeleted':
					{
						const product = this.products.find(
							(x) => x.productId === message.productId
						)
						product.parts = product.parts.filter(
							(x) => x.partId !== message.partId
						)
					}
					break
			}
		},
	},
}
</script>
<style lang="scss" scoped>
.row {
	padding-left: 12px;
}
</style>

<style lang="scss">
.project-header-row {
	background-color: #e0e0e0;
	padding-top: 4px;
	padding-bottom: 4px;
	padding-left: 12px;
	font-weight: 700;
}

.theme--dark .project-header-row {
	background-color: #272727;
}
</style>
