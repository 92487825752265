var render = function render(){var _vm=this,_c=_vm._self._c;return _c('dialog-form',{attrs:{"value":_vm.value,"title":_vm.$localize('BulkSelectArticleCalculations.title', {
			count: _vm.articleIds.length,
		}),"commit-message":_vm.$localize('BulkSelectArticleCalculations.commitMessage'),"allow-continue":false,"max-width":"1000"},on:{"input":function($event){return _vm.$emit('input', $event)},"commit":_vm.save}},[_c('v-skeleton-loader',{attrs:{"type":"card","loading":_vm.loading}},[_c('v-simple-table',{attrs:{"dense":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',[_vm._v(_vm._s(_vm.$localize('Calculation.name')))]),_c('th',{attrs:{"colspan":"4"}},[_vm._v(" "+_vm._s(_vm.$localize( 'BulkChangeArticleCalculation.articleCalculations' ))+" ")])])]),_c('tbody',_vm._l((_vm.calculations),function(calculation){return _c('tr',{key:calculation.calculationId},[_c('td',[_vm._v(_vm._s(calculation.name))]),_vm._l((calculation.input),function(input){return [(input.articleCalculationIds)?_c('td',{key:input.key},_vm._l((input.articleCalculationIds),function(articleCalculationId,index){return _c('v-checkbox',{key:`${input.key}-${index}-${articleCalculationId}`,staticClass:"ma-0",attrs:{"value":articleCalculationId,"indeterminate":"","label":_vm.getCalculationFieldName(
											articleCalculationId
										),"hide-details":"","dense":""},on:{"update:indeterminate":function($event){return _vm.IntederminateEvent(
											articleCalculationId
										)}},model:{value:(_vm.enabledCalculations),callback:function ($$v) {_vm.enabledCalculations=$$v},expression:"enabledCalculations"}})}),1):_vm._e()]})],2)}),0)]},proxy:true}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }