<template>
	<v-card>
		<v-card-title
			>{{ $localize('ArticleImportProcess.title') }}:
			{{ articleImport.originalFileName }}</v-card-title
		>
		<v-card-text>
			<v-container no-gutters fluid>
				<v-row>
					<v-col>{{
						$localize('ArticleImport.rowsRemaining')
					}}</v-col>
					<v-col>{{ rowsRemaining }}</v-col>
				</v-row>
				<v-row>
					<v-col>{{
						$localize('ArticleImport.rowsSucceeded')
					}}</v-col>
					<v-col>{{ rowsSuccess }}</v-col>
				</v-row>
				<v-row>
					<v-col>{{ $localize('ArticleImport.rowsFailed') }}</v-col>
					<v-col>{{ rowsFailed }}</v-col>
				</v-row>

				<v-row>
					<v-col>
						<v-progress-linear
							height="30"
							:value="importProgress"
						></v-progress-linear>
					</v-col>
				</v-row>
			</v-container>

			<v-simple-table dense>
				<template v-slot:default>
					<thead>
						<tr>
							<th class="text-left">
								{{ $localize('Import.failedRows.row') }}
							</th>
							<th class="text-left">
								{{ $localize('Import.failedRows.status') }}
							</th>
							<th class="text-left">
								{{ $localize('Import.failedRows.reason') }}
							</th>
						</tr>
					</thead>
					<tbody>
						<tr
							v-for="row in articleImport.failedRows"
							:key="row.rowNumber"
						>
							<td>{{ row.rowNumber }}</td>
							<td>{{ row.status }}</td>
							<td>{{ row.reason }}</td>
						</tr>
					</tbody>
				</template>
			</v-simple-table>
		</v-card-text>
		<v-card-actions> </v-card-actions>
	</v-card>
</template>

<script>
import Vue from 'vue'
import { getArticleImport } from '@/services/api'
import { subscribe, unsubscribe } from '@/services/websocketHandler'
import { isGuid } from '@/services/validation'

export default {
	props: {
		articleImportId: {
			validator: isGuid,
			required: true,
		},
	},

	data() {
		return {
			articleImport: {},
			subscription: null,
		}
	},
	computed: {
		importProgress() {
			return (
				(this.articleImport.processedRows /
					this.articleImport.totalDataRows) *
				100
			)
		},
		rowsRemaining() {
			return (
				this.articleImport.totalDataRows -
				this.articleImport.processedRows
			)
		},
		rowsSuccess() {
			return this.articleImport.processedRows - this.rowsFailed
		},
		rowsFailed() {
			if (this.articleImport.failedRows) {
				return this.articleImport.failedRows.length
			}
			return 0
		},
	},
	async created() {
		const { result, ok } = await getArticleImport.call({
			args: { articleImportId: this.articleImportId },
		})
		if (ok) {
			this.articleImport = result
			if (!this.checkRows()) {
				this.subscription = subscribe(
					'ArticleImportDataRowProcessed',
					this.onMessage,
					(x) => x.articleImportId === this.articleImportId
				)
			}
		}
	},
	destroyed() {
		unsubscribe(this.subscription)
	},
	methods: {
		onMessage(message) {
			if (message.articleImportId == this.articleImportId) {
				Vue.set(this.articleImport, 'processedRows', message.rowNumber)

				if (message.status !== 'success') {
					this.articleImport.failedRows.push({
						rowNumber: message.rowNumber,
						status: message.status,
						reason: message.reason,
					})
				}

				if (this.checkRows()) {
					unsubscribe(this.subscription)
					this.$emit('continue')
				}
			}
		},

		checkRows() {
			if (
				this.articleImport.totalDataRows ===
				this.articleImport.processedRows
			) {
				this.$emit('continue')
				return true
			}
			return false
		},
	},
}
</script>
