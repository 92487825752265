<template>
	<dialog-form
		:value="value"
		@input="$emit('input', $event)"
		:title="$localize('CreateUser.title')"
		@commit="save"
	>
		<v-text-field
			prepend-icon="mdi-text-short"
			:label="$localize('User.fullname')"
			v-model="createUserCommand.fullName"
			:rules="[validation.required, validation.maxLength(100)]"
		/>
		<v-text-field
			prepend-icon="mdi-account-key"
			:label="$localize('User.login')"
			v-model="createUserCommand.login"
            :rules="[validation.required]"
			:suffix="'@' + $session.domain"
		/>
		<input-email
			:label="$localize('User.recoveryEmail')"
			v-model="createUserCommand.recoveryEmail"
			:rules="[validation.email]"
            :hint="$localize('User.recoveryEmail.hint')"
		/>
		<select-salutation
			:label="$localize('User.salutation')"
			v-model="createUserCommand.salutation"
		/>
		<date-select
			:label="$localize('User.bornDate')"
			v-model="createUserCommand.bornDate"
		/>
		<v-text-field
			prepend-icon="mdi-map-marker"
			:label="$localize('User.location')"
			v-model="createUserCommand.location"
			:rules="[validation.maxLength(50)]"
		/>
		<v-text-field
			prepend-icon="mdi-cellphone"
			:label="$localize('User.mobilePhoneNumber')"
			v-model="createUserCommand.mobilePhoneNumber"
			:rules="[validation.maxLength(50)]"
		/>
		<input-color v-model="createUserCommand.color" />
	</dialog-form>
</template>

<script>
import DialogForm from '@/shared/components/DialogForm'
import { required, email, maxLength } from '@/services/validation'
import sendCommand from '@/services/sendCommand'
import DateSelect from '@/shared/components/DateSelect'
import InputColor from '@/main/components/forms/InputColor'
import SelectSalutation from '@/main/components/forms/SelectSalutation'

export default {
	components: { DialogForm, DateSelect, InputColor, SelectSalutation },
	props: {
		value: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			createUserCommand: {},
			validation: { required, email, maxLength },
		}
	},

	methods: {
		async save(callback) {
			const result = await sendCommand(
				'CreateTenantUser',
				this.createUserCommand
			)
			if (result.success) {
				this.$emit('saved')
			}
			callback({ success: result.success })
		},
	},
}
</script>
