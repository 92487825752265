<template>
	<dialog-form
		:value="value"
		@input="$emit('input', $event)"
		:title="
			$localize('EnterArticlePrice.title') + ' ' + priceType.displayName
		"
		@commit="save"
		:allow-continue="false"
	>
		<v-tabs v-if="enableGroups" v-model="selectedRelationGroup">
			<v-tab v-for="group in groups" :key="group.relationGroupId">{{
				group.name
			}}</v-tab>
			<v-tab-item v-for="group in groups" :key="group.relationGroupId">
				<enter-price
					v-model="pricesByGroup[group.relationGroupId]"
					:relation-group-id="group.relationGroupId"
				/>
			</v-tab-item>
		</v-tabs>
		<div v-else>
			<enter-price
				v-if="pricesByGroup[defaultRelationGroup.relationGroupId]"
				v-model="pricesByGroup[defaultRelationGroup.relationGroupId]"
				:relation-group-id="defaultRelationGroup.relationGroupId"
			/>
		</div>
		<v-btn v-if="!enableGroups" @click="enableGroups = true" class="mt-3">{{
			$localize('EnterArticlePrice.button.enablePerRelationGroupPricing')
		}}</v-btn>
	</dialog-form>
</template>
<script>
import DialogForm from '@/shared/components/DialogForm'
import EnterPrice from '../components/EnterPrice'
import { isGuid } from '@/services/validation'
import sendCommand from '@/services/sendCommand'
import {
	getArticleById,
	getPriceTypes,
	getRelationGroups,
} from '@/services/api'

export default {
	components: { DialogForm, EnterPrice },
	props: {
		value: {
			type: Boolean,
			default: false,
		},
		articleId: {
			validator: isGuid,
			required: true,
		},
		priceAlias: {
			type: String,
		},
	},
	data() {
		return {
			defaultRelationGroup: {},
			selectedRelationGroup: 0,
			enableGroups: false,
			enableScales: [],
			pricesByGroup: {},
			priceType: {},
			groups: [],
		}
	},
	async created() {
		await this.loadPriceTypes()
		await this.loadRelationGroups()
		const { result, ok } = await getArticleById.call({
			args: { articleId: this.articleId },
		})

		if (ok) {
			const article = result
			const prices = article.prices[this.priceAlias] || []
			this.pricesByGroup = {}
			this.groups.map((x) => {
				this.$set(this.pricesByGroup, x.relationGroupId, [])
			})
			prices.map((x) => {
				this.pricesByGroup[x.relationGroupId].push({
					fromAmount: x.min,
					price: x.price,
					relationGroupId: x.relationGroupId,
				})
			})

			// Check if prices per relation group are enabled
			const groupsWithPrices = Object.keys(this.pricesByGroup)
				.map((x) => this.pricesByGroup[x].length)
				.filter((x) => x > 0)
			this.enableGroups = groupsWithPrices.length > 1
		}
	},
	methods: {
		async loadPriceTypes() {
			const { result, ok } = await getPriceTypes.call()
			if (ok) {
				const priceTypes = result
				this.priceType = priceTypes.filter(
					(x) => x.alias === this.priceAlias
				)[0]
			}
		},
		async loadRelationGroups() {
			const { result, ok } = await getRelationGroups.call()
			if (ok) {
				this.groups = result
				// TODO: actually set it to the selected relation group, not default
				this.selectedRelationGroup = this.groups.findIndex(
					(x) =>
						x.relationGroupId ===
						this.$settings.defaultRelationGroup
				)
				this.defaultRelationGroup = this.groups.filter(
					(x) =>
						x.relationGroupId ===
						this.$settings.defaultRelationGroup
				)[0]
			}
		},
		async save(callback) {
			const prices = []
			Object.keys(this.pricesByGroup).map((x) =>
				this.pricesByGroup[x].map((y) => {
					if (y.price) prices.push(y)
				})
			)
			let result
			if (prices.length === 0) {
				result = await sendCommand('RemoveArticlePrice', {
					articleId: this.articleId,
					priceAlias: this.priceAlias,
				})
			} else {
				result = await sendCommand('EnterArticlePrice', {
					articleId: this.articleId,
					priceAlias: this.priceAlias,
					prices: prices,
				})
			}
			if (result.success) {
				this.$emit('saved')
			}
			callback({ success: result.success })
		},
	},
}
</script>
