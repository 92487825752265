var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.prices)?_c('span',[_vm._v("-")]):(_vm.prices.length === 1)?_c('span',{staticClass:"primary--text"},[_vm._v(" "+_vm._s(_vm.$format.currency(_vm.prices[0].price))+" ")]):_c('div',[_c('v-simple-table',{staticStyle:{"width":"100%"},attrs:{"dense":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[(_vm.numRelationGroups > 1)?_c('th',{staticStyle:{"width":"10%"}}):_vm._e(),_vm._l((_vm.breakpoints),function(b){return _c('th',{key:b.min,staticClass:"text-center"},[(b.max)?[_vm._v(" "+_vm._s(b.min)+" - "+_vm._s(b.max)+" ")]:[_vm._v(" "+_vm._s(b.min)+" + ")]],2)})],2)]),_c('tbody',[_vm._l((_vm.relationGroups),function(relationGroup){return [(
							_vm.relationGroupPrices(
								relationGroup.relationGroupId
							).length > 0
						)?_c('tr',{key:relationGroup.relationGroupId},[(_vm.numRelationGroups > 1)?_c('th',[_vm._v(" "+_vm._s(relationGroup.name)+" ")]):_vm._e(),_vm._l((_vm.relationGroupPrices(
								relationGroup.relationGroupId
							)),function(price){return _c('td',{key:`${relationGroup.relationGroupId}-${price.min}`,staticClass:"text-center primary--text",attrs:{"colspan":_vm.priceCellSpan(price)}},[_vm._v(" "+_vm._s(_vm.$format.currency(price.price))+" ")])})],2):_vm._e()]})],2)]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }