<template>
	<div>
		<v-toolbar flat class="my-3" height="36">
			<v-toolbar-title>{{
				$localize('Calculations.title')
			}}</v-toolbar-title>

			<v-spacer></v-spacer>
		</v-toolbar>
		<v-data-table
			:items="calculations"
			:headers="headers"
			:loading="loading"
			:footer-props="{ 'items-per-page-options': [10, 20, 50, -1] }"
			:options.sync="options"
			dense
		>
			<template v-slot:[`item.name`]="{ item }">
				<v-icon v-if="item.icon">{{ item.icon }}</v-icon>
				{{ item.name }}
			</template>
			<template v-slot:[`item.hasStartingCosts`]="{ item }">
				<span v-if="item.startingCosts.length > 0">
					<v-icon class="success--text">mdi-check</v-icon>
				</span>
				<span v-else>
					<v-icon class="error--text">mdi-close</v-icon>
				</span>
			</template>
			<template v-slot:[`item.isEnabled`]="{ item }">
				<span v-show="item.isEnabled">
					<v-icon class="success--text">mdi-check</v-icon>
				</span>
				<span v-show="!item.isEnabled">
					<v-icon class="error--text">mdi-close</v-icon>
				</span>
			</template>
			<template v-slot:[`item.action`]="{ item }">
				<table-toolbar>
					<v-btn
						v-if="item.isEnabled"
						small
						depressed
						class="mr-2"
						@click="disableCalculation(item)"
						>{{ $localize('form.button.disable') }}</v-btn
					>
					<v-btn
						v-else
						small
						depressed
						class="mr-2"
						@click="enableCalculation(item)"
						>{{ $localize('form.button.enable') }}</v-btn
					>
					<v-btn
						small
						depressed
						class="mr-2"
						@click="edit(item.calculationId)"
					>
						{{ $localize('Calculations.editStartingCosts') }}
					</v-btn>
				</table-toolbar>
			</template>
		</v-data-table>
		<edit-calculation-starting-costs
			v-if="editingCalculationStartingCosts"
			v-model="editingCalculationStartingCosts"
			:calculation-id="editingCalculationStartingCostsId"
			@saved="refresh"
		/>
	</div>
</template>

<script>
import EditCalculationStartingCosts from './dialogs/EditCalculationStartingCosts'
import { getAllPublishedCalculations } from '@/services/api'
import sendCommand from '@/services/sendCommand'

export default {
	components: { EditCalculationStartingCosts },
	data() {
		return {
			editingCalculationStartingCosts: false,
			editingCalculationStartingCostsId: null,
			loading: false,
			calculations: [],
			headers: [
				{ text: this.$localize('Calculation.name'), value: 'name' },
				{
					text: this.$localize('Calculation.hasStartingCosts'),
					value: 'hasStartingCosts',
					sortable: false,
					width: 100,
				},
				{
					text: this.$localize('form.label.enabled'),
					value: 'isEnabled',
					width: 100,
				},
				{
					value: 'action',
					sortable: false,
					width: 225,
				},
			],
			options: JSON.parse(
				localStorage.getItem('CalculationsOverview:options')
			) || { itemsPerPage: -1 },
		}
	},
	async created() {
		await this.refresh()
	},
	watch: {
		options() {
			localStorage.setItem(
				'CalculationsOverview:options',
				JSON.stringify(this.options)
			)
		},
	},
	methods: {
		async refresh() {
			this.loading = true
			const { result, ok } = await getAllPublishedCalculations.call()
			if (ok) {
				this.calculations = result
			}
			this.loading = false
		},
		edit(calculationId) {
			this.editingCalculationStartingCostsId = calculationId
			this.editingCalculationStartingCosts = true
		},
		async enableCalculation(calculation) {
			const result = await sendCommand('EnableCalculation', {
				calculationId: calculation.calculationId,
			})
			if (result.success) {
				calculation.isEnabled = true
			}
		},
		async disableCalculation(calculation) {
			const result = await sendCommand('DisableCalculation', {
				calculationId: calculation.calculationId,
			})
			if (result.success) {
				calculation.isEnabled = false
			}
		},
	},
}
</script>
